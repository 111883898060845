import React, { useState, useEffect } from 'react';
import './styles.scss'
import { NotificationManager } from 'react-notifications';
import catchErrors, { ERRORS } from '../../errors'
import SearchModal from '../SearchModal';
import { updatePromotionItemAPI, addItemAssociatedsAPI, deleteItemAssociatedAPI } from '../../server/Promotion/promotionAPI';
import TagSelect, { TagOption } from '../TagSelect';
import { searchAPI, findItemSuggestionsByGtinAPI, findSupliersSugestionsAPI, findSectorsSugestionsAPI } from '../../server/search/searchAPI';
import { AlertDialogWithConfirm, AlertDialog, AskDialog } from '../alertDialog/alertDialog';
import deepProps from '../../Utils';

const EditModal = (props) => { 
   const [isOpen, setIsOpen] = useState(false)
   const [options, setOptions] = useState([])
   const [suggestions, setSuggestions] = useState([])
   const [searching, setSearching] = useState()
   const [searchingMore, setSearchingMore] = useState()
   const [hasMoreResults, setHasMoreResults] = useState()
   const [selectedItemInfos, setSelectedItemInfos] = useState()
   const [data, setData] = useState([])

    useEffect(()=>{
        let {selectedItem} = props
        if(!selectedItem) return
        
        setIsOpen(true)
        let infos = getSelectedTypeInfos(selectedItem)
        setSelectedItemInfos(infos)
        loadParams(selectedItem,infos.insertedData, infos.id)
    }, [props.selectedItem])

    useEffect(()=>{
        if(!data || data.length == 0)return
        if(props.selectedItem){
            setOptions(filterOptions(options, data, selectedItemInfos.filter))
            setSuggestions(filterOptions(suggestions, data, selectedItemInfos.filter))
        }
    },[data])

    const getSelectedTypeInfos = (item) => {
        switch (item.type) {
            case 'PRODUCT':
                return {
                    id: 'GTIN',
                    filter: 'products',
                    optionRender: item => <TagOption title={item.name} img={item.resourceImage} subtitle={item.GTIN}/>,
                    tagRender: item =>  <><h2>{item.GTIN}</h2></>,
                    onAddRequest: (itemId, selectedItem) => addItemAssociatedsAPI(itemId, 'products',[selectedItem.GTIN]),
                    onRemoveRequest: (itemId, selectedItem)  => deleteItemAssociatedAPI(itemId, 'products', selectedItem.GTIN),
                    insertedData: item.products.filter(product => product.eanCode !== item.GTIN).map(product => ({GTIN: product.eanCode, name: 'Produto', ...product}))
                }   
            case 'PROVIDER':
                return {
                    id: 'id',
                    filter: 'supliers',
                    optionRender: item => item.newOption ? <TagOption title={item.name} subtitle={item.GTIN} img={item.resourceImage}/> : <TagOption title={item.name} subtitle={item.id + ' - ' + item.tradingName}/>,
                    tagRender: item => <><h2>{item.id}</h2></>,
                    onAddRequest: (itemId, selectedItem) => addItemAssociatedsAPI(itemId, 'providers',[selectedItem.id]),
                    onRemoveRequest: (itemId, selectedItem)  => deleteItemAssociatedAPI(itemId, 'providers', selectedItem.id),
                    insertedData: item.providers.filter(provider => provider.providerId !== item['providers'][0]['providerId']).map(provider => ({id: provider.providerId, ...provider}))
                } 
            case 'SECTOR':
                return {
                    id: 'sectorId',
                    filter: 'sectors',
                    optionRender: item => <TagOption title={item.description} subtitle={`Nível ${item.level}`} img={item.resourceImage}/>,
                    onAddRequest: (itemId, selectedItem) => addItemAssociatedsAPI(itemId, 'sectors',[[selectedItem.sector1,selectedItem.sector2,selectedItem.sector3,selectedItem.sector4,selectedItem.sector5]]),
                    onRemoveRequest: (itemId, selectedItem)  => deleteItemAssociatedAPI(itemId, 'sectors', selectedItem['sectorId'].split('_').join(',')),
                    tagRender: item => <><h2>{item.sectorId}</h2></>,
                    insertedData: item.sectors.filter(sector => {
                        let id = `${sector.sector1}_${sector.sector2}_${sector.sector3}_${sector.sector4}_${sector.sector5}`
                        return id !== item.sectorId
                    }).map(sector => ({sectorId: Object.values(sector).join('_')}))
                } 
            default:
                break;
        }
    }

    const loadParams = (item, insertedData, id) => {
        setData(insertedData)
        getSuggestions(item, insertedData, id)
    }


    const getSuggestions = (item, insertedData, id) => {
        setSearching(true)
        let suggestionsRequest
        switch (item.type) {
            case 'PRODUCT':
                suggestionsRequest = findItemSuggestionsByGtinAPI(item.GTIN)
                break;
            case 'PROVIDER':
                suggestionsRequest = findSupliersSugestionsAPI(item)
                break;
            case 'SECTOR':
                suggestionsRequest = findSectorsSugestionsAPI(item)
                break;
            default:
                break;
        }
        suggestionsRequest.then(response=>{
            setSuggestions(filterOptions(mapSearchResponse(response), insertedData, id))
        }).catch(e=>{
            catchErrors(e)
            console.log(e)
        }).finally(()=>{
            setSearching(false)
        })
    }

    const onSubmitHandle = async item => {
        try{
            item = validateFields(item)
            await updatePromotionItemAPI(item).then(response=>{
                NotificationManager.success(`${item.description} atualizado com sucesso!`, 'Sucesso!')
                onCloseHandle()
            }).catch(e=>{
                catchErrors(e)
            })
        }catch(e){
            catchErrors(e)
        }
    }

    const addItem = item => {
        selectedItemInfos.onAddRequest(props.selectedItem.id, item).then(response => {
            if(item.level) item['sectorId'] = `${item.sector1}_${item.sector2}_${item.sector3}_${item.sector4}_${item.sector5}`
            setData([...data, item])
            NotificationManager.success('Essa promoção agora é válida para o código selecionado', 'Associado com sucesso!')
        }).catch(e=>{
            catchErrors(e)
        }).finally(()=>{
            setSearching(false)
        })
    }


    const onAddTagHandle = item => {
        if(searching) return

        if (!item || !props.selectedItem)
            return
        let {type} = props.selectedItem
        if (type === 'PRODUCT') {
            let {products} = props.selectedItem || []
            if (products.filter(p => p['eanCode'] === item['GTIN']).length > 0) {
                return NotificationManager.warning('O item em questão já existe na lista', '')
            }
        }
        if(item.level){
            //if it`s an sector addition
            if(!isAllSectorValids(item, deepProps('selectedItem')('sectorId')(props).getOrElse(''))){
                return NotificationManager.warning(`"${props.selectedItem.description}" já inclui o setor selecionado!`)
            }else if(data.find(selectedSector => !isAllSectorValids(item, String(selectedSector.sectorId)))){
                return NotificationManager.warning('Já existe um setor selecionado que inclui essa opção!')
            }
        }
        
        if (item && item['newOption']) {
            AskDialog(
                'Atenção esse produto não foi localizado na base de dados', 
                'Você pode adicioná-lo na promoção clicando em confirmar, mas caso o tipo do produto não estiver conforme ao item de associação, existe a ' +
                'possibilidade do item não receber o desconto configurado.', () => {
                setSearching(true)
                addItem(item)
            }, () => {
                setSearching(false)  
            })
        }else {
            setSearching(true)
            addItem(item)
        }
    }

    const isAllSectorValids = (item, selectedItemSectors = '') => {
        let sectors = [item.sector1,item.sector2,item.sector3,item.sector4,item.sector5].filter(sector => sector !== 0)
 
        console.log('poo:  ', selectedItemSectors);

        selectedItemSectors = selectedItemSectors.split('_').filter(sector => sector !== "0")
        console.log('testeee: ', selectedItemSectors);
        return !(sectors.filter(sector => selectedItemSectors.includes(String(sector))).length > 0)
    }

    const onRemoveItemHandle = (item) => {
        if (!['PENDING', 'CREATING'].includes(props.statusPromotion)) {
            NotificationManager.error('Não é possível remover esse item.', `O item não pode ser removido quando o status da promoção for Publicada ou Expirada`)
            return
        }
        selectedItemInfos.onRemoveRequest(props.selectedItem.id, item).then(response => {
            setData(data.filter(d => d[selectedItemInfos.id] !== item[selectedItemInfos.id]))
            NotificationManager.success('Associado removido com sucesso!', 'Removido!')
        }).catch(e=>{
            catchErrors(e)
        })
    }

    const onSearchHandle = (input, page) => {
        if(!input) return setOptions([])
        
        if(page === 0)
            setSearching(true)
        
        else
            setSearchingMore(true)

        searchAPI(input, selectedItemInfos.filter, page).then(response => {
            let mappedResponse = mapSearchResponse(response)
            if(props.selectedItem.type !== 'SECTOR') {
                let suggest = suggestions.filter(s => !s.newOption)
                suggest.unshift({name:'Nova opção', GTIN:input, id: input, newOption:true, measurementUnit: props.selectedItem.measurementUnit})
                setSuggestions(suggest)
            }
            setHasMoreResults(mappedResponse.length >= 30)
            if(page === 0)
                setOptions(filterOptions(mappedResponse, data, selectedItemInfos.id))
            else{
                setOptions([...options, ...filterOptions(mappedResponse, data, selectedItemInfos.id)])
            }
        }).catch(e=>{
            catchErrors(e)
        }).finally(()=>{
            setSearching(false)
            setSearchingMore(false)
        })
    }

    const filterOptions = (options, alreadyInserted) => {
        switch (props.selectedItem.type) {
            case 'PRODUCT':
                return (options.filter(option => (option['GTIN'] !== props.selectedItem['GTIN'] && option['measurementUnit'] === props.selectedItem['measurementUnit'] && !alreadyInserted.find(dataItem => dataItem['GTIN'] === option['GTIN']))))
            case 'PROVIDER':
                return options.filter(option => option['id'] !== props.selectedItem['providers'][0]['providerId'] && !alreadyInserted.find(dataItem => dataItem['id'] === option['id']))
            case 'SECTOR':
                return options.filter(option => {
                    let id = `${option.sector1}_${option.sector2}_${option.sector3}_${option.sector4}_${option.sector5}`
                    return id !== props.selectedItem['sectorId'] && !alreadyInserted.find(dataItem => dataItem['sectorId'] === id)
                })
            default:
                return options;
        }
    }

    const validateFields = item => {
        if(props.statusPromotion === 'PUBLISHED' && props.selectedItem.quantity > item.quantity)
            throw ERRORS.ITEM_QUANTITY_BELLOW_MIN
        
        return item
    }

    const mapSearchResponse = (response) => {
        return response.map(r => r._source)
    }

    const onCloseHandle = () => {
        resetFields()
        props.onClose && props.onClose()
    }

    const resetFields = () => {
        props.setSelectedItem(undefined)
        setIsOpen(false)
        setOptions([])
        setSelectedItemInfos({})
        setSuggestions([])
        setData([])
    }


    return (
        <SearchModal {...props}
            open={isOpen}
            onSubmit={onSubmitHandle}
            onClose={onCloseHandle}
            editMode
            submitText='Salvar'
            loading={true}>
                <h1 className='title' style={{alignSelf: 'baseline', marginBottom: 8}}>Associados</h1>
                <TagSelect 
                    data={data} 
                    suggestions={suggestions}
                    loading={searching}
                    seeMore={hasMoreResults}
                    searchingMore={searchingMore}
                    options={options} 
                    optionRender={(item)=>selectedItemInfos.optionRender(item)}
                    tagRender={(item)=>selectedItemInfos.tagRender(item)}
                    onSearch={(input, page) => onSearchHandle(input, page)}
                    onAddItem={item => onAddTagHandle(item)}
                    onRemove={onRemoveItemHandle}
                    style={{marginTop: 8}}
                />
        </SearchModal>
    )
}

export default EditModal