import React, { useState, useEffect } from 'react';
import RankingCard from '../../rankingCard/rankingCard';
import { Filter } from 'react-feather';
import { getMeasurementLabel } from '../../../Utils';

export default function PromotionRankingKPI (props){
    const {filter} = props

    const getStatusLabel = status => {
        switch(status){
            case 'NEW': 
                return 'Nova no ranking'
            case 'UP': 
                return 'Subiu de posição'
            case 'DOWN': 
                return 'Caiu de posição'
            default:
                return 'Manteve sua posição'
        }
    }

    return (
        <RankingCard {...props}
            exportColumns={[{label: 'ID', value:'promotion_id'}, {label:'Promoção',value:'description'}, {label:'Quantidade', value:(col)=>{return col.quantity + ' ' + getMeasurementLabel(col.measurement_unit)}}, {label: 'Status',  value: (col) => getStatusLabel(col.status)}]}
            exportName = {'Top 5 de promoções mais usadas - ' + filter.value}
            exportFileName = 'crm_top5_promotions'
        />
    )
}