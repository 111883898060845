import React, { Children } from 'react';
import './style.scss';

const SmartphoneFrame = props => {
    const {children, className, notification, showNotification} = props
	return (
		<main id={'smartphone-frame'} className={className}>
            {notification && <NotificationView title={notification.title} message={notification.message} show={showNotification}/>}
            <div className='content'>
                {children}
            </div>
		</main>
	);
}

const NotificationView = ({title, message, show}) => {
 return(
    <div className={'notification-manager ' + (show ? 'show' : 'hide')}> 
        {/* <div className='icon'></div> */}
        <div>
            <h1>{title}</h1>
            <h2>{message}</h2>
        </div>
    </div>
 )
}

export default SmartphoneFrame;