import React, { Component } from 'react';
import './datepicker.css';

class DatePicker extends Component {
	constructor(props){
		super(props)
		this.state = {
            monthIndex: 0,
            month:[{name: "Janeiro", end:31},
                   {name: "Fevereiro", end:((new Date().getFullYear() % 4 === 0)?29:28)},
                   {name: "Março", end:31},
                   {name: "Abril", end:30},
                   {name: "Maio", end:31},
                   {name: "Junho", end:30},
                   {name: "Julho", end:31},
                   {name: "Agosto", end:31},
                   {name: "Setembro", end:30},
                   {name: "Outubro", end:31},
                   {name: "Novembro", end:30}, 
                   {name: "Dezembro", end:31}],
            week:[
                {
                    name:'Dom', text:'Domingo', index:1
                },{
                    name:'Seg', text:'Segunda-Feira', index:2
                },{
                    name:'Ter', text:'Terça-Feira', index:3
                },{
                    name:'Qua', text:'Quarta-Feira', index:4
                },{
                    name:'Qui', text:'Quinta-Feira', index:5
                },{
                    name:'Sex', text:'Sexta-Feira', index:6
                },{
                    name:'Sáb', text:'Sábado', index:7
                }
            ],
            header: [],
            calendar: [],
            selecteds:[{month: 9, day: 6, year: 2018, dayType: "WEEK"}],
			dateStart: '',
			dateEnd: ''
        }
        this.setDatePickerHeader = this.setDatePickerHeader.bind(this)
        this.getDatePickerDays = this.getDatePickerDays.bind(this)
        this.nextMonth = this.nextMonth.bind(this)
        this.prevMonth = this.prevMonth.bind(this)
        this.getDate = this.getDate.bind(this)
        this.getDay = this.getDay.bind(this)
        this.getYear = this.getYear.bind(this)
	}
    
    componentDidMount(){
		this.initComponent()
	}
	
	initComponent(){
		let INDEX_MONTH_START = (this.props.dateStart.substring(5, 7)) -1
		if (isNaN(INDEX_MONTH_START))
			INDEX_MONTH_START = 0
        this.setState({monthIndex: INDEX_MONTH_START, dateStart: this.props.dateStart, dateEnd: this.props.dateEnd, exceptionDay: this.props.exceptionDay}, ()=>{
			this.mapExceptions()			
			this.setState({year: new Date(new Date().setMonth(this.state.monthIndex)).getFullYear()}, ()=>{
				this.setDatePickerHeader()
				this.getDatePickerDays() 
			})
		})
	}

	mapExceptions(callback=()=>{}){
		let exceptionWeek = [], exceptionMonth = []
		let exceptionDay = this.state.exceptionDay
		if (exceptionDay === undefined)
			exceptionDay = []
		exceptionDay.map((day)=>{
			if(day.dayType === 'MONTH'){
				exceptionMonth.push(day.day)
			} else {
				exceptionWeek.push(day.day)
			}
			return day
		})
		this.setState({exceptionMonth, exceptionWeek},()=>{
			callback()
		})
	}

    getDayOfWeek(day) {
        let obj = this.state.week[day]
        if (obj === null)
            return null
        return obj.text
    }

    setDatePickerHeader(){
        let header = []
        for(let weekDay of this.state.week){
			let dayClass = (this.isExceptionDay(weekDay.index, true) ? 'weekLabel-exception' : 'weekLabel')
            header.push(<li key={'WEEK' + weekDay.name} className={dayClass} value={weekDay.index} id={weekDay.text} onDoubleClick={(e)=>{this.getDay(e)}}><h1>{weekDay.name}</h1></li>)
        }
        this.setState({header:header})
	}
	
	getWeekLi (weekDay) {
		let li = React.createElement('li', {key:`wd${weekDay}`, className:'day-inactive'})
		// let h1 = React.createElement('h1', {key:`liwd${weekDay}`})
		// return <li key={`wd${weekDay}`} className="day-inactive"><h1 key={`liwd${weekDay}`}></h1></li>
		return li
	}
	
    getDatePickerDays() {
        let m = new Date()
        let i = Math.abs(this.state.monthIndex%12)
        m.setMonth(i)
        m.setDate(1)
        let days = []
        let weekDay = 0
        let day = 1
        while(day<=this.state.month[(i)].end){
            if(weekDay < m.getDay()) {
                days.push(this.getWeekLi(weekDay))
                ++weekDay
                continue
            }
			let dayClass = this.getDayClass(day)
            days.push(<li className={`day ${dayClass}`} value={day} key={day} onDoubleClick={(e)=>{this.getDate(e)}}><h1>{day}</h1></li>)
            ++day
            
        }
		this.setState({calendar:days})
    }
    
	getDayClass(day){
		if (this.isSameDay(day))
			return 'same-day'

		if (this.isStartDay(day)) {
			// console.log('is start day ' + day)
			return 'start-day'
		}
		
		if (this.isEndDay(day))
			return 'end-day'

		if (this.isDayBetween(day)) {
			let dayClass = ''
			if(day === 1)
				dayClass =  'top-left '
			if(this.isExceptionDay(day)){
				return dayClass + 'exception-day'
			}
			if(this.state.exceptionDay.length <= 0){
				return dayClass + 'between-days'
			}
			return dayClass + 'between-days exception'
		}
	}

	fill = (str, c, length) => {
		while (str.length < length)
			str = `${c}${str}`
		return str
	}

	isSameDay(day){
		let month = ((this.state.monthIndex % 12) + 1)
		let startDay = this.state.dateStart.substring(8, 10)
		let startMonth = this.state.dateStart.substring(5, 7)
		let startYear = this.state.dateStart.substring(0, 4)

		let endDay = this.state.dateEnd.substring(8, 10)
		let endMonth = this.state.dateEnd.substring(5, 7)
		let endYear = this.state.dateEnd.substring(0, 4)
		let d = this.fill(`${day}`, '0', 2)
		month = this.fill(`${month}`, '0', 2)
		let {year} = this.state
		year = this.fill(`${year}`, '0', 2)
		//console.log(`${d}|${month}|${year}>>${startDay}|${startMonth}|${startYear}<<${endDay}|${endMonth}|${endYear}`)
		//console.log(`${typeof(d)}|${typeof(month)}|${typeof(year)}>>${typeof(startDay)}|${typeof(startMonth)}|${typeof(startYear)}<<${typeof(endDay)}|${typeof(endMonth)}|${typeof(endYear)}`)
		let result = (d === startDay && d === endDay && 
			month === startMonth && month === endMonth && 
			year === startYear && year === endYear)
		// alert('is the same day ' + result)
		return result
	}

	isStartDay(day) {
		let {year} = this.state
		let month = ((this.state.monthIndex % 12) + 1)
		let startDay = this.state.dateStart.substring(8, 10)
		let startMonth = this.state.dateStart.substring(5, 7)
		let startYear = this.state.dateStart.substring(0, 4)
		let d = this.fill(`${day}`, '0', 2)
		month = this.fill(`${month}`, '0', 2)
		year = this.fill(`${year}`, '0', 2)
		// console.log(`${d}|${month}|${year}>>${startDay}|${startMonth}|${startYear}`)
		// console.log(`${typeof(d)}|${typeof(month)}|${typeof(year)}>>${typeof(startDay)}|${typeof(startMonth)}|${typeof(startYear)}`)
		return (d === startDay && startMonth === month && startYear === year)
	}

	isEndDay(day) {
		let {year} = this.state
		let month = ((this.state.monthIndex % 12) + 1)
		let endDay = this.state.dateEnd.substring(8, 10)
		let endMonth = this.state.dateEnd.substring(5, 7)
		let endYear = this.state.dateEnd.substring(0, 4)
		let d = this.fill(`${day}`, '0', 2)
		month = this.fill(`${month}`, '0', 2)
		year = this.fill(`${year}`, '0', 2)
		return (d === endDay && endMonth === month && endYear === year)
	}

	isDayBetween(day, week){
		return (this.isBeforeEnd(day, week) && this.isAfterStart(day, week))
	}

	isAfterStart(day, week) {
		let {year} = this.state
		let month = ((this.state.monthIndex % 12) + 1)
		let startDay = parseInt(this.state.dateStart.substring(8, 10))
		let startMonth = parseInt(this.state.dateStart.substring(5, 7))
		let startYear = parseInt(this.state.dateStart.substring(0, 4))

		if (year > startYear)
			return true

		if (month > startMonth)
			return true

		if (month === startMonth) {
			if(week)
				return true
			return (day > startDay)
		}

		return false		
	}

	isBeforeEnd(day, week){
		let {year} = this.state
		let month = ((this.state.monthIndex % 12) + 1)
		let endDay = parseInt(this.state.dateEnd.substring(8, 10))
		let endMonth = parseInt(this.state.dateEnd.substring(5, 7))
		let endYear = parseInt(this.state.dateEnd.substring(0, 4))

		if (year < endYear)
			return true

		if (month < endMonth)
			return true

		if (month === endMonth){
			if(week)
				return true
			return (day < endDay)
		}

		return false	
	}

	isExceptionDay(day, week){
		let exceptions
		if(week){
			exceptions = this.state.exceptionWeek
		}else{
			exceptions = this.state.exceptionMonth
		}
		if (!exceptions)
			return
		if(exceptions.includes(day)){
			return this.isDayBetween(day, true)
		}
	}

    nextMonth(){
        this.setState({monthIndex:(this.state.monthIndex+1)}, ()=>{
			this.setState({year: new Date(new Date().setMonth(this.state.monthIndex)).getFullYear()},()=>{
				this.setDatePickerHeader() 
				this.getDatePickerDays()
			})
		})
    }
    
    prevMonth(){
        this.setState({monthIndex:(this.state.monthIndex-1)}, ()=>{
			this.setState({year: new Date(new Date().setMonth(this.state.monthIndex)).getFullYear()}, ()=>{
				this.setDatePickerHeader() 
				this.getDatePickerDays()
			})
		})
    }
    
    getYear(){
        return new Date(new Date().setMonth(this.state.monthIndex)).getFullYear()
    }
    
    getDay(target){
        let date = {}
        date['month'] = Math.abs(this.state.monthIndex % 12)
        date['day'] = (target.currentTarget.value)
        date['year'] = (this.getYear())
        date['dayType'] = 'WEEK'
        if(this.props.onClick){
            this.props.onClick(target.currentTarget.id, date)
        }
    }
    
    getDate(target){
        let date = {}
        let response = new Date()
        date['month'] = Math.abs(this.state.monthIndex % 12) + 1
        date['day'] = (target.currentTarget.value)
        date['year'] = (this.getYear())
        date['dayType'] = 'MONTH'
        date['date'] = response
        response.setMonth(this.state.monthIndex)
        response.setDate(target.currentTarget.value)
        if(this.props.onClick){
            this.props.onClick('Dia ' + date['day'], date)
        }
	}
	
	componentDidUpdate(prevProps){
		if(prevProps.dateStart !== this.props.dateStart){
			this.setState({dateStart: this.props.dateStart}, ()=>{
				this.getDatePickerDays()
			})
			// return null;
		} 

		if (prevProps.dateEnd !== this.props.dateEnd){
			this.setState({dateEnd: this.props.dateEnd}, ()=>{
				this.getDatePickerDays()
			})
			// return null;
		}

		if (prevProps.exceptionDay !== this.props.exceptionDay){
			this.setState({exceptionDay: this.props.exceptionDay}, ()=>{
				this.mapExceptions(()=>{
					this.getDatePickerDays()
					this.setDatePickerHeader()
				}) 
			})
			return null;
		}
	}
    

	render() {
		let {month, monthIndex, year} = this.state
		return (
			<div className="DatePicker" style={this.props.style}>
				<div className='DatePicker-Calendar'>
                    <div className='selector'>
                        <div className='button' id="prev-month-datePicker" onClick={()=>{this.prevMonth(monthIndex)}}>{'<'}</div>
                        <div className='monthLabel'>{(month[Math.abs(monthIndex % 12)].name) + ' - ' + year}</div>
                        <div className='button' onClick={()=>{this.nextMonth(monthIndex)}}>{'>'}</div>
                    </div>
                    <ul>
                        {this.state.header}
                        {this.state.calendar}
                    </ul>
                </div>
			</div>
		);
	}
}

export default DatePicker;