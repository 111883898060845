import React, { Component } from 'react';
import CardTabProduct from '../../screen/cardTabProduct/cardTabProduct';
import AsideProduct from '../../screen/asideProduct/asideProduct';
import './promotion.css';
import deepProps from '../../Utils'
import { Prompt } from 'react-router-dom';

export default class Promotion extends Component {
    constructor(props){
        super(props)
        this.state ={
            promotion:{
                id:0,
                desciption: 'Nova promoção',
                resourceImage: '',
                status: 'CREATING',
                dateStart: new Date(),
                dateEnd: new Date(),
                rules:'A promoção ainda não possui uma regra cadastrada',
                value: '0.0',
                typeValue: 'BY_VALUE',
                segmentationType: 'PUBLIC',
                stores: []
            },
            shouldBlock: false
        }
        this.init = this.init.bind(this)

        window.addEventListener('beforeunload', this.preventQuit);

        window.addEventListener('locationchange', function(){
            console.log('location changed!');
        })
    }
    
    componentWillMount(){
        this.setState({searching: true})
        this.init(this.props.store.getState())
        this.storeSubscribe = this.props.store.subscribe(() => {
            this.init(this.props.store.getState())
        })
        let permissions = deepProps('permissions')(this.props.store.getState()).getOrElse([])
        let permission = undefined
        for (let p of permissions) {
            if (deepProps('name')(p).get() === 'PROMOTION') {
                permission = p
                break
            }
        }
        let write = false
        let read = false
        if (permission !== undefined) {
            let array = deepProps('allow')(permission).getOrElse([])
            for (let allow of array) {
                if (allow === 'R') {
                    read = true
                    continue
                }
                if (allow === 'W') {
                    write = true
                }
            }

        }
        this.setState({write, read})
        this.preventQuit()
    }

    componentWillUnmount () {
        if (this.storeSubscribe === undefined)
            return
        try {
            this.storeSubscribe()
        }catch(e){
            console.error(e)
        }
        window.removeEventListener('beforeunload', this.preventQuit);

    }

    preventQuit(event){
        if(event){
            event.preventDefault();
            event.returnValue = '';
        }
    }
    
    loadPromotion(promotion) {
        if (promotion == null)
            return
        console.log('aqui:  ', promotion)
        let items = deepProps('items')(promotion).getOrElse([])
        let resourceImage
        let itemsThatHasImage = items.filter((item) => item.resourceImage != null && item.resourceImage.length > 0)
        if (itemsThatHasImage.length > 0)
            resourceImage = itemsThatHasImage[0].resourceImage
        for (let item of items) {
            if (item.resourceImage) {
                resourceImage = item.resourceImage
                break
            }
        }
            
        promotion['resourceImage'] = resourceImage
        this.setState({promotion, searching: false})
    }

    init (lastState) {
        let promotion = null
        switch(lastState.type) {
            case 'PROMOTION_DETAILS':
            case 'PROMOTION_ADDED':
            case 'PROMOTION_UPDATED':
                promotion = deepProps('promotionDetails')(lastState).getOrElse(null)
                break
            case 'CHANGE_STATUS_PROMOTION':
                promotion = deepProps('promotionDetails')(lastState).getOrElse(null)
                if (promotion != null) {
                    let promotionId = deepProps('id')(promotion).getOrElse(-1)
                    let promotioThatStatusChanged = deepProps('promotioThatStatusChanged')(lastState).getOrElse(0)
                    if (promotionId === promotioThatStatusChanged)
                        promotion['status'] = deepProps('promotionStatus')(lastState).getOrElse('UN_KNOW')
                }
                break
            case 'REDEEM_TYPE_CHANGE':
                if(!['MANUAL', 'ON_SEGMENT'].includes(deepProps('promotionDetails', 'redeemType')(lastState).getOrElse(null)))
                    return

                promotion = this.state.promotion

                promotion['redeemType'] = promotion['redeemType'] === 'MANUAL' ? 'ON_SEGMENT' : 'MANUAL'
                this.setState({promotion})
                return
            default:
                break
        }

        this.loadPromotion(promotion)
    }
    render() {
        let {promotion, write, read} = this.state
        let id = 0
        if (promotion !== undefined)
            id = promotion.id
        return (
            <section className="section">
                <Prompt when={true} message={location =>`É possível que as alterações feitas não sejam salvas.`}/>
                <AsideProduct promotion={promotion} store={this.props.store} write={write} read = {read}/>
                <CardTabProduct promotion={promotion} tabItens={this.props.tabItens} store={this.props.store} promotionId={id} write={this.state.write} read = {read} />
            </section>
        );
    }
}