import React, { useState, useEffect } from 'react';
import './style.scss'
import Card from '../../card';
import BaseAreaChart from '../../charts/areaChart';
import KPIInfo from '../info';
import deepProps, { getIncrease } from '../../../Utils';
import Tooltip from '../tooltip';
import ExportKPIData from '../exportKPIData'

export default function CustomerKPI (props){
    const {data, detailed, compare, filter, tooltipSubtitle, loading} = props
    const [amount, setAmount] = useState(0)
    const [pastAmount, setPastAmount] = useState(0)
    const [dataChart, setDataChart] = useState([])
    const CUSTOMERS_LINES = [{label:'Novos clientes',value:'customers_c'}]
    const FULL_CUSTOMERS_LINES = [...CUSTOMERS_LINES, {label:'Novos clientes (Período passado)', value:'customers_p'}]

    useEffect(()=>{
        try{
            setDataChart(joinCustomersPeriod(data, 'dateReference', 'customers'))
        }catch{
            setDataChart([{quantity: 0}])
        }

        setAmount(deepProps('current', 'general', 'quantity')(data).getOrElse(0))
        setPastAmount(deepProps('past', 'general', 'quantity')(data).getOrElse(0))
    },[data])

    const joinCustomersPeriod = (data, unionKey, label) => {
        let {current, past} = data
        past = past ? past.dates : []
        current = current ? current.dates : [] 
  
        let chartData = current.map((currentData)=>{
          let foundIndex = past.findIndex(pastData=> pastData[unionKey]===currentData[unionKey])
          return {[unionKey]: currentData[unionKey], [`${label}_c`]: currentData.quantity, [`${label}_p`]: (foundIndex != -1) ? past.splice(foundIndex, 1)[0].quantity : 0}
        })
  
        past = past.map(item => ({[unionKey]: item[unionKey], [`${label}_p`]: item.quantity, [`${label}_c`]: 0}))
        chartData = [...chartData, ...past]
        return getDataSortByDateReference(chartData)
    }

    const getDataSortByDateReference = (data) => data.sort((a,b)=>a.dateReference - b.dateReference)

    const getDataTip = (increase, past) => {
        if(increase === 0) return `Igual à ${past}`
        return formatIncrease(increase) + " a " + (increase > 0 ? 'mais' : 'menos') + ' que ' + past
    }

    const formatIncrease = increase => ((increase && increase !== Infinity) ?  Math.abs(increase.toFixed(1)) : 0) + '%'

    return (
        <Card style={{width: 260, minWidth:260, padding: 0, overflow: 'hidden', flex: 1}} className='customerKPI'
            header={{
                title:'Novos clientes',
                actions:[
                    <ExportKPIData data={dataChart} columns={compare ? FULL_CUSTOMERS_LINES : CUSTOMERS_LINES} filter={filter} fileName={'crm_customers_report'} name={'Novos clientes'}/>
                ]
            }}>
            <div style={{display:'flex', justifyContent: 'center', flex: 1, paddingBottom: 16, paddingTop: 16}}>
                 <KPIInfo title='Novos clientes' value={amount} loading={loading} increase={compare && getIncrease(pastAmount, amount)} color="#97e000" increaseTip={getDataTip(getIncrease(pastAmount, amount), '1 ' + filter.text + ' atrás')}/>
                {compare &&  <KPIInfo title={'Novos clientes'} loading={loading}  past={'1 ' + filter.text + ' atrás'} value={pastAmount} increase={false} color="#2196f3" increaseTip={getDataTip(getIncrease(pastAmount, amount), '1 ' + filter.text + ' atrás')}/>}
             </div>
             <BaseAreaChart hideX={!detailed} hideY={!detailed} hideGrid ={!detailed} x={'dateReference'} lines={compare ? FULL_CUSTOMERS_LINES : CUSTOMERS_LINES} 
             data={dataChart} colors={["#97e000", "#2196f3"]} customTooltip={<Tooltip title="Novos Clientes" subtitle={tooltipSubtitle} labels={{customers_c: 'Novos clientes', customers_p: '1 ' + filter.text + ' atrás'}}/>}/>
        </Card>
    )
}