import React, { Component } from 'react';
import CardTabStore from '../../screen/cardTabStore/cardTabStore';
import AsideStore from '../../screen/asideStore/asideStore';
import './store.css';
import deepProps from '../../Utils'
import StoreApi from '../../api/StoreApi';
import { AlertDialog } from '../../component/alertDialog/alertDialog';
import LoadingMessage from '../../component/LoadingMessage/loadingMessage';

export default class Promotion extends Component {
    constructor(props) {
		super(props);
		this.state = {
			tabIndex: 1,
			store: {
				description : '',
				number:0,
				id:0
			},
			card3:[]
		};
		this._changeTab = this._changeTab.bind(this);
	}


   _changeTab(index){
	 this.setState({tabIndex:index});
	}

	componentDidMount() {
		//this.setState({searching: true})
        this.unsubscribe = this.props.store.subscribe(() => {
			this.loadData()
        })
		let token = deepProps('token')(this.props.store.getState()).getOrElse(localStorage.getItem('token'))
		let logout = token === undefined
		this.loadData()
        let permissions = deepProps('permissions')(this.props.store.getState()).getOrElse([])
        let permission = undefined
        for (let p of permissions) {
            if (deepProps('name')(p).get() === 'STORE') {
                permission = p
                break
            }
        }
        let write = false
        let read = false
        if (permission !== undefined) {
            let array = deepProps('allow')(permission).getOrElse([])
            for (let allow of array) {
                if (allow === 'R') {
                    read = true
                    continue
                }
                if (allow === 'W') {
                    write = true
                }
            }

        }
		this.setState({token, logout, write, read})
	}

	componentWillUnmount () {
		this.unsubscribe()
	}

	loadData() {
		let lastState = this.props.store.getState()
		let token = deepProps('token')(lastState).getOrElse('')
		let store = deepProps('storeDetail')(lastState).getOrElse({id:0})
		this.setState({store})
		switch(lastState.type) {
			case 'STORE_DETAILS':
				if (store === undefined)
					return
				this.setState({store})
				let storeNumber = deepProps('number')(store).getOrElse(0)
				if (storeNumber > 0) {
					this.props.store.dispatch(StoreApi.findStoresAddress(token, storeNumber, () => {}, (erroMessage) => {
						AlertDialog('Ops, algo deu errado', erroMessage)
						this.setState({searching: false})
					}))
				}else {
					this.setState({card3:[]})
				}
			break
			case 'SEARCH_STORE_ADDRESS':
				let address = deepProps('storeAddress')(lastState).get()
				if (address === undefined)
					return
				let addresses = []
				addresses.push(address)
				let obj = this.addressToString(address)
				let card3 = []
				card3.push({title:'', data: obj})
				this.setState({card3, addresses, searching: false})
			break
			default:
				this.setState({searching: false})
				break
		}
	}

	addressToString(address) {
		if (address === undefined)
			return ''
		let streetType = deepProps('streetType')(address).get()
		let street = deepProps('street')(address).getOrElse('')
		if (streetType !== undefined && streetType.length > 0)
			street = streetType + ' ' + street
		let number = deepProps('number')(address).getOrElse(0)
		let complement = deepProps('complement')(address).get()
		if (complement !== undefined && complement.length > 0)
			number = number + ' ' + complement
		let zipCode = deepProps('zipCode')(address).getOrElse('000000000')
		let district = deepProps('district')(address).get()
		if (district !== undefined)
			zipCode = zipCode + ' - ' + district
		let data = street + ', ' + number + ' ' + zipCode
		return data
    }
	
    render() {
        return (
            <section className="section">
				{this.state.searching && <LoadingMessage title='Pesquisando...' description='Aguarde, estamos buscando lojas!' />}
                {this.state.store && <AsideStore data={this.state.store} store={this.props.store} card3={this.state.card3} write={this.state.write} read={this.state.read}/>}
                {this.state.store && <CardTabStore tabItens={this.props.tabItens} data={this.state.store}  write={this.state.write} read={this.state.read} store={this.props.store} address={this.state.addresses}/>}
            </section>
        );
    }
}