import Compressor from 'compressorjs'

let fileName = ''
const MIN_DIMENSION = 200

const blobToFile = (blob, name) => {
    const fileExtension = name.split(".")[name.split(".").length - 1]
    let newFile = new File([blob], name, {type:`image/${fileExtension}`})
    return newFile
}

const imgCompress = async (file, sizeRestriction = 700000, dimensionRestriction = 200) => {
    if(file == null) return
    
    if(file.name){
        fileName = file.name
    }
    if(file.size <= sizeRestriction){
        return blobToFile(file, fileName)
    } else {
        const compressedFile = await compressor(file, 1, dimensionRestriction)
       if(compressedFile.size <= sizeRestriction){
           return compressedFile
       } else {
           let newDimension = dimensionRestriction
           let iteratedCompressedFile = await compressor(file, 0.5, newDimension) 

           while(iteratedCompressedFile.size > sizeRestriction && newDimension > MIN_DIMENSION){
                newDimension *= 0.9
                iteratedCompressedFile = await compressor(file, 0.5, newDimension) 
            }

            return iteratedCompressedFile

       }
    }
}

const compressor = (file, quality, width=null) => {
    return new Promise ( (resolve, reject) => {
        new Compressor(file, {
            quality: quality,
            success : result => {
                resolve(result)
            },
            error(err) {
                console.log(err.message);
            },
            width: width
        })
    }).then(cmp => {
        return blobToFile(cmp, fileName)
    })
}

export default imgCompress