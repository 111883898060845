import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import './TabContainer.css';

class TabContainer extends Component {
		constructor(props) {
		super(props);
		this.state = {
			tabIndex: 1,
            url: this.props.from + '/' + this.props.to + '/'
		};
		this._changeTab = this._changeTab.bind(this);
	}


   _changeTab(index){
	 this.setState({tabIndex:index});
 	}
    
    componentDidMount(){
    }

	render() {
		return (
			<Router>
				<div  className="cardTabProduct">
					<ul className="tabScreen-header">
                        {this.props.tabs.map((tab)=>{
                            return(<li className="option-cardTabProduct" >
                                <NavLink key={tab.index} to={this.state.url + tab.name} activeClassName="activeTab"> {tab.title} </NavLink>
                            </li>)
                        })}
					</ul>

					<div className="route-promotion">
                        {this.props.tabs.map((tab)=>{
                            return(<li className="option-cardTabProduct" >
                                <Route path={this.state.url + tab.name} component={tab.screen} />
                            </li>)
                        })}				
					</div>
				</div>
			</Router>
		);
	}
}

export default TabContainer;