import { HighlightEP } from "../endpoints_js"
import { getFetchHeader, makeRequest } from "../../api/UtilsAPI"

export const getHighlightItemsListAPI = async() => makeRequest(HighlightEP.getHighlightItemsList())

export const highlightPromotionItemAPI = async(id) => makeRequest(HighlightEP.highlightPromotionItem(id), getFetchHeader('POST'))

export const unhighlightPromotionItemAPI = async(id) => makeRequest(HighlightEP.unhighlightPromotionItem(id), getFetchHeader('POST'))

export const reorderHighlightedItemAPI = async  (itemID, position) => makeRequest(HighlightEP.reorderHighlightedItem(itemID), getFetchHeader('POST', {position}))

export const syncHighlightListAPI = async  () => makeRequest(HighlightEP.syncHighlightList(), getFetchHeader('POST'))