import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Loading } from '../LoadingMessage/loadingMessage';
import ImageAvatar from '../image-avatar/image-avatar';
import { EmptyColorListMessage } from '../ColorList/ColorList';
import Button from '../Button/button';

const TagSelect = (props) => {
    const [hoverItem, setHoverItem] = useState(0)
    const [page, setPage] = useState(0)
    const [input, setInput] = useState()
    const [timer, setTimer] = useState()
    const {onAddItem, onSearch} = props
    const TIMER = 700

    const onSelectOptionHandle = (option) => {
        onAddItem(option)
        setInput('')
    }

    useEffect(()=>{
        if(!input) return
        clearTimeout(timer)
        setTimer(setTimeout(()=>{
            onSearch(input, 0)
            setPage(0)
        }, TIMER))
    }, [input])

    const onRemoveHandle = (item) => {
        onRemove && onRemove(item)
    }

    const onSeeMoreHandle = () => {
        setPage(page+1)
        onSearch(input, page+1)
    }

    let { data, options, suggestions, style, loading, optionRender, tagRender, onRemove, seeMore, searchingMore} = props

    return(
        <div className='tag-select-container' style={style}>
            <div className='tag-select-content'>
                {!tagRender && data.map(data => (
                    <span key={data.id} o className='selected-tag'>
                        <div className='content'>
                            <h1>{data.title}</h1>
                        </div>
                        <div className='delete-action' onClick={onRemoveHandle}>
                            x
                        </div>
                    </span>
                ))}
                {tagRender && data && data.map((data, index) => (
                    <span key={index} className='selected-tag'>
                        <div className='content'>
                            {tagRender(data)}
                        </div>
                        <div className='delete-action' onClick={()=>onRemoveHandle(data)}>
                            x
                        </div>
                    </span>
                ))}
                <input type='text' value={input} placeholder='Digite aqui...' onChange={e=>setInput(e.target.value)}/>
            </div>
            <ul className='options-container'>
                {(!loading && (options || suggestions)) && [{title: 'Sugestões', data:suggestions}, {title: 'Resultados encontrados', data:options}].map((section, index) => (
                    <div key={index}>
                        {section.data && section.data.length > 0 && <h1 className='section-title'>{section.title}</h1>}
                        {(!optionRender) && section.data && section.data.map((option, index) => (
                            <li key={index} className={`tag-option ${(hoverItem === index) ? 'hover' : ''}`} onClick={()=>onSelectOptionHandle(option, index)} >
                                <p>{option.title}</p>
                            </li>
                        ))}
                        {(optionRender) && section.data.map((option, index) => (
                            <li key={index} className={`tag-option ${(hoverItem === index) ? 'hover' : ''}`} onClick={()=>onSelectOptionHandle(option, index)} >
                                {optionRender(option)}
                            </li>))}
                    </div>
                    
                ))}
                {(seeMore && !loading && options && options.length > 0) && <Button textOnly text={searchingMore ? 'carregando...': 'ver mais'} onClick={onSeeMoreHandle}/>}
                {!loading && (options && suggestions) && (suggestions.length == 0 && options.length == 0) && <EmptyColorListMessage title='Nenhum resultado encontrado' subtitle='Pesquise acima os resultados disponíveis'/>}
                {loading && <Loading style={{width: 21, padding: 8}}/>}
            </ul>
        </div>
    )
};


export function TagOption(props) {

    const {title, subtitle, img} = props

    return (
        <div className={"tag-option-container"}>
            <ImageAvatar img={img} title={title} size='small' style={{marginRight: 8}}/>
            <div className='info'>
                <h1>{title}</h1>
                <h2>{subtitle}</h2>
            </div>
            <div>

            </div>
        </div>
    );
}


export default TagSelect;
