import React, {Component} from 'react';
import {AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Area} from 'recharts';
import deepProps from '../../Utils';
import './lineChart.css';

export default  class LineCharts extends Component {
	constructor(props) {
     super(props)
		this.state = {
			data: deepProps('props','data')(this).getOrElse([{}])
		}
	}

render () {
  	return (
		  <div className={this.props.border ? 'chartContainer border' : 'chartContainer shadow'}>
				<div className= 'chartContent'>
					{this.props.title && <h1>{this.props.title}</h1>}
					<div className="areaChart">
							<AreaChart data={this.props.data.data} width={this.props.width} height={this.props.height}>
								<CartesianGrid strokeDasharray="2 2"/>
								<XAxis dataKey={this.props.data.x.key} stroke='black' />
								<YAxis/>
								<Tooltip/>
									{this.props.data.labels.map((label, index)=>
										<Area type='monotone' key={index} dataKey={label.label} stroke={label.stroke} fill={'transparent'} strokeWidth={2}/>
									)}
								<Legend/>
							</AreaChart>
					</div>
			</div>
		</div>
    );
  }
}