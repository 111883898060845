export const getIdentifiedSalesQuery = (stores = [], filter = 'DAY', groupBy = 'DATE', offset = 0) => {
    return `identified_sales: identified_sales(stores: ${stores}, filter: ${filter}, group_by: ${groupBy}, offset: ${offset}) {
            current {
              general{
                quantity
              }
              ...dates
            }
            past {
              general{
                quantity
              }
              ...dates
            }
          }`

}

const getDateFragment = () => (
    `
    fragment salesDates on CountResult{
      dates {
          dateReference
          quantity
      }
    }
  `)

export const getDashboardQuery = (filter = 'WEEK', offset = '1', groupBy = 'DAY', stores = []) => {
    return {query:`${getDateFragment()} query d {
      identifiedSales: getSales(stores: [], filter: ${filter}, group_by: ${groupBy}, offset: ${offset}, slice: true, identified: true) {
        current {
          general{
            quantity
          }
          #...stores
          ...salesDates
          reference
        }
       past {
         general{
           quantity
         }
         #...stores
         ...salesDates
         reference
        }
      }
        
      unidentifiedSales: getSales(stores: [], filter: ${filter}, group_by: ${groupBy}, offset: ${offset}, slice: true, identified: false) {
        current {
          general{
            quantity
          }
          #...stores
          ...salesDates
        }
        past {
          general{
            quantity
          }
          #...stores
          ...salesDates
        }
      }

      newCustomers: newCustomers(filter: ${filter}, group_by: ${groupBy}, offset: ${offset}, slice:true) {
        current {
          general{
            quantity
          }
          #...stores
          ...salesDates
          reference
        }
        past {
          general{
            quantity
          }
          #...stores
          ...salesDates
          reference
        }
      }

      topPromotions: promotionRanking(filter: ${filter}, offset: 1, size:5) {
        current {
            general {
                quantity
            }
            promotions {
                promotion_id
                description
                quantity
                status
                position_change
                measurement_unit
          }
        }
        filter
      }
    }`
  }
}