export const getAllChurnQuery = (stores = [], offset = 0) => {
    return `churn: customerChurn(offset:${offset}, page:1, size:3, store: 3) {
    current {
        customers {
            customerId,
            lastSale,
            churnPrediction
        }
        count,
        page,
        size
    },
    past {
        customers {
            customerId,
            lastSale,
            churnPrediction
        }
    }
    }
    churnByStore: churnStoreRanking(offset: 0, page:0, size: 3) {
        current {
            data {
                store,
                churnquantity
            }
        }
    }
    churnAndRecovered: customerRecoveredAndChurn(offset:0) {
        current {
            dropout,
            recovered,
            reference
        }
    }`
}

export const getChurnQuery = (store, page = 0,size = 10, offset = 0) => {
    return `query {
            churn: customerChurn(offset:${offset}, page:${page}, size:${size}, store: ${store}) {
            current {
                customers {
                    customerId,
                    lastSale,
                    churnPrediction
                }
                count,
                page,
                size
            },
            past {
                customers {
                    customerId,
                    lastSale,
                    churnPrediction
                }
            }
        }
    }`
}

export const getChurnByStoreQuery = (page = 0, size = 7) => {
    return `query {
        churnByStore: churnStoreRanking(offset: 0, page:${page}, size: ${size}) {
                current {
                    data {
                        store,
                        churnquantity
                    }
                }
            }
        }`
}

export const getChurnAndRecoveredQuery = (stores = [], offset = 0) => {
    return `query {
        churnAndRecovered: customerRecoveredAndChurn(offset:0) {
            current {
                dropout,
                recovered,
                reference
            }
        }
    }`
}

export const getChurnReportQuery = () => {
    return (
        `query {
            export: customerChurnExport(offset:0, file: CSV){
                data
            }
        }`
    )
}

export const getChurnAndRecoverMock = () => ({
    
})

export const getChurnMock = {
    all: {
        data: {
            churn: {
                current: {
                    customers: [
                        {
                            customerId: 2414,
                            lastSale: "11-01-2020",
                            churnPrediction: 0.78196519613266
                        },
                        {
                            customerId: 10289,
                            lastSale: "04-01-2020",
                            churnPrediction: 0.672111928462982
                        },
                        {
                            customerId: 5802,
                            lastSale: "05-01-2020",
                            churnPrediction: 0.786353230476379
                        }
                    ],
                    count: 68,
                    page: 0,
                    size: 3
                },
                past: {
                    customers: [
                        {
                            customerId: 16581,
                            lastSale: "01-12-2019",
                            churnPrediction: 0.563601016998291
                        },
                        {
                            customerId: 19406,
                            lastSale: "01-03-2020",
                            churnPrediction: 0.555852353572845
                        },
                        {
                            customerId: 955,
                            lastSale: "02-12-2019",
                            churnPrediction: 0.563601016998291
                        }
                    ]
                }
            },
            churnByStore: {
                current: {
                    data: [
                        {
                            store: 9,
                            churnquantity: 817
                        },
                        {
                            store: 4,
                            churnquantity: 514
                        },
                        {
                            store: 5,
                            churnquantity: 457
                        }
                    ]
                }
            },
            churnAndRecovered: {
                current: {
                    dropout: 2519,
                    recovered: 187,
                    reference: "01-06-2020 - 30-06-2020"
                }
            }
        } 
    },
    churnAndRecovered: {
        data: {
            churnAndRecovered: {
                current: {
                    dropout: 2519,
                    recovered: 187,
                    reference: "01-06-2020 - 30-06-2020"
                }
            }
        }
    },
    churnByStore: {
        data: {
            churnByStore: {
                current: {
                    data: [
                        {
                            store: 9,
                            churnquantity: 817
                        },
                        {
                            store: 4,
                            churnquantity: 514
                        },
                        {
                            store: 5,
                            churnquantity: 457
                        }
                    ]
                }
            }
        }
    },
    churn: {
        data:{
            churn: {
                current: {
                    customers: [
                        {
                            customerId: 2414,
                            lastSale: "11-01-2020",
                            churnPrediction: 0.78196519613266
                        },
                        {
                            customerId: 10289,
                            lastSale: "04-01-2020",
                            churnPrediction: 0.672111928462982
                        },
                        {
                            customerId: 5802,
                            lastSale: "05-01-2020",
                            churnPrediction: 0.786353230476379
                        }
                    ],
                    count: 68,
                    page: 0,
                    size: 3
                },
                past: {
                    customers: [
                        {
                            customerId: 16581,
                            lastSale: "01-12-2019",
                            churnPrediction: 0.563601016998291
                        },
                        {
                            customerId: 19406,
                            lastSale: "01-03-2020",
                            churnPrediction: 0.555852353572845
                        },
                        {
                            customerId: 955,
                            lastSale: "02-12-2019",
                            churnPrediction: 0.563601016998291
                        }
                    ]
                }
            }
        }
    },
}