import React, { Component } from 'react';
import './toggle.css';
import deepProps from '../../Utils';

export default class Toggle extends Component {

  static propTypes = {}

  static defaultProps = {}

  constructor(props) {
    super(props)
    this.state = {
      active: this.props.active
    }
  }

  onToggleClick(state){
    if(!this.props.disabled){
      let active = !this.state.active
      this.setState({active}, ()=>{
          if(this.props.onClick)
            this.props.onClick(this.state.active)
      })
    }    
  }

  componentDidUpdate (prevProps) {
    // if(this.props.disable) return
    let active = deepProps('active')(this.props).getOrElse(false)
    let value = deepProps('value')(this.props).getOrElse(false)
    if (active !== deepProps('active')(prevProps).getOrElse(false)) {
      this.setState({active})
    }
    
    if (value !== deepProps('value')(prevProps).getOrElse(false)) {
      this.setState({active})
    }
  }
  
  render() {
    return (
		<div className='row' onClick={(state)=>{this.onToggleClick(state)}} style={this.props.style}>
      {(this.props.text && <h1 className='toggle-text'>{this.props.text}</h1>)}
      {(this.props.square) && 
        <div className="square-container" style={this.props.style}>
          <div className={((this.state.active) ? "square-inner-container right" : "square-inner-container left" )}>
            <div className="square-toggle">
              <p className={((this.state.active) ? "white" : "" )}>{this.props.right}</p>
            </div>
            <div className="square-toggle">
              <p className={((this.state.active) ? "" : "white" )}>{this.props.left}</p>
            </div>
            <div className="moving-square"></div>
          </div>
        </div>
      }
			{(this.props.switch) && <div className={((this.state.active) ? "toggle right" : "toggle left" )+ ((this.props.small) ? " small" : "")}>
				<h1>{this.props.left}</h1>
				<h1>{this.props.right}</h1>
				<span className="switcher"></span>
			</div>}
		</div>
    )
  }
} 