import React, { useState, useEffect } from 'react';
import PopupContainer from '../../../component/Popup';
import './styles.scss'
import Button from '../../../component/Button/button';
import ListItem from '../../../component/listItem/listItem';
import { getLastCreatedCustomersReportsAPI, getCustomerReportByIdAPI, exportCustomerReportAPI } from '../../../api/CustomerApi';
import deepProps, { downloadResponse } from '../../../Utils';
import moment from 'moment'
import { NotificationManager } from 'react-notifications';
import { ColorEmptyMessage } from '../../../component/emptyMessage/emptyMessage';
import LoadingMessage from '../../../component/LoadingMessage/loadingMessage';

const SearchExportModal = ({filters}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState()
    const [requesting, setResquesting] = useState()
    const [downloading, setDonwloading] = useState(false)

    useEffect(()=>{
        searchLastCreatedCustomers()
    }, [])

    const searchLastCreatedCustomers = (autoRefresh = false) => {
        if(loading && !autoRefresh) return
        setLoading(true)
        getLastCreatedCustomersReportsAPI().then((response)=>{
            let reports = deepProps('data')(response).getOrElse([])

            if(autoRefresh){
                let oldData = data
                if(oldData.length === reports.length) return setTimeout(()=>searchLastCreatedCustomers(true), 2000)
            }

            setData(reports)
            setLoading(false)
            setResquesting(false)
        }).catch((e)=>{
            setLoading(false)
            NotificationManager.error('Não foi possível pesquisar os últimos relatórios baixados')
            console.error(e);
        })
    }

    const onDownloadItemClickHandle = (id) => {
        if(downloading) return
        setDonwloading(true)
        downloadResponse('my_customers_search',()=>getCustomerReportByIdAPI(id), 'zip').then((response)=>{
            console.log(response);
            NotificationManager.success('Relatório baixado com sucesso!')
        }).catch(e=>{
            NotificationManager.warning('Tente novamente mais tarde', 'Ops, algo deu errado!')
            console.error(e);
        }).finally(()=>{
            setDonwloading(false)
        })
    }

    const onDownloadClickHandle = () => {
        if(requesting) return
        if(data.length >= 6){
            return NotificationManager.warning('Número máximo de relatórios atingido')
        }
        setResquesting(true)
        console.log(filters);
        let bodyFilter = filters
        bodyFilter['filterDescription'] = `Meus clientes ${moment().format('DD/MM/YYYY HH:mm:ss')}`
        exportCustomerReportAPI(bodyFilter).then(()=>{
            // NotificationManager.success('Te avisaremos quando o relatório estiver pronto!','Requisição feita com sucesso')
            searchLastCreatedCustomers(true)
        }).catch((e)=>{
            setResquesting(false)
            NotificationManager.error('Não foi possível solicitar esse relatório', 'Ops, algo deu errado!')
            console.error(e);
        })
    }

    return (
        <PopupContainer trigger={(false && <Button text='Exportar' invert style={{marginTop: 16}} />)} style={{maxWidth: 390, padding: 16}}>
            <div style={{flexDirection: 'column', display: 'flex',flex: '1'}} >
                <h1 style={{marginBottom: 16}}>Relatórios exportados anteriormente</h1>
                <div style={{minHeight: 170, position: 'relative', maxHeight: 300, overflow: 'scroll'}}>
                    {!loading && data && data.map(item => (
                        <ListItem key={item.id} loading={downloading} onDownloadClick={() => onDownloadItemClickHandle(item.id)} title={item.description} subtitle={`Expira ${moment(item.expiryAt).fromNow()}`} contentContainerStyle={{width: 332, marginBottom: 4}}/>
                    ))}
                    {(!loading && data.length === 0)&& <ColorEmptyMessage title='Nenhum relatório exportado anteriormente' subtitle='Clique para atualizar' onClick={() => searchLastCreatedCustomers(false)} icon='empty-box'/>}
                    {loading && <LoadingMessage title={requesting ? 'Criando novo relatório' : 'Buscando relatórios'} description='Aguarde um instante...'/>}
                </div> 
                {(data.length > 0 && !loading) && <Button text='Atualizar' style={{marginTop: 16}} loading={loading} loadingText='Atualizando...' textOnly onClick={searchLastCreatedCustomers}/>}
                {!requesting && <Button text='Exportar novo relatório' style={{marginTop: 16}} loading={requesting} loadingText='Solicitando...' onClick={onDownloadClickHandle}/>}
            </div>
        </PopupContainer>
    )
}

export default SearchExportModal