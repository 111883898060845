import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import ProfileData from '../../component/cardTabProfile/profileData/profileData';
import './cardTabProfile.css';

export default class cardTabProfile extends Component {

	render() {
		return (
			<Router>
				<div className="cardTabProfile">
					<ul className="header-cardTabProfile">					
						<li className="option-cardTabProfile" >
							<NavLink to="/main/profile/profile" activeClassName="activeTab" > Perfil </NavLink>
						</li>
					</ul>

					<div className="route-profile">
						<Route path="/main/profile/profile" component={()=><ProfileData store={this.props.store} read={this.props.read} write={this.props.write}/>} />
					</div>
				</div>
			</Router>
		);
	}
}