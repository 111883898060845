import React, { Component } from 'react';
import AsideProfileCard from '../../component/AsideProfileCard/AsideProfileCard';
import './asideOperator.css';
import deepProps from '../../Utils';
import AsideButtonCard from '../../component/AsideButtonCard/AsideButtonCard';
import OperatorApi from '../../api/OperatorApi';
import { AlertDialog, AskDialog } from '../../component/alertDialog/alertDialog';
// import { confirmAlert } from 'react-confirm-alert';
import {Redirect} from "react-router-dom";

class AsideOperator extends Component {
    constructor(props){
        super(props)
        this.state={}
    }

    componentDidMount () {
    }

	getOperatorLabel(){
		return deepProps('name')(this.props.data).getOrElse('Novo operador')
    }
    
    getOperatorSubtitle(){
		return deepProps('userName')('identifier')(this.props.data).getOrElse('')
    }
    
    async onDeleteClick(){
        AskDialog('Deseja realmente excluir este perfil?','Este operador perderá acesso ao sistema, deseja continuar?',async ()=>{
        try{
            await this.setState({deletingOperator: true})
            await OperatorApi.deleteOperator(this.props.data.operatorId)
            this.props.store.dispatch({type:'OPERATOR_DETAIL', operator:{}})
        }catch(error){
            AlertDialog('Ops, algo deu errado!', 'Não foi possível excluir o operador ' + this.props.data.name + ' (id: ' + this.props.data.operatorId + '), Tente novamente mais tarde.')
            console.log(error)
        }finally{
            this.setState({deletingOperator: false})
        }
        },()=>{})
    }

    render() {
        if (!this.props.write && !this.props.read) {
			return <Redirect to='/main/home/general'/>
		}
        return (
            <aside className="asideOperator">
                <AsideProfileCard title={this.getOperatorLabel()} subtitle={this.getOperatorSubtitle()}/>
                {this.props.data.operatorId && this.props.write && <AsideButtonCard title='Excluir' text='Ao excluir, o operador perderá acesso ao sistema' button='Excluir'  loading={this.state.deletingOperator} onClick={()=>this.onDeleteClick()}/>}
            </aside>
        );
    }
}

export default AsideOperator;