import {getUserVerificationDTO} from "./userVerificationDTO"
import { ERRORS } from "../../errors"
import { getFetchHeader } from "../../api/UtilsAPI"
import { USER_VERIFICATION_EP } from "../endpoints_js"

export const getUserVerificationAPI = async (identifier, dateOfIssue) => {
    const response = await fetch(USER_VERIFICATION_EP.verify(),getFetchHeader('PUT', getUserVerificationDTO(identifier, dateOfIssue)))
    // checkFetchStatus(response.status)
    if(response.status === 404){
        throw   ERRORS.UNKNOW_USER
    }
    return await response
}