import React, { useState, useEffect } from 'react'
import Button from '../../Button/button'
import PopupContainer from '../../Popup'
import { ColorEmptyMessage } from '../../emptyMessage/emptyMessage'
import { Image } from 'react-feather'
import {getUserVerificationAPI} from '../../../server/userVerification'
import {getTemporaryPasswordAPI} from '../../../server/temporaryPassword'
import { NotificationManager } from 'react-notifications'
import catchErrors from '../../../errors'
import { AskDialog } from '../../alertDialog/alertDialog'


const AppClientTab = (props) => {
    const [identifier, setIdentifier] = useState()
    const [aggred, setAggred] = useState()
    const [verifying, setVerifying] = useState()
    const [generatingPassword, setGeneratingPassword] = useState(false)
    const [dateOfIssue, setDateOfIssue] = useState()

    useEffect(()=>{
        let {user} = props
        setIdentifier(user.identifierDefault.identifier)
    }, [])

    const onVerifyClickHandle = () => {
        setVerifying(true)
        getUserVerificationAPI(identifier, dateOfIssue).then((response)=>{
            NotificationManager.success('O usuário recebeu um perfil verificado', 'Sucesso!')
            // setDone(true)
        }).catch((e)=>{
            console.log(e)
            catchErrors(e)
        }).finally(()=>{
            setVerifying(false)
        })
        
    }

    const onGeneratePasswordClickHandle = () => {
        setGeneratingPassword(true)
        getTemporaryPasswordAPI(identifier, dateOfIssue).then((response)=>{
            AskDialog(`Senha: ${response.data}`, `${props.user.name} agora pode acessar sua conta no app informando o CPF ${props.user.identifierDefault.identifier} com a senha acima.`)
            NotificationManager.success('Senha temporária gerada com sucesso!', 'Sucesso!')
        }).catch((e)=>{
            catchErrors(e)
            console.log(e)
        }).finally(()=>{
            setGeneratingPassword(false)
        })
    }
    
    return (
        <form className="personalData">
			<div className="form-personalData">
                {/* <h1 className='title' style={{marginBottom: 16}}>Ações disponíveis</h1> */}
                <ColorEmptyMessage  noIcon title='Solicite um documento com foto' subtitle='Aceite apenas RG ou carteira de motorista. Não aceite carteirinhas de estudante ou documentos adulterados'/>
                <div style={{paddingTop: 32}}>
                    <label>
                        Data de emissão
                        <input type="date" placeholder="Digite aqui a data de emissão..." onChange={value => setDateOfIssue(value.target.value)} style={{width: 150}}/>
                    </label>
                    <label for='user_verification' style={{flexDirection: 'row', alignItems: 'center', marginTop: 16}}>
                        <input type='checkbox' id='user_verification' checked={aggred} onChange={e => setAggred(e.target.checked)}/>
                        Declaro que solicitei o documento, verifiquei sua veracidade e o considero verdadeiro.
                    </label>
                    {(props.loginStatus && props.loginStatus !== 'VERIFIED_BY_OPERATOR') && <Button text='Verificar' loading={verifying}  disabled={!aggred} style={{float: 'right'}} dis onClick={onVerifyClickHandle}/>}
                    <Button text='Senha temporária' loading={generatingPassword} onClick={onGeneratePasswordClickHandle} invert disabled={!aggred} style={{float: 'right', marginRight: 8}}/>
                </div>
			</div>
    	</form>
    )
}

export default AppClientTab