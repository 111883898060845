import React, { useEffect, useState } from 'react';
import PopupContainer from '../Popup';
import ImageAvatar from '../image-avatar/image-avatar';
import './styles.scss'
import Button from '../Button/button';
import { getMeasurementLabel, getValueLabel, getSegmentationTypeText, getPromotionType } from '../../Utils'
import SelectableItem from '../selectableItem/selectableItem';
import { NotificationManager } from 'react-notifications';
import { publishPromotionAPI } from '../../api/PromotionApi';
const moment = require('moment');

const AbstractModal = (props) => {
    let {promotion} = props
    const [loading, setLoading] = useState(false)

    const onPublishClickHandle = () => {
        let promotionId = promotion.id
        if (promotionId < 1) {
            return NotificationManager.error('Promoção não encontrada. Favor logar-se novamente!', 'Acesso indevido!')
        }

        setLoading(true)
        publishPromotionAPI(promotionId).then((response)=>{
            if([200,204, 0].includes(response.status)){
                props.store.dispatch({type: 'CHANGE_STATUS_PROMOTION', promotionId, promotionStatus:'PUBLISHED'})
                NotificationManager.success('Disponível para uso dos clientes', 'Promoção publicada!')
            }else{
                throw (response.message)
            }
        }).catch((errorMessage)=>{
            NotificationManager.warning(errorMessage, 'Ops, algo deu errado!')
        }).finally(()=>{
            setLoading(false)
        })    
    }

    return(
        <PopupContainer trigger={<Button text='Publicar'/>}>
            {promotion && <div className='abstractModal-container' style={{width: 400}}>
                <header>
                    <ImageAvatar title={promotion.description}/>
                    <div className='info'>
                        <h1 className='title'>{promotion.description}</h1>
                        <h2>Desconto de {getValueLabel( promotion.promotionPattern === 'VOUCHER' ? promotion.maxDiscountByCustomer : promotion.value,promotion.typeValue)}</h2>
                        <h3>{`${moment(promotion.dateStart).format('L')} - ${moment(promotion.dateEnd).format('L')}`}</h3>
                    </div>
                </header>
                <main>
                    <h1 className='title'>Regras</h1>
                    <h2>{promotion.rules}</h2>
                    <h1 className='title'>Segmentação</h1>
                    {promotion.items && <><h2>{getSegmentationTypeText(promotion.segmentationType, promotion.status)}</h2>
                    <h1 className='title'>Tipo da promoção</h1>
                    <h2>{getPromotionType(promotion.promotionPattern)}</h2>
                    <h1 className='title'>Promovidos ({promotion.items.length})</h1></>}
                    <ul>
                        {promotion.items && promotion.items.map(item=>(<SelectableItem title={item.description} img={item.resourceImage} tip={item.quantity + ' ' + getMeasurementLabel(item.measurementUnit)}/>))}
                    </ul>
                    {promotion.items && promotion.items.length === 0 && <h2>Nenhum promovido selecionado</h2>}
                    <h1 className='title'>{`Lojas válidas (${promotion.stores.length})`}</h1>
                    <ul>
                        {promotion.stores && promotion.stores.map(store=>(<SelectableItem title={`Loja ${store.storeNumber}`} img={'https://cdn.dribbble.com/users/4877/screenshots/284840/store-icon.png'}/>))}
                    </ul>
                    {promotion.stores && promotion.stores.length === 0 && <h2>Nenhuma loja selecionada</h2>}
                </main>
                <footer>
                    {/* <Button text='Cancelar' textOnly style={{marginRight: 32}}/> */}
                    <Button text='Continuar' loading={loading} onClick={onPublishClickHandle} loadingText='Publicando'/>
                </footer>
            </div>}
        </PopupContainer>
    )
}

export default AbstractModal