import React, { useEffect, useState } from 'react';
import '../style.scss';
import Card from '../../../component/card';
import TextInput from '../../../component/textInput/textInput';
import SwitchList from '../../../component/SwitchList';
import { sendNotificationToEveryone } from '../../../server/notification/notificationAPI';
import { getNotificationBody } from '../../../server/notification/notificationBody';
import { NotificationManager } from 'react-notifications';
import InputText from '../../../component/inputText/inputText';
import EmojiPicker from '../../../component/emojiPicker';
import { AskDialog } from '../../../component/alertDialog/alertDialog';

const NotificationCard = props => {
    // const RECEIVERS_OPTIONS = [{ id: 'everyone', text: "Todos" }, { id: 'segmented', text: "Particular"}, { id: 'test', text: "Teste"}]
    const RECEIVERS_OPTIONS = [{ id: 'everyone', text: "Todos" }, { id: 'segmented', text: "Particular"}, { id: 'test', text: "Teste"}]
    const [receivers, setReceivers] = useState(RECEIVERS_OPTIONS[0])
    const [title, setTitle] = useState("")
    const [message, setMessage] = useState("")
    const [blockedAction, setBlockedAction] = useState(false)
    const {onTitleChange, onMessageChange} = props
    
    useEffect(() => {
        if(onTitleChange) onTitleChange(title.trim())
        if(onMessageChange) onMessageChange(message.trim())
    }, [title, message])

    const sendNotification = () => {
        AskDialog('Deseja realmente enviar essa notificação?', 'Todos os usuários com o aplicativo instalado receberão essa mensagem!', () => {
            setBlockedAction(true)
            let notificationRequest
            switch(receivers.id){
                case "everyone":
                    notificationRequest = sendNotificationToEveryone
                    break;
                default:
                    break;
            }
            let notification = getNotificationBody(title, message)
            notificationRequest(notification).then(()=>{
                NotificationManager.success('Isso pode demorar alguns segundos', 'Notificação enviada!')
            }).catch(e => {
                console.log(e)
                NotificationManager.error('Não foi possível enviar a notificação', 'Ops, algo deu errado!')
            }).finally(()=>{
                setBlockedAction(false)
            })
        })
    } 

    const validateFields = () => {
        if(title.trim().length <=0 || message.trim().length <= 0)
            throw 'Não é possível enviar uma notificação sem conteúdo.'
    }

    const onSendClickHandle = () => {
        if(blockedAction) return
        try{
            validateFields()
            sendNotification()
        }catch(e){
            NotificationManager.info(e, 'Quase lá!')
        }
    }

    return (
        <Card title='Notificações Push' buttonText='Enviar' className='app-manager-card' onClick={onSendClickHandle}>
            <div className='flex' style={{ marginBottom: 4, marginTop: 16 }}>
                <InputText  text label={'Título'} maxLength={60} value={title} onChange={(field, value)=>setTitle(value)}/>
            </div>
            <div className='flex' style={{ marginBottom: 16}}>
                <InputText multiline label={'Mensagem'} maxLength={160} value={message} onChange={(field, value)=>setMessage(value)}/>
            </div>
            <div> 
                <p>O melhor horário de envio é de Terças às Sextas, entre as 14:00 e as 19:00.</p>
            </div>
            {/* <div>
                <h1 style={{paddingBottom: 4}}>Enviar para</h1>
                <SwitchList width={300} style={{ marginRight: 8}} flat color={"#2196f3"} data={RECEIVERS_OPTIONS} onChange={(item) => setReceivers(item)} />
            </div> */}
            <EmojiPicker style={{position: 'absolute', top: -16, right: 0}}/>
        </Card>
    );
}

export default NotificationCard;