import { storesEP, customersEP } from "../server/endpoints_js";
import deepProps, {treatMessage} from "../Utils";

export default class AddressesApi {
    static find = (zipCode, successFunc = () => {}, errorFunc = () => {}) => {
        let url = 'https://viacep.com.br/ws/' + zipCode + '/json/'
        fetch(url, {
            method: "GET"
        }).then((response) => {
            if (response.status === 200)
                return response.json();
            throw response
        }).then((response) => {
            if (deepProps('erro')(response).getOrElse(false)) {
                if (errorFunc !== undefined)
                    errorFunc('Endereço não encontrado')
                return
            }
            successFunc(response)
        }).catch(error => {
            errorFunc('Endereço não encontrado')
        });
    }

    static saveAddress = (token, result, successFunc = () => {}, errorFunc = () => {}) => {
        let url = storesEP.address()
        let body = JSON.stringify(result)
        fetch(url, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            successFunc(response)
        }).catch(error =>  {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        })
    }

	static saveCustomersAddress = (token, result, successFunc = () => {}, errorFunc = () => {}) => {
        let url = customersEP.addAddress()
        let body = JSON.stringify(result)
        fetch(url, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            successFunc(response)
        }).catch(error =>  {
			console.log(error)
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        })
    }

	static saveEletronicAddress = (token, result, successFunc = () => {}, errorFunc = () => {}) => {
        let url = customersEP.addEletronicAddress()
        let body = JSON.stringify(result)
        fetch(url, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: body
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            successFunc(response)
        }).catch(error =>  {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        })
    }

	static deleteAddress = (token, identifier, addressId, successFunc = () => {}, errorFunc = () => {}) => {
        let url = customersEP.disableAddress(identifier, addressId)
        fetch(url, {
            method: "DELETE",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            successFunc(response)
        }).catch(error =>  {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        })
    }

	static deletePhone = (token, contactId, successFunc = () => {}, errorFunc = () => {}) => {
        let url = customersEP.disablePhone(contactId)
        fetch(url, {
            method: "DELETE",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            successFunc(response)
        }).catch(error =>  {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        })
    }

	static deleteEletronicAddress = (token, contactId, successFunc = () => {}, errorFunc = () => {}) => {
        let url = customersEP.disableEletronicAddress(contactId)
        fetch(url, {
            method: "DELETE",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            successFunc(response)
        }).catch(error =>  {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        })
    }
}