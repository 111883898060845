import React, { Component } from 'react';
import Score from '../score/score';
import './AsideProfileCard.css';
import ImageAvatar from '../image-avatar/image-avatar';
import Skeleton from 'react-loading-skeleton'
import { Award, UserCheck, Check } from 'react-feather';

class AsideProfileCard extends Component {
    constructor(Props){
        super(Props)
        this.getStatusLabel = this.getStatusLabel.bind(this)
    }
    
    componentDidMount(){
    }

    getStatusLabel(status){
        switch (status){
            case 'PENDING':
                return 'Pendente'
            case 'PUBLISHED':
                return 'Publicada'
            case 'CREATING':
                return 'Nova promoção'
            case 'DEPRECATED':
                return 'Desativada'
            case 'EXPIRED':
                return 'Expirada'
            default:
                return 'Status desconhecido'
        }
    }

    render() {
        let {score, moc} = this.props
        return (
            <div className="card-profile">
                <div className="background"></div>
                <div className="card-profile-content"> 
					<div className="card-img">
                    	<ImageAvatar img={this.props.img} title={this.props.title}/>
                        {this.props.verified && <Check color='#fff' size={16}/>}
					</div>
                    {(this.props.title) && <h1 className="card-nameProfile">{this.props.title}</h1>}
                    {(this.props.subtitle) && <h2 className="card-subtitle">{this.props.subtitle}</h2>}
                    {(score !== undefined && typeof(score) === 'number') ? <Score score={score}/> : 
                        <div className = 'score'>
                            {moc && <Skeleton height={15}/>}
                        </div>
                    }
                    {(this.props.status) &&
                        <div>
                            <h2 className="status-item">{this.getStatusLabel(this.props.status)}</h2>
                            <span className={'status-view ' + this.props.status}></span>
                        </div>
                    }
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default AsideProfileCard;
