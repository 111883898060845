const purchaseHistory = (stores = [], start_date, end_date, customer) => {
    return `query d {
    customer_history: customer_history(stores: [${stores}], start_date: "${start_date}", end_date: "${end_date}", customer: ${customer}) {
        current {
            general {
              quantity
            }
            history{
              store
              date
              coupon_number
              coupons_used
              pdv_discount
              total_value
              app_discount
              itens_quantity
            }
        }
          
      }
    }
    `
}

export const getCustomersMetricsQuery = (customerId) => {
  return `query {
      metrics: customersMetrics(customerId: ${customerId}) {
        customerId,
        frequency,
        churn,
        medianSpendPerPurchase,
        medianItemsPerPurchase,
        medianPurchasePerMonth,
        favoriteStore,
        amountPrediction,
        lastSale
    }
  }`
}


export default purchaseHistory