import {LoginEP } from "../server/endpoints_js";
import {treatMessage} from '../Utils'

export default class Api {

   static getPermission = (token) => {
        return dispatch => {
            fetch(LoginEP.loginPermissions(), {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            }
            }).then((response) => {
                switch(response.status) {

                    case 403:
                    case 200:
                        return response.json();
                    default:
                        return {
                            status: -1,
                            message:
                            "Nao foi possivel acessar o sistema, tente novamente mais tarde!"
                        };
                }
            }).then((response) => {
                dispatch({type:'PERMISSION', response})
            }).catch((error) => {
                console.error(error);
            })
        }
    };

    static login = (identifier, password, userType, sucessFunc = ()=>{}, errorFunc = ()=>{}) => {
        return dispatch => {
            fetch(LoginEP.login(), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "userName": {
                        "identifier" : identifier,
                        "type" : userType === 'CUSTOMER' ? 'CPF' : userType
                    },
                    "password" : password,
                    "userType" : userType
                })
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                dispatch({type: 'LOGIN', response});
				sucessFunc()
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc(message)
                })
            }) 
        };
    }
}