import React, { Component } from 'react'
import './NotificationBox.css'

class NotificationBox extends Component {
    render(){
        let box = this.props.visible ? (
            <div className='notification-box'> 
                <span>Atualização disponível!</span>
                <button onClick={
                    () => {
                        window.location.reload()
                    }
                }><span>Recarregue a página!</span></button>
            </div>
            ) : <div></div>  
        
        return( box )       
    }
}

export default NotificationBox