import {promotionsEP, elasticGatewayEP} from "../endpoints_js";
import { getFetchPOSTHeader, validateRequest, getFetchHeader } from "../../api/UtilsAPI";
import { getPromotionItemDTO, getItemAssociatedDTO, getPromotionSetupDTO } from "./promotionBody";
import { getProductIdQuery } from "../search/searchBody";

export const addNewPromotionAPI = async (promotion) => {
    console.log('addNewPromotionAPIDTO: ', promotion);
    let request = await fetch(promotionsEP.addPromotion(), getFetchHeader('POST', promotion))
    validateRequest(request, [200,201])
    return await request.json() 
}

export const updatePromotionAPI = async (promotionId, promotion) => {
    console.log('updatePromotionAPIDTO: ', promotion);
    let request = await fetch(promotionsEP.updatePromotion(promotionId), getFetchHeader('PUT', promotion))
    validateRequest(request, [200,201])
    return await request.json() 
}

export const addPromotionItemAPI = async  (promotionId, body) => {
    let request = await fetch(promotionsEP.getItems(promotionId), getFetchPOSTHeader(body))
    validateRequest(request, [200, 400])
    return await request.json() 
}

export const addNewItemAPI = async  (promotionId, body) => {
    let request = await fetch(promotionsEP.getItems(promotionId), getFetchPOSTHeader(body))
    validateRequest(request, [200,201,404])
    return await request.json() 
}

export const setupPromotionAPI = async (promotionId, body) => {
    let request = await fetch(promotionsEP.setup(promotionId, String(body.promotionPattern).toLocaleLowerCase()), getFetchPOSTHeader(getPromotionSetupDTO(body)))
    validateRequest(request, [200,201])
    return await request.json() 
}

export const getProductByGtinAPI = async  (gtin) => {
    let request = await fetch(elasticGatewayEP.findProductByEAN(gtin), getFetchHeader())
    validateRequest(request, [200])
    return await request.json() 
}

export const getProductByIdAPI = async  (id) => {
    let request = await fetch(elasticGatewayEP.findItems(), getFetchHeader('POST', getProductIdQuery(id)))
    validateRequest(request, [200])
    return await request.json() 
}

export const addItemAssociatedsAPI = async  (itemId, type, insertIdList) => {
    let request = await fetch(promotionsEP.promotionItemAddAssocieted(itemId, type), getFetchHeader('POST', getItemAssociatedDTO(insertIdList, type)))
    validateRequest(request, [200])
    return await request.json() 
}

export const deleteItemAssociatedAPI = async  (itemId, type, removeId) => {
    let request = await fetch(promotionsEP.promotionItemRemoveAssociated(itemId, removeId, type), getFetchHeader('DELETE'))
    validateRequest(request, [200, 404])
    return await request.json() 
}

export const updatePromotionItemAPI = async  (item) => {
    let request = await fetch(promotionsEP.updateItem(item.id), getFetchHeader('PUT', getPromotionItemDTO(item)))
    validateRequest(request, [200,201])
    return await request.json() 
}

export const updateElasticItem = async (id, body, type) => {
    let url = undefined
    switch (type) {
        case 'PRODUCT':
        case 'products':
            url = elasticGatewayEP.saveElasticItem(id)
        break
        case 'SECTOR':
        case 'sectors':
            url = elasticGatewayEP.saveElasticSector(id)
        break;
        case 'PROVIDER':
        case 'supliers':
            id = `ID:${id}`
            url = elasticGatewayEP.saveElasticProvider(id)
            break
    }
    if (!url)
        return
    let header = getFetchPOSTHeader(body)
    let request = await fetch(url, header)
    validateRequest(request, [200, 201, 203, 204, 205, 206])
    return await request.json()
}