import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom"
import Home from '../home/home'
import Header from '../../component/header/header'
import Menu from '../../component/menu/menu'
import SearchCustomer from '../Search/searchCustomer/searchCustomer'
import Customer from '../../screen/customer/customer'
import Promotion from '../../screen/promotion/promotion'
import Store from '../../screen/store/store'
import SearchPromotion from '../Search/searchPromotion/searchPromotion'
import SearchStore from '../Search/searchStore/searchStore'
import SearchOperator from '../Search/searchOperator/searchOperator'
import SearchProfiles from '../Search/searchProfiles/searchProfiles'
import './main.css';
import deepProps from '../../Utils';
import Operator from '../operator/operator';
import Profiles from '../profile/profile';
import history from '../../history'
import NotificationBox from '../../component/notificationBox/NotificationBox'
import {pushListener} from '../../reg-service-worker'
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css'
import AppManager from '../appManager'

class Main extends Component {

	constructor(props) {
		super(props)
		this.state = {
			token: this.props.token,
			permissions:[],
			redirectToLogin:false,
			to:'',
			notificationVisible: false
		}
		this.tabPromotion = [{name:'promotion', title:'Promoção'},{name:'itens', title:'Items'},{name:'lojas', title:'Lojas'},{name:'divulgacao', title:'Divulgação'}]
		this.unsubscribe = this.props.store.subscribe(() => {
			let lastState = this.props.store.getState()
			if (lastState.type === 'LOGIN_REQUERIED') {
				this.setState({redirectToLogin:true})
				return
			}
			if (lastState.type === 'REDIRECT') {
				let to = deepProps('redirectTo')(lastState).getOrElse('')
				this.setState({to})
			}
		})

		navigator.serviceWorker.ready
			.then( reg => {
				reg.onupdatefound = this.showNotificationBox.bind(this)
			})
	}

	componentWillMount () {
		let lastState = this.props.store.getState();
		if (lastState.type === 'PERMISSION'){
			lastState.permissions.push({entityId: 0, name: "HOME"}, {entityId: 0, name: "APP"})
			this.setState({permissions : lastState.permissions})
        }else{
			let permissions = JSON.parse(localStorage.getItem('permissions'))
			permissions.push({entityId: 0, name: "HOME"}, {entityId: 0, name: "APP"})
            this.setState({permissions: permissions})
		}
		let token = deepProps('token')(lastState).getOrElse(null)
		if (token == null) {
			this.setState({redirectToLogin:true})
			return
		}
	}

	componentWillUnmount () {
		this.unsubscribe()
	}

	showNotificationBox() {
		this.setState({...this.state, notificationVisible: true })
	}

  render() {
	let {redirectToLogin, to} = this.state
	if (redirectToLogin) {
		return <Redirect to='/'/>
	}
	switch(to) {
		case 'home':
			history.push('/main/home/general')
		break
		case 'search-promotion':
			history.push('/main/promotions')
		break
	}

    return (
    	<Router className="main" history={history}>
			<div className="main-router">
				<Menu store={this.props.store} token={this.props.token} options={this.state.permissions}/>
				<div className="container">
					<Header store={this.props.store}/>
					<div className="container-router">
						<Route path="/main/home/:report" component={() => <Home token={this.props.token} store={this.props.store}/> } />
						<Route path="/main/customers" component={() => <SearchCustomer token={this.props.token} store={this.props.store} clients={this.props.clients} /> } />
						<Route path="/main/customer" component={() => <Customer token={this.props.token} store={this.props.store} tabItens={this.props.tabItens} /> } />
						<Route path='/main/promotion' component={() => <Promotion token={this.props.token} store={this.props.store}/> } />
						<Route path='/main/store' component={() => <Store token={this.props.token} store={this.props.store}/> } />
						<Route path="/main/stores" component={() =><SearchStore token={this.props.token} store={this.props.store}/>}/>
						<Route exact path="/main/promotions" component={() =><SearchPromotion token={this.props.token} store={this.props.store} tabItens={this.props.tabItens}  user={this.props.user}/>}/>
						<Route exact path="/main/operator" component={() => <SearchOperator store={this.props.store} />}/>
						<Route path="/main/operator/data" component={() => <Operator store={this.props.store} />}/>
						<Route path="/main/profiles" component={()=><SearchProfiles store={this.props.store}/>} />
						<Route path="/main/profile" component={()=><Profiles store={this.props.store}/>}/>
						<Route path="/main/appmanager" component={()=><AppManager store={this.props.store}/>}/>
					</div>
					<NotificationBox visible={this.state.notificationVisible} />	
				</div>
				<NotificationContainer></NotificationContainer>
			</div>
		</Router>
    );
  }
}

export default Main;