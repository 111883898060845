import React, { Component } from 'react';
import Button from '../../Button/button'
import deepProps from '../../../Utils';
import AddressesApi from '../../../api/AddressesApi';
import { AlertDialog } from '../../alertDialog/alertDialog';
import './contacts.css';
import CustomerAPI from '../../../api/CustomerApi';
import SelectableItem from '../../selectableItem/selectableItem';
import LoadingMessage from '../../LoadingMessage/loadingMessage'
import { Plus } from 'react-feather';

const telephonesType = ['MOBILE_PHONE', 'PHONE']
const midiasType = ['E_MAIL', 'FACEBOOK', 'INSTAGRAN', 'WHATSAPP', 'TWITTER']

class contacts extends Component {
	constructor(Props){
		super(Props)
		this.state = {
			userDetails: '',
			identifier:'',
			address: '',
			telephoneNumber: '',
			midia: '',
			kindOfMedia: 'UN_KNOW',
			contacts:[]
		}
	}

	componentDidMount () {	
		console.log('did monut contact')
		this.setState({searching: true})
        let canEdit = this.props.store.getState().userAccess.userType === 'CUSTOMER'
		let token = deepProps('token')(this.props.store.getState()).getOrElse('')
		let userData = deepProps('customerDetails')('id')(this.props.store.getState()).getOrElse('')
		let userDetails = this.props.store.getState().userAccess.userName

		this.setState({userDetails: userDetails}, ()=>{
			CustomerAPI.findContacts(userData, token, (response)=>{
				let telephone = this.mapTelephones(deepProps('telephones')(response).getOrElse([]))
				let eletronicAddresses = this.mapEletronicAddresses(deepProps('eletronicAddresses')(response).getOrElse([]))
				this.setState({contacts: [...telephone, ...eletronicAddresses], searching: false})
			})
		})

		this.setState({canEdit, token: token})
	}

	mapEletronicAddresses(eletronicAddresses){
		let eletronicList = eletronicAddresses.map((contact) => {
			let obj = {
				title: deepProps('midia')(contact).getOrElse(''),
				data: [
					deepProps('address')(contact).getOrElse('')
				], 
				id: deepProps('id')(contact).getOrElse(0)
			}
			return obj
		})
		return eletronicList
	}

	mapTelephones(telephones){
		let telephonesList = telephones.map((contact) => {
			let obj = {
				title: deepProps('type')(contact).getOrElse(''),
				data: ["+" + contact.ddi + ' (' + contact.ddd + ') ' + contact.number], 
				id: deepProps('id')(contact).getOrElse(0)
			}
			return obj
		})
		return telephonesList
	}

	getContactJSON(address){
		let kindOfMedia = deepProps('kindOfMedia')(this.state).getOrElse('UN_KNOW')
		if(kindOfMedia === 'UN_KNOW'){
			alert('Selecione')
			return
		}
		
		if(telephonesType.includes(kindOfMedia)){
			this.mapTelephones(address)	
			return
		}

		if(midiasType.includes(kindOfMedia)){
			this.mapEletronicAddresses(address)
			return
		}
	}

    deleteContact(token, id){
		if (this.state.kindOfMedia === 'MOBILE_PHONE' || this.state.kindOfMedia === 'PHONE'){
			AddressesApi.deletePhone(token, id, ()=>{
				AlertDialog('Contato excluido!', 'Contato excluido com sucesso.')
			}, ()=>{})
		} else { 
			AddressesApi.deleteEletronicAddress(token, id, ()=>{
				AlertDialog('Contato excluido!', 'Contato excluido com sucesso.')
			}, ()=>{})
		}
		this.componentDidMount()
    }

	addAddresses(){
		let contact
		if (this.state.kindOfMedia === 'MOBILE_PHONE' || this.state.kindOfMedia === 'PHONE'){		
			contact = this.getTelephoneJson()
		} else { 
			contact = this.getEletronicAddressJson()
		}

		AddressesApi.saveEletronicAddress(this.state.token, contact, ()=> {
			AlertDialog('Contato salvo!', 'Contato salvo com sucesso.')
			this.componentDidMount()
		}, (error) => {
			AlertDialog('Ops, algo deu errado', error)
		})
	}

	getEletronicAddressJson(){
		let objectToSend  = {
			customerIdentifier:{
				identifier: this.state.userDetails.identifier,
				type: this.state.userDetails.type
			},
			eletronicAddresses:[
				{
					address: this.state.address,
					midia: this.state.kindOfMedia
				}
			]
		}
		return objectToSend
	}

	getTelephoneJson(){
		let objectToSend  = {
			customerIdentifier:{
				identifier: this.state.userDetails.identifier,
				type: this.state.userDetails.type
			},
			contact:{
				telephones:[
					{
						ddd: this.state.ddd,
                		ddi: this.state.ddi,
                		number: this.state.number,
                		type: this.state.telephoneType
					}
				]
			}
		}
		return objectToSend
	}
	
	getTitle(media){
		switch (media) {
			case 'MOBILE_PHONE': 
				return 'Celular'
			
			case 'PHONE':
				return 'Telefone'
			
			case 'E_MAIL': 
				return 'Email'
			
			case 'FACEBOOK': 
				return 'Facebook'
			
			case 'INSTAGRAN': 
				return 'Instagram'

			case 'WHATSAPP': 
				return 'Whatsapp'

			case 'TWITTER':
				return 'Twitter'
			default:
			break
		} 
	}

	render() {
        const canEdit = this.state.canEdit
		return (
			<form className="contacts">
			    <div className="form-contactsData">
                    { canEdit &&
                      <label className="contactsLabel"> Mídia
					      <select value ={this.state.kindOfMedia} onChange={(e)=>this.setState({kindOfMedia:e.target.value})}>
						   	  <option value="UN_KNOW">Selecione</option>
						      <option value="MOBILE_PHONE">Celular</option>
						      <option value="PHONE">Telefone Fixo</option>
						      <option value="E_MAIL">E-mail</option>
						      <option value="FACEBOOK">Facebook</option>
						      <option value="INSTAGRAN">Instagram</option>
						      <option value="WHATSAPP">Whatsapp</option>
						      <option value="TWITTER">Twitter</option>
					      </select>
				      </label>
                    }
                    { canEdit && this.state.kindOfMedia !== 'MOBILE_PHONE' && this.state.kindOfMedia !== 'PHONE' &&
			          <label className="contactsLabel"> Contato <input type="text" value={this.state.address} onChange={(e)=>this.setState({address:e.target.value})} placeholder="Digite o contato aqui..." /></label>
                    }
					{this.state.kindOfMedia === 'PHONE' && canEdit &&
						<label className="contactsLabel"> Telefone <input type="text" value={this.state.telephoneNumber} onChange={(e)=>this.setState({telephoneNumber:e.target.value})} placeholder="Digite o contato aqui..." /></label>
					}
					{this.state.kindOfMedia === 'MOBILE_PHONE' && canEdit &&
						<label className="contactsLabel"> Telefone <input type="text" value={this.state.telephoneNumber} onChange={(e)=>this.setState({telephoneNumber:e.target.value})} placeholder="Digite o contato aqui..." /></label>
					}
			        
                { canEdit && false &&
			      <div className='actionsButton'>
				      <Button text='Adicionar' icon={<Plus/>} onClick={() => this.addAddresses()} style={{maxWidth: 95}}/>
			      </div>
                }
				</div>
				<div className='contactsContents'>
					{this.state.searching && <LoadingMessage title='Pesquisando contatos...' description='Aguarde, estamos buscando os contatos!'/>}
					{
						this.state.contacts.map((contact, index) =>
							<SelectableItem key={index} actions title={this.getTitle(contact.title)} subtitle={contact.data[0]} />               
						)
					}
				</div>
	        </form>
		)
	}
}

export default contacts;
