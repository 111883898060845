import React, { Component } from 'react';
import './modalMessage.css';

class ModalMessage extends Component {
  render() {
    return (
    	<div className="modalMessage">
			<div className="body-modalMessage">
				<h1>{this.props.text}</h1>
			</div>
    	</div>
    );
  }
}

export default ModalMessage;