import React, { Component } from 'react'
import { SingleDatePicker } from 'react-dates'

class SingleDatePickerWrapper extends Component {
    constructor(props){
        super(props)
        this.state = {
            date: props.initialDate
        }
    }

    onDatesChange = (date) => {
        const { stateDateWrapper } = this.props

        this.setState({
          date: date && stateDateWrapper(date, this.props.type),
        })
    }

    render(){
        return(
            <SingleDatePicker 
                date={this.props.date}
                onDateChange={this.onDatesChange}
                focused={this.props.focused}
                onFocusChange={({ focused }) => this.props.focusDateWrapper(focused, this.props.type)}
                id={`singledatepicker_${this.props.type}`}
                displayFormat='DD/MM/YYYY'
                placeholder={this.props.placeholder}
                isOutsideRange={() => false}
                numberOfMonths={1}
                small={true}
                hideKeyboardShortcutsPanel={true}
                isDayHighlighted={ day => this.props.dayHighlightWrapper(day) 
                }
            />
        )
    }
}

export default SingleDatePickerWrapper