import { ERRORS } from "../../errors"
import { isCPFValid } from "../../Utils"
import moment from "moment"

export const getTemporaryPasswordDTO = (identifier, dateOfIssue) => {
    validateFields(identifier, dateOfIssue)
    return {
        dateOfIssue : moment(dateOfIssue).format('YYYY-MM-DD'),
        customerIdentifier : {
            identifier,
            type : "CPF"
        }
    }
}

const validateFields = (identifier, dateOfIssue) => {
    if(!isCPFValid(identifier)) throw ERRORS.INVALID_IDENTIFIER
    if(!dateOfIssue || moment(dateOfIssue).isAfter(moment())) throw ERRORS.INVALID_DATE
}