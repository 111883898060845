import React, { Component, useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DraggableList = (props) => {

  const [items, setItems] = useState([])
  const {data, dragItem, contentContainerStyle, onItemChange} = props

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    onItemChange && onItemChange(removed, endIndex, startIndex)
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    ...contentContainerStyle,
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    display: "flex",
    borderRadius: 5,
    paddingTop: 8,
    overflowX: 'scroll',
    maxWidth: '100%'
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems)
  }

  useEffect(()=>{
    setItems(data.map((item, idx)=>({...item, itemId: 'item-'+ idx})))
  },[data])

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal" style={{flexWrap: 'wrap'}}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {items.map((item, index) => (
                <Draggable key={item.itemId} draggableId={item.itemId} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} 
                      style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)} >
                      {dragItem(item, index)}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
}

export default DraggableList
