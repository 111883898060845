import React, { useState, useEffect } from 'react';
import './styles.scss'
import { NotificationManager } from 'react-notifications';
import catchErrors, { ERRORS } from '../../errors'
import TagSelect, { TagOption } from '../TagSelect';
import { searchAPI } from '../../server/search/searchAPI';
import { AskDialog } from '../alertDialog/alertDialog';

const SearchTagSelect = (props) => { 
   const [options, setOptions] = useState([])
   const [suggestions, setSuggestions] = useState([])
   const [searching, setSearching] = useState()
   const [searchingMore, setSearchingMore] = useState()
   const [hasMoreResults, setHasMoreResults] = useState()
   const [selectedItemInfos, setSelectedItemInfos] = useState()
   const [data, setData] = useState([])
   const {searchFor, onAddItem, onRemoveItem, onChange, showFeedback, tags, selectedItem} = props

    useEffect(()=>{
        let {selectedItem} = props
        if(!selectedItem) return
        let infos = getSelectedTypeInfos(selectedItem)
        setSelectedItemInfos(infos)
        loadParams(selectedItem.products)
    }, [])

    useEffect(()=>{
        if(!data || data.length == 0)return
        if(props.selectedItem){
            setOptions(filterOptions(options, data, selectedItemInfos.filter))
            setSuggestions(filterOptions(suggestions, data, 'GTIN'))
        }
        onChange(data)
    },[data])

    const getSelectedTypeInfos = (item) => {
        switch (searchFor) {
            case 'PRODUCT':
                return {
                    id: 'GTIN',
                    filter: 'products',
                    optionRender: item => <TagOption title={item.name} subtitle={item.GTIN} img={item.resourceImage}/>,
                    tagRender: item =>  <><h2>{item.GTIN}</h2></>,
                }   
            case 'PROVIDER':
                return {
                    id: 'id',
                    filter: 'supliers',
                    optionRender: item => item.newOption ? <TagOption title={item.name} subtitle={item.GTIN} img={item.resourceImage}/> : <TagOption title={item.name} subtitle={item.id + ' - ' + item.tradingName} img={item.resourceImage}/>,
                    tagRender: item => <><h2>{item.id}</h2></>,
                } 
            case 'SECTOR':
                return {
                    id: 'sectorId',
                    filter: 'sectors',
                    optionRender: item => <TagOption title={item.description} subtitle={`Nível ${item.level}`} img={item.resourceImage}/>,
                    tagRender: item => <><h2>{item.sectorId}</h2></>
                } 
            default:
                return {};
        }
    }

    const loadParams = (insertedData) => {
        setData(insertedData)
        setSuggestions(props.suggestions.map(suggestion => {
            return {
                name: suggestion.description,
                GTIN: suggestion.products[0]? suggestion.products[0].eanCode : '',
                ...suggestion
            }
        }))
    }

    const onAddTagHandle = item => {
        if(searching) return
        if (!item || !props.selectedItem)
            return
        let {type} = props.selectedItem
        if (type === 'PRODUCT') {
            let {products} = props.selectedItem || []
            if (products.filter(p => p['eanCode'] === item['GTIN']).length > 0) {
                return NotificationManager.warning('O item em questão já existe na lista', '')
            }
        }
        if (item && item['newOption']) {
            AskDialog(
                'Atenção esse produto não foi localizado na base de dados', 
                'Você pode adicioná-lo na promoção clicando em confirmar, mas caso o tipo do produto não estiver conforme ao item de associação, existe a ' +
                'possibilidade do item não receber o desconto configurado.', () => {
                setSearching(true)
                addItem(item)
            }, () => {
                setSearching(false)  
            })
        }else {
            setSearching(true)
            addItem(item)
        }
    }

    const addItem = item => {
        onAddItem(item, props.selectedItem).then(response => {
            if(item.level) item['sectorId'] = `${item.sector1}_${item.sector2}_${item.sector3}_${item.sector4}_${item.sector5}`
            setData([...data, item])
            // onChange && onChange([...data, item])
            showFeedback && NotificationManager.success('', 'Adicionado com sucesso!')
        }).catch(e=>{
            showFeedback && catchErrors(e)
        }).finally(()=>{
            setSearching(false)
        })
    }

    const onRemoveItemHandle = (item) => {
        if(searching) return
        setSearching(true)
        if (!['PENDING', 'CREATING'].includes(props.statusPromotion)) {
            NotificationManager.error('Não é possível remover esse item.', `O item não pode ser removido quando o status da promoção for "Publicada" ou "Expirada"`)
            return
        }
        let filteredList = data.filter(d => d.GTIN !== item.GTIN)
        onRemoveItem(filteredList, item).then(response => {
            setData(filteredList)
        }).catch(e=>{
            catchErrors(e)
        }).finally(()=>{
            setSearching(false)
        })
    }

    const onSearchHandle = (input, page) => {
        if(!input) return setOptions([])
        
        if(page === 0)
            setSearching(true)
        
        else
            setSearchingMore(true)

        searchAPI(input, selectedItemInfos.filter, page).then(response => {
            let mappedResponse = mapSearchResponse(response)
            if(searchFor !== 'SECTOR') {
                let suggest = suggestions.filter(s => !s.newOption)
                suggest.unshift({name:'Nova opção', GTIN:input, id: input, newOption:true, measurementUnit: props.selectedItem.measurementUnit})
                setSuggestions(suggest)
            }
            setHasMoreResults(mappedResponse.length >= 30)
            if(page === 0)
                setOptions(mappedResponse)
            else{
                setOptions([...options, ...filterOptions(mappedResponse, data, selectedItemInfos.id)])
            }
        }).catch(e=>{
            catchErrors(e)
        }).finally(()=>{
            setSearching(false)
            setSearchingMore(false)
        })
    }

    const filterOptions = (options, alreadyInserted) => {
        switch (searchFor) {
            case 'PRODUCT':
                return (options.filter(option => (!alreadyInserted.find(dataItem => dataItem['GTIN'] === option['GTIN']))))
            case 'PROVIDER':
                return options.filter(option => option['id'] !== props.selectedItem['providers'][0]['providerId'] && !alreadyInserted.find(dataItem => dataItem['id'] === option['id']))
            case 'SECTOR':
                return options.filter(option => {
                    let id = `${option.sector1}_${option.sector2}_${option.sector3}_${option.sector4}_${option.sector5}`
                    return id !== props.selectedItem['sectorId'] && !alreadyInserted.find(dataItem => dataItem['sectorId'] === id)
                })
            default:
                return options;
        }
    }

    const validateFields = item => {
        if(props.statusPromotion === 'PUBLISHED' && props.selectedItem.quantity > item.quantity)
            throw ERRORS.ITEM_QUANTITY_BELLOW_MIN
        
        return item
    }

    const mapSearchResponse = (response) => {
        return response.map(r => r._source)
    }

    const onCloseHandle = () => {
        resetFields()
        props.onClose && props.onClose()
    }

    const resetFields = () => {
        props.setSelectedItem(undefined)
        setOptions([])
        setSelectedItemInfos({})
        setSuggestions([])
        setData([])
    }

    const {title} = props

    return (
        <div style={{flexDirection: 'column', display: 'flex', flex: 1, height: 700}}>
            <h1 className='title' style={{alignSelf: 'baseline', marginBottom: 8}}>{title}</h1>
            <TagSelect 
                data={data} 
                suggestions={suggestions}
                loading={searching}
                seeMore={hasMoreResults}
                searchingMore={searchingMore}
                options={options} 
                optionRender={(item)=>selectedItemInfos.optionRender(item)}
                tagRender={(item)=>selectedItemInfos.tagRender(item)}
                onSearch={(input, page) => onSearchHandle(input, page)}
                onAddItem={item => onAddTagHandle(item)}
                onRemove={onRemoveItemHandle}
                style={{marginTop: 8}}
            />
    </div>
    )
}

export default SearchTagSelect