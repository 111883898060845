import { SEGMENTATION_EP } from "./segmentationEP";
import { getFetchPOSTHeader, getFetchGETHeader, getFetchDELETEHeader, validateRequest, getFetchHeader } from "../../api/UtilsAPI";
import { getSegmentationReachDTO, getProductsSegmentationReachDTO, getPromotionSegmentationDTO } from "./segmentationBody";

export const getSegmentationReachAPI = async  (filters) => {
    let request = await fetch(SEGMENTATION_EP.getReach(), getFetchPOSTHeader(getSegmentationReachDTO(filters)))
    validateRequest(request)
    return await request.json()
} 

export const getSegmentationProductsReachAPI = async  (gtinList = []) => {
    let request = await fetch(SEGMENTATION_EP.getProductsReach(), getFetchPOSTHeader(getProductsSegmentationReachDTO(gtinList)))
    validateRequest(request)
    return await request.json()
}

export const setPromotionSegmentationTargetAPI = async  (promotionId, promotionItemId, limit, filters) => {
    let request = await fetch(SEGMENTATION_EP.setTargetReach(), getFetchPOSTHeader(getPromotionSegmentationDTO(promotionId, promotionItemId, limit, filters)))
    validateRequest(request)
    return await request.json()
}

export const setSegmentationTargetCustomersAPI = async  (promotionId, customerIdList) => {
    let request = await fetch(SEGMENTATION_EP.setTargetCustomers(promotionId), getFetchHeader('POST', customerIdList))
    validateRequest(request)
    return await request.json()
}

export const getCustomersSegmentedById = async (promotionId, page) =>{
    let request = await fetch(SEGMENTATION_EP.getCustomersSegmentedUsingId(promotionId, page), getFetchPOSTHeader(page))
    validateRequest(request)
    return await request.json()
}

//oldRequests

export const getPromotionReachAPI = async  (promotionId, page = 0, size = 1) => {
    let request = await fetch(SEGMENTATION_EP.getPromotionReachURL(promotionId, page, size), getFetchGETHeader())
    validateRequest(request)
    return await request.json()
}

export const cancelSegmentation = async  (promotionId) => {
    let request = await fetch(SEGMENTATION_EP.getCancelSegmentationURL(promotionId), getFetchPOSTHeader())
    validateRequest(request)
    return await request.json()
}

export const removeCustomerSegmentation = async  (promotionId, customerId) => {
    let request = await fetch(SEGMENTATION_EP.removeCustomerSegmentation(promotionId, customerId), getFetchDELETEHeader())
    validateRequest(request, [200, 404])
    return await request.json()
}
