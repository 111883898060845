import React, { Component } from 'react';
import SmallCard from '../../../component/smallCard/smallCard';
import './searchOperator.css';
import deepProps from '../../../Utils'
import OperatorApi, { listOperators } from '../../../api/OperatorApi'
import EmptyMessage from '../../../component/emptyMessage/emptyMessage'
import {Redirect} from "react-router-dom";
import LoadingMessage, {Loading} from '../../../component/LoadingMessage/loadingMessage';
import { Observable } from 'rxjs/Rx'
import Button from '../../../component/Button/button'
import DropDownFilter from '../../../component/DropDownFilter/DropDownFilter';
import { Plus } from 'react-feather';

export default class Operator extends Component {
	constructor(props) {
        super(props)
        this.state = {
            operators: [],
            page: 0,
            quantity: 0,
            token: null,
            permission: false,
            logout: false,
            operatorDetail: false,
            count:0,
			maxPerPage:1,
			filters: ['user']
        }
    }

    async componentDidMount() {
		this.setState({searching: true})
		let token = deepProps('token')(this.props.store.getState()).getOrElse('')
		await this.setState({token})
		try{
			this.searchOperators()
		}catch(error){
			console.error(error)
		}
		let permissions = deepProps('permissions')(this.props.store.getState()).getOrElse([])
		let permission = undefined
		for (let p of permissions) {
			if (deepProps('name')(p).get() === 'OPERATOR') {
				permission = p
				break
			}
		}
		let write = false
		let read = false
		if (permission !== undefined) {
			let array = deepProps('allow')(permission).getOrElse([])
			for (let allow of array) {
				if (allow === 'R') {
					read = true
					continue
				}
				if (allow === 'W') {
					write = true
				}
			}
		}
		this.setState({write, read})
	}

	async searchOperators(){
		const operators = await listOperators(this.state.token)
		if (operators === undefined)
			return
		//console.log(operators)
		this.setState({operators,operatorsAll:operators})
	}
	
	mountScreen(){
		this.init()
		this.seeMoreEvent()
	}

	init () {
		let operators = deepProps('operatorSearch')(this.props.store.getState()).getOrElse([])
		let paging = deepProps('pagePromotion')(this.props.store.getState()).getOrElse(null)
		if (paging !== null) {
			let quantity = deepProps('count')(paging).getOrElse(0)
			let page = deepProps('page')(paging).getOrElse(0)
			page+=1
			this.setState({quantity, page})
		}
			
		this.setState({operators},()=>{
			if(operators.length !== 0)
				this.setState({searching: false})
		})
	}

	seeMoreEvent = () => {
		let seeMoreRef = document.querySelector("#seeMoreOperators")
		let eventStream = Observable.fromEvent(seeMoreRef, 'click').map(e => {
			return this.state.page * this.maxPerPage
		}).switchMap((from) => {
			// let search = document.querySelector("#inputSearchOperator") === undefined ? '' : document.querySelector("#inputSearchOperator").value
			this.setState({from, filtering:true})
			return 
			//OperatorApi.getOperatorWithFilter(
				//search, this.state.filter, this.state.from, 
				//this.maxPerPage, this.state.token, () => {this.setState({searching: false})}, (title,message)=>this.alertDialog(title,message)
			//)
				})
		this.initOperatorsReactEvent(eventStream, (result, nextPage, total) => {
						let operators = this.state.operators
						if (nextPage > 1)
								operators = operators.concat(result)
						else
								operators = result
			this.setState({operators, page:nextPage, filtering:false, quantity : total})
		},() => {
			setTimeout(() => this.seeMoreEvent(), 1)
		})
	}
	
	onOperatorCardClick(operator) {
		this.props.store.dispatch({type:'OPERATOR_DETAIL', operator:operator})
		this.setState({searching: false, operatorDetail:true})
	}

	newOperator () {
		this.props.store.dispatch({type:'OPERATOR_DETAIL', operator:{}})
			this.setState({searching: false, operatorDetail:true})
	}
    
	seeMore(){
        let result = this.state.page * this.state.maxPerPage + this.state.operators.length
        if (this.state.count > result) {
			this.setState({searching: true})
            this.props.store.dispatch(OperatorApi.listOperators(this.state.token, this.setState({searching: false}), this.setState({searching: false})))
        } else {
			this.setState({searching: false})
		}
	}
	
	onSelectFilter(filter){
		this.setState({filter:filter})
	}

	async onChangeSearch(value){
		if(value.length === 0) {
			this.setState({operators:this.state.operatorsAll})
			return
		}
		await this.setState({searching:true})
		let filtered = await this.filterOperators(this.state.filter,value)
		this.setState({operators:filtered,searching:false})
	}

	filterOperators(filter,value) {
		try{
			switch(filter.value){
				case 'user':
					return this.operatorsFilteredByUserName(value)
				case 'name': 
					return this.operatorsFilteredByName(value)
				default:
					return this.operatorsFiltered(value) 
			}
		}catch{
			return this.operatorsFiltered(value) 
		}
	
	}

	operatorsFilteredByUserName(userName){
		return this.state.operatorsAll.filter(operator=>(operator.userName.identifier.toUpperCase().includes(userName.toUpperCase())))
	}

	operatorsFilteredByName(name){
		return this.state.operatorsAll.filter(operator=>(operator.name.toUpperCase().includes(name.toUpperCase())))
	}

	operatorsFiltered(text){
		return this.state.operatorsAll.filter(operator=>(operator.name.toUpperCase().includes(text.toUpperCase()) || operator.userName.identifier.toUpperCase().includes(text.toUpperCase())))
	}

	render() {
		if (this.state.logout) {
			return <Redirect to='/'/>
		}
		if (this.state.operatorDetail) {
			return <Redirect to='/main/operator/data'/>
		}
    	return (
			<section className="store">
				<div className="page-title-header">
					<div className="box-title">
						<h1 className="title-page">Operadores</h1>
					</div>
					<form className="operatorSearch">
						{this.state.filtering && <Loading style={{width:21, marginRight: 16}}/>}
						<input type="text" ref={input => this.search = input} className='input-shadow' id='inputSearchOperator' placeholder="Pesquisar..."  onChange={(field)=>{this.onChangeSearch(field.target.value)}}/>
						<span style={{width: 8}}/>
						<DropDownFilter filters={[{text:'Selecione...', value: undefined},{text:'Usuário', value:'user'},{text:'Nome', value:'name'}]} onSelect={(filter)=>this.onSelectFilter(filter)}/>
					</form>
				</div>

				<div className="container-search" id='search-customer-container'> 
					{(this.state.searching && this.state.operators.length === 0) && <LoadingMessage title='Pesquisando...' description='Aguarde, estamos buscando informações!' />}
					{(this.state.operators.length === 0 && !this.state.searching) && <EmptyMessage title='Nenhum resultado encontrado' description='Nenhum resultado para operadores foi encontrado!' style={{left: 55}}/>}
					{
						this.state.operators.map((operator) =>{
							return (
								<SmallCard
									key={operator.operatorId} email={operator.userName.identifier} title={operator.name}
									callback={() => this.onOperatorCardClick(operator)}
								/>
							)
						})
					}
				</div>

				<div className={(this.state.operators.length === this.state.quantity) ? 'none' : "footer-store"}>
					<div className="pagination-footer">
						<div className = "paginator">
							{false && <h3 className="number-pagination" id = "seeMoreBtn">Ver mais</h3>}
						</div>
					</div>
					<div className = "option-footer" >
						{this.state.write && <Button text='Novo Operador' icon={<Plus/>} onClick={()=> this.newOperator()} style={{position: 'absolute', right: 0, minWidth: 137}}/>}
					</div>
				</div>
			</section>
    	);
	}
}
