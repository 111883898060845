export default class WebSocketInterface {
    constructor(endpoint, callback = () => {}, idx) {
        this.callback = callback
        this.open = false
        this.close = false
        this.endpoint = endpoint
        this.websocketInterface = this
        this.idx = idx
    }
    conectar = (token, promotionId) => {
		try {
            console.log('connecting')
            if (this.close || this.open || token === undefined)
                return
            this.token = token
            this.promotionId = promotionId
            this.close = false
			this.websocket = new WebSocket(this.endpoint)
			if (this.websocket !== undefined) {
				this.websocket.onmessage = (data) => {
                    if (data === undefined || data.data === undefined)
                        return
					this.callback(data.data)
				}

				this.websocket.onopen = (event) => {
                    console.log('CONNECTION OPENED')
                    if (token === undefined)
                        return
                    this.open = true
                    let obj = {
                        token : token,
                        promotion_id : [promotionId]
                    }
                    let toSend = JSON.stringify(obj)
                    // console.log(toSend)
                    this.websocket.send(toSend)
				}

				this.websocket.onclose = (event) => {
					console.log(event.code)
                    this.open = false
                    if (event.code === 1006) {
                        this.failOver()
                        return
                    }
				}

				this.websocket.onerror = (event) => {
					console.log(event.code)
                    this.open = false
                    if ([1005, 1000].includes(event.code) || event.code === undefined)
                        return
                    this.failOver()
				}
			}
		}catch(e) {
            this.failOver()
		}
    }

    failOver = () => {
        setTimeout(() => {
            this.conectar(this.token, this.promotionId)
        }, 4000)
    }

    send = (msg, doAfter = () => {}) => {
        if (this.websocket && this.open) {
            try {
                console.log(msg)
                this.websocket.send(msg)
            }catch (e) {
                console.error(e)
                this.failOver()
            }
        }
    }

    isOpen = () => {
        return this.open
    }

    closeWS = () => {
        if (this.websocket)
            this.websocket.close()
        this.close = true
    }
}