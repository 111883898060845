import deepProps, {treatMessage, formatFloat, prepareDate} from '../Utils'
import {promotionsEP} from '../server/endpoints_js'
import { makeRequest, getFetchHeader } from './UtilsAPI'

export default class PromotionAPI {
    static findPromotions = (token, page = 0,alertDialog=()=>{}, successFunc = () => {}, errorFunc = () => {}) => {
        // alert('page -> ' + page)
        return dispatch => {
            let url = promotionsEP.findPromotions(page)
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Authorization': token
                }
            }).then((response) => {
                if (response.status === 200 || response.status === 0)
                    return response.json()
                throw response
            }).then(response => {
				successFunc()
                dispatch({type:'PROMOTION_SEARCH', promotions:response.data, pagePromotion:deepProps('paging')(response).getOrElse(null)})
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
					errorFunc()
                    alertDialog('Erro ao pesquisar promoções!', message)
                })
            })
        }
    }
    
    static findPromotion = (identifier, successFunc = () => {}, errorFunc = () => {}) => {
        return dispatch => {
            fetch(promotionsEP.findPromotion(identifier), getFetchHeader()).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                dispatch({type:'PROMOTION_DETAILS', promotionDetails:response.data})
                successFunc()
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc(message)
                })
            }) 
        }
    }
    
    static getURL(filter, text, page = 0) {
        let search 
        if(filter === undefined || filter.value === undefined){
            search = 'all'
        }else{
            search = filter.value
        }

        switch(search){
            case 'pu': 
                return promotionsEP.findPromotionsPublisheds(page)
            case 'pe':
                return promotionsEP.findPromotionsPendings(page)
            case 'ds':
                return promotionsEP.findPromotionsDeprecateds(page)
            case 'cb':
                return promotionsEP.findPromotionEanCode(page, text)
            default:
                return promotionsEP.findPromotions(page)
        }
    }

    static getPromotionWithFilter = (identifier, filter = '', page = 0, token, successFunc = () => {}, errorFunc = () => {}) => {
        let url = PromotionAPI.getURL(filter, identifier, page)
        return fetch(url, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Authorization': token
            }
        }) 
    }

    static addPromotion = (token, promotion, successFunc = () => {}, errorFunc = () => {}) => {
        return dispatch => {
            let url = promotionsEP.addPromotion()
            fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: promotion
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                console.log('RESPONSE: ', response.data);
                dispatch({type:'PROMOTION_ADDED', promotionDetails:response.data})
                successFunc(response)
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc(message)
                })
            })
        }
    }
    
    static updatePromotion =  (token, promotion, promotionId, successFunc = () => {}, errorFunc= () => {}) => {
        return dispatch => {
            let url = promotionsEP.updatePromotion(promotionId)
            fetch(url, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: promotion
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(async response => {
                promotion = await JSON.parse(promotion)
                if(response.data.promotionPattern !== promotion.promotionPattern){
                    response.data['promotionPattern'] = promotion.promotionPattern.toLocaleUpperCase()
                }
                dispatch({type:'PROMOTION_UPDATED', promotionDetails:response.data})
                successFunc(response)
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc(message)
                })
            })
        }
    }

    static getPromotionJson(promotion, segmentationType = "PUBLIC"){
        let {description, dateStart, dateEnd,limitRedeem, limitUse, value, typeValue, rules, items, promotionPattern} = promotion
        let begin = new Date(prepareDate(dateStart, true))
        begin.setHours(0)
        begin.setMinutes(0)
        begin.setSeconds(0)
        begin.setMilliseconds(0)
        let end = new Date(prepareDate(dateEnd, true))
        end.setHours(23)
        end.setMinutes(59)
        end.setSeconds(59)
        end.setMilliseconds(999)
        return {
            "description": description,
            "dateStart" : begin,
            "dateEnd" : end,
			"limitRedeem" : limitRedeem,
			"limitUse" : limitUse,
            "segmentationType": segmentationType,
            "value" : (value.toString().indexOf(',') !== -1) ? formatFloat(value) : value,
            "typeValue": typeValue,
            "rules" : rules,
            "items" : items,
            promotionPattern: promotionPattern.toLocaleLowerCase()
        }
    }
    
    static addRestrictedDay = (token, id, day, dayType, successFunc = () => {}, errorFunc = () => {}) => {
        let url = promotionsEP.addRestrictDay(id)
        fetch(url, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body:JSON.stringify({
            	day: day, 
            	dayType: dayType
        	})
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => successFunc(response)).catch(error => {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        })
    }
    
    static segmentationByCustomersIdentifier = (token, promotionId = 0, body, successFunc = () => {}, 
        errorFunc = () => {}, method='POST', page=0) => {
        if (promotionId < 1) {
            errorFunc('Invalid promotionId on segmentationByCustomersIdentifier')
            return
        }
        if (['POST', 'DELETE'].includes(method)) {
            if (body === undefined) {
                errorFunc('Body is not defiend on segmentationByCustomersIdentifier')
                return
            }
        }
        let url = promotionsEP.segmentationByCustomersIdentifier(promotionId, page)
        let header = {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }
        if (body !== undefined)
            header['body'] = JSON.stringify(body)
        fetch(url, header).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => successFunc(response)).catch(error => {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        })
    }

    static removeRestrictedDay = (token, dayId, successFunc = () => {}, errorFunc = () => {}) => {
        fetch(promotionsEP.removeRestrictedDay(dayId), {
            method: "DELETE",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            if (response.status === 404) {
                console.log('day already removed')
                return {}
            }
            throw response
        }).then(response => {
            response['data'] = dayId
            successFunc(response)
        }).catch(error => {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        }) 
        
    }
    
    static addRestrictedHour = (token, dayId, hourStart, hourEnd, successFunc = () => {}, errorFunc = () => {}) => {
        let url = promotionsEP.addRestrictHour(dayId)
        fetch(url, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
                },
            body:JSON.stringify({
                'hourStart' : hourStart,
                'hourEnd' : hourEnd
            })
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            successFunc(response)
        }).catch(error => {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        })
    }

    static updateRestrictedHour = (token, hourId, body, successFunc = () => {}, errorFunc = () => {}) => {
        let url = promotionsEP.updateRestrictedHour(hourId)
        fetch(url , {
            method: "PUT",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
                },
            body:JSON.stringify(body)
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            successFunc(response)
        }).catch(error => {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        })
    }

    static getRestrictedDays = (token, promotionId, successFunc = () => {}, errorFunc = () => {}) => {
        let url = promotionsEP.getRestrictedDays(promotionId)
        fetch(url, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            successFunc(response)
        }).catch(error => {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        })
    }
    
    static removeRestrictHour = (token, itemId, successFunc = () => {}, errorFunc = () => {}) => {
        let url = promotionsEP.removeRestrictHour(itemId)
        fetch(url , {
            method: "DELETE",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            successFunc(response)
        }).catch(error => {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        })
    }

    static publish = (token, promotionId, successFunc = () => {}, errorFunc = () => {}) => {
        return dispatch => {
            let url = promotionsEP.publish(promotionId)
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                successFunc(response)
                dispatch({type: 'CHANGE_STATUS_PROMOTION', promotionId, promotionStatus:'PUBLISHED'})
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc(message)
                })
            })
        }
    }
    
    static deprecated = (token, promotionId, successFunc, errorFunc) => {
        return dispatch => {
            let url = promotionsEP.deprecated(promotionId)
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                successFunc(response)
                dispatch({type: 'CHANGE_STATUS_PROMOTION', promotionId, promotionStatus:'DEPRECATED'})
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc(message)
                })
            })
        }
    }

	static getPromotionsReport = (identifier, token, successFunc = () => {}, errorFunc = () => {}) => {
        return dispatch => {
            let url = promotionsEP.promotionReport(identifier)
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Authorization': token
                }
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                console.log(response)
                dispatch({type:'PROMOTION_REPORT', promotionReport:response.data})
                successFunc()
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc(message)
                })
            }) 
        }
    }

	static getItensTimeReport = (token, promotionId, query, successFunc = () => {}, errorFunc = () => {}) => {
        let url = promotionsEP.promotionReportItemsPromotion(promotionId, query)
        fetch(url, {
            method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Authorization': token
                }
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                successFunc(response)
            }).catch(error => {
                treatMessage(error, () => {}, (message) => {
                    errorFunc(message)
                })
            }) 
    }
}

export const publishPromotionAPI = (promotionId) => makeRequest(promotionsEP.publish(promotionId),getFetchHeader('POST'))

export const getPromotionsAPI = (page = 0) => makeRequest(promotionsEP.findPromotions(page),getFetchHeader('GET'))