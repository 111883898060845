import React, { useState, useEffect } from 'react';
import { ArrowUpCircle, Star, PauseCircle, Clock, Pause } from 'react-feather';
import AnimatedNumber from 'react-animated-number'
import './style.scss'
import { Loading } from '../../LoadingMessage/loadingMessage';
import ReactTooltip from 'react-tooltip'
import { formatBigNumber } from '../../../Utils';

export default function KPIInfo ({style, title, value, color, increase, past, loading, increaseTip}){
    const [animatedValue, setAnimatedValue] = useState(0)

    useEffect(()=>{
        setAnimatedValue(value)
    },[value])

    const getIncreaseLabel = increase => {
        return ((increase && increase !== Infinity) ?  increase.toFixed(1) : 0) + '%'
    }

    return (
        <div className='kpi-info' style={style}>
            {!loading && <div className='value-container'>
                <div className='label' style={{backgroundColor: color}}></div>
                <AnimatedNumber className='value' value={loading ? 0 : Number(animatedValue)} duration={1000} stepPrecision={0} formatValue={number => formatBigNumber(number)}/>
            </div>}
            {loading && <div className='value-container'>
                <Loading style={{width: 16, padding: 8}}/>
            </div>}
            <h2>{title}</h2>
            {(!loading && increase === 0) && <div data-tip={increaseTip} className={'comparative ' + (increase === 0 ? 'same' : ((increase > 0) ? 'up' : 'down'))}>
                {increase === 0 ? <PauseCircle size={16}/> : <ArrowUpCircle size={16}/>}
                <h3>{increase === 0 ? 'Equivalente' : getIncreaseLabel(increase)}</h3>
                {increaseTip && <ReactTooltip/>}
            </div>}
            {(!loading && past) && <div className={'comparative past'}>
                <Clock size={16}/>
                <h3>{past}</h3>
            </div>}
        </div>
    )
}


export function RankingInfo({value, description, toFixed, loading}) {
	return (
		<div className='ranking-info'>
            {!loading && <div className='value-container' style={{flexDirection: 'column'}}>
                <AnimatedNumber className='value' value={loading ? 0 : Number(value)} duration={1000} stepPrecision={0} formatValue={number => formatBigNumber(number, toFixed && 3)}/>
			    <h2>{description}</h2>
            </div>}
            {loading && <div className='value-container'>
                <Loading style={{width: 16, padding: 8}}/>
            </div>}
		</div>
	)
}

export function RankingState({position,title}) {
	return (
		<div className={'ranking-info'}>
            <div className={'comparative ' + position}>
                {position === 'new' && <Star size={16}/>}
                {/* TODO Quando a posição nao mudar e ele nao for novo na lista */}
                {position === 'equal' && <Pause size={16}/>}
                {(position === 'up' || position === 'down') && <ArrowUpCircle size={16}/>}
                <h3>{title}</h3>
            </div>
		</div>
	)
}