import React, { Component } from 'react';
import AsideProfileCard from '../../component/AsideProfileCard/AsideProfileCard';
import AsideListCard from '../../component/AsideListCard/AsideListCard';
import AsideDiscountCard from '../../component/AsideDiscountCard/AsideDiscountCard';
import AsideButtonCard from '../../component/AsideButtonCard/AsideButtonCard';
import './asideProduct.css';
import deepProps, {getFormattedDate, printLog, errorLog} from '../../Utils'
import PromotionAPI from '../../api/PromotionApi';
import {AlertDialog, AskDialog} from '../../component/alertDialog/alertDialog'
import { ReachAsideCard } from '../../component/ReachVisualization/reachAsideCard';
import { cancelSegmentation } from '../../server/segmentation/segmentationAPI';
import Button from '../../component/Button/button';
import AbstractModal from '../../component/AbstractModal/AbstractModal';

class AsideProduct extends Component {
    
    //Setar o Store para o state da tela e ao inves de pegar do props direto, pegar do state
    
    constructor(props) {
        super(props)
        this.state = {
            token: '',
        }
    }

    componentWillMount () {
        let lastState = this.props.store.getState()
        let token = deepProps('token')(lastState).getOrElse('')

        if(lastState.type !== 'NEW_PROMOTION')
        this.props.store.dispatch({type:'PROMOTION_UPDATED', promotionDetails:lastState['promotionDetails']})
        // this.setStateSegmentationTypeLabels(this.props.promotion.segmentationType)
        this.setState({token})
    }

    publish = () => {
        let promotionId = deepProps('promotion', 'id')(this.props).getOrElse (0)
        if (promotionId < 1) {
            AlertDialog('Acesso indevido!', 'Promoção não encontrada. Favor logar-se novamente!.')
            return
        }

        console.log(this.props.store.getState().promotionDetails);

        AskDialog('Deseja realmente publicar a promoção?', 'Ao publicar, a promoção "' + this.props.promotion.description + '" estará disponível para os clientes e será visível no aplicativo.', ()=>{
            this.props.store.dispatch(PromotionAPI.publish(this.state.token, promotionId, () => {
                AlertDialog('Promoção publicada!', 'O status da promoção foi alterado para "publicada"')
            }, (errorMessage) => {
                AlertDialog('Ops, algo deu errado', 'Não foi possível alterar o status da promoção.' + errorMessage)
            }))
        })
    }

    deprecated () {
        let promotionId = deepProps('promotion', 'id')(this.props).getOrElse (0)
        if (promotionId < 1) {
            AlertDialog('Acesso indevido!', 'Promoção não encontrada. Favor logar-se novamente!.')
            return
        }

        AskDialog('Deseja realmente desativar esta promoção?', 'Ao confirmar, a promoção "' + this.props.promotion.description + '" será desativada e não aparecerá mais no aplicativo.', ()=>{
            this.props.store.dispatch(PromotionAPI.deprecated(this.state.token, promotionId, (result) => {
                AlertDialog('Promoção desativada!', 'O status da promoção foi alterado para "desativada"')
                printLog(result)
            }, error => {
                AlertDialog('Ops, algo deu errado', 'Não foi possível alterar o status da promoção. ' + error)
                errorLog(error)
            }))
        })
    }

    getPromotionImg(img){
        return img
    }

    cancelSegmentation = (promotionId) => {
        // alert('cancelar')
        cancelSegmentation(promotionId)
    }

    getPromotionType = (promotion) => {
        if (!promotion)
            return ''
        let {
            promotionPattern, typeValue
        } = promotion
        switch(promotionPattern) {
            case 'VOUCHER':
                return 'BY_VALUE'
            case 'FAST':
            default:
                return typeValue
        }
    }

    getFormattedRules(rules){
        if(rules.length > 140){
            return rules.substring(0,140) + '...'
        }
        return rules
    }

    render() {
        let {promotion, write} = this.props
        if (promotion === undefined)
            promotion = {
                id: 0
            }
        return (
            <aside className="asideProduct">
                <AsideProfileCard img={this.getPromotionImg(this.props.promotion.resourceImage)} write={this.props.write} read = {this.props.read} title={deepProps('description')(this.props.promotion).getOrElse('Nova promoção')} status={this.props.promotion.status}/>
                {this.props.promotion.value && <AsideDiscountCard title={this.props.promotion.promotionPattern === 'VOUCHER' ? "Valor do voucher" : "Valor do Desconto"} value={this.props.promotion.promotionPattern === 'VOUCHER' ? this.props.promotion.maxDiscountByCustomer : this.props.promotion.value} type={this.getPromotionType(this.props.promotion)} write={this.props.write} read = {this.props.read}  />}
                {
                    this.props.promotion.status === 'PENDING' && this.props.write &&
                    <AsideButtonCard title='Publicar' text='Deixe que o mundo descubra essa promoção!'>
                            <AbstractModal promotion={promotion} store={this.props.store}/>
                    </AsideButtonCard>
                }
                {this.props.promotion.dateStart && <AsideListCard write={this.props.write} read = {this.props.read} data={[{title:'Data Início', data:getFormattedDate(this.props.promotion.dateStart, false)},{title:'Data Término', data:getFormattedDate(this.props.promotion.dateEnd, false)}, {title:'Regra', data:this.props.promotion.rules}]}/>}
                {false && <AsideListCard data={[{title:'Regra', data:this.props.promotion.rules}]} write={this.props.write} read = {this.props.read}/>}
                {
                    write &&  
                    ((promotion.status === 'PUBLISHED' && promotion.segmentationType === 'BY_IDENTIFIER') || 
                        promotion.status === 'PENDING') &&
                    <ReachAsideCard {...this.props}/>
                }
                {
                    write && false &&
                    (promotion.status === 'PENDING' && promotion.segmentationType !== 'PUBLIC') &&
                    <AsideButtonCard title='Sementada' invert text='A promoção será disponível apenas para clientes segmentados' 
                        button='Cancelar' icon='check' onClick={()=>this.cancelSegmentation(promotion.id)}/>
                }
                {
                    this.props.promotion.status === 'PUBLISHED' && this.props.write &&
                    <AsideButtonCard title='Desativar promoção' invert text='O cupom sairá de circulação. Clientes que já ativaram não serão confiscados até a data de expiração' 
                        button='Desativar' icon='check' onClick={()=>this.deprecated()}/>
                }
            </aside>
        );
    }
}

export default AsideProduct;