import React, { useState, useEffect } from 'react';
import './DropDownFilter.css';
import deepProps from '../../Utils';
import {Filter} from 'react-feather'
      
const DropDownFilter =  (props) => {
  const [filters, setFilters] = useState(props.filters)
  const [selected, setSelected] = useState(deepProps('selected')(props).getOrElse({text:'Selecione...', value:'td'}))
  const {title, style} = props

  const onSelect = (filter) => {
      if(props.onSelect)
          props.onSelect(filter)
	  if(props.removableSelect)
      removeItem(filter)
      setSelected(filter)
  }

  const removeItem = (itemToRemove) => {
	  setFilters(filters.filter((item)=>item!==itemToRemove))
  }

  useEffect(()=>{
    if(props.filters !== filters)
      setFilters(props.filters)
  },[props])

  useEffect(()=>{
    if(!props.value) return
    onSelect(filters.find(f => f.value === props.value))
  },[props.value])

  const getClassName = () => {
    return (props.border) ? 'dropdownfilter border' : 'dropdownfilter'
  }

  return (
    <div style={style}>
    {title && <h1 className='input-title'>{title}</h1>}
    <div className={getClassName()} style={props.style}>
          <div className='display'>
              <h1 className='displayed'>{selected.text}</h1>
              {!props.icon &&!props.iconless && <Filter size={21}/>}
              {props.icon && props.icon}
          </div>
          <ul className='filterlist'>
      {filters.map((filter, idx)=>
        <li key = {idx} onClick={()=>onSelect(filter)}><h1>{filter.text}</h1></li>
              )}
          </ul>
    </div>
    </div>
  )
}

export default DropDownFilter