import React, { Component } from 'react';
import Button from '../../Button/button'
import deepProps from '../../../Utils'
import StoreApi from '../../../api/StoreApi'

import './StoreData.css';
import { AlertDialog } from '../../alertDialog/alertDialog';
import { Save } from 'react-feather';

export default class StoreData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			description: deepProps('description')(this.props.data).getOrElse(''),
			number: deepProps('number')(this.props.data).getOrElse(0),
			id: deepProps('id')(this.props.data).getOrElse(0),
			cnpj: deepProps('identifierDefault', 'identifier')(this.props.data).getOrElse(''),
			store:{},
			userAccess:undefined,
			token:''
		};
	}

	componentWillUpdate(prevProps) {
		if (prevProps === this.props)
			return
		this.loadData()
	}

	componentDidMount () {
		this.loadData()
	}
	loadData () {
		let lastState = this.props.store.getState()
		let token = deepProps('token')(lastState).getOrElse('')
		let data = deepProps('storeDetail')(lastState).getOrElse({})
		let description = deepProps('description')(data).getOrElse('')
		let userAccess = deepProps('userAccess', 'userType')(lastState).get()
		let number = deepProps('number')(data).getOrElse(0)
		let id = deepProps('id')(data).getOrElse(0)
		let cnpj = deepProps('identifierDefault', 'identifier')(data).getOrElse('')
		this.setState({store:data, description, number, id, userAccess, token, cnpj})
	}
	
	canSave (write = false) {
		return ['OPERATOR'].includes(this.state.userAccess) && write
	}

	canChange (write = false) {
		return this.canSave(write) && this.state.id === 0
	}

	saveStore () {
		let {cnpj} = this.state
		if (cnpj !== undefined)
			cnpj = cnpj.replace(/[^a-z0-9]/gi,'')
		console.log(cnpj)
		let storeId = this.state.id
		let result = {
			number : this.state.number,
			description: this.state.description,
			identifierDefault : {
				identifier : cnpj,
				type : "CNPJ"
			}
		}
		StoreApi.saveStore(this.state.token, storeId, result, (response) => {
			if (storeId === 0 && response !== undefined) {
				let data = deepProps('data')(response).getOrElse({})
				let id = deepProps('id')(data).getOrElse(0)
				this.props.store.dispatch({type:'STORE_DETAILS', storeDetail:data})
				this.setState({id})
			}else {
				if (response !== undefined) {	
					let data = deepProps('data')(response).getOrElse({})
					this.props.store.dispatch({type:'STORE_DETAILS', storeDetail:data})
				}
			}
			AlertDialog('Operação concluída', 'A loja foi salva com sucesso.')
		}, (error) => {
            AlertDialog('Ops, algo deu errado.', error)
		}) 
	}
	
  	render() {
		let {write} = this.props
		return (
			<form className="StoreData">
				<div className="form-StoreData">
					<label> ID <input type="text" placeholder="Digite o ID..." value={this.state.id} 
						onChange={(e) => this.setState({id:e.target.value})} disabled={true}/> </label>
					<label> CNPJ <input type="text" placeholder="Digite o CNPJ.." value={this.state.cnpj} 
						onChange={(e) => this.setState({cnpj:e.target.value})} disabled={!this.canChange(write)}/> </label>
					<label> Número <input type="number" placeholder="Digite o número.." value={this.state.number} 
						onChange={(e) => this.setState({number:e.target.value})} disabled={!this.canChange(write)}/> </label>
				</div>
				<div className="form-StoreDataDescription">
					<label> Descrição <input type="text" placeholder="Digite a descrição..." value={this.state.description} onChange={(e) => this.setState({description:e.target.value})}/> </label>
				</div>
				<div className='btnSave'>
					{this.canSave(this.props.write) && <Button text='Salvar' icon={<Save/>} onClick={() => this.saveStore()} style={{maxWidth: 75, paddingLeft: 16, position: 'absolute', right: 32, bottom: 16}}/>}
				</div>
			</form>
		);
  	}
}