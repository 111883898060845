import React, { Component } from 'react';
import './loadingMessage.css';
import loadingGif from '../../img/loading.gif'

export default  class LoadingMessage extends Component {
  render() {
    return (
    	<div className="loadingMessage" style={this.props.style}>
        <img src={loadingGif} alt={"Loading"} style={{width: 21, height: 21}}/>
        {this.props.title && <h1>{this.props.title}</h1>}
        {this.props.description && <h2>{this.props.description}</h2>}
    	</div>
    );
  }
}

export class Loading extends Component {
  render() {
    return (
			<img src={loadingGif} style={this.props.style} className={'loadingGif'} alt={"Loading"}/>
    );
  }
}

export class LoadingPageBlocker extends Component {
  render() {
    return (
      <div className='page-blocker'>
        <div>
          <img src={loadingGif} style={{width: 30}} className={'loadingGif'} alt={"Loading"}/>
          {/* <h1>Carregando promoção...</h1> */}
        </div>
      </div>
    );
  }
}

const ProgressBarLoading = ({style}) => {
  return (
    <div className='progress-bar-loading' style={style}>
      {/* <h1>Processando...</h1> */}
    </div>
  )
}

export {ProgressBarLoading}