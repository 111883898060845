import React, { useState } from 'react'
import './style.scss'
import Button from '../../../Button/button'
import { PlusCircle, MinusCircle, Trash, Edit } from 'react-feather'
import { Loading } from '../../../LoadingMessage/loadingMessage'

const HomeSection = ({image, version, description, linkTo, id, edit, status, onClick, onPublish, onRemove, onDelete, onEdit, style, imgStyle}) => {

    const [loading, setLoading] = useState(false)

    const getStatusColor = status => {
        switch (status){
            case 'DEPRECATED':
            case 'PENDING': return 'yellow'
            case 'PUBLISHED': return 'green'

        }
    }

    const onImageClickHandle = () => {
        onClick && onClick({image, description, linkTo, id, status})
    }

    const onPublishHandle = () => {
        if(onPublish) return onPublish({image, description, linkTo, id, status})
    }

    const onRemoveHandle = () => {
        if(onRemove) return onRemove({image, description, linkTo, id, status})
    }

    const onDeleteHandle = () => {
        if(onDelete) return onDelete({image, description, linkTo, id, status})
    }

    const onEditHandle = () => {
        if(onEdit) return onEdit({image, description, linkTo, id, status})
    }

    return(
        <div id={`app-home-section-container`} className={false ? 'loading-section' : ''}>
            <div id='app-home-section' style={style}>
                <img src={`${image}?v=v${version ? version : '1'}`} style={imgStyle} onClick={onImageClickHandle}/>
                <h1>{description}</h1>
            </div>
            {edit && <div className={`infos actions`}>
                {/* <Button text='Publicar' textOnly onClick={onClickHandle}/> */}
                {(['DEPRECATED', 'PENDING'].includes(status)) && <PlusCircle color={'#8a8a8a'} size={21} onClick={onPublishHandle}/>}
                {status === 'PUBLISHED' && <MinusCircle color={'#8a8a8a'} size={21} onClick={onRemoveHandle}/>}
                {onEdit && <Edit color={'#8a8a8a'} size={21} onClick={onEditHandle}/>}
                {onDelete && <Trash color={'#8a8a8a'} size={21} onClick={onDeleteHandle}/>}
                <div className={`status ${getStatusColor(status)}`}></div> 
            </div>}
        </div>
    )
}

export default HomeSection