import {ProfileEP, OperatorEP, LiberationEP} from '../server/endpoints_js'
import deepProps, {treatMessage} from '../Utils'
import { requestResponseValidate, getRequestSettings, validateRequest } from './UtilsAPI';

export default class OperatorApi {
	static getProfile = (token, successFunc = () => {}, errorFunc = () => {}) => {
		let url = ProfileEP.getProfile()
			fetch(url, {
				method: "GET",
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': token
				}
			}).then((response) => {
				if (response.status === 200)
					return response.json()
				throw response
			}).then(response => {
				successFunc(response)
				return
			}).catch(error => {
                treatMessage(error, () => {}, (message) => {
                    errorFunc(message)
                })
			}) 
	}

	static createOperator = (token, operator, successFunc = () => {}, errorFunc = () => {}) => {
		let url = OperatorEP.createOperator()
		fetch(url, {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': token
			},
			body: JSON.stringify(operator)
		}).then((response) => {
			if (response.status === 200)
				return response.json()
			throw response
		}).then(result => {
			successFunc(deepProps('data')(result).get())
		}).catch(error => {
			treatMessage(error, () => {}, (message) => {
				errorFunc(message)
			})
		}) 
	}

	static deleteOperator = async (profileId) => {
        return validateRequest(await fetch(OperatorEP.operatorID(profileId), getRequestSettings('DELETE',await localStorage.getItem('token'))))
    }

	static insertPermissionToProfile = async (token, body, profileId) => {
        return requestResponseValidate(await fetch(ProfileEP.insertPermission(profileId), getRequestSettings('PUT',token,body)))
	}
	
	static insertProfileToOperator = async (token, body, profileId) => {
        return requestResponseValidate(await fetch(OperatorEP.operatorProfiles(profileId), getRequestSettings('PUT',token,body)))
	}
	
	static deleteProfileFromOperator = async (token, body, profileId) => {
        return requestResponseValidate(await fetch(OperatorEP.operatorProfiles(profileId), getRequestSettings('DELETE',token,body)))
    }

	static getOperatorProfiles = (token, identifier, successFunc = () => {}, errorFunc = () => {}) => {
		console.log(OperatorEP.operatorProfiles(identifier))
		let url = OperatorEP.operatorProfiles(identifier)
		fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': token
			}
		}).then((response) => {
			if (response.status === 200)
				return response.json()
			throw response
		}).then(response => {
			successFunc(response)
		}).catch(error => {
			treatMessage(error, () => {}, (message) => {
				errorFunc(message)
			})
		}) 
	}

	static getPermissions = (token, successFunc = () => {}, errorFunc = () => {}) => {
		let url = LiberationEP.getLiberations()
		fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': token
			}
		}).then((response) => {
			if (response.status === 200)
				return response.json()
			throw response
		}).then(response => {
			successFunc(response)
		}).catch(error => {
			treatMessage(error, () => {}, (message) => {
				errorFunc(message)
			})
		}) 
	}

	static getProfilesLiberations = (token, profileId, successFunc = () => {}, errorFunc = () => {}) => {
		let url = LiberationEP.getProfilesLiberations(profileId)
		fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': token
			}
		}).then((response) => {
			if (response.status === 200)
				return response.json()
			throw response
		}).then(response => {
			successFunc(response)
		}).catch(error => {
			treatMessage(error, () => {}, (message) => {
				errorFunc(message)
			})
		}) 
	}

	static updateProfile = (token, profileId, profile, successFunc = () => {}, errorFunc = () => {}) => {
		let url = ProfileEP.updateProfile(profileId)
		fetch(url, {
			method: "PUT",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': token
			},
			body: JSON.stringify(profile)
		}).then((response) => {
			if (response.status === 200)
				return response.json()
			throw response
		}).then(response => {
			successFunc(response)
		}).catch(error => {
			treatMessage(error, () => {}, (message) => {
				errorFunc(message)
			})
		}) 
	}

	static deleteProfile = async (token, profileId) => {
        return requestResponseValidate(await fetch(ProfileEP.deleteProfile(profileId), getRequestSettings('DELETE',token)))
    }

	static deletePermissionFromProfile = async (token, profileId, permissions, successFunc = () => {}, errorFunc = () => {}) => {
		let url = LiberationEP.deletePermission(profileId)
		let response = await fetch(url, {
			method: "DELETE",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': token
			},
			body: JSON.stringify([permissions])})

		if (response.status !== 200)
			throw response

		let result = await response.json()
		successFunc(deepProps('data')(result).get())
		return deepProps('data')(result).get()
	}

    static createProfile = async (token, body) => {
        return requestResponseValidate(await fetch(ProfileEP.createProfile(), getRequestSettings('POST',token,body)))
    }

}

export const listOperators = async  (token) => {
	const response = await fetch(OperatorEP.listOperators(), getFetchGETHeader(token))
	checkFetchStatus(response.status)
	const json = await response.json()
	return json.data
}

export const updateOperator = async (token, identifier, operator) => {
	const response = await fetch(OperatorEP.operatorID(identifier), getFetchPUTHeader(token, operator))
	checkFetchStatus(response.status)
	const json = await response.json()
	return json.data
}

export const updatePassword = async (token, operatorId, json) => {
	const response = await fetch(OperatorEP.updateOperatorsPassword(operatorId), 
		getFetchPUTHeader(token, json))
	checkFetchStatus(response.status)
	const result = await response.json()
	return result.data
}

const checkFetchStatus = (status) => {
    switch(status) {
        case 200:
            return 
		case 403:
			alert('Usuário não tem permissão para executar esse processo.')
		break
        case 401:
			let x = {status : 12, message : 'Requeried authentication'}
            throw x
        default:
			let y = {status : -1, message : 'Unknow receive error'}
            throw y
    }
}

const getFetchGETHeader = (token) => {
	return {
		method: "GET",
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': token
		}
	}
}

const getFetchPUTHeader = (token, operator) => {
	return {
		method: "PUT",
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': token
		},
		body: JSON.stringify(operator)
	}
}

/*const getFetchDELETEHeader = (token) => {
	return {
		method: "DELETE",
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': token
		}
	}
}*/