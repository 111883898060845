import React, { Component } from 'react';
import './image-avatar.scss';
import imgPerfil from '../../img/user.svg';

class ImageAvatar extends Component {
	constructor(props){
		super(props)
		this.state = {
			imageError: false
		}
	}

	getClass(size = 'normal'){
		switch (size) {
			case 'small':
				return "background-picture small"
			default:
				return "background-picture"
		}
	}

	render(){
		return (
			<div className={this.getClass(this.props.size)} style={this.props.style}>
				<div className={'avatar-pic'}>
					{/* {this.props.onCLick && <h3 className="edit-picture">{this.props.img ? 'Alterar' : 'Inserir'}</h3>	}									 */}
					{(this.props.img && !this.state.imageError && !this.state.imageLoaded) && <img src={this.props.img ? this.props.img : imgPerfil} alt=""></img>}
					{(this.props.img && !this.state.imageError) && <img  className={this.state.imageLoaded ? 'img-profile loaded' : 'img-profile loading'} onLoad={()=>{this.setState({imageLoaded:true})}} onError={()=>{this.setState({imageError:true})}} src={this.props.img} alt={this.props.name}></img>}
					{(!this.props.img || this.state.imageError) && <div className="name-logo" ><h1>{(this.props.title && this.props.title.length >=2) ? this.props.title.substring(0,2) : '?'}</h1></div>}
				</div>
			</div>
		)
	}
} 

export default ImageAvatar;