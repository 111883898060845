import deepProps, {treatMessage} from '../Utils'
import { promotionsEP, elasticGatewayEP, storageUploadEP, recommenderEP } from '../server/endpoints_js';
import {requestResponseValidate, getRequestSettings } from './UtilsAPI';
export default class ItemApi {
     static findItemsPromotion = (token, promotion, successFunc = () => {}, errorFunc = () => {}) => {
          return dispatch => {
                let url = promotionsEP.findItemsPromotion(promotion)
                fetch(url, {
                    method: "GET",
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token
                    }
                }).then((response) => {
                    if (response.status === 200)
                        return response.json()
                    throw response
                }).then(response => {
                    dispatch({type:'PROMOTION_ITEMS_SEARCH', promotionsItems:response.data})
                    successFunc(response.data)
                }).catch(error => {
                    treatMessage(error, dispatch, (message) => {
                        errorFunc('Não foi possível encontrar os itens da promoção. ' + message)
                    })
                }) 
          }
      }
     
    static findItems = (token, query, successFunc = () => {}, errorFunc = () => {}) => {
        let url = elasticGatewayEP.findItems()
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body : JSON.stringify(query)
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                let finalResult = []
                if (!response.timed_out) {
                    let result = deepProps('hits')(response).getOrElse({})
                    let results = deepProps('hits')(result).getOrElse([])
                    finalResult = results.map((r) => deepProps('_source')(r).getOrElse({}))
                }
                return finalResult
            }).then(response => successFunc(response)).catch(error => {
                treatMessage(error, () => {}, (message) => {
                    errorFunc('Não foi possível acessar os itens. ' + message)
                })
            })
    }

    static getItem = (token, gtin, successFunc = () => {}, errorFunc = () => {}) => {
        let url = elasticGatewayEP.findProductByEAN(gtin)
        fetch(url, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            let finalResult = undefined

            if (!response.timed_out && response.found){
                finalResult = deepProps('_source')(response).getOrElse({})
            }
            
            successFunc(finalResult)
        }).catch(error => {
            treatMessage(error, () => {}, (message) => {
                errorFunc('Não foi possível acessar os itens. ' + message)
            })
        })
    }

     static findItemsX = (token, query) => {
       let json = JSON.stringify(query)
        let url = elasticGatewayEP.findItems()
       return fetch(url, {
           method: "POST",
           headers: {
               Accept: 'application/json',
               'Content-Type': 'application/json',
               'Authorization': token
           },
           body : json
       }).catch(e => {
           console.error(e)
       })
    }

     static addItem = (token, promotionId, item, successFunc = () => {}, errorFunc = () => {}) => {
        return dispatch => {
            let url = promotionsEP.addItem(promotionId)
            fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': token
                },
                body : JSON.stringify(item)
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                dispatch({type:'PROMOTION_ADD_ITEM', itemAdded:response.data})
                successFunc(response)
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc('Não foi possível adicionar o item a promoção. ' + message)
                })
            })
        }
     }

     static updateItem = (token, itemId, item, successFunc = () => {}, errorFunc = () => {}) => {
        return dispatch => {
            let url = promotionsEP.updateItem(itemId)
            fetch(url, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': token
                },
                body : JSON.stringify(item)
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                dispatch({type:'PROMOTION_UPDATE_ITEM', itemUpdated:response.data})
                successFunc(response)
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc('Não foi possível editar o item da promoção. ' + message)
                })
            })
        }
     }
     
     static removeItem = (token, itemId, successFunc, errorFunc) => {
        return dispatch => {
            let url = promotionsEP.removeItem(itemId)
            fetch(url, {
                method: "DELETE",
                headers: {
                    Accept: 'application/json',
                    'Authorization': token
                }
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                if (deepProps('data')(response).getOrElse(null) === null)
                    response['data'] = itemId
                dispatch({type:'PROMOTION_REMOVE_ITEM', itemRemoved:response.data})
                successFunc(response)
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc('Não foi possível remover o item da promoção. ' + message)
                })
            })
        }
     }
     
     
     static removeItems = (token, promotionId, items, successFunc, errorFunc) => {
         return dispatch => {
            let json = JSON.stringify(items)
            let url = promotionsEP.removeItems(promotionId)
            fetch(url, {
                method: "DELETE",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: json
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                if (deepProps('data')(response).getOrElse(null) === null)
                    response['data'] = items
                dispatch({type:'PROMOTION_REMOVE_ITEMS', itemRemoved:response.data})
                successFunc(response)
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc('Não foi possível remover os itens da promoção. ' + message)
                })
            })
         }
     }
     
    static uploadImage = (token, file, successFunc, errorFunc) => {
        console.log('File', file)
        var data = new FormData()
        data.append('image', file)
        console.log('Data', data)
        let url = storageUploadEP.imageProductsUpload()
        return dispatch => {
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Authorization': token
                },
                body : data
            }).then((response) => {
                if (response.status === 200) {
                    return response.json()
                }
                throw response
            }).then(response => {
                dispatch({type:'PRODUCT_IMAGE_UPLOAD', productImageURL:response.url})
                successFunc(deepProps('url')(response).getOrElse(''))
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc('Não foi possível realizar o upload da imagem . ' + message)
                })
            })
        }
     }
     
     static saveIfNotExist = (token, element, elementId, successFunc, errorFunc) => {
         let url = elasticGatewayEP.findItemById(elementId)
         console.log(url)
         fetch(url, {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
            }).then((response) => {
                switch(response.status) {
                    case 200:
                    case 404:
                        return response.json()
                    default:
                        throw response
                }
            }).then((response) => {
                console.log('Response Modal antiga', response)
                let edit = deepProps('found')(response).getOrElse(false)
                let result = element
                if (edit) {
                    result = deepProps('_source')(response).getOrElse(null)
                    if (result === null)
                        return
                    result['resourceImage'] = deepProps('resourceImage')(element).getOrElse('')
                }
                ItemApi.saveElasticItem(token, result, elementId, successFunc, errorFunc)
            }).catch(error => {
                treatMessage(error, () => {}, (message) => {
                    errorFunc('Não foi possível realizar o upload da imagem . ' + message)
                })
            })
     }
     
     static saveElasticItem = (token, element, elementId, successFunc, errorFunc) => {
         if (elementId == null) {
             errorFunc(-1)
             return
         }
         let url = elasticGatewayEP.saveElasticItem(elementId)
         let data = JSON.stringify(element)
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body : data
            }).then((response) => {                
                switch(response.status) {
                    case 200:
                    case 201:
                        return response.json()
                    default:
                        throw response
                }
                
            }).then(response => {
                successFunc(response)
            }).catch(error => {
                treatMessage(error, () => {}, (message) => {
                    errorFunc('Não foi possível realizar o upload da imagem . ' + message)
                })
            })
     }

    static addGTINItem = (token, itemID, GTIN, successFunc = () => {}, errorFunc = () => {}) => {
        let url = promotionsEP.promotionItemAddAssocieted(itemID, 'products')
        fetch(url, {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body:JSON.stringify(GTIN)
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            successFunc(response)
        }).catch(error => {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        }) 
        
    }

    static removeGTINItem = (token, itemID, GTIN, successFunc = () => {}, errorFunc = () => {}) => {
        let url = promotionsEP.promotionItemRemoveProduct(itemID, GTIN)
        fetch(url, {
            method: "DELETE",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            }
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then(response => {
            successFunc(response)
        }).catch(error => {
            treatMessage(error, () => {}, (message) => {
                errorFunc(message)
            })
        }) 
        
    }

    static setupItemRecommender = async (token, body, itemID) => {
        return requestResponseValidate(await fetch(recommenderEP.setupItemRecommender(itemID), getRequestSettings('POST',token,body)))
    }
}