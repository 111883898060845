import React, { Component } from 'react';
import Button from '../../Button/button'
import './personalData.css';
import deepProps, { format, getFormattedDateInput, isEmpty } from '../../../Utils';
import {AlertDialog} from '../../alertDialog/alertDialog'
import CustomerAPI from '../../../api/CustomerApi';
import { Save } from 'react-feather';

class personalData extends Component {
	constructor(props) {
		super(props)
		this.state = {
			name:'',
			surname:'',
			identifier: '',
			birthDate:'1980-01-01',
			maritalStatus: 'SINGLE',
			educationLevel: 'UN_KNOW',
			gender : 'UN_KNOW',
			profession: 'UN_KNOW',
			userAccess: 'UN_KNOW',
		}
	}

	componentDidMount () {
		console.log('personalData')
		this.loadData()
		let userAccess = deepProps('userAccess')(this.props.store.getState()).get()
		let token = deepProps('token')(this.props.store.getState()).getOrElse('')
		if (userAccess === undefined)
			return
		this.setState({token: token, userAccess: userAccess})
	}

	loadData() {
		let userType = (this.props.store.getState().userAccess.userType)

		let user = this.props['user']
		if (user === undefined)
			return
		let name = deepProps('name')(user).getOrElse(this.props.store.getState().userAccess.name)
		let surname = deepProps('surname')(user).getOrElse('')
		let identifier = format(deepProps('identifierDefault', 'identifier')(user).getOrElse(''), '###.###.###/##', '#')
		let birthDate = deepProps('birthDate')(user).getOrElse('1980-01-01')
		let maritalStatus = deepProps('maritalStatus')(user).getOrElse('SINGLE')
		let educationLevel = deepProps('educationLevel')(user).getOrElse('UN_KNOW')
		let gender = this.genderParse(deepProps('gender')(user).getOrElse('UN_KNOW'))
		let profession = deepProps('profession')(user).getOrElse('UN_KNOW')
		this.setState({name, surname, identifier, birthDate, maritalStatus, educationLevel, gender, profession, userType})
	}

	genderParse(gender) {
		switch(gender) {
			case 'F':
				return 'FEMALE'
			case 'M':
				return 'MALE'
			case 'O':
				return 'OTHER'
			default:
				return 'UN_KNOW'
		}
	}

	save() {
		let customer = this.getCustomerJson()
        if (!this.validateFields(customer))
            return
		CustomerAPI.updateCustomer(this.state.token, customer, this.state.userAccess.id,()=> {
			AlertDialog('Dados alterados com sucesso!', 'Os dados foram alterados.')
		}, (error) => {
			AlertDialog('Ops, algo deu errado', error)
		})
    }

	getCustomerJson(){
		let objectToSend  = {
			customer:{
				name: this.state.name,
				surname: this.state.surname,
				gender: this.state.gender,
				birthDate: this.state.birthDate,
				maritalStatus: this.state.maritalStatus,
				identifierDefault:{
					identifier: this.state.identifier,
					type: 'CPF'
				}
			}
		}
		return objectToSend 
	}

	validateFields(fields){
		if(isEmpty(fields.customer.name.trim())){
			AlertDialog('Informação incompleta!','Favor informar o nome.')
			return false
		}

		if(isEmpty(fields.customer.surname.trim())){
			AlertDialog('Informação incompleta!','Favor informar o sobrenome.')
			return false
		}

		if(isEmpty(fields.customer.identifierDefault.identifier.trim())){
			AlertDialog('Informação incompleta!','Favor informar o CPF.')
			return false
		}
	}

	canChange () {
		return this.state.userType === 'CUSTOMER'
	}

  	render() {
    return (
    	<form className="personalData">
			<div className="form-personalData">
				<div className="form-firstRow">
					<label>
						Nome
						<input type="text" placeholder="Digite seu nome..." value={this.state.name}
							onChange={(e) => this.setState({name: e.target.value})} disabled={!this.canChange()}/>
					</label>
					<label>
						Sobrenome
						<input className="personalData-surname" type="text" placeholder="Digite seu sobrenome..." value={this.state.surname}
							onChange={(e) => this.setState({surname: e.target.value})} disabled={!this.canChange()}/>
					</label>
				</div>
				<div className="form-row">
					<label> Sexo
						<select value={this.state.gender} onChange={(e) => this.setState({gender: e.target.value})} disabled={!this.canChange()}>
							<option value = 'UN_KNOW'>Selecione</option>
							<option value = 'MALE'>Masculino</option>
							<option value = 'FEMALE'>Feminino</option>
							<option value = 'OTHERS'>Outros</option>
						</select>
					</label>
					<label>
						Data de nascimento
						<input type="date" placeholder="26/09/1990" value={getFormattedDateInput(this.state.birthDate)}
							onChange={(e) => this.setState({birthDate : e.target.value})} disabled={!this.canChange()}/>
					</label>
					<label> Estado civil
						<select value={this.state.maritalStatus} onChange={(e) => this.setState({maritalStatus: e.target.value})} disabled={!this.canChange()}>
							<option value = 'UN_KNOW'>Selecione</option>
							<option value = 'SINGLE'>Solteiro(a)</option>
							<option value = 'MARRIED'>Casado(a)</option>
							<option value = 'DIVORCED'>Divorciado(a)</option>
							<option value = 'WIDOW'>Viuvo(a)</option>
						</select>
					</label>
				</div>
				<div className="form-row">
					<label>
						CPF
						<input className="personalData-CPF" type="text" placeholder="Digite seu cpf..." value={this.state.identifier}
							onChange={(e) => this.setState({identifier: e.target.value})} disabled={!this.canChange()}/>
					</label>
				</div>
				<p>Dados Profissionais</p>
				<div className="form-row" style={{paddingTop: 20}}>
					<label> Escolaridade
						<select value={this.state.educationLevel} onChange={(e) => this.setState({educationLevel: e.target.value})} disabled={!this.canChange()}>
							<option value = 'UN_KNOW'>Selecione</option>
							<option value = 'FIRST'>Fundamental</option>
							<option value = 'SECOND'>Colegial</option>
							<option value = 'THIRD'>Superior Completo</option>
						</select>
					</label>

					<label> Profissão
						<select value = {this.state.profession} onChange={(e) => this.setState({profession: e.target.value})} disabled={!this.canChange()}>
							<option value = 'UN_KNOW'>Selecione</option>
						</select>
					</label>
				</div>
				
			</div>
			<div className='btnSave'>
				{this.state.userType === 'CUSTOMER' && <Button text='Salvar' icon={<Save/>} style={{maxWidth: 75, paddingLeft: 16, position: 'absolute', right: 32, bottom: 16}} onClick={()=>{this.save()}}/>}
			</div>
    	</form>
    );
  }
}

export default personalData;
