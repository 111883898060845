import React, {useEffect, useState} from 'react';
import './style.scss';
import '../style.scss';
import PromoappClone from '../../component/promoappClone';
import BannersCard from './cards/bannersCard';
import NotificationCard from './cards/notificationCard';
import SectionsCard from './cards/sectionsCard'
import HighlightedPromotionsCard from './cards/highlightedPromotions'
import { addLinkSectionAPI, getLinksSectionAPI, updateLinkSectionAPI, getBannersAPI } from '../../server/appSections/appSectionsAPI';
import { NotificationManager } from 'react-notifications';

const AppManager = props => {
    const [notificationTitle, setNotificationTitle] = useState("")
    const [notificationMessage, setNotificationMessage] = useState("")
    const [appSections, setAppSections] = useState([])
    const [appBanners, setAppBanners] = useState([])
    const [loadingSections, setLoadingSections] = useState(false)
    const [highlightedPromotions, setHighlightedPromotions] = useState([])
    
    useEffect(()=>{
        getLinksSectionAPI().then(res=>{
            setAppSections(res.data)
        })
        getBannersAPI().then(res => {
            setAppBanners(res.data)
        })
    },[])

    const onAddSectionHandle = async section => {
        if(loadingSections)return
        setLoadingSections(true)
        section['sequence'] = Number(1)

        /* addLinkSectionAPI(section).then(res=>{
            res['status'] = 'PENDING'
            setAppSections([res,...appSections])
        }).catch(e=>{
            console.log(e)
             NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
        }).finally(()=>{
            setLoadingSections(false)
        })
        */
        try {
            let res = await addLinkSectionAPI(section)
            res['status'] = 'PENDING'
            setAppSections([res, ...appSections])
            NotificationManager.success('Link criado', 'Sucesso!')
        }catch(e) {
            console.log(e)
            NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
            throw 'Não fora possível realizar essa tarefa'
        }finally {
            setLoadingSections(false)
        }
    }

    const onEditHandle = async (editSection) => {
        if(loadingSections)return
        setLoadingSections(true)
        try {
            let res = await updateLinkSectionAPI(editSection.id, editSection)
            res['version'] = res['timeStamp']
            setAppSections(appSections.map(section => {
                if(section.id === res.id) {
                    if (res.status === 1)
                        res.status = 'PUBLISHED'
                    else if (res.status === 2)
                        res.status = 'PENDING'
                    section = res
                }
                // console.log(section)
                return section
            }))
            NotificationManager.success('Os novos dados da seção já constam no app', 'Sucesso!')
        }catch(e) {
            NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
            throw 'Não fora possível realizar essa tarefa'
        }finally {
            setLoadingSections(false)
        }
    }

    const onAppSectionsChangeHandle = sections => {
        setAppSections(sections)
    }

    const onChangeBannerHandle = banners => {
        setAppBanners(banners)
    }

    const onSelectBannerHandle = banner => {
        if(banner.file)
            setAppBanners([banner, ...appBanners])
    }

    const onAppHighlightsHandle = highlights => {
        setHighlightedPromotions(highlights)
    }

    return (
        <div className="page-container">
            <header>
                <div className="box-title">
                    <h1 className="title-page">Gerenciador do Aplicativo</h1>
                    {/* <span className="subtitle-page">3/3 Total</span> */}
                </div>
            </header>

            <main className="" style={{display: 'flex'}}>
                <div className='' style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                    <NotificationCard onTitleChange={title=>setNotificationTitle(title)} onMessageChange={message=>setNotificationMessage(message)}/>
                    <BannersCard banners={appBanners} onChange={onChangeBannerHandle} onSelect={onSelectBannerHandle}/>
                    <SectionsCard sections={appSections} onChange={onAppSectionsChangeHandle} loading={loadingSections} onAdd={onAddSectionHandle} onEdit={onEditHandle}/>
                    <HighlightedPromotionsCard onChange={onAppHighlightsHandle}/>
                </div>
                <div className='' style={{flex: 1}}>
                    <PromoappClone sections={appSections} notification={{title: notificationTitle, message: notificationMessage}} showNotification={notificationTitle.length > 0 && notificationMessage.length > 0} banners={appBanners.filter(banner => banner.status === 'PUBLISHED')} highlights={highlightedPromotions}/>
                </div>
            </main>
        </div>
    );
}

export default AppManager;