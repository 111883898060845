import React, {Component} from 'react'
import { CSVLink } from "react-csv";
import './grid.scss'
import deepProps, { format } from '../../Utils';
import DropDownFilter from '../DropDownFilter/DropDownFilter';
import { Loading } from '../LoadingMessage/loadingMessage';
import { ColorEmptyMessage } from '../emptyMessage/emptyMessage';

export default class Grid extends Component{
    constructor(props){
        super(props)
        this.state={header:[], data:[], showData:[], page:0, contentStyle:{}, maxPerPage: 300, title: 'my-report.csv', increase:true}
        this.ROW_SIZE = 54;
        this.COMPONENTS = {loading:<Loading/>}
        this.SIMPLE_ROW_ITENS = ["text", "number", 'float']
    }

    componentDidMount(){
        this.mountGrid(this.props)
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.data !== this.state.data || nextProps.header !== this.state.header)
            this.mountGrid(nextProps)
    }

    async mountGrid(props){
        await this.setState({header:[], data:[], showData:[], page:0, contentStyle:{}, maxPerPage: 300, title: 'my-report.csv', increase:true})
        let {header, data, title, maxPerPage} = props
        title = (title) ? title : this.state.title
        maxPerPage = (maxPerPage) ? maxPerPage : this.state.maxPerPage
        await this.setState({header, data, title, currentPage:0, maxPerPage, pagesNumber:this.getPagesNumber(data.length, maxPerPage)})
        this.styliseContent()
        this.NEXT_PAGE = this.nextPageGenerator()
        this.NEXT_PAGE.next()
    }

    styliseContent(){
        if(this.props.maxShow){this.setState({contentStyle:{maxHeight:this.props.maxShow*this.ROW_SIZE}})}
    }

    nextPageGenerator = function * (){
        let {currentPage, maxPerPage, data, pagesNumber} = this.state

        while(currentPage < pagesNumber){
            let showData = data.slice(currentPage*maxPerPage,(currentPage+1)*maxPerPage)
            this.setState({currentPage:currentPage++, showData:[...this.state.showData, ...showData]})
            yield showData
        }
        return data
    }

    getPagesNumber(dataLength, maxPerPage){
        let pages = Math.ceil(dataLength/maxPerPage)
        return pages
    }

    sortGrid(key){
        let {showData, increase} = this.state
        let sorted = []
        if(increase)
           sorted = this.increase(showData,key)
        else
            sorted = this.decrease(showData,key)
        this.setState({showData:sorted, increase:!increase})
    }

    increase(data, key){
        return data.sort(function (a, b) {
			if (a[key] < b[key]) 
				return 1;
			if (a[key] > b[key]) 
				return -1;
			return 0;
		});
    }

    decrease(data, key){
        return data.sort(function (a, b) {
			if (a[key] < b[key])
				return -1;
			if (a[key] > b[key])
				return 1;
			return 0;
		});
    }

    getHeader(label, type='', key){
        return <th className={`grid-row ${type}`} onClick={()=>this.sortGrid(key)} key={key}>{label}</th>
    }

    getRow(key, data, type='', mask = false, props={}){
        let className = `grid-row ${type}` 
        if(this.isSimpleRowItem(type)) {
            let text = deepProps(key)(data).getOrElse('')

            if (type === 'float' && text.replace)
                text = text.replace('.', ',')
            if(mask){text = format(text,mask,'#')}
            return <td className={className} key={key}>{text}</td>
        }
        return <td className={className} key={key}>{this.getComponent(type, {})}</td>
    }

    isSimpleRowItem(type){
        return this.SIMPLE_ROW_ITENS.includes(type)
    }

    getComponent(type, props = {}){
        return this.COMPONENTS[type]
    }

    mapHeaderToSearchBar(header){
        return header.map(item=>{return {text:item.label,value:item.key,type:item.type}})
    }

    onSearch(text, key, comparator){
        if(this.state.data.length === 0){return}
        key = (key === undefined) ? this.state.header[0].key : key
        let response = this.filterData(this.state.data,text,key,comparator)
        this.setState({showData:response, empty:(response.length===0)})
    }

    filterData(data, text, key, comparator){
        return data.filter(item=>this.compareParams(comparator, item[key], text))
    }

    compareParams(comparator, paramA = '', paramB = ''){
        paramA = String(paramA).toLowerCase()
        paramB = String(paramB).toLowerCase()
        switch(comparator){
            case 'equal':
            default:
                return paramA === paramB
            case 'contain':
                return paramA.includes(paramB)
            case 'bigger':
                return paramA > paramB
            case 'smaller':
                return paramA < paramB
        }
    }

    nextPage(loading){
        // this.NEXT_PAGE.next()
        if (loading)
            return
        if(this.props.nextPage)
            this.props.nextPage()
    }

    previousPage (loading) {
        if (loading)
            return
        if (this.props.previousPage)
            this.props.previousPage()
    }

    render(){
        let {header, showData, contentStyle, data, title, empty} = this.state
        let {csv, paginator, seeMore, page, total_page, loading} = this.props
        return(
            <div className='grid'>
                {this.props.searchBar && <SearchBar filters={this.mapHeaderToSearchBar(header)} onSearch={(text, key, comparator)=>this.onSearch(text, key, comparator)}/>}
                <div className='grid-table'>
                    <div className='grid-header'>
                        {header.map(item=>this.getHeader(item.label, item.type, item.key))}
                    </div>
                    <div className='grid-content' style={contentStyle}>
                        {showData.map((data, index)=>{return <tr className='grid-body' key={index}>
                            {header.map(header=>this.getRow(header.key, data, header.type, header.mask))}
                        </tr>})}
                    </div>
                    {(empty || data.length === 0) && <ColorEmptyMessage title='Relatório sem resultados!' subtitle='A busca não retornou resultados, tente com outros filtros.'/>}
                    {(seeMore && showData.length !== data.length && !empty && data.length !== 0) && <td className={'grid-row grid-see-more'} onClick={()=>this.nextPage()}>Carregar mais...</td>}
                    {paginator && false &&
                        <tr>
                            {page > 1 && <td className={'grid-row grid-see-more'} onClick={()=>this.previousPage(loading)}>Anterior</td>}
                            <td className={'grid-row grid-see-more'}>{page}</td>
                            {page < total_page && <td className={'grid-row grid-see-more'} onClick={()=>this.nextPage(loading)}>Proxima</td>}
                        </tr>
                    }
                </div>
                {!(empty ||data.length === 0) && csv && <CSVLink data={data} headers={header} filename={title + ".csv"} className="download-button" target="_blank">
                    CSV
                </CSVLink>}
            </div>
        )
    }
}

class SearchBar extends Component{
    constructor(props){
        super(props)
        this.state={filterType: 'text', comparator:'contain'}
        this.COMPARATORS = [{text:'Igual', value:'equal'},{text:'Contenha', value:'contain'},{text:'Maior que', value:'bigger'},{text:'Menor que', value:'smaller'}]
        this.TEXT_COMPARATORS = this.COMPARATORS.slice(0,2)
    }

    getFilters(all = true){
        return (all) ? this.COMPARATORS : this.TEXT_COMPARATORS
    }

    onFilterSelect(filter){
        this.setState({filterType:filter.type, key:filter.value})
        if(this.props.onSearch)
            this.props.onSearch(this.state.text, filter.value, this.state.comparator)
    }

    onComparatorSelect(filter){
        this.setState({comparator:filter.value})
        if(this.props.onSearch)
            this.props.onSearch(this.state.text, this.state.key, filter.value)
    }

    onInputChange(text){
        this.setState({text})
        if(this.props.onSearch)
            this.props.onSearch(text, this.state.key, this.state.comparator)
    }

    render(){
        let {filterType} = this.state
        let {filters} = this.props
        return(
            <form className="search-grid">
                <DropDownFilter filters={filters} onSelect={(filter)=>this.onFilterSelect(filter)}/>
                <span style={{width: 8}}/>
                <DropDownFilter filters={this.getFilters(filterType === 'number')} onSelect={(filter)=>this.onComparatorSelect(filter)}/>
                <span style={{width: 8}}/>
                <input type="text" ref={input => this.search = input} placeholder="Pesquisar..." onChange={field=>this.onInputChange(field.target.value)}/>
            </form>
        )
    }
}