import React from 'react';
import './style.scss';
import Button from '../Button/button';

const Card = ({children, header, style, containerStyle, buttonDisabled, subtitle, onClick, buttonText, footer, className, loading, title}) => {


	const {actions} = header || {}
	
	return (
		<div className={"card " + className} style={style}>
			{title && <h1 className='title'>{title}</h1>}
            {header && <header>
                {header.title && <h1 className='title'>{header.title}</h1>}
				<h1 className='subtitle-gray'>{subtitle}</h1>
				<div>
					{actions}
				</div>
            </header>}
			<main style={containerStyle}>
				{children}
			</main>
			<footer>
				{onClick && <Button disabled={buttonDisabled} text={buttonText} textOnly style={{width: 90, alignSelf: 'center'}} onClick={onClick}/>}
			</footer>
		</div>
	);
}

export default Card;