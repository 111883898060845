import React, { Component } from 'react';
import Button from '../../Button/button'
import List from '../../list/list'

import './contacts.css';
import { Plus } from 'react-feather';

class personalData extends Component {
	constructor(Props){
		super(Props)
		this.state = {
			contacts:[{title: 'Celular', data:['(11)94232-4567','(11)94232-4567','(11)94232-4567']}]
		}
	}

	addContact(){
		
	}

	render() {
		return (
			<form className="contacts">
				<div className="contact-form">
					<label> Mídia
						<select>
							{/* <option value="CE">Celular</option> */}
							<option value="TF">Telefone Fixo</option>
							{/* <option value="EM">E-mail</option>
							<option value="FA">Facebook</option>
							<option value="IN">Instagram</option>
							<option value="WH">Whatsapp</option>
							<option value="TW">Twitter</option>
							<option value="OU">Outro</option> */}
						</select>
					</label>
					<label> Contato <input type="text" placeholder="Digite o contato aqui..." /></label>
					<List title='Contatos cadastrados' list={this.state.contacts} delete={()=>{}}/>
				</div>
				<div className='actions-contacts'>
					{this.props.write && false && <Button text='Adicionar' icon={<Plus/>}/>}
				</div>
			</form>
		);
	  }
}

export default personalData;