import { validateRequest, getFetchImagePOSTHeader } from "../../api/UtilsAPI"
import { storageUploadEP } from "../endpoints_js"
import { getUploadImageBody } from "./ImageBody"

const endPoint = storageUploadEP.imageProductsUpload()

export const uploadImageAPI = async (img) => {
    let response = await fetch(endPoint, getFetchImagePOSTHeader(getUploadImageBody(img)))
    validateRequest(response)
    return await response.json()
}