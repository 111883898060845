import {treatMessage} from '../Utils'
import { storesEP, promotionsEP } from '../server/endpoints_js';

export default class StoreApi {
    static findStores = (token, alertDialog=()=>{}, store = '-1', succesFunc=()=>{}, errorFunc=()=>{}) => {
        return dispatch => {
            let url = storesEP.findStores(store)
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Authorization': token
                }
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
				succesFunc()
                dispatch({type:'STORES_SEARCH', stores:response.data})
            }).catch(error => {
				errorFunc()
                treatMessage(error, dispatch, (message) => {
                    alertDialog('Ops, algo deu errado','Não fora possível carregar as lojas. ' + message)
                })
            })
        }
    }
    static findStoresWithFilter = (token, query) => {
        let url = storesEP.findStoresNoQuery() + "?" + query
        return fetch(url, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Authorization': token
            }
        })
        
    }

    static updateStatusStore = (token, storeId, enable, succesFunc = () => {}, errorFunc = () => {}) => {
        let url = enable ? storesEP.enableStore(storeId) : storesEP.disableStore(storeId)
        fetch(url, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Authorization': token,
                'Content-Type' : 'application/json'
            }
        }).then((response) => {
            if (response.status === 204)
                return undefined
            throw response
        }).then(response => succesFunc(response)).catch(error =>  {
            treatMessage(error, () => {}, (message) => {
                errorFunc('Não foi possível salvar os dados da loja. ' + message)
            })
        })
    }

    static deleteStore = (token, storeId, succesFunc = () => {}, errorFunc = () => {}) => {
        let url = storesEP.deleteStore(storeId)
        fetch(url, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': token,
                'Content-Type' : 'application/json'
            }
        }).then((response) => {
            if (response.status === 204)
                return undefined
            throw response
        }).then(response => succesFunc(response)).catch(error =>  {
            treatMessage(error, () => {}, (message) => {
                errorFunc('Não foi possível salvar os dados da loja. ' + message)
            })
        })
    }

    static saveStore = (token, storeId, result, succesFunc = () => {}, errorFunc = () => {}) => {
        let body = JSON.stringify(result)
        let url = storesEP.saveStore() + (storeId > 0 ? '/' + storeId : '')
        fetch(url, {
            method: storeId > 0 ? 'PUT' : 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': token,
                'Content-Type' : 'application/json'
            },
            body: body
        }).then((response) => {
            if (response.status === 201)
                return response.json()
            if (response.status === 204)
                return undefined
            throw response
        }).then(response => succesFunc(response)).catch(error =>  {
            treatMessage(error, () => {}, (message) => {
                errorFunc('Não foi possível salvar os dados da loja. ' + message)
            })
        })
    }

    static findStoresAddress = (token, store = '-1', succesFunc = () => {}, errorFunc = () => {}) => {
        return dispatch => {
            let url = storesEP.address(store)
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Authorization': token
                }
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                dispatch({type:'SEARCH_STORE_ADDRESS', storeAddress:response.data})
                succesFunc(response.data)
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc('Não foi possível carregar o endereço das lojas. ' + message)
                })
            })
        }
    }

    static findPromotionStore = (token, promotion, succesFunc = () => {}, errorFunc = () => {}) => {
          return dispatch => {
              let url = promotionsEP.findPromotionStore(promotion)
              fetch(url, {
                    method: "GET",
                    headers: {
                    Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': token
                    }
              }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
              }).then(response => {
                dispatch({type:'PROMOTION_STORE_SEARCH', promotionsStores:response.data})
                succesFunc(response.data)
              }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc('Não foi possível carregar as lojas da promoção. ' + message)
                })
              })
          }
      }

    static updateStores = (token, promotion, stores, add=true, succesFunc=()=>{}, errorFunc = ()=>{}) => {
          return dispatch => {
              let url = promotionsEP.updatePromotionsStores(promotion)
              fetch(url, {
                  method: add ? "POST" : "DELETE",
                  headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                      'Authorization': token
                  },
                  body: JSON.stringify(stores)
              }).then((response) => {
                    if (response.status === 200)
                        return response.json()
                    throw response
              }).then(response => {
                    if(add)
                        dispatch({type:'PROMOTION_ADD_STORE', storesAdded:response.data})
                    else
                        dispatch({type:'PROMOTION_REMOVED_STORE', storesRemoved:response.data})
                    succesFunc()
              }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc('Não foi possível atualizar o estado das lojas na promoção. ' + message)
                })
              }) 
          }
      }

    static catchError(error) {
        return dispatch => {
            switch(error) {
                case 403:
                    dispatch({type:'LOGIN_REQUERIED'})
                break
                default:
                    dispatch({type:'HTTP_ERROR', error: 'Erro generico'})
                break
            }
        }
    }
}