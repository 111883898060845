import React, { Component } from 'react';
import './list.css';
import deepProps from '../../Utils';


// Exemplo de lista:
// list: [{title: 'Celular', data:['(11)94232-4567']}]

class list extends Component {

  static propTypes = {}

  static defaultProps = {}

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onClick(){
      if(this.props.onClick){
          this.props.onClick()
      }
  }

  render() {
    return (
      <div className='list'>
        <h1 className='title bold'>{this.props.title}</h1>
        <ul onClick={()=>{this.onClick()}}>
            {this.props.list.map((field, index)=>
              <li key={index}><h1 className='bold'>{field.title}</h1>
                  {field.data.map((data, idx)=>
                    <h1 key = {idx}>{data}</h1>
                    )}
                <div className='actionsl'>
                  <div>
                    {this.props.delete && <svg style={{width:24, height:24}} onClick={(id)=>this.props.delete(this.props.id)} viewBox="0 0 24 24">
                              <path fill="#8a8a8a" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M9,8H11V17H9V8M13,8H15V17H13V8Z" />
                          </svg>}
                    {this.props.edit && <svg style={{width:24, height:24, marginRight:8}} onClick={()=>this.props.edit(deepProps('id')(field).getOrElse(0))} viewBox="0 0 24 24">
                        <path fill="#8a8a8a" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                    </svg>}
                  </div>
                </div>
              </li>
            )}
        </ul>
      </div>
    )
  }
}

export default list
