import React, { Component } from 'react';
import './selectableItem.css';
import Segmentation from '../cardTabProduct/segmentation/segmentation';
import { Edit, Trash, Star } from 'react-feather';

export default class SelectableItem extends Component {

    constructor(props){
        super(props)
        this.state = {
            selected: this.props.selected,
            canUnSelected: this.props.canUnSelected,
        }
        this.toggleSelected = this.toggleSelected.bind(this)
    }
    
    toggleSelected(){
        this.setState({selected: !this.state.selected}, ()=>{
            if(this.props.callback){
                this.props.callback(this.props.id, this)
            }
        })
    }
    
    selected() {
        this.setState({selected:true})
    }
    
    canUnSelected = () => this.state.canUnSelected
    
    onClick(){
        this.toggleSelected()

    }

    componentDidUpdate(prevState){
        let selected = this.props.selected
        let canUnSelected = this.props.canUnSelected
        if (prevState.selected === selected && prevState.canUnSelected === canUnSelected)
            return
        this.setState({selected, canUnSelected})
    }

    getStarColor(highlighted, fill){
        if(fill) return highlighted ? '#fbc02d' : '#fff'
        return highlighted ? '#fbc02d' : '#8a8a8a'
    }

  render() {
    let {linking, linked, data, action, title, subtitle, reach,
        edit, segmentation, img, highlighted, onHighlightClick, editable} = this.props

    return (
    	<div className="selectableItem" style={this.props.style}>
            {editable && onHighlightClick && <div className='actions upper-actions'>
                <Star color={this.getStarColor(highlighted)} fill={this.getStarColor(highlighted, true)} onClick={()=>this.props.onHighlightClick(data)} />
            </div>}
			<div className="itenProduct"  onClick={(e)=>this.onClick()}>
				<div className="body-itens">
					<div className={"img " + ((this.props.selected /*&& !this.props.unselectable*/) ? 'selected' : '') + (!this.props.canUnSelected ? '-cant-unselected' : '')} id={this.props.id}>
                    {(this.props.img && !this.state.imageError && !this.state.imageLoaded) && <img  className={'img-profile placeholder-shiny'} alt=''></img>}
				    {(this.props.img && !this.state.imageError) && <img  className={this.state.imageLoaded ? 'img-profile loaded' : 'img-profile loading'} onLoad={()=>{this.setState({imageLoaded:true})}} onError={()=>{this.setState({imageError:true})}} src={this.props.img} alt={this.props.name}></img>}
				    {(!this.props.img || this.state.imageError) && <div className="name-logo" ><h1>{(this.props.title && this.props.title.length >=2) ? this.props.title.substring(0,2) : '?'}</h1></div>}
					</div>
					{this.props.tip && (<div className="tip"> <p>{(this.props.tip > 1000 ? this.props.tip.toString().substr(0,2) + ' K' : this.props.tip)}</p> </div>)}
				</div>
			</div>

			<div className="description" onClick={(e)=>this.onClick()}>
				{title && <h2>{title}</h2>}
				{subtitle && <p>{subtitle}</p>}
                {
                    (reach && !isNaN(reach)) && 
                    <p className='interest'>Associado com {reach} {reach > 1 ? 'clientes' : 'cliente'}</p>
                }
                {linking && <p className='interest'>Integrando...</p>}
                {linked && false && <p className='interest'>Segmentado pelo hábito de compra</p>}
			</div>
            
            {segmentation && linked && <p className = 'interest indent' onClick={()=>segmentation(data)}>Ver Segmentação</p>}
           {(this.props.delete || edit)  && <div className='actions' style={{marginRight: -32}}>
                {(action && false) && <Star color={'#8a8a8a'} onClick={(id)=>this.props.action(this.props.id)} />}
                {edit && <Edit color={'#8a8a8a'} onClick={(id)=>this.props.edit(this.props.id)} />}
                {this.props.delete && <Trash  color={'#8a8a8a'} onClick={(id)=>this.props.delete(this.props.id)}/>}
            </div>}
            {this.props.type == 'PRODUCT' && action && <p className = 'interest indent' onClick={()=>this.props.action(data)}>Segmentar</p>}
    	</div>
    );
  }
}
