import React, {useState, useEffect} from 'react';
import './styles.scss'

const SeeMoreIndicator = (props) => {
    
    return ( 
        <div style={props.style}>
            <div id='see-more-indicator'>
                <img src={require('./down-arrow.gif')}/>
                <h1 className='indicator'>{props.quantity}</h1>  
            </div>
        </div>
    );
}

export default SeeMoreIndicator