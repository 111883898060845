import React, {Component} from 'react';
import { Loading } from '../LoadingMessage/loadingMessage';
import './SelectableList.scss'

export default class SelectableList extends Component{
	constructor(props){
        super(props)
        this.state = {
			token: this.props.token,
		}
	}

	static defaultProps = {
		leftItens: [],
		rightItens: []
    }
	
	moveItemFromTo(item, originList, destinationList){
		let filteredList = originList.filter(listedItem=>listedItem!==item)
		return ({item: item, removedList: filteredList, insertedList:[...destinationList,item]})
	}

	moveItemToLeft(item){
		let itemX = item
		if(this.props.moveItemToLeft){
			let {item, removedList, insertedList} = this.moveItemFromTo(itemX,this.props.rightItens,this.props.leftItens)
			this.props.moveItemToLeft(item, removedList, insertedList)
			return
		}
	}

	moveItemToRight(item){
		let itemX = item
		if(this.props.moveItemToLeft){
			let {item, removedList, insertedList} = this.moveItemFromTo(itemX,this.props.leftItens,this.props.rightItens)
			this.props.moveItemToRight(item, removedList, insertedList)
			return
		}
	}

	componentWillUpdate(nextProps){
        if(nextProps.data !== this.props.data)
            this.setState({data:nextProps.data, Item: nextProps.Item})
    }

	render(){
		return(
			<div className="selectable-list" style={{scrollBehavior: 'smooth'}}>
				{this.props.leftItens && <div className="selectableList-container">
				 <h1>{this.props.leftTitle}</h1>
					<ul className='selectable-list-container'>
						{(this.props.leftItens.length === 0) && <EmptyListItem title={this.props.leftEmptyTitle} subtitle={this.props.leftEmptySubtitle}/>}
						{this.props.leftItens.map((item)=>{
							return <ListItem title={item.text} item={item} text={item.desc} key={'p' + item.value}  added={true} moveItemToRight={(Item)=>{this.moveItemToRight(Item)}} edit={this.props.edit}/>
						})}
					</ul>
				</div>}
				{this.props.rightItens && <div className="selectableList-container">
					<h1>{this.props.rightTitle}</h1>
					<ul className='selectable-list-container'>
						{(this.props.rightItens.length === 0) && <EmptyListItem title={this.props.rightEmptyTitle} subtitle={this.props.rightEmptySubtitle}/>}
						{this.props.rightItens.map((item)=>{
							return <ListItem title={item.text} item={item} key={'s' + item.value} text={item.desc} added={false} moveItemToLeft={(Item)=>{this.moveItemToLeft(Item)}} edit={this.props.edit}/>
						})}
					</ul>
				</div>}
			</div>
		)
	}
}

export class ListItem extends Component{
    constructor(props){
        super(props)
        this.state = {
			added: false,
        }
	}

	componentDidMount(){
        this.setState({added:this.props.added, loading: false})
    }

    getClass(){
        let basicClass = 'list-item '
        if(this.props.disable)
            return basicClass + 'disable'

        if(this.props.type)
            return basicClass + this.props.type

        return basicClass 
	}
	
	async moveItemToLeft(Item){
        try{
            this.setState({loading:true})
			this.props.moveItemToLeft(Item)
        }catch(error){
            this.setState({loading:false})
        }
	}
	
	moveItemToRight(Item){
		try{
			this.setState({loading:true})
			this.props.moveItemToRight(Item)
		}catch(error){
			alert(error)
            this.setState({loading:false})
        }
    }

    render(){
        return(
            <li className={this.getClass()}>
				<div className="selectable-title">
					<h1>{this.props.title}</h1>
					{this.props.text && <h2>{this.props.text}</h2>}
				</div>
				{this.state.loading && <Loading style={{height: 24}}/>}
                {(!this.state.added && !this.state.loading) && this.props.edit && <svg onClick={(item)=>{this.moveItemToLeft(this.props.item)}} className='addPermission-button asideList-button' xmlns="http://www.w3.org/2000/svg" id="Capa_1" x="0px" y="0px" viewBox="0 0 491.86 491.86" style={{width:25, height:25}}>
                    <path d="M465.167,211.614H280.245V26.691c0-8.424-11.439-26.69-34.316-26.69s-34.316,18.267-34.316,26.69v184.924H26.69    C18.267,211.614,0,223.053,0,245.929s18.267,34.316,26.69,34.316h184.924v184.924c0,8.422,11.438,26.69,34.316,26.69    s34.316-18.268,34.316-26.69V280.245H465.17c8.422,0,26.69-11.438,26.69-34.316S473.59,211.614,465.167,211.614z" fill="#2196f3"/>
                </svg>}
                {(this.state.added && !this.props.disable && !this.state.loading) && this.props.edit && <svg style={{width:24, height:24}} className='addPermission-button asideList-button' onClick={(item)=>{this.moveItemToRight(this.props.item)}} viewBox="0 0 24 24">
                    <path fill="#8a8a8a" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M9,8H11V17H9V8M13,8H15V17H13V8Z" />
				</svg>}
            </li>
        )
    }
}

export class EmptyListItem extends Component{
    render(){
        return(
            <li className={'card-permission emptyListItem'}>
				<div className="selectable-title">
					<h1>{this.props.title}</h1>
					<h2>{this.props.subtitle}</h2>
				</div>
            </li>
        )
    }
}

