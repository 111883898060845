import { NotificationEP, AppHomeScreenEP } from "../endpoints_js";
import { validateRequest, getFetchHeader, makeRequest, getFetchFormDataHeader} from "../../api/UtilsAPI";
import { getLinkSectionBody, getBannerBody } from "./appSectionsBody";


export const getLinksSectionAPI = async  () => makeRequest(AppHomeScreenEP.getLinkSection(), getFetchHeader())

export const addLinkSectionAPI = async  (body) => makeRequest(AppHomeScreenEP.getLinkSection(), getFetchFormDataHeader('POST',getLinkSectionBody(body)))

export const publishLinksSectionAPI = async  (id) => makeRequest(AppHomeScreenEP.publishLinkSection(id), getFetchHeader('PUT'),[409, 204, 200])

export const deprecatedLinksSectionAPI = async  (id) => makeRequest(AppHomeScreenEP.deprecatedLinkSection(id), getFetchHeader('PUT'),[409, 204, 200])

export const updateLinkSectionAPI = async  (id, body) => makeRequest(AppHomeScreenEP.updateLinkSection(id), getFetchFormDataHeader('PUT', getLinkSectionBody(body)))

export const deleteLinkSectionAPI = async  (id, body) => makeRequest(AppHomeScreenEP.updateLinkSection(id), getFetchHeader('DELETE',body), [200,204,404], true)

export const deleteBannerAPI = async  (id) => makeRequest(AppHomeScreenEP.updateBanner(id), getFetchHeader('DELETE'), [200,204,404], true)

export const deprecatedBannerAPI = async  (id) => makeRequest(AppHomeScreenEP.deprecatedBanner(id), getFetchHeader('PUT'),[409, 204, 200])

export const addBannerAPI = async  (body) => makeRequest(AppHomeScreenEP.addBanner(), getFetchFormDataHeader('POST',getBannerBody(body)))

export const publishBannerAPI = async  (id) => makeRequest(AppHomeScreenEP.publishBanner(id), getFetchHeader('PUT'),[409, 204, 200])

export const getBannersAPI = async  () => makeRequest(AppHomeScreenEP.getBanners(), getFetchHeader())
