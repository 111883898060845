import { makeRequest, getFetchHeader } from "../../../../api/UtilsAPI";
import { GraphQLEP } from "../../../endpoints_js";
import { getChurnQuery, getChurnMock, getChurnReportQuery, getChurnAndRecoveredQuery, getChurnByStoreQuery } from "./querys";

// export const getChurnGraphQL = async() => makeRequest(GraphQLEP.production, getChurnQuery())

export const getChurnGraphQL = async(store, page) => {
    return makeRequest(GraphQLEP.getGraphQLServer(), getFetchHeader('POST', {query: getChurnQuery(store, page)}))
}


export const getChurnAndRecoverGraphQL = async() => makeRequest(GraphQLEP.getGraphQLServer(), getFetchHeader('POST', {query: getChurnAndRecoveredQuery()}))


export const getChurnByStoreGraphQL = async(page) => {
    return makeRequest(GraphQLEP.getGraphQLServer(), getFetchHeader('POST', {query: getChurnByStoreQuery(page)}))
}

export const getChurnReportAPI = async() => makeRequest(GraphQLEP.getGraphQLServer(), getFetchHeader('POST', {query: getChurnReportQuery()}), [200], true, 'blob')
