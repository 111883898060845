import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'
import './menu.css';
import imgPdvs from '../../img/monitor.svg';
import { Smartphone, Home, User, ShoppingCart, Bookmark, Briefcase, Tag} from 'react-feather';

class Menu extends Component {

	constructor(props) {
		super(props)
		this.state = {
			menu:[],
			permission:[],
			active: ''
		}
		this.menu= [
			{
				idx : 'HOME',
				name : 'Inicial',
				icon: <Home/>,
				to : '/main/home/general'
			},{
				idx : 'CUSTOMER',
				name : 'Clientes',
				icon : <User/>,
				to : '/main/customers'
			},{
				idx : 'STORE',
				name : 'Lojas',
				icon : <ShoppingCart/>,
				to : '/main/stores'
			},{
				idx : 'PROMOTION',
				name : 'Promoções',
				icon : <Tag/>,
				to : '/main/promotions'
			},{
				idx : 'PDV',
				name : 'PDVs',
				image : imgPdvs,
				to : '/main/promotion/promotion'
			},
			{
				idx : 'OPERATOR',
			 	name : 'Operadores',
			 	icon : <Briefcase/>,
			 	to : '/main/operator'
			},{
			 	idx : 'PROFILE',
			 	name : 'Perfís',
			 	icon : <Bookmark/>,
			 	to : '/main/profiles'
			},{
				idx : 'APP_CONFIG',
				name : 'Aplicativo',
				icon : <Smartphone/>,
				to : '/main/appmanager'
		   }
		]
	}
	
	componentDidMount () {
		let actives = this.menu.filter((item) => {
			let options = this.props.options
			let i = 0
			for (; i < options.length && options[i].name !== item.idx; i++);
			return i < options.length;
		})
		this.setState({menu:actives})
		this.unsusbscribe = this.props.store.subscribe(() => {
			let response = this.props.store.getState();
			if (response.type === 'PERMISSION')
				this.setState({permission:response.permission})
		})
	}

	activeMenu(idx){
		this.setState({active: idx})
	}

    componentWillUnmount () {
		try {
			this.unsusbscribe()
		}catch (e) {
			console.error(e)
		}
    }

  	render() {
		return (
			<nav className="menu">
				<ul>
					<li className="option-menu top" onClick={openMenu}>
						<div className="id-menu"><img style={{height: 40, width: 30, objectFit: 'center', marginLeft: -8}} src={require('../../img/p5logo.png')}></img></div>
						{/* <span><img src={require('../../img/pin.svg')} className="icon-top" alt=""></img></span> */}
					</li>

					{
						this.state.menu.map( (item, id) =>{
							return (
								<NavLink key={id} to={item.to} className={this.state.active === item.idx ? "option-menu menuActive" : "option-menu"} onClick={()=>{this.activeMenu(item.idx)}} activeClassName="menuActive">
									<span className="icon-menu">
										{item.icon && item.icon}
										{item.image && <img src={item.image} alt=""></img>}
									</span>
									<p className="title-optionMenu">{item.name}</p>
								</NavLink>
							)
						})
					}				
				</ul>
			</nav>
		);
 	}
}

export default Menu;

function openMenu() {
	// document.querySelector(".menu").classList.toggle("openMenu");
	// document.querySelector(".icon-top").classList.toggle("open");
}