import React, { useState, useEffect } from 'react';
import './styles.scss';
import SelectableItem from '../../selectableItem/selectableItem';
import { getCustomerRecommendedProductsAPI } from '../../../api/CustomerApi';
import EmptyMessage from '../../emptyMessage/emptyMessage';
import LoadingMessage from '../../LoadingMessage/loadingMessage';

const UserPreferences = (props) => {
	const [recommendedProducts, setRecommendedProducts] = useState()

	useEffect(()=>{
		let customerDetails = props.store.getState().customerDetails||{}
		getCustomerRecommendedProductsAPI(customerDetails.id).then((response)=>{
			setRecommendedProducts(response.data)
		})
		console.log('metricsss: ', props.user.metrics);
	},[])

	return (
		<div className='tab-content'>
			<h1 className='title' style={{marginBottom: 8}}>Hábitos de compras</h1>
			<div style={{display: 'flex', flexWrap: 'wrap'}}>
				{/* <label for='churn' style={{flexDirection: 'row', alignItems: 'center', marginTop: 16}}>
					<input type='checkbox' id='churn' checked={props.user.metrics.churn} onChange={e => {}}/>
					Deixou de comprar conosco 
				</label> */}
				{props.user.metrics && <ul className='border-list'>
					<li><h1>{props.user.metrics.churn ? 'Deixou de comprar conosco esse mês' : 'Consumidor frequente nesse mês'}</h1></li>
					<li><h1>Frequenta geralmente a loja {props.user.metrics.favoriteStore}</h1></li>
					<li><h1>Realiza em média {props.user.metrics.medianPurchasePerMonth} compra(s) ao mês</h1></li>
					<li><h1>Leva em média {props.user.metrics.medianItemsPerPurchase.toFixed(2)} itens por compra</h1></li>
					<li><h1>Gasta em média R${(props.user.metrics.medianSpendPerPurchase).toFixed(2)} por compra</h1></li>
				</ul>}
			</div>

			{recommendedProducts && recommendedProducts.length > 0 && <h1 className='title' style={{marginBottom: 8, marginTop: 8}}>Produtos recomendados</h1>}
			{/* <div style={{display: 'flex', flexWrap: 'wrap'}}>
				{recommendedProducts.map(product => <SelectableItem title={product.gtin} tip={product.score.toFixed(2)}/>)}
			</div> */}
			<div style={{display: 'flex', flexWrap: 'wrap', position: 'relative', minHeight: 200}}>
				{/* <EmptyMessage/> */}
				{!recommendedProducts && <LoadingMessage title='Aguarde um instante' description='Buscando produtos recomendados...'/>}
				<ul className='border-list'>
					{recommendedProducts && recommendedProducts.map(product => (<li>
						<h1 className='title'>{product.description ? product.description : 'Produto sem nome'}</h1>
						<h2>{product.gtin}</h2>
					</li>))}
				</ul>
			</div>
		</div>
	)
}

export default UserPreferences