import React, { Component } from 'react';
import './textInput.css';

const TextInput = (props) => {
		const {label, type, placeholder, style, inputStyle, onChange, value} = props
		return (
			<label style={style}>{label}
				<input type={type} placeholder={placeholder} value={value} style={inputStyle} onChange={onChange}/>
			</label>
		)
}

export default TextInput