import React, { Component } from 'react'
import Toggle from '../../toggle/toggle'
import InputText from '../../inputText/inputText'
import DatePicker from '../../DatePicker/datepicker'
import {AlertDialog, ContinueAction} from '../../alertDialog/alertDialog'
import ColorList, { ColorListItem, EmptyColorListMessage } from '../../ColorList/ColorList'
import Button from '../../Button/button'
import PromotionAPI from '../../../api/PromotionApi'
import './promotion.css'
import deepProps, {fill, isString, getFormattedDate, formatFloat, hasEmptyValues} from '../../../Utils'
import Popup from "reactjs-popup"
import DateRange from '../../DateRange'
import DropDownFilter from '../../DropDownFilter/DropDownFilter'
import { ChevronDown, Save } from 'react-feather'
import { setupPromotionAPI, updatePromotionAPI, addNewPromotionAPI} from '../../../server/Promotion/promotionAPI'
import { NotificationManager } from 'react-notifications'

const moment = require('moment');

const week = [{name:'Dom', text:'Domingo', index:1},{name:'Seg', text:'Segunda-Feira', index:2},{name:'Ter', text:'Terça-Feira', index:3},{name:'Qua', text:'Quarta-Feira', index:4},{name:'Qui', text:'Quinta-Feira', index:5},{name:'Sex', text:'Sexta-Feira', index:6},{name:'Sáb', text:'Sábado', index:7}]

export default class Promotions extends Component {
	constructor(props){
        super(props)

		this.state = {
            token:'', restrictionDays :[], restrictionHoursPopUp:[],
            typeValue:'BY_VALUE',dateStart: new Date().toISOString().split('T')[0], dateEnd: new Date().toISOString().split('T')[0],
            promotion:{
                id:0,
                desciption: 'Nova promoção',
                resourceImage: '',
                status: 'CREATING',
                dateStart: new Date(),
                dateEnd: new Date(),
                rules:'',
                value: '0.0',
                promotionPattern: 'FAST',
                typeValue: 'BY_VALUE',
                redeemType: "MANUAL",
                stores: []
            },
            hourStart:new Date(), hourEnd:new Date(), dayId : 0, hourId:0, description : '', rules: '',
            value:0, dayText:'', startDate : props.initialStartDate, endDate : props.initialEndDate,
            highlight: false,
            prefixStorage: 'pre_0'
        }
        
        this.updateType = this.updateType.bind(this)
        this.onDatePickerDoubleClick = this.onDatePickerDoubleClick.bind(this) 
        this.datePicker = new DatePicker()
    }
    
    loadPromotion (promotion) {
        if (promotion === null || promotion === undefined) {
            this.props.store.dispatch({type:'LOGIN_REQUERIED'})
            return
        }
        let description = deepProps('description')(promotion).getOrElse('')
        let rules = deepProps('rules')(promotion).getOrElse('')

        let typeValue = deepProps('typeValue')(promotion).getOrElse('BY_VALUE')
        let promotionPattern = deepProps('promotionPattern')(promotion).getOrElse('FAST')

        let redeemType = deepProps('redeemType')(promotion).getOrElse('MANUAL')
        
        let value = promotionPattern === 'VOUCHER' ?  deepProps('maxDiscountByCustomer')(promotion).getOrElse(0) : deepProps('value')(promotion).getOrElse(0)
        let dateStart = deepProps('dateStart')(promotion).get()
        
        if (dateStart !== undefined) {
            var split = dateStart.split("+")[0]
            dateStart = split += "Z"
            let date = new Date(dateStart)
            var momentStart = moment(date)
            dateStart =  momentStart.format().substring(0,10)
        }
        
        let dateEnd = deepProps('dateEnd')(promotion).get()
        if (dateEnd !== undefined) {
            var split = dateEnd.split("+")[0]
            dateEnd = split += "Z"
            let dateX = new Date(dateEnd)
            var momentEnd = moment(dateX)
            dateEnd = momentEnd.format().substring(0,10)
        }
        this.setState({
            promotion, description, promotionPattern, rules, typeValue, value, dateStart, dateEnd, 
            startDate: momentStart, endDate: momentEnd, redeemType, originalRules: rules
        })
    }

    loadState (lastState) {
        let token = deepProps('token')(lastState).getOrElse('')
        let promotion = {...deepProps('promotionDetails')(this.props.store.getState()).getOrElse({id:0})}

        this.setState({iRule: promotion.rules})
        switch(lastState.type) {
            case 'PROMOTION_DETAILS':
            case 'PROMOTION_ADDED':
            case 'PROMOTION_UPDATED':
            case 'PROMOTION_ITEMS_SEARCH':
                this.loadPromotion(promotion)
                if (!(lastState.type === 'PROMOTION_UPDATED' && promotion.id > 0)) {
                    let restrictionDays = deepProps('restrictDays')(promotion).getOrElse([])
                    this.setState({restrictionDays}, () => this.createRule())
                }
                break
            case 'CHANGE_STATUS_PROMOTION':
                promotion = deepProps('promotionDetails')(this.props.store.getState()).getOrElse(null)
                if (promotion != null) {
                    let promotionId = deepProps('id')(promotion).getOrElse(-1)
                    let promotioThatStatusChanged = deepProps('promotioThatStatusChanged')(lastState).getOrElse(0)
                    if (promotionId === promotioThatStatusChanged)
                        promotion['status'] = deepProps('promotionStatus')(lastState).getOrElse('UN_KNOW')
                }
                break
			default:
			break
        }
    }

    getRestrictionDays(token, id){
        PromotionAPI.getRestrictedDays(token, id, (response) => {
            let data = deepProps('data')(response).getOrElse(null)
            if (data === null || data === undefined)
                return
            this.setState({restrictionDays:data}, () => this.createRule())
        }, (errorMessage) => {
            AlertDialog('Ops, algo deu errado ao carregar as retrições', errorMessage)
        })
    }

    componentDidMount(){
        let lastState = this.props.store.getState()
        let {userAccess} = lastState
        let companyId = 0
        let prefixStorage = `pre_${companyId}`
        if (userAccess) {
            companyId = userAccess.companyId
            prefixStorage = `pre_${companyId}`
            this.setState({prefixStorage})
        }
        let token = deepProps('token')(this.props.store.getState()).getOrElse('')
        let promotionID = deepProps('promotionDetails','id')(lastState).getOrElse({id:0})
        let aditionalRule = localStorage.getItem(`${prefixStorage}-aditionalRule`)
        // debugger
        this.setState({token, aditionalRule})
        if (lastState.type !== 'NEW_PROMOTION') {
            let promotion = deepProps('promotionDetails')(this.props.store.getState()).getOrElse(null)
            this.loadPromotion(promotion)
            this.loadState(lastState)
            this.getRestrictionDays(token, promotionID)
        }

        this.unsubscribe = this.props.store.subscribe(() => {
            let lastState = this.props.store.getState()
            this.loadState(lastState)
        })

		if(this.state.rule === '')
            this.createRule()
        moment.locale('pt-br')
    }

    componentWillUnmount () {
        if(this.unsubscribe) this.unsubscribe()
    }

    updateType(){
        if(this.state.promotionPattern === 'VOUCHER'){
            return
        }
        this.setState({typeValue: ((this.state.typeValue === 'BY_VALUE') ? 'BY_PERCENT' : 'BY_VALUE')}, ()=> {
            this.onChange('updateType')
        })
    }
  
    onChange(field= '', value) {
        let {promotion} = this.state
        promotion[field] = value
        this.setState({[field]: value, promotion}, () => {
            if (promotion === undefined)
                return
            if (['date_fields', 'value', 'updateType', 'description', 'aditionalRule'].includes(field))
                this.createRule()
		});
    }

    createRule() {
        const prefixAditionalRules = '['
        const sufixAditionalRules = ']'
        let {
            promotionPattern, restrictionDays, aditionalRule, promotion,
            description, value, typeValue, startDate, endDate, originalRules
        } = this.state
        try{
            let begin = ''
            if(promotionPattern === 'VOUCHER'){
                begin = `O voucher "${description}" oferece um valor de R$${formatFloat(value)} para você utilizar em suas compras`
            }else{
                begin = 'Ganhe ' + (typeValue === 'BY_VALUE' ? 'R$' + 
                formatFloat(value) : formatFloat(value) + '%') + ' de desconto com a promoção "' + 
                description + '"'
            }

            let rules
            try{
                rules = begin + ', válidos do dia ' + startDate.format('L') + 
                ' até o dia ' + endDate.format('L') + '.'
            }catch{
                rules = begin + ', válidos do dia ' + moment().format('L') + 
                ' até o dia ' + moment().format('L') + '.'
            }

            let providers = []
            let sectors = []
            let products = []

            let items = this.props.store.getState()['promotionDetails'].items || []

            items.map(item => {
                switch(item.type){
                    case 'PROVIDER':
                        providers.push(item.description)
                        break
                    case 'PRODUCT':
                        products.push(`${item.description} (${item.products.map(p => p.eanCode).join(', ')})`)
                        break
                    case 'SECTOR':
                        sectors.push(item.description)
                        break
                }
            })
            
            if(items.length > 0){
                if(providers.length > 0){
                    rules = `${rules}\nFornecedores participantes: ${providers.join('; ')}.`
                }
    
                if(products.length > 0){
                    rules = `${rules}\nProdutos participantes: ${products.join('; ')}.`
                }
    
                if(sectors.length > 0){
                    rules = `${rules}\nSetores participantes: ${sectors.join('; ')}.`
                }
            }

            if(restrictionDays && restrictionDays.length > 0){
                rules = `${rules}\nRestrições de horário: ${restrictionDays.map(dayRestriction => `${this.getDayLabel(dayRestriction.day, dayRestriction.dayType)} (${this.getColorListHourLabel(dayRestriction.hours, true)})`).join('; ')}`
            }

            //Identifica as informações adicionais que foram apresentadas na regra adicional.
            if (originalRules) {
                let array = originalRules.split('\n')
                .filter(e => e.startsWith(prefixAditionalRules) && e.endsWith(sufixAditionalRules))
                if (array.length < 1)
                    array = ['']
                let reduced = array.reduce((a, b) => {
                    a = !a ? '' : a.trim()
                    b = !b ? '' : b.trim()
                    return `${a}${a.length > 1 ? ' ' + b : b}`
                }).replace(/[\[\]]/gi, '')
                if (reduced && !aditionalRule) {
                    aditionalRule = reduced
                }
            }
            
            let append = `${aditionalRule ? '[' + aditionalRule + ']' : ''}`
            rules = `${rules}\n\n${append}`
            let newPromotion = promotion
            newPromotion['rules'] = rules

            this.setState({rules, promotion: newPromotion}, () => document.getElementById('ruleFieldInput').scrollTop = 1000)

        }catch(e){
            console.error(e)
        } 
    }

    hourToString(hour) {
        let regex = /([01]\d|2[0-3]):?[0-5]\d/
        let result = '1970-01-01T'
        let match = hour.match(regex)
        if (match !== null && match !== undefined) {
            result = `${result}${hour}:00.000+0000`
            return result
        }
        return null
    }

    getHour(strDate) {
        if (!isString(strDate))
            return '00:00'
        let array = strDate.split('+')
        if (array.length > 0) {
            let r = array[0]
            try {
                let date = new Date(r)
                return fill(date.getHours() + '', '0', 2) + ":" + fill(date.getMinutes() + '', '0', 2)
            }catch(e){
                return '00:00'
            }
        }
    }
    
    async openPopUp(day, write) {
        if (!write) {
            AlertDialog('Você não tem permissão para realizar essa tarefa', 'Entendi')
            return;
        }
        
        if(day.id === undefined || day.id === null){return}
        let {dayType, id, hours} = day
        await this.setState({dayId:id, hourStart:'00:00', hourEnd:'00:00', restrictionHoursPopUp:hours, dayType})
        this.popup.openPopup(undefined, write)
    }

    async onAddHourClick(hourStart, hourEnd, dayId){
        try{
            this.validateHourRestriction({hourStart, hourEnd, dayId})
            let hourRestriction = await this.addHourRestrictionToDay(hourStart, hourEnd, dayId)
            this.addNewHourRestrictionToState(hourRestriction, dayId)
            this.cleanHourFields()
        }catch(error) {
            let message = ''
            if (typeof(error) === 'string')
                message = error
            else if (typeof(error) === 'object')
                message = deepProps('message')(error).getOrElse('')
            AlertDialog('Ops, algo deu errado', message)
            console.error(message)
        } 
    }

    addHourRestrictionToDay(hourStart, hourEnd, dayId){
        return new Promise((resolve, reject)=>{
            PromotionAPI.addRestrictedHour(this.state.token, dayId, this.hourToString(hourStart), this.hourToString(hourEnd), (result) => {
                resolve(result.data)
            }, (error)=>{
                reject(error)
            })
        })  
    }

    validateHourRestriction(hourRestriction) {
        let error = {}
        if(hasEmptyValues(hourRestriction)) {
            error['message'] = 'Os campos não podem ficar em branco.'
            throw error
        }
        if(hourRestriction.hourStart === hourRestriction.hourEnd) {
            error['message'] = 'Os horários não podem ser os mesmos.'
            throw error
        }
        if(this.hourToString(hourRestriction.hourStart) > this.hourToString(hourRestriction.hourEnd)) {
            error['message'] = 'O horário de início não pode ser depois do horário de fim.'
            throw error
        }
    }

    async addNewHourRestrictionToState(newHour, dayId) {
        let restrictionHoursPopUp = []
        let restrictionDays = await this.state.restrictionDays.map(restriction=>{
            if(restriction.id === dayId){
                restriction.hours = [...restriction.hours, newHour]
                restrictionHoursPopUp = restriction.hours
            }
            return restriction
        })
        this.setState({restrictionDays, restrictionHoursPopUp})
        this.createRule()
    }

    async onRemoveHourClick(hourId){
        try {
            await this.removeHourRestriction(hourId)
            this.removeHourRestrictionFromState(hourId)
            this.createRule()
        } catch(error) {
            AlertDialog('Ops, algo deu errado', error)
        }
    }

    removeHourRestriction(hourId) {
        return new Promise((resolve, reject)=>{
            PromotionAPI.removeRestrictHour(this.state.token, hourId, (response) => {
                this.createRule()
                resolve(response)
            }, (errorMessage) => {
                reject(errorMessage)
            })
        })
    }

    removeHourRestrictionFromState(hourId, dayId = this.state.dayId){
        let restrictionHoursPopUp = this.state.restrictionHoursPopUp.filter(restriction => restriction.id !== hourId)
        let restrictionDays = this.state.restrictionDays.map(restriction=>{
            if(restriction.id === dayId){
                restriction.hours = restrictionHoursPopUp
            }
            return restriction
        })
        this.setState({restrictionHoursPopUp, restrictionDays})
        this.createRule()
    }

    cleanHourFields () {
        this.setState({hourStart:'00:00', hourEnd: '00:00', hourId:0})
    }

    async onDatePickerDoubleClick(text, date, write) {
        if (!write) {
            AlertDialog('Você não tem permissão para realizar essa tarefa', 'Entendi')
            return;
        }
        if (!['PENDING', 'CREATING'].includes(this.state.promotion.status)) {
            if (!this.state.promotion.restrictDays || this.state.promotion.restrictDays.length < 1) {
                return NotificationManager.error(
                    'Ops, não é possível realizar essa operação', 
                    'Não é possível adicionar restrições de dias em campanhas publicadas onde não exista uma restrição prévia'
                )
            }
        }
        try{
            if(this.state.promotion.status === 'DEPRECATED'){return}
            let {day, dayType} = date
            this.cleanHourFields()
            let searchDayRestriction = await this.findDayRestrictionByDay(day, dayType)

            if(searchDayRestriction.length > 0){
                this.openPopUp(searchDayRestriction[0], write)
                return
            }

            this.addTemporaryDayRestrictionToState(date)

            let restrictionDay =  await this.addDayRestriction(this.state.promotion.id, day, dayType)
            restrictionDay.hours = (restrictionDay.hours === undefined || restrictionDay.hours === null) ? [] : restrictionDay.hours
            this.addNewDayRestrictionToState(restrictionDay)
        }catch(error){
            AlertDialog('Ops, algo deu errado', error)
            this.removeTemporaryDayRestrictionFromState(date)
        }
    }

    findDayRestrictionByDay(day, dayType) {
        return this.state.restrictionDays.filter(restriction=>(restriction.day === day && restriction.dayType === dayType))
    }

    addDayRestriction(promotionId, day, dayType) {
        return new Promise((resolve, reject)=>{
            PromotionAPI.addRestrictedDay(this.state.token, promotionId, day, dayType, (response) => {
                resolve(response.data)
            },(error)=>{
                reject(error)
            })
        })
    }

    async addNewDayRestrictionToState(newDayRestriction) {
        let newDayRestrictionState = await this.state.restrictionDays.map(restriction=>{
            if((restriction.day === newDayRestriction.day) && (restriction.dayType === newDayRestriction.dayType)){
                restriction = newDayRestriction
            }
            return restriction
        })
        this.setState({restrictionDays:newDayRestrictionState}, ()=>this.createRule())
        let {promotion} = this.state
        let {store} = this.props
        if (promotion === undefined || store === undefined)
            return
        promotion['restrictDays'] = newDayRestrictionState
        store.dispatch({type:'PROMOTION_UPDATE_RESTRICTDAYS', promotionDetails:promotion})
    }

    addTemporaryDayRestrictionToState(day) {
        day['hours'] = []
        console.table(day)
        this.setState({restrictionDays:[...this.state.restrictionDays, day]})
    }

    async removeTemporaryDayRestrictionFromState(temporaryRestriction){
        let newState = await this.state.restrictionDays.filter(restriction => restriction.day !== temporaryRestriction.day)
        this.setState({restrictionDays:newState})
    }

    async onDeleteDayClick(dayRestriction){
        let {id, day, dayType} = dayRestriction
        try{
            let confirmed = await ContinueAction('Deseja realmente excluir?', 'As restrições de ' + this.getDayLabel(day, dayType) + ' serão removidas')
            if(!confirmed){return}
            let dayRestriction = await this.deleteDayRestriction(id)
            this.removeDayRestrictionFromState(dayRestriction)
        }catch(error){
            AlertDialog('Ops, algo deu errado', error)
        }
    }

    deleteDayRestriction(dayId){
        return new Promise((resolve, reject)=>{
            PromotionAPI.removeRestrictedDay(this.state.token, dayId, (response) => {
                resolve(response.data)
            }, (error) => {
                reject(error)
            })
        })
    }

    removeDayRestrictionFromState(dayId) {
        let restrictionDays = this.state.restrictionDays.filter(day => day.id !== dayId)
        this.setState({restrictionDays}, () => this.createRule())
        let {promotion} = this.state
        let {store} = this.props
        if (promotion === undefined || store === undefined)
            return
        promotion['restrictDays'] = restrictionDays
        store.dispatch({type:'PROMOTION_UPDATE_RESTRICTDAYS', promotionDetails:promotion})
    }

    async savePromotion(){
        let {promotion} = this.state

        if (!this.validateFields(this.state))return

        let body = PromotionAPI.getPromotionJson(this.state, deepProps('segmentationType')(promotion).get())
        console.log('%c Body da requisição', 'color: purple')
        console.log(body);
       
        this.setState({loading: true})
        let promotionId = promotion === undefined ? 0 : deepProps('id')(promotion).getOrElse(0)
        
        if (promotionId < 1) {
            addNewPromotionAPI(body).then(response => {
                this.props.store.dispatch({type:'PROMOTION_ADDED', promotionDetails:response.data})
                this.savePromotionPattern(response.data.id, body, response)
            }).catch(()=>{
                NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
            }).finally(()=>{
                this.setState({loading: false})
            })

        }else {
            updatePromotionAPI(promotionId ,body).then((response)=>{
                this.savePromotionPattern(promotionId, body, response)

            }).catch(()=>{
                NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
            }).finally(()=>{
                this.setState({loading: false})
            })
        }
    }

    savePromotionPattern(promotionId, body, response) {
        setupPromotionAPI(promotionId, body).then((e)=>{
            NotificationManager.success('Promoção salva com sucesso!', 'Sucesso!')
            if(body.promotionPattern === 'voucher' && body.typeValue === 'BY_VALUE'){
                e.data['typeValue'] = 'BY_VALUE'
            }
            response.data = e.data
        }).catch((e)=>{
            if(e.status === 409 && body.promotionPattern === 'voucher'){
                return NotificationManager.info('Salva com o tipo "promoção simples". Voucher não pode ter mais de 1 item', 'Promoção salva com restrições!')
            }
            NotificationManager.info('Verifique as informações e tente novamente', 'Rascunho salvo!')
        }).finally(()=>{
            this.saveAditionalRuleOnLocalStorage(this.state.aditionalRule)
            this.props.store.dispatch({type:'PROMOTION_UPDATED', promotionDetails:response.data})
            this.setState({loading: false})
        })
    }

    validateFields(promotion){
        let {description, dateStart, dateEnd, value, rules, promotionPattern} = promotion

        if(description.trim() === ''){
            NotificationManager.warning('Favor informar o título da promoção.', 'Informação incompleta!')
            return false
        }

        if(rules.trim() === ''){
            NotificationManager.warning('Favor informar as regras da promoção.', 'Informação incompleta!')
            return false
        }

        if(value <= 0){
            NotificationManager.warning('A promoção não pode ter um desconto menor ou igual a zero.', 'Informação conflitante!')
            return false
        }

        if(dateStart > dateEnd){
            NotificationManager.warning('A data de início não pode ser posterior a data de término.', 'Informação conflitante!')
            return false
        }
        let today = new Date() 
        today.setMilliseconds(0)
        today.setSeconds(0)
        today.setMinutes(0)
        today.setHours(0)
        if(dateEnd < today){
            NotificationManager.warning('A data de término não pode ser anterior a data de hoje.', 'Informação conflitante!')
            return false
        }

        if(!promotionPattern){
            NotificationManager.warning('Favor selecione o tipo da promoção.','Informação incompleta!')
            return false
        }

        if(promotionPattern === 'VOUCHER' && this.props.store.getState()['promotionDetails'].items &&  this.props.store.getState()['promotionDetails'].items.length > 1){
            NotificationManager.warning('A campanha contém mais de 1 (um) item promovido.', 'Não pode ser um voucher')
            return false
        }

        if(promotionPattern === 'PROGRESSIVE' && this.props.store.getState()['promotionDetails'].segmentationType === 'BY_CUSTOMER'){
            NotificationManager.warning('Essa é uma promoção segmentada, não pode ser progressiva', 'Não pode ser do tipo "Progressiva"')
            return false
        }

        return true
    }

    saveAditionalRuleOnLocalStorage(aditionalRule){
        let {prefixStorage} = this.state
        let savedRule = localStorage.getItem(`${prefixStorage}-aditionalRule`)
        if((aditionalRule || savedRule) && (aditionalRule != savedRule)){
            let verb = (!savedRule) ? 'salvar' : 'atualizar'
            NotificationManager.info(`Clique aqui se deseja ${verb} a regra adicional para todas as novas promoções`, 'Nova regra adicional', 20000, () => {
                localStorage.setItem(`${prefixStorage}-aditionalRule`, aditionalRule)
                NotificationManager.success('Regra adicional salva com sucesso', 'Concluído!')
            })   
        }
    }

    canUpdate(promotionStatus) {
        return ['PENDING', 'CREATING'].includes(promotionStatus)
    }

    canAddRestriction(promotionStatus) {
        return ['PENDING', 'CREATING', 'PUBLISHED'].includes(promotionStatus)
    }

    getDayLabel(day, dayType){
        if(dayType === 'WEEK'){
            for (let i =0; i < week.length; i++)
                if (week[i].index === day)
                    return week[i].text
        }
        return 'Todo dia ' + day
    }

    getColorListHourLabel(hours, showAllHours){
        try{
            switch(hours.length){
                case 0:
                    return "O dia todo"
                case 1:
                    return this.getHour(hours[0].hourStart) + " às " + this.getHour(hours[0].hourEnd)
                default:
                    return (showAllHours) ? hours.map(h => this.getColorListHourLabel([h])).join(', ') : hours.length + " horários"
            }
        }catch(error){
            return 'O dia todo'
        }   
    }

    getColorListTitleLable(start, end){
        return 'Do dia ' + getFormattedDate(start) + ' ao ' + getFormattedDate(end)
    }

    onDatesChange = (date, state) => {
        this.onChange('date_fields', 'zzzz')
        let dateStrValue = date.toDate().toISOString().split('T')[0];
        this.setState( {[state] : dateStrValue} )
        return date
    }

    onDateChangeHandle = (start, end) => {
        if (!start || !end) return
        if(start)
            this.setState({dateStart: start.format().substring(0,10)})
            
        if(end){
            this.setState({dateEnd: end.format().substring(0,10)})
        }

        this.setState({startDate:start, endDate:end}, () => this.createRule())
    }

    onPromotionPatternChangeHandle = (filter) => {
        this.setState({promotionPattern: filter.value})
        
        if(filter.value === 'VOUCHER' && this.state.typeValue === 'BY_PERCENT'){
            this.onChange('typeValue', 'BY_VALUE')
        }
        this.createRule()
    }


	render() {
        let {write} = this.props
		return (
			<div className="tabContent" style={{flexDirection: 'column'}}>
              <div className='content'>
                <div className='row'>
                    <InputText disabled={!this.canUpdate(this.state.promotion.status)} text label='Título' state='description' placeholder="Digite o título aqui..." value={this.state.description} onChange={this.onChange.bind(this)} />
                    <DropDownFilter icon={<ChevronDown/>} onSelect={this.onPromotionPatternChangeHandle} filters={[{text: 'Simples', value: 'FAST'},{text: 'Voucher', value: 'VOUCHER'},{text: 'Progressiva', value: 'PROGRESSIVE'}]}  selected={this.state.promotionPattern} value={this.state.promotionPattern} border style={{marginBottom: 2, marginRight: 4}} title='Tipo da promoção'/>
                    <div className='row' style={{minWidth: 500}}>
                        <InputText disabled={!this.canUpdate(this.state.promotion.status, this.state.promotionPattern)} number label={'Desconto'} state='value' placeholder="Digite o valor de desconto aqui..." value={this.state.value} onChange={this.onChange.bind(this)} style={{maxWidth: 72}}/>
                        <div style={{alignSelf: 'flex-end', marginBottom: 8, marginRight:4, marginLeft: -8}}>
                            <Toggle left='%' right='R$' square disabled={this.state.promotionPattern === 'VOUCHER'} active={(this.state.typeValue === 'BY_VALUE' || this.state.promotionPattern === 'VOUCHER')} teste={this.state.promotionPattern} onClick={()=>{this.updateType()}}/>
                        </div>
                        <div className='datesContainer'>
                            <DateRange startDate={this.state.startDate} endDate={this.state.endDate} onChange={(startDate, endDate)=>this.onDateChangeHandle(startDate, endDate)}/>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <InputText styleInput={{height:120, minWidth: 300}} id= 'ruleFieldInput'
                        disabled={!this.canUpdate(this.state.promotion.status)} label='Regras' multiline 
                        state='rules' placeholder="Digite as regras aqui..." value={this.state.rules} onChange={this.onChange.bind(this)}/>
                    <InputText styleInput={{height:120, minWidth: 300}} 
                        disabled={!this.canUpdate(this.state.promotion.status)} label='Regra adicional' multiline 
                        state='aditionalRule' placeholder="Digite o adicional das regras aqui..." value={this.state.aditionalRule} onChange={this.onChange.bind(this)}/>
                </div>
                <div className='row'  style={{alignItems:'unset', paddingTop: 8}}>
                    <DatePicker style={{marginRight: 16}} onClick={(text, date)=>{this.onDatePickerDoubleClick(text, date, this.props.write)}} dateStart={this.state.dateStart} dateEnd={this.state.dateEnd} exceptionDay={this.state.restrictionDays}/>  
                    <ColorList items={this.state.restrictionDays} text='Promoção válida'>
                        {this.state.restrictionDays.length === 0 && <EmptyColorListMessage title={this.getColorListTitleLable(this.state.dateStart, this.state.dateEnd)} subtitle='Adicione uma restrição com dois cliques no calendário'/>}
                        {this.state.restrictionDays.map((restriction, idx)=>{
                            if(this.state.promotion.status === 'PENDING'){
                                return <ColorListItem key={idx} data={[{title: this.getDayLabel(restriction.day, restriction.dayType), style:'title'}, {title: this.getColorListHourLabel(restriction.hours), style:'subtitle'}]} onDoubleClick={()=>this.openPopUp(restriction, this.props.write)} delete={(pause)=>this.onDeleteDayClick(restriction)} enable={restriction.id !== undefined}/>
                            }
                            return <ColorListItem key={idx} data={[{title: this.getDayLabel(restriction.day, restriction.dayType), style:'title'}, {title: this.getColorListHourLabel(restriction.hours), style:'subtitle'}]} onDoubleClick={()=>this.openPopUp(restriction, this.props.write)} enable={restriction.id !== undefined}/>
                        })}
                    </ColorList>
                </div>
              </div>
                {(this.canUpdate(this.state.promotion.status) && write) && <Button text="Salvar" loading={this.state.loading} style={{margin:16}} small icon={<Save/>} onClick={()=>{this.savePromotion()}} tip={this.state.iRule !== this.state.rules}/>}
                <Popup ref={ref => this.popup = ref} style={{backgroundColor: 'red'}}>
                    <h1 className= "ColorList-popup-title">Promoção válida {this.state.dayText} nos seguintes horários</h1>
                    <ul className = "ColorList-popup" >
                        {this.state.restrictionHoursPopUp.length === 0 && <EmptyColorListMessage title={'Esse dia não possui restrição de horários'} subtitle='Ativa entre 00:00 e 23:59'/>}
                        {this.state.restrictionHoursPopUp.map((hour, idx) => {
                            if(this.state.promotion.status === 'PENDING'){
                                return <ColorListItem key={idx} data={[{title:this.getHour(hour.hourStart), status:'green'},{title:this.getHour(hour.hourEnd), status:'red'}]} delete={()=>this.onRemoveHourClick(hour.id)} enable/>
                            }
                            return <ColorListItem key={idx} data={[{title:this.getHour(hour.hourStart), status:'green'},{title:this.getHour(hour.hourEnd), status:'red'}]} enable/>}
                            )
                        }
                    </ul>
                    {(this.state.promotion.id > 0 && this.canAddRestriction(this.state.promotion.status) && write) && <div className='row' style={{margin: 10, marginLeft: 16}}>
                        <InputText label='Hora Início' time state='hourStart' placeholder="00:00:00" value={this.state.hourStart} onChange={this.onChange.bind(this)} style={{maxWidth: 120}}/>
                        <InputText label='Hora Fim' time state='hourEnd' placeholder="00:00:00" value={this.state.hourEnd} onChange={this.onChange.bind(this)} style={{maxWidth: 120}}/>	
                        <svg onClick={()=>this.onAddHourClick(this.state.hourStart, this.state.hourEnd, this.state.dayId)} className='add-button' xmlns="http://www.w3.org/2000/svg" id="Capa_1" x="0px" y="0px" viewBox="0 0 491.86 491.86" style={{marginTop: 19}}>
                            <path d="M465.167,211.614H280.245V26.691c0-8.424-11.439-26.69-34.316-26.69s-34.316,18.267-34.316,26.69v184.924H26.69    C18.267,211.614,0,223.053,0,245.929s18.267,34.316,26.69,34.316h184.924v184.924c0,8.422,11.438,26.69,34.316,26.69    s34.316-18.268,34.316-26.69V280.245H465.17c8.422,0,26.69-11.438,26.69-34.316S473.59,211.614,465.167,211.614z" fill="#2196f3"/>
                        </svg>
                    </div>}
                </Popup>
			</div>
		);
  	}
}