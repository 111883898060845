import React, { Component } from 'react';
import './reachVisualization.scss'
import AsideButtonCard from '../AsideButtonCard/AsideButtonCard';
import deepProps, { getSegmentationTypeText } from '../../Utils';
import { cancelSegmentation } from '../../server/segmentation/segmentationAPI';
import {AskDialog} from '../../component/alertDialog/alertDialog'
import PromotionAPI from '../../api/PromotionApi';
import { confirmAlert } from 'react-confirm-alert';
import { NotificationManager } from 'react-notifications';

export class ReachAsideCard extends Component {
	constructor(props) {
		super(props);
    this.state = {};
  }

  componentDidMount(){
    this.setStateSegmentationTypeLabels(this.props.promotion.segmentationType, this.props.promotion.doneSegmentation)   
  }

  toggleSegmentationType() {
    let itemsFiltered = this.props.promotion.items.filter(item=>item.highlighted)
    if(itemsFiltered.length > 0) return NotificationManager.warning('Essa promoção possui promovidos em destaque', 'Ops, algo deu errado!')
  }

  setStateSegmentationTypeLabels(segmentationType, doneSegmentation){
      this.setState({segmentationType, doneSegmentation, segmentationTypeTitle:this.getSegmentationTypeTitle(segmentationType), segmentationTypeText: getSegmentationTypeText(segmentationType, this.props.promotion.status)})
  }

  getSegmentationTypeTitle(type, doneSegmentation){
    if(type === 'PUBLIC'){return 'Não segmentada'}
    return (doneSegmentation === false) ? 'Segmentação em andamento' : 'Segmentada'
  }

  getButtonLabel(type, doneSegmentation){
    if(type === 'PUBLIC') {
      return 'Tornar segmentada'
    }
    return (doneSegmentation === false) ? 'Cancelar' : 'remover segmentação'
  }

  alertDialog = (title,message) => {
		confirmAlert({
		  title: title,
		  message: message,
		  buttons: [
			{
			  label: 'Entendi!',
			  onClick: () => {}
			}
		  ]
		})
  }
  
  cancelSegmentation = (promotionId) => {
    let {store} = this.props
    if (store === undefined || promotionId === undefined || promotionId < 1)
      return

    AskDialog('Deseja realmente tornar essa promoção pública?', 
      'Ao continuar, essa promoção deixará de ser segmentada à clientes específicos e qualquer um poderá acessá-la.', ()=> {
      let result = cancelSegmentation(promotionId)
      result.then(response => {
        if (response && response.status === 0) {
          NotificationManager.success('Essa promoção agora e pública e vicível para todos os clientes', 'Segmentação removida com sucesso!')
          this.props.store.dispatch({type:'CHANGE_SEGMENTATION'})
          this.props.store.dispatch(PromotionAPI.findPromotion(promotionId, () => {
          }, (erroMessage) => {
              this.alertDialog('Ops, algo deu errado', erroMessage)
          }))
        }
      })
    })

  }

  onRedeemTypeChecked(){
    this.props.store.dispatch({type:'REDEEM_TYPE_CHANGE'})
  }

  render() {
    let {promotion, store} = this.props
    let {segmentationType, doneSegmentation, loading} = this.state
    if (promotion !== undefined && promotion !== null)
      segmentationType = deepProps('segmentationType')(promotion).getOrElse(segmentationType)
    return (
      <div>
        <AsideButtonCard 
          title={this.getSegmentationTypeTitle(segmentationType, doneSegmentation)} 
          text={getSegmentationTypeText(segmentationType, this.props.promotion.status)}
          invert
          loading={loading} textLink = 'remover segmentação'
          showLink={promotion.segmentationType !== 'PUBLIC' && promotion.status === 'PENDING'}
            onLinkClick = {() => this.cancelSegmentation(promotion.id)}>

            {/* {!promotion.doneSegmentation && <SegAnimation/>} */}
        </AsideButtonCard>
      </div>
    );
  }
}