import React, {useState, useEffect} from 'react';
import {PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import deepProps from '../../Utils';
import './pieChart.scss';
import EmptyMessage from '../emptyMessage/emptyMessage'
import Button from '../Button/button';

const COLORS = ['#FD9D02', '#2AC940', '#ec6b56', '#393CF9'];

const RADIAN = Math.PI / 180;                    
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
 	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  	const x  = cx + radius * Math.cos(-midAngle * RADIAN);
  	const y = cy  + radius * Math.sin(-midAngle * RADIAN);
 
  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
    	{`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function PieCharts(props){

	const [data, setData] = useState([])
	const [empty, setEmpty] = useState(false)

	const getColorIndex = (name) => {
		switch (name){
			case 'Pendente':
				return 0
			case 'Publicado':
				return 1
			case 'Descontinuado':
				return 2
			default: 
				return 3
		}
	}

  	return (
		<div className='piechartContainer'>
			{false && <div className='pieChartTitle'>
				{props.title && <h1>{props.title}</h1>}
			</div>}
			<div className= 'piechartContent'>
			{empty &&  <EmptyMessage icon='empty-box' title="Sem dados suficientes" description="Não há dados suficientes para elaborar o gráfico" />}
			{props.data.length > 0 && <ResponsiveContainer width={180} height={180}>
					<PieChart>
							<Pie dataKey="value" data={props.data} isAnimationActive={false} cx="50%" cy="50%" fill="#8884d8" labelLine={false} innerRadius={74} outerRadius={90}>
								{
									props.data.map((entry, index) => <Cell key={index} fill={COLORS[getColorIndex(entry.name)]}/>)
								}
							</Pie>
							<Tooltip/>
					</PieChart>
				</ResponsiveContainer>}
				<div className='info'>
					<h1 className='value'>{props.value}</h1>
					<h2 className='value-description'>promoções</h2>
				</div>
			</div>
			<div className='info'>
				<Button text='Nova promoção' shine style={{width:140}}/>
			</div>
		</div>
    )
}