export const getSearchBody = (input, database, page = 0, size = 30) => {
    let from = page
    if(page > 0){
        from = (size * page) + 1
    }
    
    switch(database){
        case "supliers":
            return getSupliersSearchBody(input, from)
        case 'sectors':
            return getSectorsSearchBody(input, from)
        case 'products':
        default:
            return getProductsSearchBody(input, from)
    }
}

export const getSectorsSearchBody = (query, from = 0, size = 30) => 
    {
        let should = []
        if (query.match(new RegExp('^\\d+$'))) {
            should = [
                {
                    bool: {
                        must: [
                            {term: {sector1: query}}, {term : {sector2: "0"}}, 
                            {term : {sector3: "0"}}, {term : {sector4: "0"}}, 
                            {term : {sector5: "0"}}
                        ],
                        boost: 10
                    }
                },
                {
                    bool: {
                        must : [
                            {term: {sector1: query}}
                        ],
                        boost: 9
                    }
                },
                {
                    bool: {
                        must: [
                            {term: {sector2: query}}, {term : {sector3: "0"}}, 
                            {term : {sector4: "0"}}, {term : {sector5: "0"}}
                        ],
                        boost: 9
                    }
                },
                {
                    bool: {
                        must : [
                            {term : {sector2: query}}
                        ],
                        boost: 8
                    }
                },
                {
                    bool: {
                        must: [
                            {term : {sector3: query}}, 
                            {term : {sector4: "0"}}, {term : {sector5: "0"}}
                        ],
                        boost: 8
                    }
                },
                {
                    bool: {
                        must: [ 
                            {term : {sector3: query}}
                        ],
                        boost: 7
                    }
                },
                {
                    bool: {
                        must: [
                            {term : {sector4: query}}, {term : {sector5: "0"}}
                        ],
                        boost: 7
                    }
                },
                {
                    bool: {
                        must: [
                            {term : {sector4: query}}
                        ],
                        boost: 6
                    }
                },
                {
                    bool: {
                        must: [
                            {term: {sector5: query}}
                        ],
                        boost: 6
                    }
                }
            ]
        }
        should = should.concat([
            {
                match: {
                    description: {
                        query: query, 
                        boost: 2
                    }
                }
            },
            {
                match: {
                    parentDescription: {
                        query: query,
                        boost: 1
                }
              }
            }
        ])
    let result = {
        from,
        size,
        query: {
            bool: {
                should
            }
        }
    }
    return result
}

export const getSupliersSearchBody = (query, from = 0, size = 30) => 
    {
    let result = {
        from,
        size,
        query: {
            bool: {
                should: [
                    {
                        match: {
                            name: {
                                query,
                                boost: 2
                            }
                        }
                    }, {
                        match: {
                            tradingName: {
                                query,
                                boost: 1
                            }
                        }
                    }
                ]
            }
        }
    }
    if (query.match(new RegExp('^\\d+$'))) {
        result.query.bool.should.push(
            {
                bool: {
                    must: {
                        term: {
                            id: query
                        }
                    }, boost: 100
                }
            }
        )
    }
    return result
}

export const getProductsSearchBody = (query, from = 0, size = 30) => {
    return {
        from,
        size,
        query: {
            bool: {
                should: [{
                        match: {
                            name: {
                                query,
                                boost: 2
                            }
                        }
                    }, {
                        match: {
                            "provider.name": {
                                query,
                                boost: 1
                            }
                        }
                    },
                    {
                        match: {
                            GTIN: {
                                query,
                                boost: 3
                            }
                        }
                    }
                ]
            }
        }
    }
}


export const getProductIdQuery = productId => {
    return {
        query: {match: {productId}}
    }
}