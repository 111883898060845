import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import StoreData from '../../component/cardTabStore/StoreData/StoreData';
import Adress from '../../component/cardTabStore/adress/adress';
import deepProps from '../../Utils'
import Contacts from '../../component/cardTabStore/contacts/contacts';
import './cardTabStore.css';

class CardTabStore extends Component {
	constructor(props) {
		super(props);
		this.state = {
			description: deepProps('description')(this.props.data).getOrElse(''),
			number: deepProps('number')(this.props.data).getOrElse(0),
			id: deepProps('id')(this.props.data).getOrElse(0),
			store:{}
		};
	}

	componentWillMount () {
		this.loadData(this.props.data)
	}

	componentDidUpdate(prevProps) {
		let data = deepProps('data')(this.props).get()
		if(prevProps === this.props)
			return
		this.loadData(data)
	}

	loadData (data) {
		let description = deepProps('description')(data).getOrElse('')
		let number = deepProps('number')(data).getOrElse(0)
		let id = deepProps('id')(data).getOrElse(0)
		this.setState({store:data, description, number, id})
	}

	render() {
		let {data} = this.props
		return (
			<Router>
				<div className="TabContainer">
					<ul className="header-cardTabc">					
						<li className="option-cardTabc" >
							<NavLink to="/main/store/store" activeClassName="active-user">Dados da Loja</NavLink>
						</li>

						{data.id > 0 && 
							<li className="option-cardTabc" >
								<NavLink to="/main/store/adress" activeClassName="active-user">Endereço</NavLink>
							</li>
						}
						{/*<li className="option-cardTabc" >
							<NavLink to="/main/store/contacts" activeClassName="active-user">Contatos</NavLink>
						</li>*/}
					</ul>

					<div className="routec">
						<Route path="/main/store/store" component={()=><StoreData store={this.props.store} write={this.props.write} read={this.props.read} data={this.state.store}/>}/>
						<Route path="/main/store/adress" component={()=><Adress store={this.props.store} write={this.props.write} read={this.props.read} data={this.props.address} objStore={this.props.data}/>}/>
						<Route path="/main/store/contacts" component={()=><Contacts store={this.props.store}  data={this.state.store}  write={this.props.write} read={this.props.read}/>}/>
					</div>
				</div>
			</Router>
		);
	}
}

export default CardTabStore;