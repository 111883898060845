import React, { Component } from 'react';
import './userDropBox.css';
import Button from '../Button/button';
import { NavLink } from 'react-router-dom';
import imgPerfil from '../../img/perfil_2.svg'


export default class UserDropBox extends Component {
  constructor(Props){
    super(Props)
    this.state = {
      show: false
    }
  }

  toggleState(){
    this.setState({show:!this.state.show})
  }

  render() {
    return (
      <div className={this.state.show ? "info-user open" : "info-user"} onClick={()=>{this.toggleState()}}>
        {/* <p className="name-user">{this.props.user}</p> */}
        <div className="photo-user">
          <img src={imgPerfil} alt=""></img>
        </div>
        <Dropper show={this.state.show} store={this.props.store} img={imgPerfil} user={this.props.user} identifier={this.props.identifier}/>
      </div>
    )
  }
}

export class Dropper extends Component{
  logout() {
    this.props.store.dispatch({type:'LOGIN_REQUERIED'})
  }

  render() {
    return (
      <div className='userDropBox'>
        <div className='info-userDropBox'>
            <img src={this.props.img} alt="imagem de perfil do usuário"></img>
                <div className='data'>
                    <h1>{this.props.user}</h1>
                    <h2>{this.props.identifier}</h2>
                </div>
        </div>
        <div className='footer'>
        <Button smallButton text='Sair' onClick={() => this.logout()}/>
			{false && <NavLink to='/customers' activeClassName="button-personaldata"> Configurações </NavLink>}
        </div>
      </div>
    )
  }
}