import React, { useState, useEffect } from 'react';
import './segmentation.scss';
import '../../TabScreen/style.scss'
import Button from '../../Button/button';
import TextInput from '../../textInput/textInput';
import ReactTooltip from 'react-tooltip'
import SegmentationFilters from '../../addComboListView/SegmentationFilters';
import { getPromotionReachAPI, removeCustomerSegmentation, getSegmentationReachAPI, setPromotionSegmentationTargetAPI, setSegmentationTargetCustomersAPI } from '../../../server/segmentation/segmentationAPI';
import {NotificationManager} from 'react-notifications';
import WSInterface from '../../websocket/WSInterface';
import deepProps, { formatBigNumber, isCPFValid } from '../../../Utils';
import ItemApi from '../../../api/ItemApi';
import CustomerAPI, { getCustomerDataAPI } from '../../../api/CustomerApi';
import ListModal from '../../ListModal';
import {INITIAL_WS} from '../../../server/endpoints_js'
import catchErrors, { ERRORS } from '../../../errors';
import AnimatedNumber from 'react-animated-number/build/AnimatedNumber';
import Toggle from '../../toggle/toggle';
import { AskDialog } from '../../alertDialog/alertDialog';

const wsEndpoint = `${INITIAL_WS}ws/link-user-promotion`
const MAX_CUSTOMER_PAGE = 15

const Segmentation = (props) => {
	const [segmentations, setSegmentations] = useState({})
	const [page, setPage] = useState(0)
	const [reach, setReach] = useState(0)
	const [filters, setFilters] = useState({})
	const [customerCount, setCustomerCount] = useState(0)
	const [items, setItems] = useState([])
	const [segmentationSize, setSegmentationSize] = useState(0)
	const [promotionDetails, setPromotionDetails] = useState(props.store.getState().promotionDetails)
	const [searchingCustomers, setSearchingCustomers] = useState(false)
	const [token, setToken] = useState('')
	const [customers, setCustomers] = useState([])
	const [blockSearch, setBlockSearch] = useState(false)
	const [loadingMore, setLoadingMore] = useState(false)
	const [targeting, setTargeting] = useState(true)
	const [segmentAll, setSegmentAll] = useState(false)

	let storeSubscribe = undefined

	useEffect(()=>{
		init()
		storeSubscribe = props.store.subscribe(()=>{
			if(props.store.getState().type === 'CHANGE_SEGMENTATION') init()
		})

		return () => {if(storeSubscribe)storeSubscribe()}
	},[])

	useEffect(()=>{
		if(segmentAll)
			setCustomerCount(reach)
		else
			setCustomerCount(0)
	}, [segmentAll])

	useEffect(()=>{
		setSegmentAll(reach > 0 && customerCount == reach)
	}, [customerCount])

	const init = () => {
		searchSegmentationReach()
		loadItens()
	}

	const searchSegmentationReach = async () => {
		try{
			setTargeting(true)
			console.log('Promotion detail : ')
			console.log(promotionDetails)
			let alreadySegmentedReach = await getSegmentationPromotion(promotionDetails.id)
			// debugger
			console.log(alreadySegmentedReach)
			let count = deepProps('paging', 'count')(alreadySegmentedReach).getOrElse(0)
			console.log(`Segmentation size : ${count}`)
			setSegmentationSize(count)
		}catch(e){
			console.error(e)
			NotificationManager.error('Não foi possível realizar essa operação, tente mais tarde', 'Ops, algo deu errado!')
		}finally{
			setTargeting(false)
		}
	}

	const getSegmentationPromotion = (promotionId, page = 0, size = 1) => getPromotionReachAPI(promotionId, page, size)

    const loadItens = () => {
		let lastState = props.store.getState()
		if (lastState === undefined)
			return
		
		let tkn = deepProps('token')(lastState).get()
		setToken(tkn)
        let promotion = deepProps('promotionDetails')(lastState).get()
        if (promotion !== undefined) {
			props.store.dispatch(ItemApi.findItemsPromotion(tkn, promotion.id, (response) => {
				if (response instanceof Array)
					setItems(response)
			}, (errorMessage) => {
				NotificationManager.error('Não foi possível realizar essa operação', 'Tente mais tarde')
			}))
        }
	}

	const onRemoveSegmentation = (item) =>{
		setReach(0)
		let segmentationState = segmentations
		delete segmentationState[item.id]
		setSegmentations(segmentationState)
		return true
	}
	
	const segmentPromotion = () => {
		if(targeting){return}
	
		try{
			setTargeting(true)
			validateSegmentationData(filters)
			let {promotionDetails} = props.store.getState()

			if (promotionDetails === undefined) return
			let {id} = promotionDetails 
			setPromotionSegmentationTargetAPI(id,items[0].id,customerCount, filters).then(()=>{
				NotificationManager.info('A segmentação pode durar alguns minutos', 'Por favor, aguarde!')
			})
		}catch(e){
			catchErrors(e)
			setTargeting(false)
		}
	}

	const validateSegmentationData = (data) => {
		if(!data)
			throw {error: 'Utilize o campo ao lado para definir filtros'}

		if(reach === 0) {
			throw {error : 'Nenhum cliente para segmentar, informe outros filtros.'}
		}
		if (data.simpleDropout && data.simpleDropout < 15) {
			throw {error: 'Dias sem comprar deve ser maior que 15'}
		}
	
		if(promotionDetails.status !== 'PENDING'){throw {error : 'Só é possível segmentar promoções com o status "Pendente"'}}
		
		if(!customerCount || customerCount <= 0){throw {error : 'Informe um valor maior que zero para segmentar'}}
		if(customerCount > reach){throw {error : 'Informe um valor menor que o número de clientes encontrados'}}
		if(!items || items.length == 0){throw {error : 'Essa promoção ainda não possui items promovidos'}}
	}

	const searchCustomers = async () => {
		let lastState = props.store.getState()
		if (lastState === undefined)
			return
		let token = deepProps('token')(lastState).get()
		if (token === undefined || searchingCustomers){
			NotificationManager.info('A busca pode durar alguns minutos', 'Por favor, aguarde!')
			return
		}
		if(targeting){
			NotificationManager.warning('Estamos calculando o alcance da promoção', 'Tente novamente!')
			return
		}
		if (segmentationSize > 0) {
			setSearchingCustomers(true)
			getCustomerListContent(promotionDetails.id, page)
		}
	}

	const getCustomerListContent = async (promotionId, page = 0, size = MAX_CUSTOMER_PAGE) => {
		let alreadySegmentedReach = await getSegmentationPromotion(promotionId, page, size)
		let data = deepProps('data')(alreadySegmentedReach).getOrElse([])
		if (data instanceof Array) {
			let customers = await searchCustomerInformations(data)
			setCustomers(customers)
			setPage(1)
			setSearchingCustomers(false)
		}
	}

	const isSegmentAvailable = () => promotionDetails.status === 'PENDING' && props.write

	const searchCustomerInformations = async (data = []) => {
		let customerIDs = data.map(customer => deepProps('customerId')(customer).getOrElse(0))
		let customersES = await CustomerAPI.getCustomersById(token, JSON.stringify(
			{"from" : 0, "size" : 9000,
				"query" : {
					"constant_score": {
						"filter": {
							"terms": {
								"ID": customerIDs
							}
						}
					}
				}
			}))
		
		if (customersES instanceof Array && customersES.length > 0) {
			let customersInfo = data.map(e => {
				let searchAnonymousCustomer = customersES.find((customer)=> deepProps('ID')(customer).getOrElse(-1) === deepProps('customerId')(e).getOrElse(0))
				if (searchAnonymousCustomer !== undefined) {
					e['identifierDefault'] = searchAnonymousCustomer['identifierDefault']
					e['name'] = searchAnonymousCustomer['fullName']
					e['id'] = searchAnonymousCustomer['ID']
				}
				return e
			})
			return mapCustomersData(customersInfo)
		}
		return []
	}

	const mapCustomersData = (customers) => {
		let mappedCustomers = customers.map(customer=>{
			if(!customer.identifierDefault){return {id:customer.customerId,title:customer.customerId,subtitle:'Visualização de dados não autorizada',...customer}}
			return {id:customer.ID,title:customer.name,subtitle:customer.identifierDefault.identifier,...customer}
		})
		return mappedCustomers
	}

    const onMessage = (message) => {
		let {store} = props
		let result = JSON.parse(message)
        let status = result['status']
        switch(status) {
            case 0:
				let response = deepProps('data')(result).getOrElse({})
                if (response.source === 'DROPOUT') {
					NotificationManager.success(`Promoção segmentada com sucesso!`)
					setTargeting(false)
					searchSegmentationReach()
                    let items = deepProps('fastPromotion', 'items')(response).getOrElse([])
					// debugger
                    if (promotionDetails !== undefined) {
                        promotionDetails['segmentationType'] = deepProps('fastPromotion', 'segmentationType')(response).getOrElse('PUBLIC')
                        store.dispatch({type:'PROMOTION_DETAILS', promotionDetails})
                    }
                    store.dispatch({type:'PROMOTION_ITEMS_SEARCH', promotionsItems:items})
                }
                break;
            case 13:
                NotificationManager.info('Outra sessão foi iniciada com o seu usuário em outro dispositivo','Atenção')
				store.dispatch({type:'REDIRECT', to:'search-promotion'})
                break;
            default:
                NotificationManager.error('Ops, algo deu errado')
                break
        }
	}
	
	const shouldConnectWebsocket = () => (promotionDetails && promotionDetails.id > 0 && props.write && (promotionDetails.status === 'PENDING' || 
	(promotionDetails.status === 'PUBLISHED' && promotionDetails.segmentationType === 'BY_IDENTIFIER')))
	
	const onAddCustomerClickHandle = (newItem) => {
		let {store} = props
		return new Promise((accept, reject) => {
			try{
				if(!isCPFValid(newItem.input)) throw ERRORS.INVALID_IDENTIFIER
				if(customers.find(alreadyInserted => alreadyInserted.subtitle === newItem.input)) throw ERRORS.IDENTIFIER_ALREADY_EXIST
				getCustomerDataAPI(newItem.input, 'CPF', 'ABSTRACT').then((response)=>{
					let {fullName, id} = response.data
					let customerId = id
					setCustomers([{id, customerId, title: fullName, subtitle: newItem.input}, ...customers])
					setSegmentationTargetCustomersAPI(promotionDetails.id, [id]).then(()=>{
						accept()
                        promotionDetails['segmentationType'] = '"BY_CUSTOMER"'
                        store.dispatch({type:'PROMOTION_DETAILS', promotionDetails})
						NotificationManager.success(`${fullName} agora pode visualizar essa promoção`, 'Sucesso!')
					}).catch(()=>{
						reject()
					})
				}).catch(()=>{
					reject()
					NotificationManager.warning('CPF não cadastrado', 'Atenção')
				})
			}catch(e){
				catchErrors(e)
				reject()
			}
		})		
	}

	const onRemoveClick = (item) => {
		return new Promise((accept, reject)=>{
			AskDialog(`Deseja realmente remover ${item.name} da segmentação?`, 'O cliente não terá mais acesso à essa promoção.' ,async ()=>{
				try{
					if (item === undefined || promotionDetails === undefined)
						return
					let {customerId} = item
					let {id} = promotionDetails
					await removeCustomerSegmentation(id, customerId)
					let customersArray = customers.filter(customer => deepProps('customerId')(customer).getOrElse(-1) !== customerId)
					setCustomers(customersArray)
					if (customersArray.length < 1) {
						await setPage(0)
						await setSearchingCustomers(true)
						getCustomerListContent(id)
					}
					accept()
				}catch (e){
					console.error(e)
					NotificationManager.error('Não foi possível remover da segmentação', 'Ops, algo deu errado!')
					reject()
				}
			},()=>{reject()})	
		})
	}

	const closeCustomerList = () => {
		setPage(0)
		searchSegmentationReach()
		setBlockSearch(false)
		setCustomers([])
	}

	const onModalListEndReached = async () => {
		if(blockSearch || loadingMore){return}

		setPage(page+1)
		setLoadingMore(true)
		let alreadySegmentedReach = await getSegmentationPromotion(promotionDetails.id, page, MAX_CUSTOMER_PAGE)		
		let data = deepProps('data')(alreadySegmentedReach).getOrElse([])
		if (data instanceof Array) {
			let newCustomers = await searchCustomerInformations(data)
			await setCustomers([...customers, ...newCustomers])
			if(data.length===0){
				NotificationManager.info('Você chegou ao fim da lista','Sem novos resultados!')
				setBlockSearch(true)
			}
		}
		setLoadingMore(false)
	}

	const onSearchReachHandle = async (filters) =>{
		return new Promise((accept, reject)=>{
			setFilters(filters)
			try{
				setTargeting(true)
				if(filters.simpleDropout && filters.simpleDropout < 15) throw {warning: 'Dias sem comprar deve ser maior que 14 dias'}
				getSegmentationReachAPI(filters).then(response => {
					if(response.status === 0){
						setSegmentAll(false)
						setReach(response.data.general.reach)
					}
					accept()
					NotificationManager.success('Buscar realizada com sucesso','Sucesso!')
				}).catch(()=>{reject()})
			}catch(e){
				catchErrors(e)
				reject()
			}finally{
				setTargeting(false)
			}
		})
	}

	const onAddSegmentationHandle = async (item) =>{
		if(item.value <= 0) {return false}

		try{
			let segmentationState = segmentations
			segmentationState[item.id] = item.value
			setSegmentations(segmentationState)
		}catch(e){
			console.error(e)
			NotificationManager.error('Não foi possível realizar essa operação, tente mais tarde', 'Ops, algo deu errado!')
		}
	}

	const getDataTip = filters => {
		let tip = ''
		if(filters.simpleDropout) tip = tip + `Não compram há ${filters.simpleDropout} dias.`
		if(filters.likesProductFilter && filters.likesProductFilter.length > 0) tip = tip + ` Gostam de ${filters.likesProductFilter.map(product => product.name).join('; ')}`
		return tip 
	}

	return (
		<div className="tabScreen-content">		
			{isSegmentAvailable() && <div className="informations">
				<SegmentationFilters title={'Público alvo'} noRepeat  onAdd={onAddSegmentationHandle} onRemove={onRemoveSegmentation} onSubmit={filters=>onSearchReachHandle(filters)}
					emptyTitle='Nenhuma segmentação definida' emptyText='Quando você não define uma segmentação, a promoção fica disponível para o público em geral' 
					listTitle='Filtros selecionados:' onAction={()=>{}} store={props.store} promotionItens={items}/>
			</div>}
			<div className='reach-visualization-container'>
				<h1 className='title'>Segmentação</h1>
				<div className='row'>
					{segmentationSize > 0 && <h1 className='segmentationLabel'>Atualmente segmentada para <span>{segmentationSize}</span> cliente(s)</h1>}
					{segmentationSize === 0 && <h1 className='segmentationLabel'>Essa é uma promoção pública. Visível para todos</h1>}
					<ListModal 
						onClose={closeCustomerList}
						trigger='Ver clientes'
						onOpen={searchCustomers} 
						title='Clientes segmentados' 
						data={customers}
						options={[{value:'cpf', text:'CPF'}]}
						onRemoveClick={onRemoveClick}
						onAddClick={isSegmentAvailable() && onAddCustomerClickHandle}
						onEndReached={onModalListEndReached}
						loading={searchingCustomers}/>
				</div>
				<div>
					{isSegmentAvailable() && <div className={'reach-container'}>
						<AnimatedNumber data-tip={getDataTip(filters)} className='reach-value' value={Number(reach)} duration={1000} stepPrecision={0} formatValue={number => formatBigNumber(number)}/>
						<h2>clientes encontrados</h2>
						<ReactTooltip/>
					</div>}
					{isSegmentAvailable() && <div className='segmentation-action-container'>
						<TextInput label="Quantidade de clientes" type="number" placeholder="número de clientes..." value={customerCount} onChange={e=>setCustomerCount(e.target.value)} style={{width: 120}}/>
						<label className={'select-all'}>
							Segmentar todos
							<Toggle disable={reach==0}  switch small active={segmentAll} value={segmentAll} onClick={(on)=>setSegmentAll(on)} style={{marginLeft: 8}}/>
						</label>
					</div>}
				</div>
				{isSegmentAvailable() && <div className='row' style={{justifyContent: 'flex-end'}}>
					<Button text='Segmentar' loading={targeting} loadingText='Segmentando...' onClick={segmentPromotion} style={{width: 120, marginTop: 32}}/>
				</div>}
			</div>
			{shouldConnectWebsocket &&
			<WSInterface 
				endpoint={wsEndpoint}
				onMessage= {onMessage} 
				write={props.write} read = {props.read} 
				store={props.store} promotionId={promotionDetails.id} keepAlive={true} />}
		</div>
	)
}

export default Segmentation