import React, { Component, useEffect } from 'react';
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import Promotions from '../../component/cardTabProduct/promotions/promotion';
import Itens from '../../component/cardTabProduct/itens/itens';
import Lojas from '../../component/cardTabProduct/store/store';
import Statistics from '../../component/cardTabProduct/statistics/statistics';
import './cardTabProduct.css';
import deepProps from '../../Utils';
import Reports from '../../component/cardTabProduct/reports/reports';
import PigeonMaps from '../../component/cardTabProduct/publish/maps';
import Segmentation from '../../component/cardTabProduct/segmentation/segmentation';
import { Provider } from 'react-redux';
import ProgressiveTab from '../../component/cardTabProduct/progressive/progressive';

class cardTabProduct extends Component {
	constructor(props){
		super(props)
		this.DOMAIN = "/main/promotion/"
		const {store, read, write} = this.props
		this.state = {
			domain: '',
			tabs: [
				{idx : 'PROMOTION', label: "Promoção",path : 'promotion', onClick: ()=>this.toPromotion.bind(this), enable:true, render:  <Promotions {...props} store={store} write={write} read = {read} />},
				{idx : 'PROGRESSIVE', label: "Níveis",path : 'values', onClick: ()=>{}, enable:true,render: <ProgressiveTab {...props} store={store} write={true} read = {read} />},
				{idx : 'ITENS', label: "Promovidos",path : 'itens', onClick: ()=>{}, enable:true,render: <Itens {...props} store={store} write={write} read = {read} />},
				{idx : 'STORES', label: "Lojas",path : 'lojas', onClick: ()=>{}, enable:true, render: <Lojas {...props} store={store} write={write} read = {read} />},
				{idx : 'MARKETING', label: "Divulgação",path : 'divulgacao', onClick: ()=>{}, enable:false, render: <PigeonMaps {...props} store={store} write={write} read = {read} />},
				{idx : 'STATISTICS', label: "Estatísticas",path : 'statistics', onClick: ()=>{}, enable:false, render: <Statistics store={store} write={write} read = {read} />},
				{idx : 'SEGMENTATION', label: "Segmentação",path : 'segmentacao', onClick: ()=>{}, enable:true, render: <Segmentation store={store} write={write} read = {read} />},
				{idx : 'REPORTS', label: "Relatórios",path : 'reports', onClick: ()=>{}, enable:true, render: <Reports store={store} write={write} read = {read} />}
			]
		}
	}

	toPromotion = () => {
		this.props.store.dispatch({type : 'PROMOTION_UPDATED', promotionDetails: deepProps('promotionDetails')(this.props.store.getState()).getOrElse({})})
	}

	getScreenTabs(){
		return(
			<ul className="tabScreen-header">
				{this.state.tabs.map(tab=>{
					if(!tab.enable || (this.props.store.getState().type === 'NEW_PROMOTION' && tab.idx !== 'PROMOTION')) return
					if(tab.idx === 'PROGRESSIVE' && !(deepProps('promotionDetails')(this.props.store.getState()).getOrElse({}).promotionPattern === 'PROGRESSIVE')) return
					if(tab.idx === 'SEGMENTATION' && (deepProps('promotionDetails')(this.props.store.getState()).getOrElse({}).promotionPattern === 'PROGRESSIVE')) return
					return(

						<li className="option-cardTabProduct" key={tab.idx}>
							<NavLink to={this.DOMAIN + tab.path} activeClassName="activeTab" onClick={tab.onClick}> {tab.label} </NavLink>
						</li>
					)
				})}
			</ul>
		)
	}

	getScreenRoutes(){
		return(
			<div className="tabScreen-routes">
			{this.state.tabs.map((tab, idx) => {
				if(!tab.enable) return
				return <Route key={idx} path={this.DOMAIN + tab.path} render={(props) => tab.render} key={tab.idx}/>
			})}
			</div>
		)
	}
    
	render() {
		return (
			<Provider store={this.props.store}>
				<Router>
					<div className="tabScreen-container">
						{this.getScreenTabs()}
						{this.getScreenRoutes()}
					</div>
				</Router>
			</Provider>
		);
	}
}

export const Tabs = props => {
	const {data} = props
	let { report } = useParams();

	return(
		<ul className="tabScreen-header">
			{data.map((tab, idx)=>{
				return(
					<li key={idx} className="option-cardTabProduct" key={tab.idx}>
						<NavLink to={tab.path} activeClassName={report == tab.index ? "activeTab" : ''} onClick={tab.onClick}> {tab.label} </NavLink>
					</li>
				)
			})}
		</ul>
	)
}

export default cardTabProduct;