import React, { useEffect, useState } from 'react';
import '../../../style.scss';
import Card from '../../../../component/card';
import ImagePicker from '../../../../component/ImagePicker';
import InputText from '../../../../component/inputText/inputText';
import HomeSection from '../../../../component/promoappClone/components/homeSection';
import Button from '../../../../component/Button/button';
import { ColorEmptyMessage } from '../../../../component/emptyMessage/emptyMessage';
import { NotificationManager } from 'react-notifications';
import { updateLinkSectionAPI, publishLinksSectionAPI, deleteLinkSectionAPI, deprecatedLinksSectionAPI } from '../../../../server/appSections/appSectionsAPI';
import { AskDialog } from '../../../../component/alertDialog/alertDialog';
import DraggableList from '../../../../component/draggableList';

const SectionsCard = props => {
    const [description, setDescription] = useState('')
    const [linkTo, setLinkTo] = useState('')
    const [image, setImage] = useState('')
    const [imageFile, setImageFile] = useState()
    const [selectedItem, setSelectedItem] = useState()
    const [reordering, setReordering] = useState(false)
    const [edit, setEdit] = useState(false)
    const [reorderedItems, setReorderedItems] = useState([])
    const {sections, onAdd, onChange, loading, onEdit} = props

    const onAddHandle = async (item) => {
        try{
            validateData()
            if(edit) {
                if (onEdit) {
                    await onEdit({
                        description, linkTo, imageFile:imageFile, image, status: selectedItem.status, 
                        id: selectedItem.id, sequence: selectedItem.sequence
                    })
                }
            }else {
                if (onAdd)
                    await onAdd({description, linkTo, imageFile:imageFile, image, status: 'PENDING'})
            }
            cleanFields()
        }catch(e){
            NotificationManager.warning(e, 'Quase lá!')
        }
    }

    const validateData = () => {
        if(!description) throw ("Informe um título")
        if(!linkTo) throw ("Informe um link")
        if(linkTo.substring(0,8) !== 'https://') throw ("Os links precisam ser seguros. Iniciados com 'https://'")
        if([linkTo.indexOf('https://')].includes(-1)) throw ("O link inserido é inválido")
        if(!image) throw ("É preciso adicionar uma imagem")
    }

    const onSelectCoverHandle = (base64, file) => {
        setImage(base64)
        setImageFile(file)
    }

    const onPublishHandle = newItem => {

        if(sections.filter(section => section.status === 'PUBLISHED').length >= 6)
            return NotificationManager.warning('Você já atingiu o limite de 6 (seis) links publicados', 'Não podemos prosseguir')

        if(sections.filter(section => ((section.linkTo === newItem.linkTo) || (section.description === newItem.description)) && (section.status === 'PUBLISHED')  ).length >= 1)
            return NotificationManager.warning('Você já possui uma seção com o mesmo link ou título', 'Não podemos prosseguir')

        publishLinksSectionAPI(newItem.id).then(res=>{
            if(res.status === -99)
            return NotificationManager.warning('Você já atingiu o limite de 6 (seis) links publicados', 'Não podemos prosseguir')
            
            newItem['status'] = 'PUBLISHED'
            onChange(sections.map(section => {
                if(section.id === newItem.id)
                    return newItem
                return section
            }))

            NotificationManager.success(`A seção ${newItem.description} agora está disponível no app`, 'Sucesso!')
        }).catch(e=>{
            NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
            console.log(e)
        })
    }

    const onDeleteHandle = itemToRemove => {
        AskDialog('Deseja realmente excluir a seção ' + itemToRemove.description + '?', 'Essa ação não poderá ser desfeita.' , ()=> {
            deleteLinkSectionAPI(itemToRemove.id).then(res => {
                onChange(sections.filter(section=>section.id !== itemToRemove.id))
                cleanFields()
            }).catch((e)=>{
                NotificationManager.error('Não foi possível deletar essa seção', 'Ops, algo deu errado!')
                console.log(e)
            })
        })
    }

    const onRemoveHandle = itemToRemove => {
        itemToRemove['status'] = 'DEPRECATED'
        deprecatedLinksSectionAPI(itemToRemove.id).then(res => {
            onChange(sections.map(section=>{
                if(section.id === itemToRemove.id)
                    section['status'] = 'DEPRECATED'
                return section
            }))
        }).catch((e)=>{
            console.log(e)
            NotificationManager.error('Não foi possível desativar essa seção', 'Ops, algo deu errado!')
        })
    }

    const onEditHandle = itemToEdit => {
        setDescription(itemToEdit.description)
        setLinkTo(itemToEdit.linkTo)
        setSelectedItem(itemToEdit)
        setImage(itemToEdit.image)
        setImageFile(itemToEdit.image)
        setEdit(true)
    }

    const cleanFields = () => {
        setDescription('')
        setLinkTo('')
        setImageFile()
        setImage()
        setEdit(false)
    }

    const openLinkNewTab = (url) => {
        var win = window.open(url, '_blank');
        win.focus();
    }

    const onReorderItemHandle = async (item, currentIndex, pastIndex) => {
        if(reordering) return NotificationManager.info('Isso pode levar alguns segundos', 'Reordenando itens!')
        let {id} = item
        // console.log(pastIndex, currentIndex)
        if (pastIndex === currentIndex)
            return
        setReordering(true)

        let reorderList = sections.filter(ritem => ritem.id !== item.id)
        reorderList.splice(currentIndex, 0, item)

        updateLinkSectionAPI(id, {sequence: currentIndex}).then(()=>{
            NotificationManager.success(`A posição do item ${item.description} foi alterada com sucesso!`, 'Sucesso!')
            setReorderedItems(reorderList)
        }).catch((e)=>{
            console.log(e)
            NotificationManager.error(`Não foi possível alterar a posição do item ${item.title}`, 'Ops, algo deu errado!')
        }).finally(()=>{
            setReordering(false)
        })

    }

    useEffect(()=>{
        if(reorderedItems.length == 0) return
        props.onChange(reorderedItems)
    },[reorderedItems])


    return (
        <Card title='Sempre com você' style={{marginTop: 16}} className='app-manager-card'>
            <div className='flex' style={{marginTop: 16}}>
                <div style={{flex: 1}}>
                    <InputText text label='Título' value={description} onChange={(e, value)=>setDescription(value)} style={{flex: 1}} maxLength={20}/>
                    <InputText text label='Link' value={linkTo} onChange={(e, value)=>setLinkTo(value)} maxLength={255} style={{flex: 1}}/>
                </div>
                <ImagePicker resourceImage={imageFile} id='image-picker-section' 
                    label={'Selecionar capa'} style={{ maxWidth: 105, minHeight: 105, marginLeft: 16}} 
                    noUpload onSelect={onSelectCoverHandle} 
                    compressLimit={75000}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button text={'Limpar'} textOnly style={{alignSelf: 'flex-end', paddingRight: 49}} onClick={cleanFields}/>
                <Button text={loading ? 'Aguarde...' : (edit ? 'Salvar' : 'Adicionar') } textOnly style={{alignSelf: 'flex-end', paddingRight: 49}} onClick={onAddHandle}/>
            </div>
            <h1 className='title'>Meus links</h1>
            <div className='flex' style={{maxWidth: 'calc(100vw/1.5)'}}>
                {(!sections || sections.length === 0) && <ColorEmptyMessage title='Nenhum link cadastrado' subtitle='A seção "Sempre com você" estará oculta ao usuário' icon={'empty-box'}/>}
                <DraggableList data={sections} onItemChange={onReorderItemHandle} dragItem={(section, idx)=> {
                    return <HomeSection {...section} key={idx} style={{width: 100}} imgStyle={{maxHeight: 100}} edit onPublish={onPublishHandle} onDelete={onDeleteHandle} onRemove={onRemoveHandle} onClick={({linkTo})=>openLinkNewTab(linkTo)} onEdit={onEditHandle}/>}
                }/>
            </div>
        </Card>
    );
}

export default SectionsCard;