import React, {Component} from 'react';
import './ColorList.css';
export default class ColorList extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return ( <div className="ColorList">
			<div className="colorList-title">
				<h1 className='colorList-text'>{this.props.text}</h1>
			</div>
			<div className = "ColorList-content" id="Color-list" style={{scrollBehavior: "smooth"}}>
                <ul>
                    {this.props.children}
                </ul>
			</div>
		</div>)
    }
}

export class ColorListItem extends Component{
    constructor(props) {
        super(props)
        this.state = {}
    }

    onClick(){
        if(this.props.onClick)
            this.props.onClick()
    }

    onDoubleClick(){
        if(this.props.onDoubleClick && !this.state.loading && this.props.enable)
            this.props.onDoubleClick()
    }

    async onDeleteClick(){
        if(this.props.delete){
            this.setState({loading:true})
            await this.props.delete()
            this.setState({loading:false})
        }
    }

    getClass(loading){
        return (loading || !this.props.enable) ? 'ColorListItem disable' : 'ColorListItem'
    }

    render(){
        return (
        <li className={this.getClass(this.state.loading)} onDoubleClick={()=>this.onDoubleClick()}>
            <div>
                {this.props.data.map((item, idx)=>{return(
                    <div key={idx}>
                        {item.status && <div className={'status ' + item.status}></div>}
                        <h2 className={item.style}>{item.title}</h2>
                    </div>)
                })}
            </div>
           {(this.props.delete && !this.state.loading) && <svg style={{width:21, height:21}} className="icon-bounce" onClick={()=>this.onDeleteClick()} viewBox="0 0 24 24">
                <path fill="#8a8a8a" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M9,8H11V17H9V8M13,8H15V17H13V8Z" />
            </svg>}
        </li>
        )
    }
}

export class EmptyColorListMessage extends Component{
    constructor(props) {
        super(props)
        this.state = {}
    }

    render(){
        return(
            <div className='emptyColorListMessage'>
                <h1>{this.props.title}</h1>
                <h2>{this.props.subtitle}</h2>
            </div>
        )
    }
}
