import React, { Component } from 'react';
import './AsideListCard.css';
import Skeleton from 'react-loading-skeleton';
import ReactTooltip from "react-tooltip";

class AsideListCard extends Component {
	constructor(props){
		super(props)
		this.state = {
			data: props.data
		}
	}

	render() {
		let {data} = this.props
		return (
			<div className="asideListCard">
				<div className="asideListCard-content">
					{(this.props.title) && <p className="asideListCard-title">{this.props.title}</p>}
					<div className='scrollVertical'>
						{(this.props.data) ? 
							data.length > 0 &&
							<span className={(this.props.table) ? "asideListCard-table" : "asideListCard-item"}>
								{this.props.data.map((data, index) => <div key={index} data-tip={(data.title === 'Regras' && data.data.length > 140) ? 'Completa na aba "promoção"' : ''}><p>{data.title}</p><p>{data.data}</p></div>)}
							</span>
							:
							<div>
								<p>
									<Skeleton count={1} width={100} height={15}/>
								</p>
								<p>
									<Skeleton count={1} width={100} height={15}/>
								</p>
							</div>
						}
					</div>
				</div>	
				<ReactTooltip/>
			</div>
		);
	}
}

export default AsideListCard;