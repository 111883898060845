import React, { useEffect, useState } from 'react'
import AsideProfileCard from '../../component/AsideProfileCard/AsideProfileCard'
import AsideListCard from '../../component/AsideListCard/AsideListCard'
import AsideDiscountCard from '../../component/AsideDiscountCard/AsideDiscountCard'
import './asideUser.css'
import {Redirect} from "react-router-dom";
import Card from '../../component/card'
import KPIInfo from '../../component/KPI/info'
import { getDcJWT } from '../../Utils'

const lista3 = [{title:'1', data:'Congelados'},{title:'2', data:'Bebidas Alcoólicas'},{title:'3', data:'Frios'},
{title:'4', data:'Vinhos'}]

const data = [
	{ name: 'actual', value: 50 },
	{ name: 'general', value: 100 },
  ];

const COLORS = ['#FF8042', '#FFB300', '#00C49F', '#f2f2f2' ];
  
const AsideUser = (props) => {
	const [showChurn, setShowChurn] = useState()

	useEffect(()=>{
		if (!props.read && !props.write) {
			return <Redirect to='/main/home/general'/>
		}	
		setShowChurn(getDcJWT().companyId === 4)
	}, [])

	let {user, card2, card3, score} = props

	const onPieEnter = () => {

	}

	const getFrequencyColor = frequency => {
		if(!frequency || frequency <= 0) return COLORS[0]
		if(frequency <= 1) return COLORS[1]
		if(frequency > 1) return COLORS[2]
	}

    return (
    	<aside className="asideUser">
			<AsideProfileCard img={props.user.profileImg} verified={props.loginStatus === 'VERIFIED_BY_OPERATOR'} title={user.fullName} score={score} moc={true}/>
			{props.card2.length !== 0 && <AsideListCard data={card2}/>}
			{card3.length > 0 && true && <AsideListCard title={"Endereço"} data={card3}/>}
			{showChurn && user.metrics && <AsideDiscountCard title={"Ticket Médio"} value={user.metrics.medianSpendPerPurchase} coupons={props.user.coupons} type='BY_VALUE' />}
			{showChurn && user.metrics && <Card>
				<h1 className='title'>Frequência de retorno</h1>
				<div style={{display: 'flex', alignItems: 'center', height: 80}}>
					<KPIInfo value={user.metrics.frequency} title='vezes/mês' color={getFrequencyColor(user.metrics.frequency)}/>
					{/* <PieChart width={160} height={80} onMouseEnter={onPieEnter}>
						<Pie
						data={data}
						cx={80}
						cy={80}
						startAngle={180}
						endAngle={0}
						innerRadius={60}
						outerRadius={76}
						fill="#8884d8"
						paddingAngle={0}
						dataKey="value"
						>
						{
							data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
						}
						</Pie>
					</PieChart> */}
				</div>
				{user.metrics.lastSale && <h2 style={{paddingTop: 16}}>Última compra em <span className='title'>{user.metrics.lastSale.split('-').join('/')}</span></h2>}
			</Card>}
			{false && <AsideListCard title={"Itens Preferidos"} table data={lista3}/>}
			{/* <AsideButtonCard title='Aplicativo'>
				<label for='user_verification' style={{flexDirection: 'row'}}>
					<input type='checkbox' id='user_verification'/>
					Os dados informados foram verificados e portanto, os declaro verdadeiros.
				</label>
				<Button text='Verificar' style={{alignSelf: 'flex-end', marginTop: 8}}/>
			</AsideButtonCard> */}
    	</aside>
    );
}

export default AsideUser;
