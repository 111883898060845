import React, {useState, useEffect} from 'react';
import InputText from '../../inputText/inputText';
import './styles.scss'
import DropDownFilter from '../../DropDownFilter/DropDownFilter';
import Button from '../../Button/button';
import deepProps from '../../../Utils';

const SearchBar = ({onClick,filters, onSubmit, selected, timeout, onCleanSearch, onFilterChange, onInputChange}) => {
    const [input, setInput] = useState('')
    const [filter, setFilter] = useState(selected ? selected.value : '')
    const [inputChangeTimer, setInputChangeTimer] = useState()
    const TIMEOUT = timeout ? timeout : 1000

    const getSearch = () => {
        return {input, filter}
    }

    useEffect(()=>{
        handleTimer()
    },[input, filter])

    const handleTimer = () => {
        if(inputChangeTimer != undefined){
            clearTimeout(inputChangeTimer)
        }

        if(input == ''){
            if(onCleanSearch) onCleanSearch()
            return
        }

        setInputChangeTimer(setTimeout(
            () => {
                onSubmit({input,filter})
            }, TIMEOUT)
        )
    }

    const onFilterChangeHandle = filter => {
        setFilter(filter.value)
        if(onFilterChange) onFilterChange(filter) 
    }

    const onInputChangeHandle = value => {
        setInput(value)
        if(onInputChange) onInputChange(value)
    }

    
    return ( 
        <div style={{display: 'flex', alignItems: 'center'}} className='search-bar'>
            <DropDownFilter border filters={filters} selected={selected} onSelect={filter=>onFilterChangeHandle(filter)}/>
            <InputText text placeholder='Digite sua pesquisa aqui...' style={{margin: 0, flex:1}} value={input} onChange={(field, value) => onInputChangeHandle(value)}/>
            {onClick && <Button text='Pesquisar' onClick={()=>onClick(getSearch())}></Button>}
        </div>
    );
}

export default SearchBar