export const getUploadImageBody = img => {
    let data = new FormData()
    data.append('image', img)
    return data
}

export const getUploadCompressesImageBody = (img, number) => {
    return {
        data: img
    }
}