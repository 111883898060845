import React, { useEffect, useState } from 'react';
import '../style.scss';
import Card from '../../../component/card';
import ImagePicker from '../../../component/ImagePicker';
import { PlusCircle } from 'react-feather';
import { getBannersAPI, addBannerAPI, deleteBannerAPI, deprecatedBannerAPI, publishBannerAPI } from '../../../server/appSections/appSectionsAPI';
import { NotificationManager } from 'react-notifications';
import { ProgressBarLoading } from '../../../component/LoadingMessage/loadingMessage';
import HomeSection from '../../../component/promoappClone/components/homeSection';
import { AskDialog } from '../../../component/alertDialog/alertDialog';

const BannersCard = props => {
    const {banners, onAdd, onChange, onSelect} = props
    const [image, setImage] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getBanners()
    }, [])

    const getBanners = () => {
        getBannersAPI().then(data => {
            // console.log(data)
        }).catch(e=>{
            console.log(e)
        })
    }

    const onSelectImageHandle = (image, file) => {
        if(!image) return
        if(loading) return
        setImage(file)
        setLoading(true)
        addBannerAPI({imageFile: file, sequence:1}).then(data => {
            onSelect && onSelect({image, file, status: 'PENDING', id: data.id})
            // NotificationManager.success('O novo banner agora está disponível no app', 'Sucesso!')
        }).catch(e => {
            NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
        }).finally(()=>{
            setImage()
            setLoading(false)
        })
    }

    const onPublishHandle = newItem => {
        if(banners.filter(banner => banner.status === 'PUBLISHED').length >= 1)
            return NotificationManager.warning('Você já atingiu o limite de 1 (um) banner publicado', 'Não podemos prosseguir')

        publishBannerAPI(newItem.id).then(res=>{
            if(res.status === -99)
            return NotificationManager.warning('Você já atingiu o limite de 1 (um) banner publicado', 'Não podemos prosseguir')
            
            newItem['status'] = 'PUBLISHED'
            onChange(banners.map(banner => {
                if(banner.id === newItem.id)
                    return newItem
                return banner
            }))

            NotificationManager.success(`O novo banner agora está disponível no app`, 'Sucesso!')
        }).catch(e=>{
            NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
            console.log(e)
        })
    }

    const onDeleteHandle = itemToRemove => {
        let message = 'Essa ação não poderá ser desfeita'
        if(itemToRemove.status === 'PUBLISHED')
            message = 'O aplicativo ficará sem imagem no banner. ' + message
        AskDialog('Desejar realmente excluir?', message, () =>{
            deleteBannerAPI(itemToRemove.id).then(res => {
                onChange(banners.filter(banner=>banner.id !== itemToRemove.id))
            }).catch((e)=>{
                NotificationManager.error('Não foi possível deletar essa seção', 'Ops, algo deu errado!')
                console.log(e)
            })
        })
    }

    const onRemoveHandle = itemToRemove => {
        itemToRemove['status'] = 'DEPRECATED'
        deprecatedBannerAPI(itemToRemove.id).then(res => {
            onChange(banners.map(banner=>{
                if(banner.id === itemToRemove.id)
                    banner['status'] = 'DEPRECATED'
                return banner
            }))
        }).catch((e)=>{
            console.log(e)
            NotificationManager.error('Não foi possível desativar essa seção', 'Ops, algo deu errado!')
        })
    }

    return (
        <Card title='Banners' buttonText='Salvar' style={{marginTop: 16}} className='app-manager-card' containerStyle={{overflow: 'scroll'}}>
            <div className='flex' style={{ marginBottom: 16, marginTop: 16 }}>
                <div style={{marginRight: 8}}>
                    <ImagePicker id={'image-picker-banner'} resourceImage={image} cover onRemove={()=>alert('deletado')} 
                        label={'Selecionar banner'} style={{ maxWidth: 200, minHeight: 90, minWidth: 200 }} onSelect={onSelectImageHandle}
                        compressLimit={300000}/>
                    {loading && <ProgressBarLoading style={{marginTop: 8}}/>}
                </div>
                {banners.map(banner => <HomeSection key={banner.id} style={{width: 232, height: 122}} {...banner} edit  onPublish={onPublishHandle} onRemove={onRemoveHandle} onDelete={onDeleteHandle}/>)}
                {/* <PlusCircle onClick={onAdd}/> */}
            </div>
        </Card>
    );
}

// onPublish={onPublishHandle}

export default BannersCard;