import React, { useState, useEffect } from 'react';
import './smallCard.css';
import deepProps from '../../Utils'

const SmallCard = ({title, photo, address, cpf, callback, status, email}) => {

	const [imageError, setImageError] = useState()
	const [imageLoaded, setImageLoaded] = useState()
	const [textLength, setTextLength] = useState('')

	useEffect(()=>{
		setTextLength(email)
	}, [])

	return (
		<div className="smallCard" onClick={() => callback()}>
				<span className={"status " + status}/>
				<div className="header-smallCard">
				{(photo && !imageError && !imageLoaded) && <img  className={'img-profile placeholder-shiny'} alt=''></img>}
				{(photo && !imageError) && <img  className={imageLoaded ? 'img-profile loaded' : 'img-profile loading'} onLoad={()=>{setImageLoaded(true)}} onError={()=>{setImageError(true)}} src={photo} alt={title}></img>}
				{(!photo || imageError) && <div className="name-logo" ><h1>{(title && title.length >=2) ? title.substring(0,2) : '?'}</h1></div>}
				</div>

				<div className="content-smallCard">
					<div className="date-smallCard">
						<p className="title-smallCard">{title}</p>
					</div>

					<div>
						{textLength && <p className="email-smallCard" >{(textLength.length > 114 ? textLength.substring(0,114)+"..." : textLength)}</p>}
						<p>{cpf}</p>
					</div>

					<div className="footer-smallCard">
						<p>{address}</p>
					</div>
				</div>
		</div>
	)
}

export default SmallCard;

