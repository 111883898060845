import React, { Component } from 'react';
import './button.scss';
import { Loading } from '../LoadingMessage/loadingMessage';

export default class Button extends Component {
  static propTypes = {}

  static defaultProps = {
  }

  constructor(props) {
    super(props)
    this.state = {
        classes : 'normal-button',
        text:this.props.text
      }
  }

  componentDidMount(){
    this.getClass()
  }

  onClick(){
    if(this.props.onClick && !this.props.loading && !this.props.disabled){
      this.props.onClick()
    }  
  }

  getClass(props){
    let classes = ' normal-button '

    if(this.props.textOnly)
      classes = classes + ' text-only '

    if(this.props.invert)
      classes = classes + ' invert '
    
    if(this.props.small)
      classes = classes + '  small '
    
    if(this.props.smallButton)
        classes = classes + '  small-button '

    if(this.props.shine)
      classes = classes + '  shine-button '

    if(this.props.disabled)
      classes = classes + '  disabled '

    if(this.props.className)
      classes = this.props.className

    if(this.props.icon && this.props.text)
      classes = classes + ' has-icon'
    
    return classes
  }

  getText(text, loading, loadingText){
    if(loadingText){
      return loading ? loadingText : text
    }
    return text
  }

  render() {
    return (
      <div className={this.getClass({...this.props})} onClick={()=>{this.onClick()}} style={this.props.style}>
        {this.props.tip && <span className={'status-view PENDING'} style={{position: 'relative', marginRight: 8, marginLeft: 0}}></span>}
        {this.props.loading && <Loading style={{width: 16, marginRight: 8}}/>}
        <h1>{this.getText(this.props.text, this.props.loading, this.props.loadingText)}</h1>
        {this.props.icon &&  this.props.icon}
      </div>
    )
  }
}