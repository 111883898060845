export const SEGMENTATION_BODY = {
    getListBody(data){
        return {
            "stores": [],
            "days": data.days
        }
    },

    getLinkPromotionBody(data){
        return {
            "promotionId": data.id,
            "promotionItems": [ {
                "itemPromotionId": data.itemId, 
                "stores": [],
                "days": data.days,
                "customerCount":parseInt(data.customerCount)
            }]
        }
    }
}

export const getPromotionSegmentationDTO = (promotionId, promotionItemId, limit, filters) => {
    let filter = {}

    if(filters.simpleDropout)
        filter['simpleDropout'] = getDropoutSegmentationReachDTO(filters.simpleDropout).simpleDropout

    if(filters.likesProductFilter)
        filter['likesProductFilter'] = getProductsSegmentationReachDTO(filters.likesProductFilter.map(product=>({GTIN: product.GTIN, range: product.range}))).likesProductFilter

    return {
        pomotionId: promotionId,
        itemPromotionId: promotionItemId,
        asked: limit,
        filter
    }
}

export const getSegmentationReachDTO = (filters) => {
    console.log('filters: ', filters)
    let {simpleDropout, likesProductFilter} = filters
    if(likesProductFilter) likesProductFilter = getProductsSegmentationReachDTO(likesProductFilter.map(product=>{return {GTIN: product.GTIN, range: product.range}})).likesProductFilter
    if(simpleDropout && simpleDropout >= 15) simpleDropout = getDropoutSegmentationReachDTO(simpleDropout).simpleDropout
    
    return {
        likesProductFilter:likesProductFilter,
        simpleDropout: simpleDropout ? simpleDropout : undefined
    }
}

export const getProductsSegmentationReachDTO = (gtinList = [], range='HIGH_CHANCE',limit = 2000) => {
    // console.log(('fitressss: ', gtinList));
    // return
    let products = gtinList.map(item => {
        if(item.GTIN && item.range)
            return {gtin: item.GTIN, range: item.range, limit}
        
        return {gtin: item, range, limit}
    })
    return {
        "likesProductFilter": products
    }
}

export const getDropoutSegmentationReachDTO = (numberDaysDropout) => {
    return {
        "simpleDropout": {
            "days": numberDaysDropout
        }
    }
}