import React, { Component } from 'react';
import CardTabOperator from '../cardTabOperator/cardTabOperator';
import AsideOperator from '../asideOperator/asideOperator';
import './operator.css';
import deepProps from '../../Utils';

export default class Operator extends Component {

    constructor(props) {
        super(props)
        this.state = {
            operator: {},
            write:true,
            read:true
        }
    }

    componentDidMount() {

        this.unsubscribe = this.props.store.subscribe(() => {
            if (this.props.store.getState().type === 'OPERATOR_DETAIL') {
                this.load()
            }
        })
        this.load()
    }
    load () {
        let operator = deepProps('operatorDetail')(this.props.store.getState()).getOrElse({})
		let permissions = deepProps('permissions')(this.props.store.getState()).getOrElse([])
		let permission = undefined
		for (let p of permissions) {
			if (deepProps('name')(p).get() === 'OPERATOR') {
				permission = p
				break
			}
		}
		let write = false
		let read = false
		if (permission !== undefined) {
			let array = deepProps('allow')(permission).getOrElse([])
			for (let allow of array) {
				if (allow === 'R') {
					read = true
					continue
				}
				if (allow === 'W') {
					write = true
				}
			}
		}
		this.setState({write, read, operator})
    }

    render() {
        return (
            <section className="operator">
				<AsideOperator data={this.state.operator} store={this.props.store} write={this.state.write} read = {this.state.read}/>
				<CardTabOperator store={this.props.store} write={this.state.write} read = {this.state.read}/>
			</section>
        );
    }
}