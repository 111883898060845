import React, { useState, useEffect } from 'react';
import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,} from 'recharts';

export default function BaseAreaChart (props){

    const {hideX, hideY, hideGrid, data, lines, x,customTooltip} = props
    const [colors, setColors] = useState(props.colors || ["#2196f3", "#ff68a1","#ffa600","#97e000"])

    useEffect(()=>{

    },[data])

    return (
      <div style={{height: 200, marginRight: hideGrid ? 0 : 32 }}>
        <ResponsiveContainer>
          <AreaChart data={data} margin={{top: 8}}>
            {!hideGrid && <CartesianGrid strokeDasharray="3 3" />}
            {!hideX && <XAxis dataKey={x}/>}
            {!hideY && <YAxis />}
            <Tooltip content={customTooltip}/>
            {lines && lines.map((line, idx)=><Area type="monotone" dataKey={line.value} fill={colors[idx]} stroke={colors[idx]} key={idx}/>)}
          </AreaChart> 
        </ResponsiveContainer>
      </div>
    )
}