import React, { Component } from 'react';
import './tinyCard.css';

export default class TinyCard extends Component {

  render() {
    return (
    	<div className="TinyCard" onClick={() => this.props.callback()}>
				<div className="content-TinyCard">
					<div className="date-TinyCard">
						<p className="title-TinyCard">{this.props.name}</p>
					</div>
				</div>
    	</div>
    );
  }
}


