
import deepProps,{treatMessage, isCPFValid} from '../Utils'
import formatValue from '../Format'
import {customersEP, elasticGatewayEP} from '../server/endpoints_js'
import { getFetchHeader, validateRequest } from './UtilsAPI'
import { ERRORS } from '../errors'

export default class CustomerAPI {
    static findAddresses = (identifier, token, successFunc = () => {}, errorFunc = () => {}) => {
        return dispatch => {
            let url = customersEP.findAddresses(identifier.identifier, identifier.type)
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Authorization': token
                }
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                dispatch({type:'ADDRESSES_DETAILS', addressesDetail:response.data})
                successFunc(response.data)
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc(message)
                })
            }) 
        }
    }

	static findContacts = (identifier, token, successFunc = () => {}, errorFunc = () => {}) => {
		// console.log(identifier)
		let url = customersEP.findContacts(identifier)
		fetch(url, {
			method: "GET",
			headers: {
				Accept: 'application/json',
				'Authorization': token
			}
		}).then((response) => {
			if (response.status === 200)
				return response.json()
			throw response
		}).then(response => {
			successFunc(response.data)
			return response.data
		}).catch(error => {
			treatMessage(error, 
			(message) => {
				errorFunc(message)
			})
		}) 
    }

    static find = (identifier, token, successFunc = () => {}, errorFunc = () => {}) => {
        return dispatch => {
            let url = customersEP.find(identifier.identifier, identifier.type)
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Authorization': token
                }
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                dispatch({type:'CUSTOMER_DETAILS', customerDetails:response.data})
                successFunc(response.data)
            }).catch((error) => {
                treatMessage(error, dispatch, (message) => {
                    errorFunc(message)
                })
            }) 
        }
    }
    
	static customersScore = (identifier, token, successFunc = () => {}, errorFunc = () => {}) => {
		let url = customersEP.customersScore(identifier)
		fetch(url, {
			method: "GET",
			headers: {
				Accept: 'application/json',
				'Authorization': token
			}
		}).then((response) => {
			if (response.status === 200)
				return response.json()
			throw response
		}).then(response => {
            console.log(response)
			successFunc(response.data)
		}).catch(error => {
			treatMessage(error, 
			(message) => {
				errorFunc(message)
			})
		}) 
    }
    static customerCount = (token, successFunc=()=>{}, errorFunc=()=>{}) => {
        return dispatch => {
            let url = elasticGatewayEP.customerCount()
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token

                }
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(response => {
                // console.log(response)
                dispatch({type:'COUNT', count:response.count})
                successFunc(response.count)
            }).catch(error => {
                /*treatMessage(error, dispatch, (message) => {
                    errorFunc(message)
                })*/
            }) 
        }
    }

    
	static getQuery(text, filter, from, size) {
        let search 
        if((filter === undefined || filter.value === undefined) && text.trim() !== ''){
            search = 'any'
        }else if(text.trim() === ''){
            search = 'all'
        }else{
            search = filter.value
        }
        
        switch(search){
            case 'name': 
                return '{"from":' + from  + ', "size": '+ size + ',"query":{"query_string": { "default_field": "fullName","query": "*'+text.toLowerCase().replace(' ', '* *')+'*"}}}'
            case 'state':
                return '{"from":' + from  + ', "size": '+ size + ',"query":{"query_string": { "fields": ["addresses.street", "addresses.district", "addresses.city", "addresses.state", "addresses.country.name"],"query": "*'+text.toLowerCase().replace(' ', '* *')+'*"}}}'
            case 'cpf':
                return '{"from":' + from  + ', "size": '+ size + ',"query":{"query_string": { "default_field": "identifierDefault.identifier","query": "*'+text.toLowerCase().replace(' ', '* *')+'*"}}}'
            case 'zipCode':
                return '{"from":' + from  + ', "size": '+ size + ',"query":{"query_string": { "default_field": "addresses.zipCode","query": "*'+text.toLowerCase().replace(' ', '* *')+'*"}}}'
            case "any":
                return '{"from":' + from  + ', "size": '+ size + ',"query":{"query_string": { "fields": ["identifierDefault.identifier", "fullName", "addresses.zipCode","addresses.street", "addresses.district", "addresses.city", "addresses.state", "addresses.country.name"],"query": "*'+text.toLowerCase().replace(' ', '* *')+'*"}}}'
            default:
                return '{"from":' + from  + ', "size": '+ size + ',"query": {"match_all": {}}}'
        }
    }
    
    static getCustomerWithFilter = (text, filter, from, size, token) => {
        return fetch(elasticGatewayEP.findCustomer(), {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: CustomerAPI.getQuery(text, filter, from, size)
        })
    }

    static getCustomersById = (token, elasticQuery, successFunc = () => {}, errorFunc = () => {}) => {

        return fetch(elasticGatewayEP.findCustomer(), {
            method: "POST",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: elasticQuery
        }).then((response) => {
            if (response.status === 200)
                return response.json()
            throw response
        }).then((response) => {
            if(response.timed_out) {
                result["error"] = true
                result["message"] = "Time out"
                errorFunc('Tempo de espera excedido!','Não foi possível acessar os clientes')
                return
            }
            let result = response.hits.hits.map(x => {
                let customer = x._source
                return CustomerAPI.mapCustomer(customer)
            })
            successFunc(result)
            return result
        }).catch((error) => {
            errorFunc('Erro ao pesquisar cientes!','Não foi possível acessar os clientes')
        })
    }

    static getCustomer = (query, from = 0, size, token, successFunc = () => {}, errorFunc=()=>{}) => {
        return dispatch => {
            let url = elasticGatewayEP.findCustomer()
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': token
                },body: JSON.stringify({
                  "from": from,
                  "size": size, 
                  "query": query
                })
            }).then((response) => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then((response) => {
                let result = {error: false, data : []}
                if(response.timed_out) {
                    result["error"] = true
                    result["message"] = "Time out"
                    errorFunc('Tempo de espera excedido!','Não foi possível acessar os clientes')
                    return result
                }
                result["data"] = response.hits.hits.map(x => {
                    let customer = x._source
                    return CustomerAPI.mapCustomer(customer)
                })
                return result
            }).then((response) => {
                if (response.error)
                    return
                if(from === 0){
                    dispatch({type: 'CUSTOMER_SEARCH', customers:response.data})
                }else{
                    dispatch({type: 'CUSTOMER_SHOW_MORE', customers:response.data})
                }
                successFunc(from)
            }).catch((error) => {
                errorFunc('Erro ao pesquisar cientes!','Não foi possível acessar os clientes')
            })
        }
    }

    
	static mapCustomer(customer) {
		let perfilPhoto = deepProps('perfilPhoto', 0)(customer).getOrElse('')
		customer["perfilPhoto"] = perfilPhoto
		let eletronicAddresses = deepProps('contact', 'eletronicAddresses', 0)(customer).getOrElse([])
		if (!(eletronicAddresses instanceof Array)) {
			let array = []
			array.push(eletronicAddresses)
			eletronicAddresses = array
		}
		for (let i = 0; i < eletronicAddresses.length; i++) {
			let midia = deepProps('midia')(eletronicAddresses[i]).getOrElse(null)
			let status = deepProps('status')(eletronicAddresses[i]).getOrElse(null)
			if (midia === 'E_MAIL' && status === 'ENABLE') {
				customer["emailDefault"] = deepProps('address')(eletronicAddresses[i]).getOrElse(null)
				break
			}
		}
		let identifier = deepProps('identifierDefault')(customer).getOrElse(null)
		if (identifier != null) {
			customer["formatIdentifier"] = formatValue(identifier.identifier, identifier.type)
		}else {
			customer["formatIdentifier"] = null
		}
		let addresses = deepProps('addresses')(customer).getOrElse(null)
		customer["abstractAddress"] = ''
		if (addresses != null) {
			if (!(addresses instanceof Array)) {
				let array = []
				array.push(addresses)
				addresses = array
			}
			if (addresses.length > 0)
				customer["abstractAddress"] = deepProps('acronymState')(addresses[0]).getOrElse('') + '/' + deepProps('city')(addresses[0]).getOrElse('')
        }
		return customer
	}

	static updateCustomer = (token, customer, customerId, successFunc = () => {}, errorFunc= () => {}) => {
		let url = customersEP.getCustomerById(customerId)
		fetch(url, {
			method: "PUT",
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': token
			},
			body: JSON.stringify(customer)
		}).then((response) => {
			if (response.status === 200)
				return response.json()
			throw response
		}).then(response => {
			successFunc(response)
		}).catch(error => {
			treatMessage(error, (message) => {
				errorFunc(message)
			})
		})
    }
    
    static customersScore = (identifier, token, successFunc = () => {}, errorFunc = () => {}) => {
		let url = customersEP.customersScore(identifier)
		fetch(url, {
			method: "GET",
			headers: {
				Accept: 'application/json',
				'Authorization': token
			}
		}).then((response) => {
			if (response.status === 200)
				return response.json()
			throw response
		}).then(response => {
            console.log(response)
			successFunc(response.data)
		}).catch(error => {
			treatMessage(error, 
			(message) => {
				errorFunc(message)
			})
		}) 
    }

}

export const getCustomersAPI = async (filters, page, filter) => {
    let request = await fetch(customersEP.getCustomers(page), getFetchHeader('POST', filters))
    validateRequest(request)
    return await request.json()
}

export const getCustomerScoreAPI = async (userId) => {
    let request = await fetch(customersEP.getScore(userId), getFetchHeader())
    validateRequest(request)
    return await request.json()
}

export const getCustomerDataAPI = async (identifier, identifierType = 'CPF', queryType = 'FULL') => {
    if(!isCPFValid(identifier)){
        throw ERRORS.INVALID_IDENTIFIER
    }
    let request = await fetch(customersEP.find(identifier, identifierType, queryType), getFetchHeader())
    validateRequest(request)
    return await request.json()
}

export const getCustomerRecommendedProductsAPI = async (userId) => {
    let request = await fetch(customersEP.getRecommendedProducts(userId), getFetchHeader())
    validateRequest(request)
    return await request.json()
}

export const getCustomersByIdListAPI = async (customersIDList = []) => {
    try{
        let request = await fetch(elasticGatewayEP.findCustomer(), getFetchHeader('POST', 
        {"from" : 0, "size" : 9000,"query" : {"constant_score": {"filter": {"terms": {"ID": customersIDList}}}}}))
        validateRequest(request)
        let response = await request.json()
        response = response.hits.hits
        if(customersIDList.length === response.length){
            return response 
        }else{
            let notFoundList = customersIDList.filter(asked => {return !response.find(r => r._source.ID === asked)})
            return [...notFoundList.map(nf => ({_source: {ID: nf, fullName: 'Dados não compartilhados', name: 'Dados não compartilhados'}})), ...response]
        }
    }catch{
        throw 'getCustomerByIdAPI Error'
    }
}

export const getCustomerByIdAPI = async (customerId) => {
    let request = await fetch(customersEP.getCustomerById(customerId), getFetchHeader())
    validateRequest(request)
    return await request.json()
}

export const getLastCreatedCustomersReportsAPI = async (page = 0, size = 16) => {
    let request = await fetch(customersEP.lastCreatedCustomerReports(page, size), getFetchHeader())
    validateRequest(request)
    return await request.json()
}

export const getCustomerReportByIdAPI = async (id) => {
    let request = await fetch(customersEP.downloadCustomerReportById(id), getFetchHeader())
    validateRequest(request)
    return await request.blob()
}

export const exportCustomerReportAPI = async (filters) => {
    let request = await fetch(customersEP.exportCustomerReport(), getFetchHeader('POST', filters))
    validateRequest(request)
    return await request.json()
}