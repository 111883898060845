import React, { Component } from 'react';
import AsideUser from '../asideUser/asideUser';
import CardTab from '../cardTab/cardTab';
import './customer.css';
import deepProps, { format } from '../../Utils';
import { Redirect } from 'react-router-dom';
import CustomerAPI, { getCustomerScoreAPI } from '../../api/CustomerApi';
import { confirmAlert } from 'react-confirm-alert';
import { checkAvailableIdentifierAPI } from '../../server/customer/customerAPI';
import { getCustomerMetricsGraphQL } from '../../server/graphql/customers';

class Customer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            user: {
                name:'',
            },
            token:'',
            redirectToSearch:false,
            card2:[],
            card3:[],
            addresses:[],
            read : true,
            write:false
        }
    }

    componentWillMount () {
        let lastState = this.props.store.getState()
        if (lastState.type === 'ADDRESSES_DETAILS')
            lastState.type = 'CUSTOMER_DETAILS'
        this.loadData(lastState)
    }

    loadCustomer(lastState) {
        let user = deepProps('customerDetails')(lastState).get()
		if (user === undefined) {
			this.setState({redirectToSearch:true})
			return
        }

        if(user.ID){
            user['id'] = user.ID
        }

		let identifiers = deepProps('identifiers')(user).getOrElse([])
        let card2 = []
        let identifier = deepProps('identifierDefault')(user).get()
		for (let identifier of identifiers) {
			let type = deepProps('type')(identifier).getOrElse(null)
			if (type === undefined)
				continue
			card2.push(this.identifierToString(identifier))
        }

        let token = deepProps('token')(lastState).getOrElse("")
        this.setState({user, card2, token: token})
        if (identifier !== undefined) {
            this.findAddresses(identifier, deepProps('token')(lastState).getOrElse(""))

            getCustomerScoreAPI(user.id).then((response)=>{
                this.setState({score:response.data})
                checkAvailableIdentifierAPI(identifier.identifier).then(result => {
                    let {status, data} = result
                    if (status === 0 && data)
                        this.setState({loginStatus: data.loginStatus})
                })
            })

            getCustomerMetricsGraphQL(user.id).then(response => {
                let {data} = response
                if (!data || !data.metrics) return
                let user = this.state.user
                user['metrics'] = data.metrics
                this.setState({user})
            })
        }
    }
    
	alertDialog = (title,message) => {
		confirmAlert({
		  title: title,
		  message: message,
		  buttons: [
			{
			  label: 'Entendi!',
			  onClick: () => {}
			}
		  ]
		})
	}

	findAddresses(identifier, token){
		this.props.store.dispatch(CustomerAPI.findAddresses(identifier, token, () => {
			}, (error) => {
				this.alertDialog('Ops, algo deu errado', 'Não conseguimos encontrar endereço do cliente. ' + error)
		}))	
	}

	findContacts(identifier){
		this.props.store.dispatch(CustomerAPI.findContacts(identifier, this.state.token, () => {
			}, (error) => {
				this.alertDialog('Ops, algo deu errado', 'Não conseguimos encontrar os contatos do cliente. ' + error)
		}))
	}

    loadAddresses (lastState) {
		let addresses = deepProps('addressesDetail')(lastState).getOrElse([])
		let card3 = []
		for (let address of addresses)
            card3.push({title:'', data: this.addressToString(address)})
        this.setState({card3, addresses})
    }

	loadData (lastState) {
        this.setState({token:deepProps('token')(lastState).getOrElse()})
        switch(lastState.type) {
            case 'CUSTOMER_DETAILS':
                this.loadCustomer(lastState)
            break
            case 'ADDRESSES_DETAILS':
                this.loadAddresses(lastState)
            break
            case 'CONTACTS_DETAILS':
            break
            default:
            break
        }

    }

	addressToString(address) {
		if (address === undefined)
			return ''
		let data = deepProps('type')(address).getOrElse('') + ' ' + deepProps('street')(address).getOrElse('') + ', '	+
		deepProps('number')(address).getOrElse('') + ' ' + deepProps('complement')(address).getOrElse('')
		return data
    }

    identifierToString(identifier) {
        let type = identifier['type']
        if (type === undefined)
            type = ''
        let data = deepProps('identifier')(identifier).getOrElse('')
        switch(type) {
            case 'CPF':
                data = format(data, '###.###.###/##', '#')
            break
			default:
			break
        }
        return {title:type, data}
    }

	componentDidMount () {
		this.unsubscribe = this.props.store.subscribe(() => {
			let lastState = this.props.store.getState()
			this.loadData(lastState)
        })
        let permissions = deepProps('permissions')(this.props.store.getState()).getOrElse([])
        let permission = undefined
        for (let p of permissions) {
            if (deepProps('name')(p).get() === 'CUSTOMER') {
                permission = p
                break
            }
        }
        let write = false
        let read = false
        if (permission !== undefined) {
            let array = deepProps('allow')(permission).getOrElse([])
            for (let allow of array) {
                if (allow === 'R') {
                    read = true
                    continue
                }
                if (allow === 'W') {
                    write = true
                }
            }

        }
        this.setState({write, read})
    }

    componentWillUnmount () {
        if (this.unsubscribe !==  undefined)
            this.unsubscribe()
    }

    render() {
		if (this.state.redirectToSearch) {
			return <Redirect to='/main/customers'/>
        }
        let {score, read, write, card2, card3, user, addresses} = this.state
        let {tabItens, store} = this.props
        return (
    	    <section className="section">
			    {card2 && <AsideUser user={user} card2={card2} loginStatus={this.state.loginStatus} card3={card3} read = {read} write = {write} score={score}/>}
			    {user && <CardTab tabItens={tabItens} user={user} loginStatus={this.state.loginStatus} store={store} addresses={addresses}/>}
    	    </section>
        );
    }
}

export default Customer;
