import React, { useState, useEffect } from 'react';
import {XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line,} from 'recharts';

export default function BaseLineChart (props){

    const {hideX, hideY, hideGrid, data, lines, x, customTooltip} = props
    const COLORS = ["#ff68a1","#2196f3","#ffa600","#97e000"]

    useEffect(()=>{
    },[props.data])

    return (
      <div style={{  height: 200, marginRight: hideGrid ? 0 : 32 }}>
        <ResponsiveContainer>
          <LineChart data={data} margin={{top: 8}}>
            {!hideGrid && <CartesianGrid strokeDasharray="3 3" />}
            {!hideX && <XAxis dataKey={x} />}
            {!hideY && <YAxis />}
            <Tooltip content={customTooltip}/>
            {lines.map((line, idx)=><Line type="monotone" dataKey={line.value} fill="#fff" stroke={COLORS[idx]} key={line.value}/>)}
          </LineChart> 
        </ResponsiveContainer>
      </div>
    )
}
