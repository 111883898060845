import {treatMessage} from '../Utils'
import {promotionsEP} from '../server/endpoints_js'

export default class HomeApi {
	static getHomeReport = (token,alertDialog=()=>{}) => {
        return dispatch => {
            let url = promotionsEP.report()
            fetch(url, {
				method: "GET",
				headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
				}
            }).then((response) => {
                if (response.status === 200 || response.status === 0)
                    return response.json()
                throw response
            }).then(response => {
                dispatch({type:'HOME_REPORT', homeReport:response.data})
            }).catch(error => {
                treatMessage(error, dispatch, (message) => {
                    alertDialog('Não foi possível encontrar as estatísticas iniciais!', message)
                })
            })
        }
    }
}