import React from 'react';
import './style.scss'
import { Trash, Plus, Download } from 'react-feather';
import { Loading } from '../LoadingMessage/loadingMessage';

const ListItem = (props) => {
    let {title, subtitle, loading, onAddClick, onRemoveClick, onDownloadClick, style, data, contentContainerStyle} = props

    const getClassName = (loading) => !loading ? 'listItemContainer' : 'listItemContainer loading'

    return ( 
        <div className={getClassName(loading)} style={style}>
            <div className='border' style={contentContainerStyle}>
                <h1 className='bold'>{title}</h1>
                <h2>{subtitle}</h2>
            </div>
            {onRemoveClick && !loading && <Trash onClick={() => onRemoveClick(data)}/>}
            {onAddClick && !loading && <Plus onClick={() => onAddClick(data)}/>}
            {onDownloadClick && !loading && <Download onClick={() => onDownloadClick(data)}/>}
            {/* {loading && <Loading style={{width: 21, marginLeft: 16}}/>} */}
        </div> );
}
 
export default ListItem;