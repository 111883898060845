import React, { Component } from 'react';
import './operatorData.css';
import Button from '../../Button/button';
import OperatorApi, { updateOperator, updatePassword } from '../../../api/OperatorApi';
import { AlertDialog } from '../../alertDialog/alertDialog';
import deepProps from '../../../Utils';
import LoadingMessage from '../../LoadingMessage/loadingMessage';
import SelectableList from '../../SelectableList/SelectableList';
import { NotificationManager } from 'react-notifications';
import { Save } from 'react-feather';

class OperatorData extends Component {
	constructor(props){
		super(props)
		this.state = {
			selectedProfiles: [],
			profileList: [],
			loading : false
		}
	}

	async componentDidMount () {
		await this.loadStateFromStore()
		this.loadOperatorData()
	}

	async loadStateFromStore() {
		let lastState = this.props.store.getState()
		await this.setState({token:deepProps('token')(lastState).getOrElse(''), 
			searching: true, userAccess: deepProps('operatorDetail')(lastState).getOrElse({})})
	}

	async searchProfileList(){
		let profileList = await this.getAllProfiles(this.state.token)
		console.log(profileList)
		this.setState({searching: false, profileList})
	}

	async loadOperatorData () {
		let lastState = this.props.store.getState()
		let token = deepProps('token')(lastState).getOrElse('');
		let allProfiles = this.getAllProfiles(token)
		if (!token){
			this.setState({searching: false, profileList: await allProfiles, selectedProfiles: []})
			return
		}

		let operatorProfileList = this.getOperatorProfiles()
		let sugestions = this.filterSugestions(await operatorProfileList, await allProfiles)
		this.setState({user: deepProps('userAccess', 'userName', 'identifier')(this.state).getOrElse(''), 
			name: deepProps('userAccess', 'name')(this.state).getOrElse(''), selectedProfiles: await operatorProfileList, profileList: await sugestions})
	}

	filterSugestions(operatorProfiles, sugestionProfiles){
		// console.log()
		if (operatorProfiles === undefined || !(operatorProfiles instanceof Array))
			return
		operatorProfiles = operatorProfiles.map(profile=>profile.value)
		return sugestionProfiles.filter(sugestion=>(!operatorProfiles.includes(sugestion.value)))
	}

	getAllProfiles(token){
		return new Promise((resolve, reject)=>{
			OperatorApi.getProfile(token, (response)=>{
				let list = response.data.map(profile=>{return {text:profile.name, value:profile.id, permissions: profile.permissions}})
				resolve(list)
			})
		})
	}
	
	getOperatorProfiles(){
		let operatorId = deepProps('userAccess', 'operatorId')(this.state).get()
		if (operatorId === undefined)
			return
		console.log( this.state.userAccess)
		return new Promise((resolve, reject)=>{
			OperatorApi.getOperatorProfiles(this.state.token, operatorId, (response => {
				let list = response.data.map(profile=>{return {text:profile.name, value:profile.id, permissions: profile.permissions}})
				resolve(list)
			})
		)}
		)
	}

	createOperator(objectToSend){
		OperatorApi.createOperator(this.state.token, objectToSend, (data)=>{
			NotificationManager.success(`${this.state.name} agora é um novo operador`, `Sucesso!`)
			if (data !== undefined) {
				this.setState({userAccess:data})
				this.props.store.dispatch({type: 'OPERATOR_DETAIL', operator : data})
			}
		}, (error)=>{
			AlertDialog("Ops! Algo deu errado", error)
		})
	}

	async updateOperator(operatorId, objectToSend) {
		let newPass = undefined
		this.setState({loading: true}, async () => {
			const updatedOperator = await updateOperator(this.state.token, operatorId, objectToSend)
			this.setState({userAccess: updatedOperator})
			if ((newPass = deepProps('password')(objectToSend).get()) === undefined) {
				this.setState({loading:false})
				AlertDialog("Sucesso!", updatedOperator.name + " alterado com sucesso")
				return
			}
			await updatePassword(this.state.token, operatorId, {
				newPass
			})
			this.setState({loading:false})
			AlertDialog("Sucesso!", updatedOperator.name + " alterado com sucesso")
		})
	}

	isValidFields(){
		let error = {}
		if (this.state.password !== this.state.confirmPassword) {
			error['message'] = "As senhas não coincidem"
			throw error
		}
	}

	isNewOperator(){
		let operatorId = deepProps('userAccess', 'operatorId')(this.state).getOrElse(-1)
		if (operatorId > 1){
			return false
		}
		return true
	}

	onButtonClick(){
		try{
			this.isValidFields()
			let operatorId = deepProps('userAccess', 'operatorId')(this.state).getOrElse(-1)
			let objectToSend = this.getOperatorObject()
			if (operatorId < 1) {
				if (deepProps('userName', 'identifier')(objectToSend).get() === undefined) {
					AlertDialog("Por favor, verifique os campos!", "Usuário deve estar preenchido")
					return
				}
				if (deepProps('name')(objectToSend).get() === undefined) {
					AlertDialog("Por favor, verifique os campos!", "O campo Nome deve estar preenchido")
					return
				}
				this.createOperator(objectToSend)
				return
			}
			this.updateOperator(operatorId, objectToSend)
		}catch(error) {
			let message = ''
			if (typeof(error) === 'string')
				message = error
			else if (typeof(error) === 'object')
				message = deepProps('message')(error).getOrElse('')
			AlertDialog("Ops! Algo deu errado", message)
		}
	}

	getOperatorObject(){
		let obj={
			"name": this.state.name,
			"password": this.state.password,
			"userName":{
				"identifier": this.state.user,
				"type": "OPERATOR"
			},
			"perfis": this.state.selectedProfiles
		}

		return obj
	}

	canChange () {
		let operatorId = deepProps('userAccess', 'operatorId')(this.state).getOrElse(-1)
		return operatorId < 1
	}

	async onSelectProfile(item, removedList, insertedList){
		let operatorId = deepProps('userAccess')('operatorId')(this.state).getOrElse(-1)
		await OperatorApi.insertProfileToOperator(this.state.token, [item.value], operatorId)
		this.setState({selectedProfiles: insertedList, profileList: removedList})
	}

	async onRemoveProfile(item, removedList, insertedList){
		let operatorId = deepProps('userAccess')('operatorId')(this.state).getOrElse(-1)
		await OperatorApi.deleteProfileFromOperator(this.state.token, [item.value], operatorId)
		this.setState({selectedProfiles: removedList, profileList: insertedList})
	}

	render() {
		return (
			<div className="operatorData-page">
				{this.state.loading && 
					<LoadingMessage title='Salvando...' description='Por favor, aguarde...' />
				}

				<form className="operator-data" ref = {(ref) => this.form = ref}>
					<div className="operatorData-form">	
						<div className="operatorData-content">
							<div className="operatorData-left">
								<div className="operatorData-row">
									<label className="user" >Usuário
										<input type="text" placeholder="Digite o usuário aqui..." 
											value={this.state.user} onChange={(field)=>this.setState({user:field.target.value})} 
											maxLength={255} disabled={!this.canChange()} 
											pattern=".{3,}" required={true} title="Digite pelo menos 3 letras"/>
										</label>
									<label className="name" >Nome<input type="text" placeholder="Digite o nome aqui..." value={this.state.name} onChange={(field)=>this.setState({name:field.target.value})} maxLength={255}></input></label>
								</div>

								<div className="operatorData-row">
									<label className="password" >Senha
										<input type="password" placeholder="*********" value={this.state.password} onChange={(field)=>this.setState({password:field.target.value})} maxLength={255}></input>
									</label>
									<label className="confirmPassword" >Confirmar senha
										<input type="password" placeholder="*********" value={this.state.confirmPassword} onChange={(field)=>this.setState({confirmPassword:field.target.value})} maxLength={255}></input>
									</label>
								</div>
								{(this.state.profileList && this.state.selectedProfiles)&& 
								<SelectableList leftTitle="Perfis do operador" leftItens={this.state.selectedProfiles} rightTitle="Sugestões de perfil" rightItens={this.state.profileList}
									moveItemToRight={(item, removedList, insertedList)=>this.onRemoveProfile(item, removedList, insertedList)} 
									moveItemToLeft={(item, removedList, insertedList)=>this.onSelectProfile(item, removedList, insertedList)} 
									leftEmptyTitle={"Nenhum perfil"} leftEmptySubtitle={"Não há perfis"} 
									rightEmptyTitle={"Nenhuma sugestão"} rightEmptySubtitle={"Não há sugestões disponíveis"} edit={this.props.write}/>}
							</div>
						</div>
					</div>
					{this.props.write && <Button text='Salvar' icon={<Save/>} style={{maxWidth: 75, bottom: 16, position: 'absolute', right: 16}} onClick={()=>this.onButtonClick()}/>}
				</form>
			</div>
		);
  	}
}

export default OperatorData;