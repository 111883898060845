import React, { Component } from 'react';
import Button from '../Button/button'
import './AsideButtonCard.css';

class AsideButtonCard extends Component {
    constructor(Props){
        super(Props)
        this.state = {}
    }
    
    onClickButton () {
        let {onClick} = this.props
        if(onClick) {
            onClick()
        }
    }
    
    render() {
        let {title, text, children, onClick, button, loading, onLinkClick, textLink, showLink} = this.props
        return (
            <div className="aside-button-card card" style={{position:'relative'}}>
                <h1 className="title">{title}</h1>
                <h2 className="text">{text}</h2>
                {children}
                {onClick && <Button text={button} invert={this.props.invert} loading={loading} onClick={()=>this.onClickButton()}/>}

            {onLinkClick && showLink && <Button text={textLink} onClick={()=> onLinkClick()} invert/>}
            </div>
        );
    }
}

export default AsideButtonCard;