import React, { useEffect, useState } from 'react';
import './score.css';
import ReactTooltip from 'react-tooltip';
import { Star } from 'react-feather';
import Skeleton from 'react-loading-skeleton';

const Score = props => {
	const [score, setScore] = useState([])
	const COLORS = {yellow:'#FFB300',gray: '#CCC',white: '#FFF'}

	useEffect(()=>{
		if(props.score === undefined) return
		let userScore =[...new Array(Math.floor(props.score)).fill(true), ...new Array(5-Math.floor(props.score)).fill(false)]
		setTimeout(()=>{
			setScore(userScore)
		}, 500)
	}, [props.score])

	return (
		<div className="score" data-tip={props.score}>
			{score.length === 0 && <Skeleton count={1} width={100} height={16} />}
			{score.map( (active, id)=> <Star key={id} color={active ? COLORS.yellow : COLORS.gray} fill={active ? COLORS.yellow : COLORS.white} style={{width: 16, height: 16}} className="img-score"/>)}
			<ReactTooltip/>
		</div>
	);
}
export default Score;