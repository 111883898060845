import React, { Component } from 'react';
//import { isAuthenticated } from '../../auth';
import ModalMessage from '../../component/modalMessage/modalMessage';
import { Link } from "react-router-dom";
import './login.css';
import Api from '../../api/Api'
//import decode from "jwt-decode";
import {Redirect} from "react-router-dom";
import jwt_decode from 'jwt-decode'
import deepProps from '../../Utils';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import LoadingMessage from '../../component/LoadingMessage/loadingMessage';

class Login extends Component {

	constructor(props) {
		super(props)
		this.state = {
			messageError: '',
			identifier: '',
			password: '',
			token : null,
            userType: 'OPERATOR',
			permissions: [],
			redirect : false
		}
	}

	loginAlert = (title,message) => {
		confirmAlert({
		  title: title,
		  message: message,
		  buttons: [
			{
			  label: 'Entendi!',
			  onClick: () => {}
			}
		  ]
		})
	  };

	readToken (token) {
		token = token.replace('Bearer ', '')
		let obj = jwt_decode(token)
		obj = JSON.parse(deepProps('sub')(obj).getOrElse('{}'))
		this.props.store.dispatch({type:'READ_USER', userAccess: obj})
	}

	componentDidMount() {
		let input = document.getElementById("inputLabel");
		input.addEventListener("keyup", function(event) {
			event.preventDefault();
			if (event.keyCode === 13) {
				document.getElementById("divBtn-login").click();
			}
		});
		this.props.store.subscribe(() => {
			let result = this.props.store.getState()
			switch(result.type) {
				case 'LOGIN':
					if (result.login.status === 0){
						let token = result.login.data
						this.readToken(token)
                        localStorage.setItem('token', token)
						this.props.store.dispatch(Api.getPermission(result.login.data))
                    }
				break;
				case 'PERMISSION':
                    localStorage.setItem('permissions', JSON.stringify(result.permissions))
					this.setState({redirect: true})
				break;
				default:
			}
		})
	}

	setOperatorLogin(){
		this.setState({ userType: 'OPERATOR' }, ()=>{
			let type = this.state.userType
			this.props.store.dispatch({type: 'USER_TYPE', userType:type})
		})
	}

	setCustomerLogin(){
		this.setState({ userType: 'CUSTOMER' })
	}

	attemptLogin() {
		this.setState({searching: true})
		this.props.store.dispatch(Api.login(this.state.identifier, this.state.password, this.state.userType, ()=>{} ,(title,message)=>{this.loginAlert(title,message); this.setState({searching: false})}))
	}

	render() {
		if (this.state.redirect) {
			this.setState({searching: false})
			return <Redirect to='/main/home/general'/>
		}
		return (
			<div className="login">
				<ModalMessage text={this.state.messageError} />
				{this.state.userType === 'CUSTOMER' && <div className="divBtn-login-operator" onClick={() => this.setOperatorLogin()}>
					Trocar para lojista
				</div>}
				{this.state.userType === 'OPERATOR' && false && <div className="divBtn-login-customer" onClick={() => this.setCustomerLogin()}>
					Trocar para cliente
				</div>}
				<form className="form-login">
					<div className="content-login">
						{this.state.userType === 'OPERATOR' && <div className="img-login">
							<img src={require('../../img/perfil_employee.svg')} alt="" />
						</div>}
						{this.state.userType === 'CUSTOMER' && <div className="img-login">
							<img src={require('../../img/perfil_1.svg')} alt="" />
						</div>}
						<div className="info-login">
							<label> Usuário <input type="text" onChange={(field) => { this.setState({ identifier: field.target.value }) }} placeholder="Digite seu usuário aqui..." value={this.state.identifier} name="email" /></label>
							<label style={{marginTop: 16}}> Senha <input id="inputLabel" type="password" onChange={(field) => { this.setState({ password: field.target.value }) }} placeholder="Digite sua senha aqui..." value={this.state.password} name="password" /> </label>
							<div className="box-login">
								<div className={this.state.searching ? "divBtn-login-blocked" : "divBtn-login"} id="divBtn-login" onClick={() => this.attemptLogin()}>
									Entrar
								</div>
								{false && <div className="divBtn-signup">
									<Link to="/register" className="link-login">Não sou cadastrado</Link>
								</div>}
								{this.state.searching && <LoadingMessage />}
							</div>
							
						</div>
					</div>
				</form>
				{this.state.userType === 'OPERATOR' && <div className="background-login-operator"></div>}
				{this.state.userType === 'CUSTOMER' && <div className="background-login-customer"></div>}
			</div>
		);
	}
}

export default Login;