import React, { useState, useEffect } from 'react';
import './SegmentationFilters.scss'
import ProductReachModal from '../ProductReachModal';
import Button from '../Button/button';
import { SEGMENTATIONS_FILTERS } from '../cardTabProduct/segmentation/segmentationTypes';
import SelectableItem from '../selectableItem/selectableItem';

const SegmentationFilters = (props) => {
    const [input, setInput] = useState({})
    const [filters, setFilters] = useState([])
    const [selecteds, setSelecteds]  = useState([])
    const [selectedFilter, setSelectedFilter] = useState()
    const [loading, setLoading] = useState(false)
    const [isProductReachModalVisible, setIsProductReachModalVisible] = useState(false)
    const {onAction, children} = props
    
    useEffect(()=>{
        setFilters(SEGMENTATIONS_FILTERS.slice(0,3))
        loadStore()
    },[])

    const loadStore = () => {
        props.store.subscribe(()=>{
            let lastState = props.store.getState()
            switch(lastState.type){
                case 'ADD_SEGMENT_PRODUCT_FILTER':{
                    console.log('itemss: ', lastState.segmentProductFilter);
                    // const products = lastState.segmentProductFilter.map(product => product.GTIN).join(',')
                    const newInput = input
                    newInput['likesProductFilter'] = lastState.segmentProductFilter
                    setInput(newInput)
                }
            }
        })
    }

    useEffect(()=>{
        if(!selectedFilter) return
        //TODO Check for repeted items
        if(props.noRepeat && selecteds.length > 0){
            let alreadySelected = selecteds.filter(item=>item.id === selectedFilter.value).length > 0
            if(alreadySelected){
                return
            }
        }
    },[selecteds, selectedFilter])

    const onActionHandle = (e) => {
        if(e.value === 'likesProductFilter'){
			setIsProductReachModalVisible(true)
        }
        onAction && onAction(e)
    }

    const onInputChange = (id, value) => {
        let newInput = input
        newInput[id] = value 
        setInput(newInput)
    }
    
    const onSubmitHandle = () =>{
        if(loading) return
        setLoading(true)
        let callback = props.onSubmit(input)
        if(callback.then){
            callback.finally(()=>{
                setLoading(false)
            })
        }else{
            setLoading(false)
        }
    }

    return (
        <div className='addComboListView'>
            {props.title && <h1 className='title'>{props.title}</h1>}
            <div>
                {filters.map((filter, idx)=>(
                    <div key={filter + idx} style={{marginBottom: 8}} className='border'>
                        <h1 className='title'>{filter.inputText}</h1>
                        {['text', 'number'].includes(filter.type) && <input style={{margin: 0, height: 22}} placeholder={filter.placeholder} type={filter.type} min={0} step={1} onChange={event=>onInputChange(filter.value,event.target.value)} id='list-item-input'/>}
                        {(filter.type === 'selectableList') && (
                            <>
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    {input[filter.value] && input[filter.value].map((item, idx) =>
                                        <SelectableItem key={idx} title={item.name} img={item.resourceImage} subtitle={item.GTIN} style={{margin: 0}}/>
                                    )}
                                </div>
                                <Button text={filter.buttonText} textOnly style={{justifyContent: 'flex-end', paddingRight: 8}} onClick={()=>onActionHandle({value: 'likesProductFilter'})}/>
                            </>
                        )}
                    </div>
                ))}
                {children}
                {/* <Button text='+ adicionar filtro' textOnly style={{justifyContent: 'flex-start'}}/> */}
                <Button text='Buscar' loading={loading} loadingText='Buscando...' invert style={{width: 90, marginTop: 8}} onClick={onSubmitHandle}/>
                <ProductReachModal title='Clientes que gostam de:' isVisible={isProductReachModalVisible} onClose={()=>setIsProductReachModalVisible(false)} store={props.store} suggestions={props.promotionItens}/>
            </div>
        </div>
    );
}

export default SegmentationFilters;