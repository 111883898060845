import { treatMessage } from "../Utils";

export default class TokenApi {
	static getToken (successFunc = ()=>{}, errorFunc= ()=>{}, token = '4EABAD855911F6284A5098AB7D8F54BC7EAD5920A3F282FD59340DA932F7B8D325A3F75C81173F62E16F533537A9C0D6') {
		fetch('http://18.223.179.117:5000/auth/token', {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'X-Auth-Token' : token
		}
		}).then((response) => {
			if(response.status === 200)
				return response.json()
		}).then(response => successFunc(response)).catch((error) => {
			treatMessage(error, () => {}, (message) => {
				errorFunc('Não foi possível atualizar o estado das lojas na promoção. ' + message)
			})
		})
	}
}