import { LoginEP } from "./server/endpoints_js";
import decode from "jwt-decode";


export const isAuthenticated = (login, password, callback) => {
  console.log("login");
  //carrega();
  //console.warn(LoginEP.login())
  fetch(LoginEP.login(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      userName: {
        identifier: login,
        type: "CPF"
      },
      password: password,
      app: "PROMOSHARE",
      userType: "CUSTOMER"
    })
  })
    .then(response => {
      switch (response.status) {
        case 403:
        case 200:
          return response.json();
        default:
          return {
            status: -1,
            message:
              "Nao foi possivel acessar o sistema, tente novamente mais tarde!"
          };
      }
    })
    .then(response => {
      switch (response.status) {
        case 12:
        case 23:
          console.error("teste: " + response.message);
          //errorUser();
          console.error("Usuário ou senha inválida!");
          return false;
        case 0:
          console.log("data: " + response.data);
          console.log("teste: " + response.message);
          var decoded = decode(response.data);
          console.log(decoded);
		  isPermission();
          callback();
          return true;
        case -1:
          console.error(response);
          return false;
        default:
          console.error("Uknow error");
          return false;
      }
    })
    .catch(error => {
      console.error(error);
      if (typeof error.text === "function") {
        error.text().then(errorMessage => {
          console.log(errorMessage);
        });
      } else {
        console.log("Erro na requisição: " + error);
      }
      return false;
    });
};


export const isPermission = () => {
  console.log("permission");
  fetch('http://18.232.189.55:8080/auth/permissions', {
    method: "GET",
    headers: {
		'Content-Type': 'application/json',
		'X-Auth-Token': 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJ7XCJjdXN0b21lcklkXCI6NSxcImlkXCI6OCxcIm5hbWVcIjpcIkd1aWxoZXJtZVwiLFwidXNlck5hbWVcIjp7XCJpZGVudGlmaWVyXCI6XCI0MDkwMTY1Nzg0MFwiLFwidHlwZVwiOlwiQ1BGXCIsXCJzdGF0dXNcIjpcIkVOQUJMRVwiLFwic2VxdWVuY2VcIjowfSxcImFwcFwiOlwiUFJPTU9TSEFSRVwiLFwidXNlclR5cGVcIjpcIkNVU1RPTUVSXCJ9IiwiZXhwIjoxNTM4NjY1NzI3fQ.xPc7WPJKTs8wMjDf2G3ty3Rvvm63l8MEkz3S9QhNKCV4kTfj-UiLgSrAxsFJ6yrrpZj8IJCLOsRY7dTJRY-wog'
    }
  }).then((response) => {
	  if (response.status === 200) {
		  console.log(response);
	  }
  }).catch( (error) => {
	console.warn(error);
  })
  
};
