import React, { Component } from 'react'
import Button from '../../Button/button'
import deepProps from '../../../Utils'
import refresh from '../../../img/refresh.svg'
import {AlertDialog} from '../../alertDialog/alertDialog'
import './adress.css';
import AddressesApi from '../../../api/AddressesApi';
import { Save } from 'react-feather'

export default class AddressData extends Component {
	constructor(Props){
		super(Props)
		this.state = {
			userType: '',
			address: {},
			id:0,
			addressState:'UN_KNOW',
			addresses:[/*{title: 'Rua Gisele', data:['204', 'Pq. dos Camargos', 'Barueri', 'SP', '06436-120']}*/],
			zipCode: '',
			street:'',
			acronymState: '',
			district:'',
			city:'',
			number:0,
			complement: '',
			lastSearch:'',
			found:false,
			refresh:false,
			canEdit: false,
			token:''
		}
	}

	componentWillMount() {
		let data = deepProps('data')(this.props).getOrElse([])
		let token = deepProps('token')(this.props.store.getState()).getOrElse('')
		if (data.length < 1) {
			this.setState({token})
			return
		}
		let address = data[0]
		let objStore = deepProps('objStore')(this.props).getOrElse({})
		let addressState = deepProps('state')(address).getOrElse('UN_KNOW')
		let zipCode = deepProps('zipCode')(address).getOrElse('')
		let street = deepProps('street')(address).getOrElse('')
		let streetType = deepProps('streetType')(address).getOrElse('')
		if (streetType.length > 0)
			street = streetType + ' ' + street
		let acronymState = deepProps('acronymState')(address).getOrElse('')
		let district = deepProps('district')(address).getOrElse('')
		let city = deepProps('city')(address).getOrElse('')
		let number = deepProps('number')(address).getOrElse(0)
		let complement = deepProps('complement')(address).getOrElse('')
		this.setState({address, objStore, token, addressState, zipCode, street, acronymState, district, city, number, complement, found: (zipCode.length > 0)})
	}

	findAddress() {
		let zipCode = this.state.zipCode
		if (zipCode === undefined || zipCode.length < 2 || this.state.lastSearch === zipCode)
			return
		zipCode = zipCode.replace('-', '')
		AddressesApi.find(zipCode, (address) => {
			let found = address !== undefined && deepProps('error')(address).getOrElse(true)
			let addressState = deepProps('uf')(address).getOrElse('UN_KNOW')
			let acronymState = deepProps('uf')(address).getOrElse('UN_KNOW')
			let zipCode = deepProps('cep')(address).getOrElse('').replace('-', '')
			let district = deepProps('bairro')(address).getOrElse('')
			let city = deepProps('localidade')(address).getOrElse('')
			let number = 0
			let complement = ''
			let street = deepProps('logradouro')(address).getOrElse('')
			this.setState({acronymState, addressState, zipCode, district, city, number, complement, street, id:0, found, lastSearch:zipCode, refresh:false})
		}, (error) => {
			AlertDialog('Ops, algo deu errado', error + '.\nFavor preencha os dados')
			this.setState({lastSearch:zipCode})
			this.setState({refresh:false, found:false})
			this.clearAddress()
		})
	}

	clearAddress () {
		this.setState({
			addressState:'UN_KNOW',
			street:'',
			acronymState: '',
			district:'',
			city:'',
			number:0,
			complement: '',
		})
	}

	edit(input, edited = false) {
		console.log(input)
		return this.state.found && (input !== undefined && input.length > 0) && !edited
	}

	validate = (field, label) => {
		let error = {}
		if (field === undefined || field.length < 1) {
			error['message'] = `O campo ${label} não pode estar vazio`
			throw error
		}

	}

	saveAddress = () => {
		/**
			userType: '',
			address: {},
			id:0,
			addressState:'UN_KNOW',
			addresses:[/*{title: 'Rua Gisele', data:['204', 'Pq. dos Camargos', 'Barueri', 'SP', '06436-120']}],
			zipCode: '',
			street:'',
			acronymState: '',
			district:'',
			city:'',
			number:0,
			complement: '',
			lastSearch:'',
			found:false,
			refresh:false,
			canEdit: false,
			token:'' */
		let {zipCode, street, district, city, addressState} = this.state
		
		try {
			this.validate(zipCode, 'CEP')
			this.validate(street, 'Rua')
			this.validate(district, 'Bairro')
			this.validate(addressState, 'Estado')
			this.validate(city, 'Cidade')
		}catch(e) {
			AlertDialog('Ops, algo deu errado.', deepProps('message')(e).getOrElse('Erro ao validar os campos'))
			return
		}

		let array = street.split(' ')
		let streetType = ''
		if (array.length > 0) {
			streetType = array[0]
			for (let i = 1; i < array.length; i++)
				street += array[i]
		}

		let store = deepProps('objStore')(this.props).getOrElse({})
		let address = {
			acronymState : this.state.acronymState,
			city : this.state.city,
			complement : this.state.complement,
			country: {
				name :'Brazil',
				initials : 'BRA'
			},
			district : this.state.district,
			kindOfAddress : "1",
			number: this.state.number,
			state : this.state.addressState,
			street,
			streetType,
			zipCode : this.state.zipCode
		}
		let obj = {
			store,
			address
		}
		AddressesApi.saveAddress(this.state.token, obj, () => {
			this.props.store.dispatch({type:'SEARCH_STORE_ADDRESS', storeAddress:deepProps('address')(obj).get()})
            AlertDialog('Dados salvos com sucesso', 'O endereço fora salvo com sucesso')
		}, (error) => {
			AlertDialog('Ops, algo deu errado', 'Não fora possível salvar os dados. ' + error)
		})
	}

    render() {
		let {write} = this.props
		let {street, streetChanged, district, districtChanged, addressState, addressStateChanged, city, cityChanged} = this.state
        return (
    	    <form className="storeData">
			    <div className="form-storeData">
					<div className="storeData-row">
						   <label className='CEP'> 
								   	{write && <img 
										className='refresh-icon' alt="refresh icon" 
									   src={refresh} onClick={() => this.setState({refresh:true})}/> 
									}
									CEP
								<input disabled={this.edit() && !this.state.refresh} type="text" placeholder="Digite o CEP aqui..."
									  value={this.state.zipCode} onChange={(e)=>this.setState({zipCode:e.target.value})}
									  onBlur={() => this.findAddress()}/> </label>
							<label className="form-name"> Rua 
								<input disabled={this.edit(street, streetChanged)} 
									type="text" placeholder="Digite o endereço aqui..."  
									value={street} 
									onChange={(e)=> {
										this.setState({street:e.target.value, streetChanged:true})}
									}/> </label>
					</div>
					<div className="storeData-row">
						<label className="form-number"> Número 
							<input type="text" placeholder="Digite o número aqui..." value={this.state.number} 
								onChange={(e)=> {
									this.setState({number:e.target.value})
								}}/> </label>
						<label className="form-district"> Bairro 
							<input disabled={this.edit(district, districtChanged)} 
								type="text" placeholder="Digite o bairro aqui..." value={district} 
								onChange={(e)=> {
									this.setState({district:e.target.value, districtChanged:true})
								}}/> </label>
						<label className="form-number"> Complememento 
							<input type="text" placeholder="Digite o componente aqui..." value={this.state.complement} 
								onChange={(e)=> {
									this.setState({complement:e.target.value})}
								}/> </label>
					</div>
					<div className="storeData-row">			
						<label> Estado
							<select value ={addressState} onChange={(e)=> {
								this.setState({addressState:e.target.value, addressStateChanged:true})}

							} disabled={this.edit(addressState, addressStateChanged)}>
								<option value="AC">Acre</option>
								<option value="AL">Alagoas</option>
								<option value="AP">Amapá</option>
								<option value="AM">Amazonas</option>
								<option value="BA">Bahia</option>
								<option value="CE">Ceará</option>
								<option value="DF">Distrito Federal</option>
								<option value="ES">Espírito Santo</option>
								<option value="GO">Goiás</option>
								<option value="MA">Maranhão</option>
								<option value="MT">Mato Grosso</option>
								<option value="MS">Mato Grosso do Sul</option>
								<option value="MG">Minas Gerais</option>
								<option value="PA">Pará</option>
								<option value="PB">Paraíba</option>
								<option value="PR">Paraná</option>
								<option value="PE">Pernambuco</option>
								<option value="PI">Piauí</option>
								<option value="RJ">Rio de Janeiro</option>
								<option value="RN">Rio Grande do Norte</option>
								<option value="RS">Rio Grande do Sul</option>
								<option value="RO">Rondônia</option>
								<option value="RR">Roraima</option>
								<option value="SC">Santa Catarina</option>
								<option value="SP">São Paulo</option>
								<option value="SE">Sergipe</option>
								<option value="TO">Tocantins</option>
							</select>
				    </label>
					<label className="form-name"> Cidade 
					<input disabled={this.edit(city, cityChanged)} type="text" placeholder="Digite a cidade aqui..." value={city} 
						onChange={(e)=> {
							this.setState({city:e.target.value, cityChanged:true})}
						}/> </label>
			    </div>
			    <div className='btnSave'>
				   {this.props.write && <Button text='Salvar' icon={<Save/>} onClick={() => this.saveAddress()} style={{maxWidth: 75, paddingLeft: 16, position: 'absolute', right: 32, bottom: 16}}/>}
			    </div>
				</div>
    	    </form>
        );
    }
}
