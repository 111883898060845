import React, {Component} from 'react';
import SmallCard from '../../../component/smallCard/smallCard';
import './searchStore.css';
import deepProps, { format, treatMessage } from '../../../Utils'
import StoreApi from '../../../api/StoreApi'
import {Redirect} from "react-router-dom";
import DropDownFilter from '../../../component/DropDownFilter/DropDownFilter'
import Button from '../../../component/Button/button'
import EmptyMessage from '../../../component/emptyMessage/emptyMessage'
import storeImg from '../../../img/store-icon.png'
import { confirmAlert } from 'react-confirm-alert';
import LoadingMessage, {Loading} from '../../../component/LoadingMessage/loadingMessage';
import { Observable } from 'rxjs/Rx'
import { NavLink } from 'react-router-dom'
import { Plus } from 'react-feather';

export default class SearchStore extends Component {

    constructor(props) {
        super(props)
        this.state = {
            stores: [],
            controller: window.AbortController,
            query: '',
            filters: ['description', 'value'],
            page: 0,
            quantity: 0,
            token: null,
            permission: false,
            logout: false,
            storeDetail: false,
            filter:'store',
            write : false,
            read : false,
            text:''
        }
        this.maxPerPage = 9999
        this.searchEvent = null
        this.showStore = this.showStore.bind(this)
        this.search = ''
    }

    alertDialog = (title,message) => {
		confirmAlert({
		  title: title,
		  message: message,
		  buttons: [
			{
			  label: 'Entendi!',
			  onClick: () => {}
			}
		  ]
		})
	}

    componentDidMount() {
		this.setState({searching: true})
        this.unsubscribe = this.props.store.subscribe(() => {
            let lastState = this.props.store.getState()
            switch(lastState.type) {
                case 'STORES_SEARCH':
                    this.init()
                break
                case 'STORE_DETAILS':
                    this.setState({storeDetail:true})
                break
                default:
                    break
            }
        })
        let token = deepProps('token')(this.props.store.getState()).getOrElse(localStorage.getItem('token'))
        let logout = token === null || token === undefined
        this.setState({token, logout})
        this.init()
        this.props.store.dispatch(StoreApi.findStores(token, (title, message)=> this.alertDialog(title,message), -1, ()=>this.setState({searching: false}), ()=>this.setState({searching: false})))
        this.initSearch()
    }

    getFilter () {
        let store = -1
        switch(this.state.filter) {
            case 'state':
                return 'acronymState=' + this.state.text
            case 'store':
                store = parseInt(this.state.text)
                if (isNaN(store))
                    store = -1;
                return 'store=' + store
            default:
                return 'store=-1'
        }
    }
    
    initSearch () {
        this.inputStream = Observable.fromEvent(this.search, 'input').map((e) => {
            this.setState({text:e.target.value})
            return e.target.value
        }).debounceTime(650)
        this.suggestionsStream = this.inputStream.switchMap(() => {
            let query = this.getFilter()
            this.setState({filtering:true})
            return StoreApi.findStoresWithFilter(this.state.token, query);
        }).mergeMap(resp => {
            if (resp === null || !(resp instanceof Object))
                throw new Error('No result')
            return resp.json()
        }).map(response => {
            this.setState({filtering:false})
            return deepProps('data')(response).getOrElse([])
        }).catch(error => {
            this.setState({filtering:false})
        })
        this.subscribe = this.suggestionsStream.subscribe(stores => {
			this.setState({stores})
        },(error) => {
            setTimeout(() => this.initSearch(), 1)
        })
    }

    init () {
        let stores = deepProps('stores')(this.props.store.getState()).getOrElse([])
        let paging = deepProps('pageStore')(this.props.store.getState()).getOrElse(null)
		let city = deepProps('city')('storeAddress')(this.props.store.getState()).getOrElse(null)
        let addressState = deepProps('state')('storeAddress')(this.props.store.getState()).getOrElse(null)
        let permissions = deepProps('permissions')(this.props.store.getState()).getOrElse([])
        let permission = undefined
        for (let p of permissions) {
            if (deepProps('name')(p).get() === 'STORE') {
                permission = p
                break
            }
        }
        let write = false
        let read = false
        if (permission !== undefined) {
            let array = deepProps('allow')(permission).getOrElse([])
            for (let allow of array) {
                if (allow === 'R') {
                    read = true
                    continue
                }
                if (allow === 'W') {
                    write = true
                }
            }

        }
        // console.log(this.props.store.getState())
        if (paging !== null) {
            let count = deepProps('count')(paging).getOrElse(0)
            let page = deepProps('page')(paging).getOrElse(0)
            let maxPerPage = deepProps('size')(paging).getOrElse(0)
            this.setState({count, page, maxPerPage})
        }
        this.setState({stores},()=>{
			if(stores.length !== 0)
			this.setState({searching: false})
		})

		this.setState({city: city, addressState: addressState, write, read})
    }

    componentWillUnmount() {
        if (this.unsubscribe === undefined)
            return
        try {
            this.unsubscribe()
        }catch(e) {
            console.error(e)
        }
    }

	alertDialog = (title, message) => {
		confirmAlert({
		  title: title,
		  message: message,
		  buttons: [
			{
			  label: 'Entendi!',
			  onClick: () => {}
			}
		  ]
		})
	}

	previousPage () {
		this.setState({searching: true})
        if (this.state.page >= 0) {
            this.props.store.dispatch(StoreApi.findStores(this.state.token, this.state.page - 1,(title,message)=>this.alertDialog(title,message), this.setState({searching: false}), this.setState({searching: false})))
        } else {
			this.setState({searching: false})
		}
    }

    nextPage() {
        this.setState({searching: true})
        let result = this.state.page * this.state.maxPerPage + this.state.stores.length
        if (this.state.count > result) {
            this.props.store.dispatch(StoreApi.findStores(this.state.token, this.state.page + 1,(title,message)=>this.alertDialog(title,message), this.setState({searching: false}), this.setState({searching: false})))
        } else { 
			this.setState({searching: false})
		}
    }

    showStore(store) {
        this.props.store.dispatch({type:'STORE_DETAILS', storeDetail:store})
    }

    paginator() {
        if (this.state.count % this.state.maxPerPage === 0)
            return this.state.count / this.state.maxPerPage
        return ((this.state.count / this.state.maxPerPage) >> 0) + 1
    }

    newStore () {
        this.setState({storeDetail:true, searching: false})
        this.props.store.dispatch({type:'STORE_DETAILS', storeDetail:{}})
    }

    handleInputChange = () => {
        clearTimeout(this.searchEvent)
        this.setState({
            query: this.search.value, from:0, page:1
        }, () => {
            let query = this.search.value
            this.doSearch(query)
        })
    }
    
    changeFilter = (filter) => {
        let value = deepProps('value')(filter).getOrElse('nothing')
        this.setState({filter:value}, () => {
            let query = this.getFilter()
            StoreApi.findStoresWithFilter(this.state.token, query)
            .then(response => {
                if (response.status === 200)
                    return response.json()
                throw response
            }).then(result => {
                let stores = deepProps('data')(result).getOrElse([])
                this.setState({stores})
            }).catch(error => {
                treatMessage(error, () => {}, (message) => {
                    this.alertDialog('Ops, algo deu errado','Não fora possível carregar as lojas. ' + message)
                })
            })
        })
    }

    render() {
        if (this.state.logout)
            return <Redirect to = '/' />
        if (this.state.storeDetail)
            return <Redirect to = '/main/store/store' />
        return ( <section className = "store" >
            <div className = "page-title-header" >
            <div className = "box-title" >
            <h1 className = "title-page" > Lojas </h1> <span className = "subtitle-page" > {
                this.state.stores.length + ' '
            }
             Total</span>
            </div>
            <form className="search" style={{display: "flex"}}>
                {this.state.filtering && <Loading style={{width:21, marginRight: 16}}/>}
                <input type="text"  className='input-shadow' ref={input => this.search = input} placeholder="Pesquisar..." id = 'typeaheadstore'/> 
                <span style={{width: 8}}/>
                <DropDownFilter onSelect={this.changeFilter} filters={[{text:'Loja', value:'store'}, {text:'Região', value:'state'}]}/>
            </form>   
            </div>
            <div className = "container-search" > 
			{this.state.searching && <LoadingMessage title='Pesquisando...' description='Aguarde, Estamos buscando informações!'  style={{left: 55}}/>}
            {(this.state.stores.length === 0 && !this.state.searching) && <EmptyMessage title='Nenhum resultado encontrado' description='Nenhum resultado para lojas foi encontrado!' style={{left: 55}}/>}
            {
                this.state.stores.map((store) => {
                    return ( <SmallCard key = {
                            store.id
                        }
                        address = {
                            deepProps('address', 'city')(store).getOrElse('City') + "/" + 
                            deepProps('address', 'acronymState')(store).getOrElse('State')
                        }
						email = {
							'Store contact'
						}
                        photo = {
                            storeImg
                        }
                        cpf = {
                            //(value, mask, replace)
                            format(deepProps('identifierDefault', 'identifier')(store).getOrElse('0'), '##.###.###/####-##', '#')
                        }
                        title = {
                            store.description
                        }
                        callback = {
                            () => this.showStore(store)
                        }
                        />
                    )
                })
            } </div>
                <div className = "footer-store" >
                <div>
					<NavLink to='/main/store/store'>					
						{this.state.write && <Button text='Nova Loja' icon={<Plus/>} onClick={()=>this.newStore()} style={{position: 'absolute', right: 16, minWidth: 89}}/>}
					</NavLink>
					
                </div>
                </div>
                </section>
            );
        }
}