import React, { useState, useEffect } from 'react'
import { SearchScreen } from '../../searchScreen/searchScreen'
import Button from '../../../component/Button/button'
import InputText from '../../../component/inputText/inputText'
import { NotificationManager } from 'react-notifications'
import moment from 'moment'
import deepProps from '../../../Utils'
import SmallCard from '../../../component/smallCard/smallCard'
import DropDownFilter from '../../../component/DropDownFilter/DropDownFilter'
import CustomerAPI, { getCustomersAPI, getCustomerDataAPI } from '../../../api/CustomerApi'
import formatValue from '../../../Format'
import { useHistory } from 'react-router-dom'
import ExportModal from '../modal/exportModal'
import SearchExportModal from '../modal/exportModal'
import { LoadingPageBlocker } from '../../../component/LoadingMessage/loadingMessage'

const SearchCustomer = (props) => {
    const EMPTY_FILTERS = {churn: 'all', gender: 'all', maxAge: 0, minAge: 0, score: 0}
    const EMPTY_BODY = {query: '_ALL'}

    const [searchFilter, setSearchFilter] = useState({query: '_ALL'})
    const [loadingReport, setLoadingReport] = useState()
    const [customersCount, setCustomersCount] = useState(0)
    const [loadingCustomer, setLoadingCustomer] = useState()
    const [filterBody, setFilterBody]  = useState({query: '_ALL'})
    const [filters, setFilters] = useState(EMPTY_FILTERS)
    const history = useHistory()

    const getUserRegisterDate = date => date ? moment(date).format('L') : 'Sem data de registro'

    const getUserFormatedBirthdate = birth => birth ? moment(birth).format('L') : 'Não definido'

    const getUserFormattedAddress = (addresses) => {
        if (!addresses || addresses.length === 0) return 'Sem endereço cadastrado'
        let selectedAddress = addresses[0]    
        return `${deepProps('city')(selectedAddress).getOrElse('')} / ${deepProps('acronymState')(selectedAddress).getOrElse('')}`

    }

    const onSearchRequestDoneHandle = (result) => {
        setCustomersCount(deepProps('count')(result).getOrElse(0))
    }

    const onSearchChangeHandle = (value, filterType) => {
        if(filterType.enum === '_ALL' && !value){
            return setSearchFilter({
                query: filterType.enum,
            })
        }
        setSearchFilter({
            query: filterType.enum,
            [filterType.value]: {
                value,
                type: 'EQUAL',
                function: 'EQ'
            }
        })
    }

    const onFilterChangeHandle = (id, field, functionType) => {
        if(id === 'rating' && field.value !== 0 && (field.value < 1 || field.value > 5)){
            return NotificationManager.warning('Informe um valor entre 1 e 5', 'Pontuação fora dos limites')
        }

        let newFilterBody = filterBody

        if(!field.value || field.value === 'all' || field.value === 0){
            delete newFilterBody[id]
        }else{
            newFilterBody[id] = {value: field.value}

            if(functionType){
                newFilterBody[id].function = functionType
            }

        }
        let filtersValue = filters
        filtersValue[id] = field.value
        setFilters(filtersValue)
        setFilterBody({...newFilterBody})
    }

    const onCardClickHandle = (identifier) => {
        if(loadingCustomer) return
        setLoadingCustomer(true)
        getCustomerDataAPI(identifier.identifier).then(response=>{
            props.store.dispatch({type:'CUSTOMER_DETAILS', customerDetails:response.data})
            console.log(props);
            history.push('customer/data')
        }).catch((e)=>{
            NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
        }).finally(()=>{
            setLoadingCustomer(false)
        })

    }
    
    const getCombinedFilters = () => {
        let combined = {...searchFilter, ...filterBody}
        combined['query'] = (searchFilter.query !== '_ALL') ? searchFilter.query : filterBody.query
        return combined
    }

    const onResetClickHandle = () => {
        let body = filterBody
        delete body['maxAge']
        delete body['minAge']
        delete body['score']
        delete body['churn']
        delete body['gender']
        setFilters(EMPTY_FILTERS)
        setFilterBody(body)
    }

    return (
        <div style={{flex:1, display: 'flex'}}>
            {loadingCustomer && <LoadingPageBlocker/>}
            <SearchScreen
                title='Meus Clientes'
                searchFilters={[{text:'Selecione...', value:'_all', enum: '_ALL'},{text:'Nome', value:'name', enum: 'NAME'},{text:'CPF', value:'cpf', enum: 'CPF'},{text:'Endereço', value:'place', enum: 'PLACE'},{text:'CEP', value:'zipCode', enum: 'ZIP_CODE' }]}
                searchRequest={(page, filter) => getCustomersAPI(filter ? getCombinedFilters() : searchFilter, page, filter)}
                onSearchRequestDone={onSearchRequestDoneHandle}
                onSearchChange={onSearchChangeHandle}
                onSearchClick={()=>{}}
                filter
                render={(customer, index) => <SmallCard key={deepProps('identifierDefault', 'identifier')(customer).getOrElse(index)} callback={() => onCardClickHandle(deepProps('identifierDefault')(customer).getOrElse(0))}
                    cpf={getUserFormattedAddress(deepProps('addresses')(customer).getOrElse([]))} 
                    status={deepProps('status')(customer).getOrElse('UNKNOW')}
                    title={deepProps('fullName')(customer).getOrElse('Sem Descrição')}
                    address = {`Desde ${getUserRegisterDate(deepProps('registeredAt')(customer).getOrElse(false))}`}
                    email = {getUserFormatedBirthdate(deepProps('birth')(customer).getOrElse(false))}/>}
				card={
					<>
						<h1 style={{fontSize: 21, fontWeight: '700'}}>{customersCount}</h1>
						<h2>clientes encontrados</h2>
					</>
				}
                cardStyle={{display: 'flex', flexDirection: 'collumn', alignItems: 'center', justifyContent: 'center'}}
                filters={<>
                    {/* <DropDownFilter title='Clientes' filters={[{text:'Todos', value:'all'},{text:'Ativos', value:false},{text:'Inativos', value:true}]} value={filters.churn} onSelect={(filter)=>onFilterChangeHandle('churn',filter)} style={{marginBottom: 12, zIndex: 9}}/> */}
                    <DropDownFilter title='Gênero' filters={[{text:'Todos', value:'all'},{text:'Feminino', value:'F'},{text:'Masculino', value:'M'},{text:'Desconhecido', value:'UNKNOW'}]}  value={filters.gender} onSelect={(filter)=>onFilterChangeHandle('gender',filter)} style={{marginBottom: 12}}/>
                    <label>
                        Faixa etária
                        <div style={{display: 'grid', gridTemplateColumns: '98px 98px', gridColumnGap: 4}}>
                            <InputText style={{flex: 1}} placeholder='mínima' number state={'minAge'} value={filters.minAge} onChange={(field, value) => {onFilterChangeHandle(field, {value: Number(value)}, 'GT_EQ')}}/>
                            <InputText placeholder='máxima' number  state={'maxAge'} value={filters.maxAge} onChange={(field, value) => {onFilterChangeHandle(field, {value: Number(value)}, 'LT_EQ')}}/>
                        </div>
                    </label>
                    
                    {/* <InputText label='Pontuação' number style={{marginTop: 8}}  min={1} max={5} state={'rating'} value={filters.score} onChange={(field, value) => {onFilterChangeHandle(field, {value: Number(value)}, 'EQ')}}/> */}
                    <SearchExportModal filters={{...searchFilter, ...filterBody}}/>
                </>}
                onResetClick={onResetClickHandle}
				/>
        </div>  
    )
}

export default SearchCustomer;