import React, { Component } from 'react';
import './statistics.css';
import RankingCard from '../../rankingCard/rankingCard';
import LineCharts from '../../lineCharts/lineCharts';
import deepProps from '../../../Utils';
import PromotionAPI from '../../../api/PromotionApi';
import { AlertDialog } from '../../alertDialog/alertDialog';
import LoadingMessage from '../../LoadingMessage/loadingMessage';

export default class Statistics extends Component {
	constructor(props) {
     super(props)
	 this.state = {
	}
	 this.unsubscribe = this.props.store.subscribe(() => {
            let lastState = this.props.store.getState()
            switch(lastState.type) {
                case 'PROMOTION_REPORT':
				let itensPromotion= deepProps('promotionReport', 'items')(lastState).getOrElse([])

				let itensPromotionRedeemed = itensPromotion.map((field)=>{return {title: field.description, subtitle: field.redeemCount + ' Destacados'}})
				let itensPromotionUsed = itensPromotion.map((field)=>{return {title: field.description, subtitle: field.totalQuantitySold + ' Usados'}})
				this.sortRanking(itensPromotionRedeemed)
				this.setState({itensPromotionRedeemed, itensPromotionUsed})
				break
				case 'ITENS_TIME_REPORT':
					let itensPromotionTime= deepProps('itensTimeReport')(lastState).getOrElse([])
					console.log(itensPromotionTime)
					let chartsData = itensPromotionTime.map((field)=>{return {dia: field.day, usados: field.uses, destacados: field.redeems}})
					this.setState({promotionPerformance:{
						data: chartsData,
						labels: [{label:'usados'},{label:'destacados'}],
						x:{key:'dia'}
					}})
				break
				default:
				break
            }
        })
   }

   componentDidMount () {
	this.setState({searching: true})
	let lastState = this.props.store.getState()
	let promotion = deepProps('promotionDetails')(lastState).getOrElse(null)
	
	if (promotion == null) {
		this.setState({redirect:true})
		return
	}

	let promotionId = deepProps('id')(promotion).getOrElse (0)
    if (promotionId < 1) {
    	AlertDialog('Erro!', 'Erro ao carregar estatísticas')
		return
	}
	let token = deepProps('token')(this.props.store.getState()).getOrElse(localStorage.getItem('token'))
		if (token == null)
			this.setState({logout: true})
		this.setState({token})
	this.props.store.dispatch(PromotionAPI.getPromotionsReport(promotionId, token, ()=>{}, ()=>{}))
	// this.props.store.dispatch(PromotionAPI.getItensTimeReport(promotionId, 'ini=2000-12-01T00:00:00&end=2019-12-18T00:00:00', token, ()=>{this.setState({searching: false})}, ()=>{},))
	this.props.store.dispatch(PromotionAPI.getItensTimeReport(320, 'ini=2000-12-01T00:00:00&end=2019-12-18T00:00:00', token, ()=>{this.setState({searching: false})}, ()=>{},))
  	}

	sortRanking(array){
		return array.sort(function (a, b) {
			if (a.subtitle < b.subtitle) {
				return 1;
			}
			if (a.subtitle > b.subtitle) {
				return -1;
			}
			return 0;
		});
	}

	render() {
		return (
			<div className="statistic">
				<div className="content" style={{display:'flex', flexDirection:'column'}}>
					{this.state.searching && <LoadingMessage title='Pesquisando...' description='Aguarde, Estamos buscando informações!' />}
					{(!this.state.searching && this.state.itensPromotionRedeemed) && <RankingCard title={'Itens mais destacados'} data={this.state.itensPromotionRedeemed} border/>}
					{(!this.state.searching && this.state.itensPromotionUsed) && <RankingCard title={'Itens mais usados'} data={this.state.itensPromotionUsed} border/>}
					{(!this.state.searching && this.state.promotionPerformance) && <LineCharts data={this.state.promotionPerformance} title="Desempenho da promoção" width={500} height={200} border/>}
				</div>
			</div>
		)
	}
}