

const MASK_CPF = '###.###.###-##'

export default function formatValue(value, type) {
    let mask = getMaskByType(type)
    if (mask == null)
        return value
    let j = 0
    if (type === 'CPF')
        j = 11
    while (value.length < j)
        value = `0${value}`
    let result = ''
    let jump = 0
    for (var i = 0; i < mask.length; i++) {
        if (mask[i] === '#')
            result += value[i - jump]
        else {
            jump++
            result += mask[i]
        }
    }
    return result
};

function getMaskByType(type) {
    let mask = null
    if (type == null)
        return null
    switch(type) {
        case 'CPF':
            mask =  MASK_CPF
        break
        default:
            return null
    }
    return mask
}