import {promotionsEP, GraphQLEP, customersEP} from "../endpoints_js";
import { getFetchPOSTHeader, validateRequest, getFetchHeader} from "../../api/UtilsAPI";
import purchaseHistory from "../graphql/customers/query";

export const customerPurchaseHistory = async  (stores = [], startDate, endDate, customer) => {
    
    let query = purchaseHistory(stores, startDate, endDate, customer)
    // console.log(query)
    let url = GraphQLEP.getGraphQLServer()
    let body = {query}
    let request = await fetch(url, getFetchPOSTHeader(body))
    validateRequest(request, [200])
    return await request.json() 
}

export const checkAvailableIdentifierAPI = async (identifier) => {
    let response = await fetch(customersEP.checkAvailableIdentifier(identifier), getFetchHeader())
    return await response.json()
} 