import React, { useState, useEffect } from 'react';
import './styles.scss'
import InputText from '../../component/inputText/inputText';
import DropDownFilter from '../../component/DropDownFilter/DropDownFilter';
import { getSectorsSearchBody } from '../../server/search/searchBody';
import LoadingMessage, { Loading } from '../../component/LoadingMessage/loadingMessage';
import SmallCard from '../../component/smallCard/smallCard';
import deepProps from '../../Utils';
import TinyCard from '../../component/tinyCard/tinyCard';
import Card from '../../component/card';
import Button from '../../component/Button/button';
import { NotificationManager } from 'react-notifications';
import { Trash2 } from 'react-feather';

export const SearchScreen = (props) => {
    const {title, searchFilters, searchRequest, footer, filters, filter, render, card, cardStyle, onAddClick, addText, onSearchClick, onSearchChange, onSearchRequestDone, onDataChange, countParam, onResetClick} = props

    const [data, setData] = useState([])
    const [searchInput, setSearchInput] = useState()
    const [searchType, setSearchType] = useState()
    const [loading, setLoading] = useState(false)
    const [endOfSearch, setEndOfSearch] = useState(false)
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)

    useEffect(()=>{
        executeSearchFetch(page)
    }, [page])

    useEffect(()=>{
        if(endOfSearch && page > 0) NotificationManager.info('Você chegou ao fim da sua pesquisa!')
    }, [endOfSearch])

    const seeMoreHandle = () => {
        if (loading) return
        setPage(page+1)
    }

    const executeSearchFetch = (filter) => {
        setLoading(true)
        searchRequest(page, filter).then(response => {
            if(page === 0) setEndOfSearch(false)

            let newData = deepProps('data')(response).getOrElse([])

            if(page > 0 && ((newData.length === 0) || (newData.length < 20))) 
                setEndOfSearch(true)

            if(page === 0 && newData.length === 0) 
                NotificationManager.warning('Sua busca não retornou resultados','Nenhum resultado encontrado!')

            setData(page > 0 ? [...data,...newData] : newData)
            getDataCount(response)
            onSearchRequestDone && onSearchRequestDone(response)
        }).catch((e)=>{
            console.log(e);
            NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
        }).finally(()=>{
            setLoading(false)
        })
    }

    const getDataCount = (response) => setCount(countParam ? countParam(response) : deepProps('count')(response).getOrElse(0))

    useEffect(()=>{
        onDataChange && onDataChange(data)
    },[data])

    const onSearchClickHandle = (filter) => page === 0 ? executeSearchFetch(filter) : setPage(0)

    useEffect(()=>{
        if(searchType && onSearchChange) onSearchChange(searchInput || '', searchType)
    }, [searchType, searchInput])

    return (
        <div className='search-container'>
            <div className='container'>
                <header>
                    <h1 className='search-title'>{title}</h1>
                    <h2 className='search-subtitle'>{data.length || 0}/{count} total</h2>
                    <nav>
                        {onAddClick && <Button text={addText} style={{marginRight: 8, height: 22}} onClick={onAddClick}/>}
                        {/* {filtering && <Loading style={{width:21, marginRight: 16}}/>} */}
                        <InputText placeholder='Digite sua pesquisa aqui...' text shadow onChange={(field, value) => setSearchInput(value)}/>
                        <DropDownFilter filters={searchFilters} value={searchFilters[0].value} onSelect={(filter)=>setSearchType(filter)}/>
                        {onSearchClick && <Button loading={loading} loadingText='Pesquisando...' text={'Pesquisar'} style={{marginLeft: 8, height: 22, width: 120}} onClick={onSearchClickHandle}/>}
                    </nav>
                </header>
                <main>
                    {(loading && data.length === 0) && <LoadingMessage title='Buscando informações' description='Aguarde um instante...'/>}
                    {data && data.map((item, idx) => (render(item, idx)))}
                    
                </main>
                <footer className='search-footer'>
                    {(!endOfSearch && (data.length > 0 && data.length < count)) && <Button text={'Ver mais'} loading={loading} loadingText={'Carregando...'} textOnly onClick={seeMoreHandle}/>}
                    {footer}
                </footer>
            </div>
            <div className='filter-container' style={{marginTop: 30, padding: 32, width: 200}}>
                {card && <Card style={{height: 100, margin: 0, marginBottom: 16}} containerStyle={cardStyle}>
                    {card}
                </Card>}
                {filters}
                {filter && 
                    <div style={{display: 'flex', marginTop: 16}}>
                        <Button icon={<Trash2/>} invert  onClick={onResetClick && onResetClick}/>
                        <Button text='Filtrar' style={{marginLeft: 4, flex: 1}} onClick={() => onSearchClickHandle(true)}/>
                    </div>
                }
            </div>
        </div>
    )
}