import React, { Component } from 'react'
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom"
import Login from './screen/login/login'
import {isAuthenticated} from './auth'
import Register from './screen/register/register'
import Main from './screen/main/main'
import './screen/login/login.css'
import './App.scss'
import './animations.scss'

const PrivateRoute = ({component:Component,...rest}) => (
	<Route
		{...rest}
		render={props =>
			isAuthenticated() ? (
				<Main clients={this.state.clients} tabItens={this.state.tabItens} user={this.state.user} product={this.state.product}/>,
				console.log("resp "+true)
			) : (
				<Redirect to={{pathname: '/', state:{from:props.location}}} />,
				console.log("resp "+false)
			)
		}
	/>
);

class App extends Component {
	constructor(props) {
		super(props)
		this.state ={
			dataTerminal:[],
			token : '',
			tableCard:[]
		}
		console.log()
	}

	componentWillMount () {
		this.unsubscribe = this.props.store.subscribe(() => {
			let lastState = this.props.store.getState()
			switch(lastState.type) {
				case 'RETRIEVE_SESSION':
					return
				case 'LOGIN_REQUERIED':
					sessionStorage.setItem('lastState', JSON.stringify(lastState))
					return
				default:
					if (lastState.type.indexOf('redux') > -1)
						return
					sessionStorage.setItem('lastState', JSON.stringify(lastState))
				break
			}
		})
		let lastState = sessionStorage.getItem('lastState')
		if (lastState == null)
			return
		try {
			lastState = JSON.parse(lastState)
			this.props.store.dispatch({type:'RETRIEVE_SESSION', lastState})
		}catch(e) {
			console.error(e)
		}
	}

	componentWillUnmount () {
		this.unsubscribe()
	}

	render() {
		return (
			<Router>
				<div className="root">
					<Switch>
							<Route exact path="/" component={() =><Login token={this.state.token} store={this.props.store}/>}  />
							<Route path="/register" component={Register} />
							<Main token ={this.state.token} store={this.props.store} />
							<PrivateRoute path="/main" component={() => <Main token ={this.state.token} clients={this.state.clients} tabItens={this.state.tabItens} user={this.state.user} product={this.state.product}/>}/>
					</Switch>
				</div>
			</Router>
		);
	}
}

export default App;