import React, { useState, useEffect } from 'react';
import RankingCard from './../../component/rankingCard/rankingCard';
import './home.css';
import CustomerApi from '../../api/CustomerApi';
import deepProps, { MONTH_LABELS, WEEK_LABELS, getDcJWT } from '../../Utils'
import HomeApi from '../../api/HomeApi';
import LoadingMessage from '../../component/LoadingMessage/loadingMessage';
import PieCharts from '../../component/pieChart/pieChart';
import SalesKPI from '../../component/KPI/sales';
import CustomerKPI from '../../component/KPI/customer';
import SwitchList from '../../component/SwitchList';
import DropDownFilter from '../../component/DropDownFilter/DropDownFilter';
import { Calendar, Filter } from 'react-feather';
import EmptyMessage from '../../component/emptyMessage/emptyMessage';
import { getDashboardAnalyticsAPI } from '../../server/graphql/analytics';
import { NotificationManager } from 'react-notifications';
import PromotionRankingKPI from '../../component/KPI/promotionRanking';
import { NavLink, useRouteMatch, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import TabContainer from '../../component/Tab/TabContainer';
import { Tabs } from '../cardTabProduct/cardTabProduct';
import ChurnRateKPI from '../../component/KPI/churnRate';

const Home = (props) => {
	
	const TIME_OPTIONS = [{ id: 'WEEK', text: "Semana" }, { id: 'FORTNIGHT', text: "Quinzena" }, { id: 'MONTH', text: "Mês" }, { id: 'QUARTER', text: "Trimestre" }, { id: 'YEAR', text: "Ano" }]
	const VIEW_OPTIONS = [{ id: 'current', text: "Atual" }, { id: 'past', text: "Comparar" }]
	const CHART_OPTIONS = [{ id: 'simple', text: "Simples" }, { id: 'detailed', text: "Detalhado" }]

	const [userType, setUseType] = useState('OPERATOR')
	const [pieChart, setPieChart] = useState()
	const [loadingScreen, setLoadingScreen] = useState(true)
	const [loadingData, setLoadingData] = useState(false)
	const [salesData, setSalesData] = useState([])
	const [rankingPromotion, setRankingPromotion] = useState([])
	const [newCustomers, setNewCustomers] = useState([])
	const [promotionsRanking, setPromotionsRanking] = useState([])
	const [details, setDetails] = useState({id:'simple'})
	const [compare, setCompare] = useState({id:'current'})
	const [filter, setFilter] = useState(TIME_OPTIONS[0])
	const [offset, setOffset] = useState(1)

	const [showChurn, setShowChurn] = useState(false)

	useEffect(() => {
		subscribe()
		setShowChurn(getDcJWT().companyId === 4)
	}, [])

	useEffect(()=>{
		// setOffset((compare.id === 'past') ? '1' : 0)
	},[compare])

	const setCompareHandle = (item) => {
		setCompare(item)
		// setOffset((compare.id === 'past') ? 1 : 0)
	}

	useEffect(()=>{
		getDashboardData()
	}, [filter])

	// Pra quando a pessoa puder definir quantos períodos ela deseja voltar
	// useEffect(()=>{
	// 	getDashboardData()
	// }, [filter, offset])

	const getDashboardData = async () => {
		setLoadingData(true)
		getDashboardAnalyticsAPI(filter.id, offset).then((response) => {
			setSalesData({ identifiedSales: response.data.identifiedSales, unidentifiedSales: response.data.unidentifiedSales })
			setNewCustomers(response.data.newCustomers)
			setRankingPromotion(response.data.topPromotions.current.promotions)
		}).catch((error) => {
			NotificationManager.warning('Não encontramos estatísticas para esse período', 'Ops, algo deu errado!')
		}).finally(() => {
			setLoadingScreen(false)
			setLoadingData(false)
		})
	}

	const subscribe = () => {
		setUseType(props.store.getState().userAccess.userType)

		let unsubscribe = props.store.subscribe(() => {
			let lastState = props.store.getState()
			let data = {}
			switch (lastState.type) {
				case 'HOME_REPORT':
					data = deepProps('homeReport')(lastState).getOrElse([])
					let pending = deepProps('promotions', 'pending')(data).getOrElse(0)
					let published = deepProps('promotions', 'published')(data).getOrElse(0)
					let deprecated = deepProps('promotions', 'deprecated')(data).getOrElse(0)
					let total = pending + published + deprecated

					let pieChartStatus = {
						amount: total, data: [{ name: 'Pendente', value: pending },
						{ name: 'Publicado', value: published },
						{ name: 'Descontinuado', value: deprecated }]
					}

					// let rankingPromotionUsed = deepProps('promotions', 'ranking')(data).getOrElse([])
					// rankingPromotionUsed = rankingPromotionUsed.map((field) => { return { title: field.description, subtitle: fill(field.customersThatUsedSum, '0', 2) + ' pessoas utilizaram', redeem: field.redeemCountSum, sold: field.useCountSum, ...field } })
					setReportDatas({ pieChartStatus })
					setLoadingScreen(false)
					break
				default:
					break
			}
		})
	}

	const setReportDatas = ({ pieChartStatus, rankingPromotionUsed }) => {
		setPieChart(pieChartStatus)
	}

	const loadScreenParams = (token, type) => {
		if (type === 'OPERATOR') {
			setLoadingScreen(true)
			props.store.dispatch(CustomerApi.customerCount(token, (response) => {
				if (isNaN(response) || response < 1)
					response = 'No customer'
				setLoadingScreen(false)
			}, () => { }))
			props.store.dispatch(HomeApi.getHomeReport(token))
		}
	}

	const sortRedeem = (array) => {
		return array.sort(function (a, b) {
			if (a.customersThatUsedSum < b.customersThatUsedSum) {
				return 1;
			}
			if (a.customersThatUsedSum > b.customersThatUsedSum) {
				return -1;
			}
			return 0;
		});
	}

	const getTooltipSubtitle = (data) => {
		if(!data) return
		data = data[0]
		if (!data) return 
		switch (filter.id){
			case 'MONTH':
			case 'FORTNIGHT':
				return 'Dia ' + data.payload.dateReference
			case 'WEEK':
				return WEEK_LABELS[data.payload.dateReference-1]
			case 'QUARTER':
				return data.payload.dateReference + '°' + ' mês'
			case 'YEAR':
				return MONTH_LABELS[data.payload.dateReference-1]
			default:
				return data.payload.dateReference
		}
	}

	const getPromotionRankingTitle = (filter) => {
		return 'Promoções mais usadas ' + (['FORTNIGHT', 'WEEK'].includes(filter.id) ? 'da ' : 'do ') + filter.text.toLowerCase()
	}

	return (<div className={userType === 'CUSTOMER' ? 'home-row' : 'home-column'}>
		{userType === 'OPERATOR' && <div className="page-title-header" style={{padding: 0}}>
			<div className="box-title" style={{justifyContent: 'space-between'}}>
				<h1 className="title-page">Meu Dashboard</h1>
				{/* <div style={{ display: 'flex' }}>
					<SwitchList width={200} style={{ marginRight: 8 }} color={"#2196f3"} data={CHART_OPTIONS} onChange={(item) => setDetails(item)} />
					<SwitchList width={200} style={{ marginRight: 8 }} color={"#2196f3"} data={VIEW_OPTIONS} onChange={(item) => setCompareHandle(item)} />
					<DropDownFilter filters={TIME_OPTIONS} icon={<Calendar />} selected={filter} onSelect={(option) => setFilter(option)} />
				</div> */}
			</div>
			
			{loadingScreen && <LoadingMessage title='Pesquisando...' description='Aguarde, Estamos buscando os dados iniciais!' style={{ left: 55 }} />}
		</div>}

		<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: 4, marginLeft: -12, marginBottom: 16}}>
			<Tabs data={showChurn ? [{path: '/main/home/general', label: 'Geral', index: 'general'}, {path: '/main/home/customers', label: 'Clientes', index: 'customers'}] : [{path: '/main/home/general', label: 'Geral', index: 'general'}]}/>
			<div style={{ display: 'flex' }}>
				<SwitchList width={200} style={{ marginRight: 8 }} color={"#2196f3"} data={CHART_OPTIONS} onChange={(item) => setDetails(item)} />
				<SwitchList width={200} style={{ marginRight: 8 }} color={"#2196f3"} data={VIEW_OPTIONS} onChange={(item) => setCompareHandle(item)} />
				<DropDownFilter filters={TIME_OPTIONS} icon={<Calendar />} selected={filter} onSelect={(option) => setFilter(option)} />
			</div>
		</div> 
 
		{userType === 'OPERATOR' && <div className="operator-column">

				<Switch>
					<Route path='/main/home/general'>
						<>
							<div className="operator-row">
								{/* {(pieChart.data &&!loadingScreen) && <PieCharts data={pieChart.data} title='Promoções' value={pieChart.amount} />} */}
								{!loadingScreen && <SalesKPI data={salesData} loading={loadingData} detailed={details.id === 'detailed'} compare={compare.id === 'past'} filter={filter} tooltipSubtitle={getTooltipSubtitle}/>}
								{!loadingScreen && <CustomerKPI data={newCustomers} loading={loadingData} detailed={details.id === 'detailed'} compare={compare.id === 'past'} filter={filter} tooltipSubtitle={getTooltipSubtitle}/>}
							</div>
							<div className="operator-row">
								{/* {!loadingScreen && <RankingCard title={'Promoções mais utilizadas'} data={promotionsRanking} />} */}
								{/* {(rankingPromotion && !loadingScreen) && <RankingCard title={getPromotionRankingTitle(filter)} data={rankingPromotion} loading={loadingData} filter={filter} columns={[{label: 'ID', value: 'promotion_id'}, {label:'Descrição',value:'description'}, {label:'Quantidade',value:'quantity'}, {label:'measurement_unit',value:'Unidade de medida'}, {label:'',value:''}, {label:'',value:''}]}/>} */}
								{(rankingPromotion && !loadingScreen) && <PromotionRankingKPI title={getPromotionRankingTitle(filter)} data={rankingPromotion} loading={loadingData} filter={filter}/>}
							</div>
						</>
					</Route>

					<Route exact path='/main/home/customers'>
						{showChurn && !loadingScreen && <>
							{/* <div className="box-title" style={{paddingBottom:32, paddingTop: 32}}>
								<h1 className="title-page">Esse mês</h1>
							</div> */}
							<div className="operator-row">
								<ChurnRateKPI data={newCustomers} loading={loadingData} store={props.store} detailed={details.id === 'detailed'} compare={compare.id === 'past'} filter={filter} tooltipSubtitle={getTooltipSubtitle}/>
							</div>
						</>}
					</Route>
				</Switch>

		</div>
		}
	</div>
	)
}

export default Home

{/* <EmptyMessage description='Não foi possível estabelecer conexão' title='Ops, algo deu errado!' style={{marginLeft: 55}}/> */ }