import React, { useState, useEffect } from 'react';
import {XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar,} from 'recharts';

export default function BaseBarChart (props){

    const {hideX, hideY, hideGrid, data, customTooltip, bars} = props
    const [colors, setColors] = useState(props.colors || ["#2196f3", "#ff68a1","#ffa600","#97e000"])
    
    return (
      <div style={{height: 200, marginRight: hideGrid ? 0 : 32}}>
        <ResponsiveContainer>
          <BarChart data={data} margin={{top: 0}}>
            {!hideGrid && <CartesianGrid strokeDasharray="3 3" />}
            {!hideX && <XAxis dataKey="name" />}
            {!hideY && <YAxis />}
            <Tooltip content={customTooltip}/>
            {bars && bars.map((line, idx)=><Bar type="monotone" dataKey={line.value} fill={colors[idx]} stroke={colors[idx]} key={idx}/>)}
          </BarChart> 
        </ResponsiveContainer>
      </div>
    )
}
