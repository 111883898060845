import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {createStore, applyMiddleware} from 'redux'
import {reducer} from './reducers/reducers'
import thunkMiddleware from 'redux-thunk'
import RegisterSW from './reg-service-worker'
import 'react-dates/initialize'

const store = createStore(reducer, applyMiddleware(thunkMiddleware))
const VERSION = "60"

ReactDOM.render(<App store={store}/>, document.getElementById('root'));
RegisterSW()