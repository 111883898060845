import { NotificationManager } from 'react-notifications';

export const ERRORS = {
    INVALID_IDENTIFIER: 'INVALID_IDENTIFIER',
    IDENTIFIER_ALREADY_EXIST:'IDENTIFIER_ALREADY_EXIST',
    INVALID_DATE: 'INVALID_DATE',
    INVALID_ITEM: 'INVALID_ITEM',
    INVALID_STAGE: 'INVALID_STAGE',
    EMPTY_USER: 'EMPTY_USER',
    EMPTY_PASSWORD: 'EMPTY_PASSWORD',
    WRONG_LOGIN: 'WRONG_LOGIN',
    UNKNOW_USER: 'UNKNOW_USER',
    LIST_MAX: 'LIST_MAX',
    ITEM_QUANTITY_BELLOW_MIN: 'ITEM_QUANTITY_BELLOW_MIN'
}

const catchErrors = e => {
    if(typeof(e)==='object'){
        if(e.warning) return NotificationManager.warning(e.warning, 'Atenção')
        if(e.info) return NotificationManager.warning(e.info)
        if(e.error) return NotificationManager.warning(e.error, 'Ops, algo deu errado')
    }
    switch (e) {
        case ERRORS.INVALID_IDENTIFIER:
            return NotificationManager.warning('Informe um CPF válido!', 'Atenção')
        case ERRORS.INVALID_DATE:
            return NotificationManager.warning('Informe uma data válida!', 'Atenção')
        case ERRORS.INVALID_ITEM:
            return NotificationManager.warning('Informe um item válido!', 'Atenção')
        case ERRORS.INVALID_STAGE:
            return NotificationManager.warning('Informe um nível com valores maiores aos já aplicados anteriormente!', 'Atenção')
        case ERRORS.EMPTY_USER:
            return NotificationManager.warning('Informe um usuário!', 'Atenção')
        case ERRORS.EMPTY_PASSWORD:
            return NotificationManager.warning('Informe uma senha!', 'Atenção')
        case ERRORS.WRONG_LOGIN:
            return NotificationManager.error('verifique os dados informados','Usuário ou senha inválido')
        case ERRORS.UNKNOW_USER:
            return NotificationManager.error('verifique os dados informados','Usuário não encontrado')
        case ERRORS.ITEM_QUANTITY_BELLOW_MIN:
            return NotificationManager.warning('A quantidade não pode diminuir em campanhas já publicadas', 'Atenção')
        case ERRORS.IDENTIFIER_ALREADY_EXIST:
            return NotificationManager.warning('CPF já cadatsrado', 'Atenção')
        case ERRORS.LIST_MAX:
            return NotificationManager.warning('Quantidade máxima da lista atingida', 'Atenção')
        default:
            return NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
    }
}

export default catchErrors