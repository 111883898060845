import React, { Component } from 'react'
import Button from '../../Button/button'
import refresh from '../../../img/refresh.svg'
import './adress.css';
import deepProps from '../../../Utils';
import AddressesApi from '../../../api/AddressesApi';
import { AlertDialog, AskDialog } from '../../alertDialog/alertDialog';
import SelectableItem from '../../selectableItem/selectableItem';
import { Plus } from 'react-feather';

export default class Address extends Component {

	constructor(props){
		super(props)
		this.state = {
			userDetails: '',
			list:[],
			id:0,
			addressState:'UN_KNOW',
			addresses:[/*{title: 'Rua Gisele', data:['204', 'Pq. dos Camargos', 'Barueri', 'SP', '06436-120']}*/],
			zipCode: '',
			street:'',
			district:'',
			city:'',
			number:0,
			complement: '',
			lastSearch:'',
			found:false,
			refresh:false,
			canEdit: false,
			user:{}
		}
	}

	componentDidMount () {
		let userData = this.props.store.getState().userAccess
        let canEdit = this.props.store.getState().userAccess.userType === 'CUSTOMER'
		this.setState({userDetails: this.props.store.getState().userAccess.userName})
		
		let token = deepProps('token')(this.props.store.getState()).getOrElse('')

		let list = deepProps('addresses')(this.props).getOrElse([])
		
		let user = deepProps('user')(this.props).getOrElse({})
		let addresses = this.mapAddressList(list)
		this.setState({addresses, list, user, canEdit, token: token, userData})
	}
	
	mapAddressList(list){
		return list.map((endereco) => {
			let obj = {
				title : deepProps('streetType')(endereco).getOrElse('') + ' ' + deepProps('street')(endereco).getOrElse(''),
				data: [
					deepProps('number')(endereco).getOrElse('') + ' ' + deepProps('complement')(endereco).getOrElse(''),
					deepProps('district')(endereco).getOrElse(''), deepProps('city')(endereco).getOrElse(''),
					deepProps('state')(endereco).getOrElse(''), deepProps('zipCode')(endereco).getOrElse('')
				], id: deepProps('id')(endereco).getOrElse(0)
			}
			return obj
		})
	}

	selectAddresses (addressId) {
		let result = this.state.list.filter((a) => a.id === addressId)
		if (result.length === 0)
			return
		let address = result[0]
		let addressState = deepProps('acronymState')(address).getOrElse('UN_KNOW')
		let zipCode = deepProps('zipCode')(address).getOrElse('')
		let district = deepProps('district')(address).getOrElse('')
		let city = deepProps('city')(address).getOrElse('')
		let number = deepProps('number')(address).getOrElse('')
		let complement = deepProps('complement')(address).getOrElse('')
		let street = deepProps('street')(address).getOrElse('')
		this.setState({addressState, zipCode, district, city, number, complement, street, id:addressId})
	}

	edit() {
		return this.state.found
	}

	findAddress() {
		let zipCode = this.state.zipCode
		if (zipCode === undefined || zipCode.length < 2 || this.state.lastSearch === zipCode)
			return
		zipCode = zipCode.replace('-', '')
		AddressesApi.find(zipCode, (address) => {
			let found = address !== undefined && deepProps('error')(address).getOrElse(true)
			let addressState = deepProps('uf')(address).getOrElse('UN_KNOW')
			let zipCode = deepProps('cep')(address).getOrElse('').replace('-', '')
			let district = deepProps('bairro')(address).getOrElse('')
			let city = deepProps('localidade')(address).getOrElse('')
			let number = 0
			let complement = ''
			let street = deepProps('logradouro')(address).getOrElse('')
			this.setState({addressState, zipCode, district, city, number, complement, street, id:0, found, lastSearch:zipCode})
		}, (error) => {
			AlertDialog('Ops, algo deu errado', error)
			this.setState({refresh:false, found:false})
		})
	}

	addAddresses () {
		let identifier = deepProps('identifierDefault')(this.state.user).get()
		if (identifier === null) {
			alert('User is null')
			return
		}

		let array = this.state.street.split(' ')
		let streetType = ''
		let street = ''
		if (array.length > 1) {
			streetType = array[0]
			for (let i = 1; i < array.length; i++)
				street += array[i]
		}

		let obj = {
			identifier:{
				identifier: this.state.userDetails.identifier,
				type: this.state.userDetails.type
			},
			address: {
				zipCode: this.state.zipCode, 
				streetType,
				street,
				complement: this.state.complement,
				district : this.state.district,
				city: this.state.city,
				state: this.state.addressState,
				number: this.state.number,
				country: {
					name: 'Brazil',
					initials: 'BRA'
				}
			}
		}

		AddressesApi.saveCustomersAddress(this.state.token, obj, (response)=> {
			let newAddress = this.mapAddressList([obj.address])
			this.setState({addresses: [...this.state.addresses, ...newAddress]})
			let list = document.getElementById("address-list");
            list.scrollTop = list.scrollHeight;
		}, (error) => {
			AlertDialog('Ops, algo deu errado', error)
		})
	}

	confirmDelete(token, customerId, id){
        AskDialog('Este endereço será excluído!', 'deseja realmente excluir o endereço?', ()=>this.deleteAddress(token, customerId, id))
    }

    deleteAddress(token, identifier, id) {
		AddressesApi.deleteAddress(token, identifier, id, ()=>{
			this.mapAddressList(this.state.addresses)
			AlertDialog('Sucesso', 'Endereço excluído com sucesso')
		}, ()=>{
			AlertDialog('Erro', 'Erro ao excluir endereço')
		})
    }

  	render() {
        const canEdit = this.state.canEdit
        return (
    	    <form className="personalDataAddress">
			    <div className="form-personalDataAddress">
                    {canEdit && (
                         <div className="personalDataAddress-content">
				            <div className="personalDataAddress-row">
								<label className='CEP'><img className='refresh-icon' src={refresh} onClick={() => this.setState({refresh:true})} alt='refresh-icon' /> CEP
									<input disabled={this.edit() && !this.state.refresh} type="text" placeholder="Digite o CEP aqui..."
											value={this.state.zipCode} onChange={(e)=>this.setState({zipCode:e.target.value})}
											onBlur={() => this.findAddress()}/> </label>
								<label className="form-name"> Rua <input disabled={this.edit()} type="text" placeholder="Digite o endereço aqui..."  value={this.state.street} onChange={(e)=>this.setState({street:e.target.value})}/> </label>
				                <label className="form-number"> Número <input type="text" placeholder="Digite o número aqui..." value={this.state.number} onChange={(e)=>this.setState({number:e.target.value})}/> </label>
				                <label className="form-number"> Complemento <input type="text" placeholder="Digite o componente aqui..." value={this.state.complement} onChange={(e)=>this.setState({complement:e.target.value})}/> </label></div>
							<div className="personalDataAddress-row">
				             	<label className="form-district"> Bairro <input disabled={this.edit()} type="text" placeholder="Digite o bairro aqui..." value={this.state.district} onChange={(e)=>this.setState({district:e.target.value})}/> </label>								
								<label style={{width: 285}}> Cidade <input disabled={this.edit()} type="text" placeholder="Digite a cidade aqui..." value={this.state.city} onChange={(e)=>this.setState({city:e.target.value})}/> </label>
				                <label> Estado
					                <select value ={this.state.addressState} onChange={(e)=>this.setState({addressState:e.target.value})} disabled={this.edit()}>
						                <option value="UN_KNOW">Selecione</option>
						                <option value="AC">Acre</option>
						                <option value="AL">Alagoas</option>
						                <option value="AP">Amapá</option>
						                <option value="AM">Amazonas</option>
						                <option value="BA">Bahia</option>
						                <option value="CE">Ceará</option>
						                <option value="DF">Distrito Federal</option>
						                <option value="ES">Espírito Santo</option>
						                <option value="GO">Goiás</option>
						                <option value="MA">Maranhão</option>
						                <option value="MT">Mato Grosso</option>
						                <option value="MS">Mato Grosso do Sul</option>
						                <option value="MG">Minas Gerais</option>
						                <option value="PA">Pará</option>
						                <option value="PB">Paraíba</option>
						                <option value="PR">Paraná</option>
						                <option value="PE">Pernambuco</option>
						                <option value="PI">Piauí</option>
						                <option value="RJ">Rio de Janeiro</option>
						                <option value="RN">Rio Grande do Norte</option>
						                <option value="RS">Rio Grande do Sul</option>
						                <option value="RO">Rondônia</option>
						                <option value="RR">Roraima</option>
						                <option value="SC">Santa Catarina</option>
						                <option value="SP">São Paulo</option>
						                <option value="SE">Sergipe</option>
						                <option value="TO">Tocantins</option>
					                 </select>
				                 </label>
				            </div>
						 </div>
                    )}
                </div>
				<ul className="address-content" id="address-list" style={{scrollBehavior: "smooth"}}>
					{ 
						this.state.addresses.map((address, index) =>
							{console.log(address)
							return <SelectableItem key={index} title={address.title} subtitle={address.data.join(' / ')}/>}
						)
					}
				</ul>

                {canEdit &&
                 <div className='btnSave-address'>
				     <Button text='Adicionar' icon={<Plus/>} onClick={() => this.addAddresses()} style={{maxWidth: 95, paddingLeft: 16, position: 'fixed', right: 32, bottom: 16}}/>
			     </div>
                }
    	    </form>
        );
    }
}