export const getLinkSectionBody = ({description, linkTo, imageFile, sequence}) => {
    let body = new FormData()
    if (!sequence)
        sequence = 1
    body.append('sequence', sequence)
    description && body.append('description', description)
    linkTo && body.append('linkTo', linkTo)
    // status && body.append('status', status)
    if (imageFile && (imageFile instanceof File))
        body.append('image', imageFile)
    return body
}


export const getBannerBody = ({imageFile, sequence}) => {
    let body = new FormData()
    sequence && body.append('sequence', sequence)
    imageFile && body.append('image', imageFile)
    return body
}
