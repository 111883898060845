import React, { useState, useEffect } from 'react';
import ReactExport from "react-export-excel";
import { Download } from 'react-feather';
import { WEEK_LABELS, MONTH_LABELS, downloadResponse } from '../../../Utils';

import './style.scss'
import { Loading } from '../../LoadingMessage/loadingMessage';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



const ExportKPIData = (props) => {
	const [loading, setLoading] = useState(false)
    const {fileName, name, data, columns, filter, request} = props

    useEffect(()=>{
    },[data])

    const getDateReferenceLabel = (dateReference) => {
        switch (dateReference){
			case 'MONTH':
			case 'FORTNIGHT':
				return 'Dia do mês'
			case 'WEEK':
				return 'Dia da semana'
			case 'QUARTER':
				return 'Mês do trimestre'
			case 'YEAR':
				return 'Mês'
			default:
				return dateReference
		}
    }

    const getDateReferenceValue = (value) => {
        switch (filter.id){
			case 'MONTH':
			case 'FORTNIGHT':
				return 'Dia ' + value.dateReference
			case 'WEEK':
				return WEEK_LABELS[value.dateReference-1]
			case 'QUARTER':
				return value.dateReference + '°' + ' mês'
			case 'YEAR':
				return MONTH_LABELS[value.dateReference-1]
			default:
				return value.dateReference
		}
	}
	
	const onClickHandle = () => {
		setLoading(true)
		downloadResponse(fileName, request, 'csv').then(()=>{
			setLoading(false)
		})
	}

	return !data ? (loading ? <Loading style={{width: 16}}/> : <Download size={16} onClick={onClickHandle} className='export-card-data-button'/>) : 
		(<ExcelFile element={<Download size={16} className='export-card-data-button'/>} filename={fileName}>
			<ExcelSheet data={data} name={name}>
				<ExcelColumn label={getDateReferenceLabel(filter.id)} value={(col) => getDateReferenceValue(col)}/>
				{columns && columns.map((column,idx) => (
					<ExcelColumn key={idx} label={column.label} value={column.value}/>
				))}
			</ExcelSheet>
		</ExcelFile>)
}

export default ExportKPIData