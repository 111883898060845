import React, { Component } from 'react'
import './header.css'
import deepProps from '../../Utils'
import jwt_decode from 'jwt-decode'
import imgPerfil from '../../img/perfil_2.svg'
import UserDropBox from '../../component/userDropBox/userDropBox';

class Header extends Component {

	constructor(props) {
		super(props)
		this.state = {
			user: {},
			company_image: ''
		}
	}

	componentWillMount () {
		let token = deepProps('token')(this.props.store.getState()).get()
		if (token === undefined || token === '')
			return
		token = jwt_decode(token)
		let user = JSON.parse(deepProps('sub')(token).getOrElse('{}'))
		this.setState({user})
		let {companyId} = user
		if (companyId) {
			let company_image = `https://s3.us-east-2.amazonaws.com/crm-staging.p5/midias_image/company_${companyId}/webapp_resources/header/company_logo.png`
			this.setState({company_image})
		}
		console.log(user)
	}

  render() {
	let {company_image} = this.state 
    return (
    	<header className="header">
			<div className="title-header"></div>
			<span style={{padding: 0}}> <img style={{width:50}} src={company_image} alt=""></img> </span>
			<div className="option-header">
				{/*<span className="span-header"> <img className="icon-header" src={require('../../img/wall-clock.svg')} alt=""></img> </span>
				<span className="span-header"> <img className="icon-header" src={require('../../img/notification.svg')} alt=""></img> </span>*/}
				<UserDropBox store={this.props.store} img={imgPerfil} user={deepProps('name')(this.state.user).getOrElse('Unknow')} identifier={deepProps('userName','identifier')(this.state.user).getOrElse('')}/>
			</div>
    	</header>
    );
  }
}

export default Header;