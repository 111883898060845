import React, { useEffect, useState } from 'react';
import '../style.scss';
import Card from '../../../component/card';
import { NotificationManager } from 'react-notifications';
import EmptyMessage from '../../../component/emptyMessage/emptyMessage';
import LoadingMessage from '../../../component/LoadingMessage/loadingMessage';
import { getHighlightItemsListAPI, reorderHighlightedItemAPI } from '../../../server/highlight/highlightedAPI';
import { getMeasurementLabel } from '../../../Utils';
import DraggableList from '../../../component/draggableList';
import HighlightedCoupon from '../../../component/highlightedCoupon'

const HighlightedPromotionsCard = props => {

    const [highlights, setHighlights] = useState([])
    const [imutableHighlights, setImutableHighlights] = useState([])
    const [reordering, setReordering] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reorderedItems, setReorderedItems] = useState([])

    useEffect(() => {
        searchHighlightedPromotions()
    }, [])

    const searchHighlightedPromotions = () => {
        if (loading) return
        setLoading(true)
        getHighlightItemsListAPI().then(data => {
            let mappedHighlights = mapHighlights(data.data)
            setHighlights(mappedHighlights)
            setImutableHighlights(mappedHighlights)
        }).catch(error => {
            NotificationManager.warning('Não foi possível encontrar as promoções em destaque', 'Ops, algo deu errado!')
        }).finally(() => {
            setLoading(false)
        })
    }

    const mapHighlights = (highlights) => {
        return highlights.map((item, position) => {
            return {
                position,
                id: item.itemPromotion.id,
                title: item.itemPromotion.description,
                subtitle: item.itemPromotion.quantity + ' ' + getMeasurementLabel(item.itemPromotion.measurementUnit),
                img: item.itemPromotion.resourceImage,
                alt: 'imagem representando ' + item.itemPromotion.description,
            }
        })
    }

    const onReorderItemHandle = (item, currentIndex, pastIndex) => {
        if(reordering) return NotificationManager.info('Isso pode levar alguns segundos', 'Reordenando itens!')
        if(currentIndex === pastIndex) return 

        setReordering(true)

        let reorderList = highlights.filter(ritem => ritem.id !== item.id)
        reorderList.splice(currentIndex, 0, item)

        reorderHighlightedItemAPI(item.id, currentIndex).then(()=>{
            NotificationManager.success(`A posição do item ${item.title} foi alterada com sucesso!`, 'Sucesso!')
            setReorderedItems(reorderList)
        }).catch((e)=>{
            console.log(e)
            NotificationManager.error(`Não foi possível alterar a posição do item ${item.title}`, 'Ops, algo deu errado!')
        }).finally(()=>{
            setReordering(false)
        })

    }

    useEffect(()=>{
        if(reorderedItems) props.onChange(reorderedItems)
    },[reorderedItems])

    useEffect(()=>{
        if(highlights) props.onChange(highlights)
    },[highlights])

    return (
        <Card title='Promoções em destaque' subtitle={'Clique e arraste para reordenar'}
            containerStyle={{ marginTop: 8, maxWidth: 'calc(100vw/1.5)', minHeight: 160}} style={{marginTop: 16}} className='app-manager-card'>
            {(!loading && highlights.length) === 0 && <EmptyMessage title='Nenhum promovido em destaque'
                description='Promoções publicadas podem ter seus promovidos em destaque'
                icon='empty-box' />}
            {loading && <LoadingMessage title='Buscando itens em destaque' description={'Aguarde alguns instantes...'} />}
            <DraggableList  data={highlights} dragItem={(item) => <HighlightedCoupon {...item}/>} contentContainerStyle={{marginRight: 8, paddingTop: 8}} onItemChange={onReorderItemHandle}/>
        </Card>
    )
}

export default HighlightedPromotionsCard;