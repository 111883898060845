// export const ENDPOINT = 'api.p5mkt.com.br/'
export const HOMOLOG = 'https://homolog.p5mkt.com.br/'
export const ENDPOINT = `api.p5mkt.com.br/`

export const INITIAL_API = 'https://' + ENDPOINT
export const INITIAL_WS = 'wss://' + ENDPOINT

const PROMOSHARE = INITIAL_API + 'promoshare/'
const SEGMENTATION = INITIAL_API + 'segmentation/'
export const PROMOTION = PROMOSHARE + 'promotions/'
const auth = INITIAL_API + 'auth/'
const customers = INITIAL_API + 'customers/'
const elasticGateway = INITIAL_API + 'elastic-search/'
export const STORAGE_EP = INITIAL_API + 'storage/'
const register = customers
const profile = `https://${ENDPOINT}auth/profile/`
const operator = `https://${ENDPOINT}auth/operator/`
const liberation = `https://${ENDPOINT}auth/liberations/`
const HISTORIC = INITIAL_API + 'statistics/historic/'
const REPORTS = INITIAL_API + 'statistics/report/'
export const RECOMMENDER = INITIAL_API + 'recommender/'
const statistics = INITIAL_API + 'statistics/'
const NOTIFICATIONS = INITIAL_API + 'notifications/'
const ANALYTICS = INITIAL_API + 'analytics/graphql'


const APP_HOME_SCREEN = INITIAL_API + 'storage/home/'
// const websocket = INITIAL_API + 'ws/link-user-promotion'

export const definition = {
  ip: '18.232.189.55',
  host: '8080',
  promotionHost: '8080',
  url(promotion) { return 'http://' + this.ip + ':' + ((promotion) ? this.promotionHost : this.host) + '/'},
}

export const storesEP = {
    findStores(store) {return customers + 'stores?store=' + store + '&size=16'},
    address(store) {return customers + (store === undefined ? 'stores/address' : 'stores/address?store=' + store)},
    saveStore() {return customers + 'stores'},
    findStoresNoQuery() {return customers + 'stores'},
    disableStore(storeId) {return customers + 'stores/' + storeId + '/disable'},
    enableStore(storeId) {return customers + 'stores/' + storeId + '/enable'},
    deleteStore(storeId) {return customers + 'stores/' + storeId}
}

export const elasticGatewayEP = {
    findItems() {return elasticGateway + 'products/my_company/_search'},
    findItemById (elementId) {return elasticGateway + 'products/my_company/' + elementId},
    saveElasticItem(elementId) {return elasticGateway + 'products/my_company/' + elementId},
    saveElasticSector(elementId) {return elasticGateway + 'sectors/my_company/' + elementId},
    saveElasticProvider(elementId) {return elasticGateway + 'supliers/my_company/' + elementId},
    customerCount() {return elasticGateway + 'customers/my_company/_count'},
    findCustomer() {return elasticGateway + 'customers/my_company/_search'},
    findProductByEAN(eanCode) {return elasticGateway + 'products/my_company/' + eanCode}
}

export const storageUploadEP = {
    imageProductsUpload() {return STORAGE_EP + 'product_pictures/upload'}
}

export const facebookEP = {
   facebookCheck(id, app) {return definition.url() + 'customers/customers/facebook?facebookId=' + id + '&app=' + app},
}

export const customersEP = {
    customers(complete) {return customers + ((complete) ?  'customers?queryType=FULL' : 'customers')},
    findAddresses(identifier, type) {return customers + 'customers/address?customerIdentifier=' + identifier + '&type=' + type},
    find(identifier, type) {return `${customers}customers?identifier=${identifier}&type=${type}&queryType=FULL&size=16`},
    getCustomerById (customerId) {return customers + 'customers/' + customerId},
    updateCustomer (customerId) {return customers + 'customers/' + customerId},
	addAddress(){return customers + 'customers/address'},
	addEletronicAddress(){return customers + 'customers/eletronicaddress'},
	findContacts(identifier) {return customers + 'customers/' + identifier + '/contact/' },
    getScore(userId) {return  `${SEGMENTATION}customer/${userId}/rating`},
    getRecommendedProducts(userId){return  `${SEGMENTATION}customer/${userId}/products/recommendation?page=0&size=10`},
	disablePhone(phoneId) {return customers + 'customers/telephone/' + phoneId},
	disableAddress(identifier, addressId) {return customers + 'customers/' + identifier + '/address/' + addressId},
    disableEletronicAddress(eletronicId) {return customers + 'customers/eletronicaddress/' + eletronicId},
    checkAvailableIdentifier(identifier) { return `${auth}customer?cpf=${identifier}&type=ABSTRACT`},
    getCustomers(page) {return `${SEGMENTATION}customers/_search?page=${page}&size=20`},
    lastCreatedCustomerReports(page = 0, size = 16) {return `${SEGMENTATION}customers/s3/csv?page=${page}&size=${size}`},
    downloadCustomerReportById(id) {return `${SEGMENTATION}customers/s3/${id}/csv`},
    exportCustomerReport(){return `${SEGMENTATION}customers/_export`}
}

export const LoginEP ={
    login() {return auth + 'auth'},
    loginPermissions() {return auth + 'auth/permissions'}
}

export const promotionsEP ={
    getItems(promotionId) {return `${PROMOTION}fast/${promotionId}/items`},
    setup(promotionId, type) {return `${PROMOTION}fast/${promotionId}/setup/${type}`},
    fastPromotion(identifier) {return definition.url(true) + 'PROMOSHARE/promotion-items?type=AVAILABLE&company=3'},
    findPromotions(page) { return PROMOSHARE + 'promotions/fast?page=' + page + '&size=16&sort=id,desc'},
    findPromotion(promotionId) {return PROMOSHARE + 'promotions/fast/' + promotionId},
    findPromotionWithFilter(filter, page) { return PROMOSHARE + 'promotions/fast?page=' + page + '&size=16&' + filter},
    findPromotionsPublisheds(page) { return PROMOSHARE + 'promotions/fast?page=' + page + '&size=16&status=PUBLISHED'},
    findPromotionsDeprecateds(page) { return PROMOSHARE + 'promotions/fast?page=' + page + '&size=16&status=DEPRECATED'},
    findPromotionsPendings(page) { return PROMOSHARE + 'promotions/fast?page=' + page + '&size=16&status=PENDING'},
    findPromotionEanCode(page, eanCode) { return PROMOSHARE + 'promotions/fast?page=' + page + '&size=16&ean_code=' + eanCode},
    addPromotion() {return PROMOSHARE + 'promotions/fast'},
    updatePromotion (promotionId) {return PROMOSHARE + 'promotions/fast/' + promotionId},
    addRestrictDay (promotionId) {return PROMOSHARE + 'promotions/fast/' + promotionId + '/restricted-days'},
    segmentationByCustomersIdentifier (promotionId, page) {return PROMOSHARE + 'promotions/fast/' + promotionId + '/target-identifiers?page='+page +'&size=30'},
    removeRestrictedDay(dayId) {return PROMOSHARE + 'restricted-days/' + dayId},
    addRestrictHour(dayId) {return PROMOSHARE + 'restricted-days/' + dayId + '/restricted-hours'},
    updateRestrictedHour(hourId) {return PROMOSHARE + 'restricted-hours/' + hourId},
    getRestrictedDays(promotionId) {return PROMOSHARE + 'promotions/fast/' + promotionId + '/restricted-days'},
    removeRestrictHour(itemId) {return PROMOSHARE + 'restricted-hours/' + itemId},
    publish(promotionId) {return PROMOSHARE + 'promotions/fast/' + promotionId + '/publish'},
    deprecated(promotionId) {return PROMOSHARE + 'promotions/fast/' + promotionId + '/deprecate'},
    findItemsPromotion(promotionId) {return PROMOSHARE + 'promotions/fast/' + promotionId + '/items'},
    addItem(promotionId) {return PROMOSHARE + 'promotions/fast/' + promotionId + '/items'},
    updateItem(itemId) {return PROMOSHARE + 'promotion-items/' + itemId},
    removeItem(itemId) {return PROMOSHARE + 'promotion-items/' + itemId},
    removeItems(promotionId) {return PROMOSHARE + 'promotions/fast/' + promotionId + '/items'},
    findPromotionStore(promotionId) {return PROMOSHARE + 'promotions/fast/' + promotionId + '/stores'},
    updatePromotionsStores(promotionId) {return PROMOSHARE + 'promotions/fast/' + promotionId + '/stores'},
	report(){return PROMOSHARE + 'report'},
    promotionReport(promotionId){return PROMOSHARE + 'promotions/fast/' + promotionId + '/report'},
    promotionReportItemsPromotion(promotionId, query){return statistics + 'historic/promotions-items/' + promotionId + '?' + query},
    promotionItemAddAssocieted(id, type){return `${PROMOSHARE}promotion-items/${id}/${type}`},
    promotionItemRemoveAssociated(itemId, removeId, type){return `${PROMOSHARE}promotion-items/${itemId}/${type}/${removeId}`},
}

export const recommenderEP = {
    setupItemRecommender(itemID) {return PROMOTION + 'fast/' + itemID + '/setup-link-user'}
}

export const registerEP = {
	addCustomer() {return register + 'customers'}
}

export const ProfileEP = {
	getProfile() {return profile},
	createProfile() {return profile},
	insertPermission(operatorId) {return profile + operatorId + '/permissions'},
	updateProfile(profileId) {return profile + profileId},
	deleteProfile(profileId) {return profile + profileId},
}

export const OperatorEP = {
	createOperator() {return operator},
    listOperators() {return operator},
	operatorID(operatorId) {return operator + operatorId},
	updateOperatorsPassword(operatorId) {return operator + operatorId + '/new/password'},
	operatorProfiles(operatorId) {return operator + operatorId + '/profile'},
}

export const LiberationEP ={
	getLiberations(){return liberation},
	getProfilesLiberations(profileId) {return profile + profileId + '/liberations'},
	deletePermission(profileId) {return profile + profileId + '/permissions'}
}


export const ReportsEP ={
	getHistoricPromotionItens(promotionID, dateStart, dateEnd, store = -1, format='json'){
        return HISTORIC + `promotions-items/${promotionID}?ini=${dateStart}&end=${dateEnd}&store=${store}&format=${format}`
    },
	getPromotionReportByPromotion(dateStart, dateEnd, store=-1, promotion_id=-1, page=0, size=16, format='json') {
        return REPORTS + `sale-items?ini=${dateStart}&end=${dateEnd}&store=${store}&promotion_id=${promotion_id}&page=${page}&size=${size}&format=${format}`
    },
    getSaleItemsReport(dateStart, dateEnd){
        return `${REPORTS}sale-items?ini=${dateStart}&end=${dateEnd}&store=-1&page=0&size=0&format=csv`
    }
}

export const HighlightEP = {
    getHighlightItemsList(){return `${PROMOSHARE}highlighted-items/main`},
    highlightPromotionItem(promotionItemID){return `${PROMOSHARE}promotion-items/${promotionItemID}/highlight`},
    unhighlightPromotionItem(promotionItemID){return `${PROMOSHARE}promotion-items/${promotionItemID}/unhighlight`},
    reorderHighlightedItem(itemID){return `${PROMOSHARE}highlighted-items/main/${itemID}/reorder`},
    syncHighlightList(){return `${PROMOSHARE}highlighted-items/main/sync`}
}

export const NotificationEP = {
    sendAll(){return `${NOTIFICATIONS}api/notification/send/all`},
}


export const AppHomeScreenEP = {
    getLinkSection(){return `${APP_HOME_SCREEN}link?all=true`},
    updateLinkSection(id){return `${APP_HOME_SCREEN}link/${id}`},
    updateBanner(id){return `${APP_HOME_SCREEN}banner/${id}`},
    getBanners(){return `${APP_HOME_SCREEN}banner?all=true`},
    addBanner(){return `${APP_HOME_SCREEN}banner`},
    publishBanner(id){return `${APP_HOME_SCREEN}banner/${id}/publish`},
    publishLinkSection(id){return `${APP_HOME_SCREEN}link/${id}/publish`},
    deprecatedLinkSection(id){return `${APP_HOME_SCREEN}link/${id}/deprecated`},
    deprecatedBanner(id){return `${APP_HOME_SCREEN}banner/${id}/deprecated`}

}

// export const GraphQLEP = {
//     getGraphQLServer() { return ANALYTICS}
// }

export const GraphQLEP = {
    getGraphQLServer() { return `${ANALYTICS}`}
}


export const PASSWORD_EP = {
    generate(){return `${customers}recover/password`}
}

export const USER_VERIFICATION_EP = {
    verify(){return `${customers}verify`}
}