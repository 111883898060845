import React, { Component } from 'react';
import AsideProfileCard from '../../component/AsideProfileCard/AsideProfileCard';
import AsideListCard from '../../component/AsideListCard/AsideListCard';
import AsideButtonCard from '../../component/AsideButtonCard/AsideButtonCard';
import './asideProfile.css';
import OperatorApi from '../../api/OperatorApi';
import { AlertDialog, AskDialog } from '../../component/alertDialog/alertDialog';
import deepProps from '../../Utils';
import {Redirect} from "react-router-dom";

export default class AsideProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
	}
	
	componentDidMount(){
        this.setState({profile: this.props.data})
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.data !== this.state.profile)
            this.setState({profile: nextProps.data})
    }
    
    onDeleteClick(){
        if(this.state.deletingProfile){return}
        AskDialog('Deseja realmente excluir este perfil?','Todos os operadores com este perfil perderão as permissões associadas, deseja continuar?',async ()=>{
        try{
            this.setState({deletingProfile:true})
            let profileId = deepProps('id')(this.props.data).getOrElse(-1)
            await OperatorApi.deleteProfile(localStorage.getItem('token'), profileId)
            AlertDialog("Sucesso!", "Perfil removido com sucesso")
            this.props.store.dispatch({type:'PROFILE_DETAIL', profile:{name:'Novo perfil'}})
        }catch{
            AlertDialog("Ops, algo deu errado!", "Não foi possível excluir o perfil, tente novamente mais tarde.")
            this.loadProfileIfExists()
        }finally{
            this.setState({deletingProfile:false})
        }		
        },()=>{})
	}

    render() {
        if (!this.props.write && !this.props.read) {
			return <Redirect to='/main/home/general'/>
		}
        return (
            <aside className="asideProfile">
               {this.state.profile && <AsideProfileCard title={this.state.profile.name} read={this.state.read} write={this.state.write}/>}
               <AsideListCard data={[{title:'Perfil de operador', data:'Este perfil é aplicado aos operados do sistemas, atribuindo suas respectivas permissões'}]}/>
              {this.props.data.id && this.props.write && <AsideButtonCard title='Excluir' text='Excluir um perfil pode limitar o acesso dos operadores com essas permissões' button='Excluir' loading={this.state.deletingProfile} onClick={()=>this.onDeleteClick()}/>}
            </aside>
        );
    }
}
