import { requestResponseValidate, getRequestSettings, getFetchHeader } from "./UtilsAPI";
import { ReportsEP } from "../server/endpoints_js";

export const getPromotionItensHistoric = async (token, promotionID, dateStart, dateEnd, store=0, format = 'json') => {
    return requestResponseValidate(await 
        fetch(ReportsEP.getHistoricPromotionItens(promotionID, dateStart, dateEnd, store, format), 
        getRequestSettings('GET', token)), format
    )
}

export const getPromotionReportByPromotion = async (token, promotionID = -1, dateStart, dateEnd, store=-1, page=0, size=16, format = 'json') => {
    //(dateStart, dateEnd, store=-1, promotion_id=-1, page=0, size=16)
    //, body = undefined, content_type = 'application/json', accept = 'application/json'
    let content_type = undefined
    let accept = undefined
    if (format === 'json') {
        content_type = 'application/json'
        accept = 'application/json'
    }
    return requestResponseValidate(await 
        fetch(ReportsEP.getPromotionReportByPromotion(dateStart, dateEnd, store, promotionID, page, size, format), 
            getRequestSettings('GET', token, undefined, content_type, accept)), format
    )
}

export const getSaleItemsReportAPI = async (dateStart, dateEnd) => {
    return requestResponseValidate(await fetch(ReportsEP.getSaleItemsReport(dateStart, dateEnd), getFetchHeader()), 'csv')
}