import React, { Component } from 'react';
import './AsideDiscountCard.css';

class AsideDiscountCard extends Component {

	formatValue (value) {
		if (isNaN(value))
			return value
		return parseFloat(value + "").toFixed(2)
	}

  render() {
    return (
    	<div className="AsideDiscountCard">
			<div className="AsideDiscountCard-content">
				<h1 className="AsideDiscountCard-title">{this.props.title}</h1>
				<h2 className="AsideDiscountCard-value">{(this.props.type === 'BY_VALUE') ? 'R$ ' + this.formatValue(this.props.value) :  this.props.value + '%'}</h2>
				{(this.props.coupons) && <div className="AsideDiscountCard-footer">
					<img src={require('../../img/coupon.svg')} alt=""/> 
					<p className="AsideDiscountCard-number">{this.props.coupons}</p> 
				</div>}
			</div>
    	</div>
    );
  }
}

export default AsideDiscountCard;