import React, { useState, useEffect } from 'react';
import InputText from '../../inputText/inputText';
import Button from '../../Button/button';
import Toggle from '../../toggle/toggle';
import catchErrors, { ERRORS } from '../../../errors';
import { setupPromotionAPI } from '../../../server/Promotion/promotionAPI';
import ListItem from '../../listItem/listItem';
import { AskDialog } from '../../alertDialog/alertDialog';

const ProgressiveTab = (props) => {
    const [invites, setInvites] = useState()
    const [value, setValue] = useState()
    const [promotion, setPromotion] = useState({value: 0})
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        try{
            let promotion = props.store.getState().promotionDetails
            setPromotion(promotion)
        }catch(e){
            console.log(e);
        }
    },[])

    const addStageHandle = (value, invites) => {
        // debugger
        try{
            if(loading) return
            validateStage(value, invites)
            setLoading(true)
            let newStages = [...mappedStages(promotion.stages), {newValue: value, minInvitations: invites}]
            let newPromotion = promotion
            newPromotion['stages'] = newStages
            setupPromotionAPI(promotion.id, newPromotion).then(response => {
                console.log(response);
                setPromotion(response.data)
                props.store.dispatch({type : 'PROMOTION_UPDATED', promotionDetails: response.data})
                // setStages(newStages)
            }).finally(()=>{
                setLoading(false)
            })
        }catch(e){
            catchErrors(e)
        }
    }

    const validateStage = (value, invites) => {
        if(promotion.stages.length >= 6) throw ERRORS.LIST_MAX
        if(!value || !invites || value <= 0 || invites <= 0 ||value <= promotion.value || invites === 0) throw ERRORS.INVALID_STAGE
        if(promotion.stages.find(stage => (stage.minInvited >= invites || stage.newValue >= value))){
            throw ERRORS.INVALID_STAGE
        }
    }

    const onRemoveClickHandle = (selectedListItem) => {
        AskDialog('Deseja realmente remover esse nível?', 'Essa operação não poderá ser desfeita', () =>{
            if(loading) return
            setLoading(true)
            let newPromotion = promotion
            newPromotion['stages'] = mappedStages(promotion.stages.filter(stage => stage.minInvited !== selectedListItem.minInvited && stage.newValue !== selectedListItem.newValue) )
    
            setupPromotionAPI(promotion.id, newPromotion).then(response => {
                setPromotion(response.data)
                console.log('DELETEEE: ', response.data);
                props.store.dispatch({type : 'PROMOTION_UPDATED', promotionDetails: response.data})
            }).finally(()=>{
                setLoading(false)
            })
        })
    }

    const mappedStages = (stages) => {
        return stages.map(stage => ({minInvitations: stage.minInvited, newValue: stage.newValue})).filter(stage => stage.minInvitations > 0)
    }

    const getValueLabel = (value, type) => {
       if(type === 'BY_PERCENT'){
           return value + '%'
       }
       return 'R$' + Number(value).toFixed(2)
    }

    return ( 
        <div className="tabContent">
            <div className="content">
                <h1 className='bold' style={{marginBottom: 4}}>{promotion.status === 'PENDING' ? 'Defina os níveis de desconto' : 'Níveis de desconto'}</h1>
                <h2 className='gray' style={{marginBottom: 16}}>Exemplo: Compartilhando a promoção com 10 amigos, o desconto aumenta para 50%</h2>
                {promotion.status === 'PENDING' && <>
                    <div className='row'>
                        <InputText number label='Compartilhamentos' placeholder="Ex: 5 amigos"  onChange={(field, value) => setInvites(value)} style={{maxWidth: 140}}/>
                        <div style={{display: 'flex'}}>
                            <InputText number label='Desconto' placeholder="Ex: 30" onChange={(field, value) => setValue(value)} style={{maxWidth: 140}}/>
                            <Toggle left='%' right='R$' square disable={true} active={promotion.typeValue === 'BY_VALUE'} onClick={()=>{}} style={{flexDirection: 'collumn', marginTop: 13.5, marginLeft: -4.5}}/>
                        </div>
                    </div>
                </>}
                {promotion.status === 'PENDING' &&  <Button text='+ Adicionar nível'textOnly style={{justifyContent: 'end', marginBottom: 8}} loading={loading} loadingText='Adicionando...' onClick={() => addStageHandle(value, invites)}/>}
                <div>
                    {promotion && <ListItem title='Desconto inicial' subtitle={`Sem compartilhar: desconto de ${getValueLabel(promotion.value,promotion.typeValue)}`} style={{marginBottom: 4}} contentContainerStyle={{width: 360}} loading={loading}/>}
                    {promotion.stages && promotion.stages.map((stage, idx)=>(
                        <ListItem
                            key={`${stage.minInvited}${stage.newValue}`}
                            title={`Nível ${idx+1}`} 
                            subtitle={`Compartilhando com ${stage.minInvited || 0} ${stage.minInvited === 1 ? 'amigo' : 'amigos'}: desconto de ${getValueLabel(stage.newValue, promotion.typeValue)}`}
                            style={{marginBottom: 4}}
                            contentContainerStyle={{width: 360}}
                            loading={loading}
                            onRemoveClick={promotion.status === 'PENDING' && onRemoveClickHandle}
                            data={stage}
                        />
                    ))}
                </div>
            </div>
        </div> );
}
 
export default ProgressiveTab;