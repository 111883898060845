import React, { Component } from 'react';
import './searchProfiles.css';
import OperatorApi from '../../../api/OperatorApi'
import EmptyMessage from '../../../component/emptyMessage/emptyMessage'
import {Redirect} from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import LoadingMessage, {Loading} from '../../../component/LoadingMessage/loadingMessage';
import { Observable } from 'rxjs/Rx'
import Button from '../../../component/Button/button'
import DropDownFilter from '../../../component/DropDownFilter/DropDownFilter';
import TinyCard from '../../../component/tinyCard/tinyCard';
import { NavLink } from 'react-router-dom'
import deepProps from '../../../Utils';
import { Plus } from 'react-feather';

export default class Operator extends Component {
	constructor(props) {
        super(props)
        this.state = {
            profiles: [],
            page: 0,
            quantity: 0,
            token: null,
            permission: false,
            logout: false,
            profileDetail: false,
						count:0,
						maxPerPage:1,
						filters: ['name'],
						read:true,
						write:false
        }
    }

    alertDialog = (title,message) => {
		confirmAlert({
		  title: title,
		  message: message,
		  buttons: [
			{
			  label: 'Entendi!',
			  onClick: () => {}
			}
		  ]
		})
	}

    async componentDidMount() {
			this.setState({searching: true})
			await this.setState({token:localStorage.getItem('token')})
			try{
				this.searchProfiles()
			}catch(error){
				alert(error)
			}

			let permissions = deepProps('permissions')(this.props.store.getState()).getOrElse([])
			// console.log(permissions)
			let permission = undefined
			for (let p of permissions) {
				if (deepProps('name')(p).get() === 'PROFILE') {
					permission = p
					break
				}
			}
			let write = false
			let read = false
			if (permission !== undefined) {
				let array = deepProps('allow')(permission).getOrElse([])
				for (let allow of array) {
					if (allow === 'R') {
						read = true
						continue
					}
					if (allow === 'W') {
						write = true
					}
				}
			}
			// console.log(write + ":" + read)
			this.setState({write, read})
	}

	async searchProfiles(){
		await OperatorApi.getProfile(this.state.token, (response)=>{
			if (response === undefined)
				response = {}
			let profiles = deepProps('data')(response).getOrElse([])
			this.setState({profiles, searching:false})
		})
	}
	
	mountScreen(){
		this.seeMoreEvent()
	}

    seeMoreEvent = () => {
		let seeMoreRef = document.querySelector("#seeMoreOperators")
		let eventStream = Observable.fromEvent(seeMoreRef, 'click').map(e => {
			return this.state.page * this.maxPerPage
		}).switchMap((from) => {
			// let search = document.querySelector("#inputSearchOperator") === undefined ? '' : document.querySelector("#inputSearchOperator").value
			this.setState({from, filtering:true})
			return 
        })
		this.initOperatorsReactEvent(eventStream, (result, nextPage, total) => {
            let profiles = this.state.profiles
            if (nextPage > 1)
                profiles = profiles.concat(result)
            else
                profiles = result
			this.setState({profiles, page:nextPage, filtering:false, quantity : total})
		},() => {
			setTimeout(() => this.seeMoreEvent(), 1)
		})
    }
    
    onProfileCardClick(profile) {
			// console.log(profile)
		this.props.store.dispatch({type:'PROFILE_DETAIL', profile:profile})
		this.setState({searching: false, profileDetail:true})
    }

    newProfile () {
      this.props.store.dispatch({type:'PROFILE_DETAIL', profile:{name: "Novo perfil", permissions: Array(0)}})
			this.setState({searching: false, profileDetail:true})
    }
    
    seeMore(){
        let result = this.state.page * this.state.maxPerPage + this.state.profiles.length
        if (this.state.count > result) {
			this.setState({searching: true})
            this.props.store.dispatch(OperatorApi.listOperators(this.state.token, this.setState({searching: false}), this.setState({searching: false})))
        } else {
			this.setState({searching: false})
		}
	}
	
	onSelectFilter(filter){
		this.setState({filter:filter})
	}

  	render() {
		if (this.state.logout) {
			return <Redirect to='/'/>
		}
		if (this.state.profileDetail) {
			return <Redirect to='/main/profile/profile'/>
		}
    	return (
			<section className="store">
				<div className="page-title-header">
					<div className="box-title">
						<h1 className="title-page">Perfis</h1>
					</div>
					{false && <form className="operatorSearch">
						{this.state.filtering && <Loading style={{width:21, marginRight: 16}}/>}
						<input type="text" ref={input => this.search = input} id='inputSearchOperator' placeholder="Pesquisar..."/>
                        <span style={{width: 8}}/>
                        <DropDownFilter filters={[{text:'Selecione...', value: undefined},{text:'Título', value:'name'}]} onSelect={(filter)=>this.onSelectFilter(filter)}/>
					</form>}
					{false && <form className="search">
						{this.state.filtering && <Loading style={{width:21, marginRight: 16}}/>}
						<input type="text" ref={input => this.search = input} id='inputSearchOperator' placeholder="Pesquisar..."/>
                        <span style={{width: 8}}/>
					</form>}
				</div>
				<div className="container-search" id='search-customer-container'> 
					{(this.state.searching && this.state.profiles.length === 0) && <LoadingMessage title='Pesquisando...' description='Aguarde, estamos buscando informações!' /> }
					{(this.state.profiles.length === 0 && !this.state.searching) && <EmptyMessage title='Nenhum resultado encontrado' description='Nenhum resultado para perfís foi encontrado!' style={{left: 55}}/>}
					{
						this.state.profiles.map((profile, index) =>{
							return (
								<NavLink to='/main/profile/profile' key={index}>
									<TinyCard
										key={profile.id} name={profile.name}
										callback={() => this.onProfileCardClick(profile)}
									/>
								</NavLink>
							)
						})
					}
				</div>
				{this.state.write && <div className={"footer-store"}>
					<div className="pagination-footer">
						<div className = "paginator">
							{false && <h3 className="number-pagination" id = "seeMoreBtn">Ver mais</h3>}
						</div>
					</div>
					<div className = "option-footer" >
						<NavLink to='/main/profile/profile'>
            	<Button text='Novo Perfil' icon={<Plus/>} onClick={()=> this.newProfile()} style={{position: 'absolute', right: 4, minWidth: 137}}/>
						</NavLink>
          </div>
				</div>}
			</section>
    	);
  }
}
