import React, { useState, useEffect } from 'react';
import './styles.scss'

const ProductItemRange = (props) => {
    const [selectedChance, setSelectedChance] = useState('HIGH_CHANCE')
    const {id, value, title, subtitle, ranges, style, onSelect} = props

    useEffect(()=>{
        if(value !== selectedChance)
            setSelectedChance(value ? value : 'HIGH_CHANCE')
    },[])

    useEffect(()=>{
        onSelect({id, selectedChance})
    }, [ selectedChance])

    return ( 
        <div className='product-item-range border' style={style}>
            <h1 className='title'>{title}</h1>
            <h2 className='subtitle'>{subtitle}</h2>
            {/* <h3>{ranges.reach} clientes gostam</h3> */}
            <div className='ranges-container'>
                {ranges.highChance > 0 && <label>
                    <input type="radio" checked={selectedChance=== 'HIGH_CHANCE'} onChange={(e)=>setSelectedChance(e.target.value)} value={'HIGH_CHANCE'} name={"range"+id}/>
                    {ranges.highChance + ' gostam muito'}
                </label>}
                {ranges.mediumChance > 0 && <label>
                    <input type="radio" value={'MEDIUM_CHANCE'} name={"range"+id} checked={selectedChance=== 'MEDIUM_CHANCE'} onChange={(e)=>setSelectedChance(e.target.value)}/>
                    {ranges.mediumChance + ' gostam moderadamente'}
                </label>}
                {ranges.lowChance > 0 && <label>
                    <input type="radio" value={'LOW_CHANCE'} name={"range"+id} checked={selectedChance=== 'LOW_CHANCE'} onChange={(e)=>setSelectedChance(e.target.value)}/>
                    {ranges.lowChance + ' gostam pouco'}
                </label>}
            </div>
        </div>
        
    );
}
 
export default ProductItemRange;