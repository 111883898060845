import {INITIAL_API, PROMOTION} from "../endpoints_js";
const FAST_PROMOTION = PROMOTION + 'fast/'

export const SEGMENTATION_EP = {
    getReach(){return INITIAL_API + 'segmentation/reach'},
    getProductsReach(){return INITIAL_API + 'segmentation/reach/products'},
    setTargetReach(){return INITIAL_API + 'segmentation/target/promotion'},
    getPromotionReachURL(promotionId, page = 0, size = 1){return `${FAST_PROMOTION}${promotionId}/target-customers?page=${page}&size=${size}`},
    setTargetCustomers(promotionId){return `${FAST_PROMOTION}${promotionId}/target-customers`},
    getCancelSegmentationURL(promotionId){return `${FAST_PROMOTION}${promotionId}/segmentation/clear`},
    removeCustomerSegmentation(promotionId, customerId){return `${FAST_PROMOTION}${promotionId}/target-customers/${customerId}`},
    getCustomersSegmentedUsingId (promotionId, page) {return FAST_PROMOTION + promotionId + '/target-identifiers?page='+page +'&size=30'},
}