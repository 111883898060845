import React, { useState, useEffect } from 'react';
import Popup from "reactjs-popup";
import './listModal.scss'
import TextInput from '../textInput/textInput';
import { NotificationManager } from 'react-notifications';
import LoadingMessage, { Loading } from '../LoadingMessage/loadingMessage';
import { Plus, Trash } from 'react-feather';

const ListModal = (props) => { 
    const [select, setSelect] = useState('')
    const [input, setInput] = useState('')
    const [loadingMore, setLoadingMore] = useState(false)
    const [loadingAction, setLoadingAction] = useState(false)

    useEffect(()=>{
        setDefaultSelect()
    }, [props.options])

    const setDefaultSelect = () => {
        if(props.options && props.options.length > 0){
            setSelect(props.options[0].value)
        }
    }
    
    const onAddClickHandle = (item) =>{
        if(!props.onAddClick || loadingAction){return}
        setLoadingAction(true)
        try{
            validateAddClick()
            let callback = props.onAddClick(item)
            
            if(!callback || callback == undefined){
                setLoadingAction(false)
            }else if(callback.then){
                callback.then(() => {
                }).finally(()=>{
                    setLoadingAction(false)
                })
            }
        }catch(e){
            NotificationManager.warning(e)
            setLoadingAction(false)
        }
    }

    const validateAddClick = () => {
        if(!select || !input){throw 'Favor informe os dados!'}
    }

    const handleScroll = async e => {
        let {onEndReached} = props
        if (loadingMore && !onEndReached || onEndReached === undefined) {return}
        
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            await setLoadingMore(true)
            await props.onEndReached().finally(()=>{
                setLoadingMore(false)
            })
        }
    }

    return (
        <Popup  onClose = {props.onClose} 
        trigger={<a className='trigger-toggle'>{props.trigger}</a>}
        modal closeOnDocumentClick contentStyle={{maxWidth: 700, minHeight:500,display: 'flex', padding: 16, flexDirection: 'column'}} onOpen={props.onOpen}>
            <div className='list-modal-style' onScroll={handleScroll}>
                <h1 className='title'>{props.title}</h1>
                {props.data && <h2 className='subtitle'>{`${props.data.length} resultados encontrados`}</h2>}
                <ul>
                    {!props.loading && (!props.data || props.data.length) === 0 && <ItemList title={'Nenhum resultado encontrado'} subtitle={'Não foi possível encontrar clientes segmentados para essa promoção'} empty/>}
                    {(props.data && !props.loading)&& props.data.map(item=><ItemList key={item.id} title={item.title} subtitle={item.subtitle} onRemoveClick={()=>props.onRemoveClick(item)}/>)}
                    {loadingMore && <Loading style={{height:21, width: 21, alignSelf: 'center', padding: 32}}/>}
                </ul>
                {props.loading && <LoadingMessage title='Listando clientes...' description='Aguarde enquanto selecionamos os clientes segmentados'/>}
                {props.onAddClick && (props.data.length !== 0 || props.loading === undefined || !props.loading) &&
                    <footer>
                        <h1 className='title'>Novo cliente</h1>
                        <div>
                            {props.options && <select onChange={e=>setSelect(e.target.value)}>
                            {props.options.map((option, idx)=> <option key={idx} value={option.value}>{option.text}</option>)}
                            </select>}
                            <TextInput placeholder='Digite aqui...' styles={{flex:1, width: '100%'}} onChange={e => setInput(e.target.value)}></TextInput>
                            {!loadingAction && <Plus onClick={() => onAddClickHandle({select,input})} color='#2296F3' style={{padding: 8}}/>}
                            {loadingAction && <Loading style={{width: 21, height: 21, padding: 8}}/>}
                        </div>
                    </footer>
                }
            </div>
        </Popup>
    )
}

const ItemList = props => {
    const [loading, setLoading] = useState(false)

    const onRemoveClick = async () => {
        setLoading(true)
        await props.onRemoveClick().finally(()=>setLoading(false))
    }

    return(
        <li className={`list-modal-list-item${props.empty ? ' empty ' : ''}${loading ? ' loading ' : ''}`}>
            <div>
                <h1>
                    {props.title}
                </h1>
                <h2>
                    {props.subtitle}
                </h2>
            </div>
            {props.onRemoveClick && !loading && <RemoveButton onClick={onRemoveClick}/>}
        </li>
    )
}

const RemoveButton = props => {
    return (
        <Trash onClick={props.onClick} style={{width: 18, height: 18}} color='#8a8a8a'/>
    )
}

export default ListModal
