import React, {useState, useEffect} from 'react';
import './styles.scss'
import { Loading } from '../LoadingMessage/loadingMessage';
import ImageCompress from '../../ImageCompress/imageCompression';
import { uploadImageAPI } from '../../server/Image/ImageAPI';

const ImagePicker = ({style, resourceImage, onSelect, onRemove, loading, onImageUpload, id, label, noUpload, cover, compressLimit, sizeImage}) => {
  const SIZE_LIMIT_DEFAULT = 5000
  const IMAGE_WIDTH_DEFAULT = 800
  const [img, setImg]=useState()

   useEffect(()=>{
    if(resourceImage === ''){
      reset()
    }
    setImg(resourceImage)
  },[resourceImage])

  const preview = async (e) =>{
    try{
      const fileToUpload = e.target.files.item(0)
      let limitSize = compressLimit || SIZE_LIMIT_DEFAULT
      let size = sizeImage || IMAGE_WIDTH_DEFAULT
      const reader = new FileReader()
      let compressedImg = await ImageCompress(fileToUpload, limitSize, size)
      
      reader.readAsDataURL(compressedImg)

      reader.onload = async e => {
        setImg(e.target.result)
        if(onSelect) onSelect(e.target.result, compressedImg)
      }

      reader.onError = e => {
        console.error(e)
        throw 'Não foi possível converter a imagem'
      }

      if(!noUpload){
        let response = await uploadImageAPI(compressedImg)
        onImageUpload && onImageUpload(response)
      }

    }catch(err){
        return
    }
  }

  const reset = () => {
    document.getElementById(id ? id : 'image-picker').value = "";
    if(onSelect) onSelect('')
    setImg('')
    onRemove && onRemove(id)
  }

  return ( 
    <div className='image-picker-container' style={style}>
      {!loading && <p>{label ? label : 'Selecionar imagem'}</p>}
      {img && <p id='image-picker-remove' onClick={reset}>Remover</p>}
      {loading && <Loading style={{height: 21, width: 21}}/>}
      <input id={id ? id : 'image-picker'} type="file" style={{display: 'none'}} accept="image/png,image/jpeg" onChange={(e)=>preview(e)} />
      <label id="image-picker-label" className={`${img ? 'selected' : 'normal'}`} htmlFor={id ? id : 'image-picker'} style={{backgroundImage: `url(${img})`, backgroundSize: (cover ? 'cover' : 'contain'), backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} />
    </div>
  ); 
}

export default ImagePicker