import { getFetchPOSTHeader, validateRequest, getFetchGETHeader } from "../../api/UtilsAPI";
import { getSearchBody } from "./searchBody";

import {INITIAL_API} from '../endpoints_js'
import { getProductByGtinAPI, getProductByIdAPI } from "../Promotion/promotionAPI";
const ELASTIC_EP = `${INITIAL_API}elastic-search/`

const getSearchEP = (search) => `${ELASTIC_EP}${search}/my_company/_search`
const getItemByIdEP = (id) => `${ELASTIC_EP}products/my_company/${id}`

export const searchAPI = async  (input, database, page) => {
    let request = await fetch(getSearchEP(database), getFetchPOSTHeader(getSearchBody(input, database, page)))
    validateRequest(request)
    let response = await request.json()
    let {hits} = response
    if (hits) {
        return hits.hits || []
    }
    return []
}

export const getItemByIdAPI = async  (id) => {
    let request = await fetch(getItemByIdEP(id), getFetchGETHeader())
    validateRequest(request, [200,404])
    return await request.json()
} 

export const findItemSuggestionsByGtinAPI = (gtin) => {
    return new Promise((accept, reject) => {
        try{
            getProductByGtinAPI(gtin).then(response => {
                let {_source} = response
                let {productId, id} = _source
                if (!productId)
                    productId = id
                getProductByIdAPI(productId).then(response => {
                    accept(response.hits.hits)
                }).catch(()=>{
                    reject()
                })
            }).catch(()=>{
                accept([])
            })
        }catch(e){
            console.log(e)
        }
   
    })
}

export const findSupliersSugestionsAPI = async (suplier) => {
    return new Promise((accept, reject) => {
        searchAPI(suplier.description, 'supliers', 0).then(resp => {
            accept(resp)
        }).catch(()=>{
            accept([])
        })
    })
}

export const findSectorsSugestionsAPI = async (sector) => {
   return new Promise((accept, reject) => {
        searchAPI(sector.description, 'sectors', 0).then(resp => {
            accept(resp.slice(0,6))
        }).catch(()=>{
            accept([])
        })
    })
}