import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-dates/lib';
import {ArrowLeft, ArrowRight} from 'react-feather'
import 'react-dates/constants'
import 'react-dates/lib/css/_datepicker.css'
import 'moment/locale/pt-br'
import './style.scss'
const moment = require('moment');

const DateRange = (props) => {
    const [focusedInput, setFocusedInput] = useState(false)

    const onDateChangeHandle = (startDate, endDate) => onChange && onChange(startDate, endDate)

    const {startDate, endDate, onChange} = props

    return (
        <div>
        <p className='datePickerLabel'>Validade</p>
        <DateRangePicker 
            onFocusChange={focusedInput => setFocusedInput(focusedInput)} 
            focusedInput={focusedInput} 
            startDate={moment(props.startDate)}  
            endDate={moment(props.endDate)} 
            onDatesChange={({ startDate, endDate }) => onDateChangeHandle(startDate, endDate)} 
            startDateId="start_date_id" 
            endDateId="end_date_id"
            initialStartDate={props.startDate}
            initialEndDate={props.endDate}
            isDayBlocked={()=>false}
            isOutsideRange={day => false}
            small
            hideKeyboardShortcutsPanel
            verticalSpacing={undefined}
            navNext={<Arrow right/>}
            navPrev={<Arrow left/>}
            minimumNights={0}/>
        </div>
    );
}

const Arrow = (props) => {
    return(props.right ?
        <ArrowRight className={'DateRangeArrow'} style={{right: 8}}/>
        :
        <ArrowLeft className={'DateRangeArrow'} style={{left: 8}}/>
    )
}

export default DateRange;