import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import PersonalData from '../../component/cardTabUser/personalData/personalData';
import Historic from '../../component/cardTabUser/historic';
import Contacts from '../../component/cardTabUser/contacts/contacts';
import Address from '../../component/cardTabUser/adress/address';
import './cardTab.css';
import AppClientTab from '../../component/cardTabUser/application';
import { checkAvailableIdentifierAPI } from '../../server/customer/customerAPI';
import UserPreferences from '../../component/cardTabUser/preferences/preferences';
import { getDcJWT } from '../../Utils';

const CardTab = props => {
	const [hasAppAcount, setHasAppAcount] = useState()
	const [ loginStatus, setLoginStatus] = useState()

	useEffect(()=>{
		// let {user} = props
		// checkAvailableIdentifierAPI(user.identifierDefault.identifier).then(response=>{
		// 	setHasAppAcount(true)
		// 	setLoginStatus(response.data.loginStatus)
		// }).catch(e=>{

		// })
	}, [])


	return (
		<Router>
			<div className="TabContainer">
				<ul className="header-cardTabc">
					{false &&
					<li className="option-cardTabc" >
						<NavLink to="/main/customer/historic" activeClassName="active-user" className={'first-idx'}>Histórico</NavLink>
					</li>
					}
					<li className="option-cardTabc" >
						<NavLink to="/main/customer/data" activeClassName="active-user">Dados Pessoais</NavLink>
					</li>

					{getDcJWT().companyId === 4 && <li className="option-cardTabc" >
						<NavLink to="/main/customer/preferences" activeClassName="active-user">Perfil</NavLink>
					</li>}

					<li className="option-cardTabc" >
						<NavLink to="/main/customer/address" activeClassName="active-user">Endereço</NavLink>
					</li>

					<li className="option-cardTabc" >
						<NavLink to="/main/customer/contacts" activeClassName="active-user">Contatos</NavLink>
					</li>

					{props.loginStatus && <li className="option-cardTabc" >
						<NavLink to="/main/customer/app" activeClassName="active-user">Recuperação de acesso</NavLink>
					</li>}
					{/* <li className="option-cardTabc" >
						<NavLink to="/main/customer/historico" activeClassName="active-user">Histórico</NavLink>
					</li> */}
				</ul>

				<div className="routec">
					{/*
					<Route path="/main/customer/historic" component={()=><Historic store={props.store} user={props.user}/>}/>
					<Route path="/main/customer/data" component={()=><PersonalData store={props.store} user={props.user}/>}/>
					<Route path="/main/customer/address" component={()=><Address store={props.store} addresses={props.addresses} user= {props.user}/>}/>*/}
					<Switch>
						{false && <Route path="/main/customer/historic">
							<Historic store={props.store} user={props.user}/>
						</Route>}
						<Route path="/main/customer/data">
							<PersonalData store={props.store} user={props.user}/>
						</Route>
						{getDcJWT().companyId === 4 && <Route path="/main/customer/preferences">
							<UserPreferences store={props.store} user={props.user}/>
						</Route>}
						<Route path="/main/customer/address">
							<Address store={props.store} addresses={props.addresses} user= {props.user}/>
						</Route>
						<Route path="/main/customer/contacts">
							<Contacts store={props.store} />
						</Route>
						<Route path="/main/customer/app">
							<AppClientTab {...props} store={props.store} user={props.user}/>
						</Route>
					</Switch>
				</div>
			</div>


		</Router>
	);	
}

export default CardTab;
