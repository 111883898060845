import React, { Component } from 'react';
import './emptyMessage.scss';
import { Box, Search } from 'react-feather';

export default  class emptyMessage extends Component {
  render() {
    return (
    	<div className="emptyMessage" style={this.props.style}>
        {(this.props.icon === 'empty-box') && <Box size={42}/>}
        {(!this.props.icon) && <Search size={42}/>}
        <h1>{this.props.title}</h1>
        <h2>{this.props.description}</h2>
    	</div>
    );
  }
}

export class ColorEmptyMessage extends Component {
  render() {
    return (
    	<div className="ColorEmptyMessage" style={this.props.style} onClick={this.props.onClick ? this.props.onClick : ()=>{}}>
        {(this.props.icon === 'empty-box') && <Box size={42}/>}
        {(this.props.icon !== 'empty-box') && this.props.icon}
        {(!this.props.icon && !this.props.noIcon) && <Search size={42}/>}
        <h1>{this.props.title}</h1>
        <h2>{this.props.subtitle}</h2>
    	</div>
    );
  }
}