import { get } from "immutable";
import jwt_decode from 'jwt-decode'

const Functor = (isValid) => {
    const F = (initialValue) => ({
      _value: initialValue,
      of: function(value) {
        return F(value);
      },
      _isValid: isValid,
      map: function(fn) {
        if (this._isValid(this._value)) {
          return this.of(fn(this._value));
        }
        return this.of();
      },
      getOrElse: function(value) {
        return !this._isValid(this._value) ? value : this._value;
      },
      get: function() {
        return this._value;
      }
    })

    return F();
}

const Maybe = Functor((value) => value !== undefined && value !== null);

export default function deepProps(...params) {
    const lastKey = params.length - 1;
    const lastParam = params[lastKey];
    const nodes = params.slice(0, -1);

    if (typeof lastParam === 'object') {
      return Maybe.of(nodes.reduce((acc, current) => {
        if (acc) {
          return acc[current] || undefined;
        }

        return undefined;
      }, lastParam));
    }

    return deepProps.bind(this, ...params);
}

export const isString = (x) => {
  if (x == null)
    return false
  return Object.prototype.toString.call(x) === "[object String]"
}

export const UTF8toStr = (str) => {
  str = [str]
  return decodeURIComponent(str.reduce((p, c)=>`${p}%${c.toString(16)}`,''))
  // return decodeURIComponent(str.reduce((p,c)=>{return p+'%'+c.toString(16),''}))
}

export const getFormattedDate = (date, addDay = true) => {
  if (date instanceof Date) {
    date = date.toISOString()
  }
  var split = date.split("+")
  let newDate = split[0]
  if (split.length > 1)
    newDate += "Z"

  let formatted = new Date(newDate)
  if(addDay)
    formatted.setDate(formatted.getDate()+1)
  return formatted.toLocaleDateString()
}

export const getFormattedDateInput = (date) => {
  try{
    let formatted = new Date(date)
    // console.log('isoooo: ', date);
    return formatted.toISOString().slice(0, 10)
  }catch{
    if(date.year)
      return(`${date.year}-${(date.month < 10 ? `0${date.month}` : date.month)}-${(date.dayOfMonth < 10 ? `0${date.dayOfMonth}` : date.dayOfMonth)}`)
  }
    
}

export const errorLog = (error) => {
  console.error(error)
}

export const errorStatusLog = (error, request = 'Não definida') => {
  console.error('Erro ' + error + ' na requisição ' + request)
}

export const zerateHour = (hour) =>{
	hour.setHours(0)
	hour.setMinutes(0)
	hour.setSeconds(0)
	hour.setMilliseconds(0)
	return hour
}

export const printLog = (error) => {
}

export const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
export const fill = (dado, c, length) => {
  dado=String(dado)
  while(dado.length < length)
    dado = c + dado
  return dado
}

export const format = (value, mask, replace) => {
  if (value == null || value === undefined)
    return ''
  value = value.replace(/[^a-z0-9]/gi,'')
  if (mask == null || replace == null)
    return value
  value = fill(value, '0', mask.split(replace).length - 1)
  let result = ''
  let j = 0
  for (let i = 0; i < mask.length && j < value.length; i++) {
    let append = mask.charAt(i)
    if (append === replace)
      append = value.charAt(j++)
    result += append
  }
  return result
}

export const isCPFValid = (cpf) => {
  if(!cpf) return false
    let soma;
    let resto;

    soma = 0;

    if (
      cpf === '00000000000' || cpf === '11111111111' || cpf === '22222222222' ||
      cpf === '33333333333'|| cpf === '44444444444'|| cpf === '55555555555' ||
      cpf === '66666666666'|| cpf === '77777777777'|| cpf === '88888888888' ||
      cpf === '99999999999'
    ) {
      return false;
    }

    for (let i=1; i<=9; i++) soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11))  resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10)) ) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11))  resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11) ) ) return false;
       return true;
}

export const isEmailValid = (email) => {
  let usuario = email.substring(0, email.indexOf("@"));
  let dominio = email.substring(email.indexOf("@")+ 1, email.length);
  return ((usuario.length >=1) &&
      (dominio.length >=3) && 
      (usuario.search("@")===-1) && 
      (dominio.search("@")===-1) &&
      (usuario.search(" ")===-1) && 
      (dominio.search(" ")===-1) &&
      (dominio.search(".")!==-1) &&      
      (dominio.indexOf(".") >=1)&& 
      (dominio.lastIndexOf(".") < dominio.length - 1)) 
}

export const isEqual = comparativo => valor =>  (valor === comparativo);

export const isEmpty = isEqual('')

export const treatMessage = (error, dispatch = () => {}, result = () => {}) => {
  if (error instanceof Object) {
    if (!(error.json instanceof Function)) {
      let message = error.message
      let status = error.status
      if (message === undefined || status === 0)
        message = 'Tente novamente mais tarde'
      if (status === undefined) {
        status  = -1
        message = 'Tente novamente mais tarde'
      }
      result(message, status)
      return
    }
    let promise = error.json()
    if (promise instanceof Promise) {
      promise.then(e => {
        if (e === undefined)
          return
        let message = e.message
        let status = e.status
        if (message === undefined)
          message = 'Tente novamente mais tarde'
        if (status === undefined) {
          status  = -1
          message = 'Tente novamente mais tarde'
        }
        dispatchError(dispatch, status)
        result(message, status)
      })
    }
  }else {
    result(error)
  }
}

function dispatchError(dispatch, status) {
  switch(status) {
    case 401:
      dispatch({type:'LOGIN_REQUERIED'})
    break
    default:
    break
  }
}

export const scrollToTop = (id)=>{
  if(document.getElementById(id).scrollTop === 0)
    return
  document.getElementById(id).classList.add('smooth-scroll')
  document.getElementById(id).scrollTop = 0
}

export const formatCurrency = (value, fixed = false) => {
  if (value === undefined || isNaN(value))
    return value
  if (fixed)
    value = formatFloat(value)
  return `${value}`.replace('.', ',')
}

export const formatFloat = (value) => {
  if(value.toString().indexOf(',') !== -1){
    value = value.replace(',','.')}

  let reg = /^[+-]?\d+(\.\d+)?$/
  value = value + ''
  if (value.match(reg))
    return parseFloat(value).toFixed(2)
  return value
}

export const hasSpecialCharacters = (value) => {
  return value.match('[^a-zA-Z0-9]+')
}

export const hasEmptyValues = (field) => {
  let validate = (field instanceof Array) ? field : Object.values(field)
  return validate.filter(value => (value === '' || value === null || value === undefined)).length > 0
}

export const prepareDate = (date, begin = true) => {
  if(date.indexOf('T') > 0)
    return date
  return `${date}T23:59`
}

export const debounce = (func, wait, immediate)=>{
  var timeout;
  return function() {
      var context = this, args = arguments;
      var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
  };
};

export const getValueLabel = (value = 0, type = "BY_VALUE", complement = '') => {
  try{
    if(!value) value = 0
    switch(type){
      case 'BY_VALUE':
      default:
        return 'R$' + value.toFixed(2).replace(".", ",") + complement
      case "BY_PERCENT":
        return value + '%' + complement
    }
  }catch{
    return 0
  }

}


export const removeUndefinedParams = obj => {
  Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key])
  return obj
}

export const isObjEmpty = (obj) => {
  return obj === undefined || Object.keys(obj).length === 0;
}

export const getMeasurementLabel = (measurementUnit) => {return (measurementUnit == 'WEIGHTED') ? 'Kg' : 'Un'}

export const getIncrease = (init, last) => ((last-init)/(init === 0 ? 1 : init))*100

export const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min)) + min

export function formatBigNumber (labelValue, weighted) {
  if (weighted) {
    if (Number(labelValue) < 1)
      return Number(labelValue).toFixed(3).replace('.', ',')
    return Number(labelValue)
  }
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9
  ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
  // Six Zeroes for Millions 
  : Math.abs(Number(labelValue)) >= 1.0e+6
  ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
  // Three Zeroes for Thousands
  : Math.abs(Number(labelValue)) >= 1.0e+3
  ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"
  : Math.abs(Number(labelValue));
}

export const getSegmentationTypeText = (type, status) => {
  if(type !== 'PUBLIC'){return 'Disponível para clientes selecionados'}
  return (['PENDING', 'CREATING'].includes(status)) ? 'Será visível para todos os clientes' : 'Visível para todos os clientes'
}

export const getPromotionType = (type) => {
  switch(type){
    case 'PROGRESSIVE':
      return 'Progressiva'
    case 'FAST':
      return 'Simples'
    case 'VOUCHER':
      return 'Voucher'
    default:
      return 'Desconhecido'
  }
}

export const downloadResponse = (filename, request, extension = 'csv') => {
  return new Promise((accept, reject) => {
    request().then(async blob => {
      let a = document.createElement("a")
      let url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = `${filename }-${new Date().getTime()}.${extension}`
      a.click()
      window.URL.revokeObjectURL(url)
      accept()
    }).catch((e)=>{
      console.error(e);
      reject()
    })
  })
}

export const WEEK_LABELS = ['Segunda-Feira', 'Terça-Feira', 'Quarta-Feira', 'Quinta-Feira', 'Sexta-Feira', 'Sábado', 'Domingo']

export const MONTH_LABELS = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']

export const getDcJWT = () => {
  let token = localStorage.getItem('token')
  let decoded = jwt_decode(token)
  return JSON.parse(decoded.sub)
}