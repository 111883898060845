import React, { useState, useEffect } from 'react';
import './style.scss'
import Card from '../../card';
import BaseAreaChart from '../../charts/areaChart';
import KPIInfo from '../info';
import { getIncrease } from '../../../Utils';
import Tooltip from '../tooltip';
import ExportKPIData from '../exportKPIData'
import { Maximize, Minimize } from 'react-feather';
import Ranking from '../../ranking';
import { BarChart } from 'recharts';
import BaseBarChart from '../../charts/barChart';
import { getChurnGraphQL, getChurnAndRecoverGraphQL, getChurnByStoreGraphQL, getChurnReportAPI } from '../../../server/graphql/analytics/churn';
import { NotificationManager } from 'react-notifications';
import catchErrors from '../../../errors';
import { getCustomersByIdListAPI, getCustomerByIdAPI } from '../../../api/CustomerApi';
import { useHistory } from "react-router-dom";

export default function ChurnRateKPI (props){
    const {data, detailed, compare, filter, tooltipSubtitle} = props
    let history = useHistory()
    const [churnAmount, setChurnAmount] = useState(0)
    const [ressurectAmount, setRessurectAmount] = useState(0)
    const [dataChart, setDataChart] = useState([])
    const [storesChurn, setStoresChurn] = useState([])
    const [storesChurnPage, setStoresChurnPage] = useState(0)
    const [customersPage, setCustomersPage] = useState(0)
    const [endStoresChurnPage, setEndStoresChurnPage] = useState()
    const [endCustomersPage, setEndCustomersPage] = useState()
    const [churnedCustomers, setChurnedCustomers] = useState([])
    const [expand, setExpand] = useState(false)
    const [selectedStore, setSelectedStore] = useState()

    const CUSTOMERS_LINES = [{label:'Evasão',value:'churn'}, {label:'Retorno',value:'ressurect'}]
    // const FULL_CUSTOMERS_LINES = [...CUSTOMERS_LINES, {label:'Evasão (Período passado)',value:'churn_p'},{label:'Retorno (Período passado)',value:'ressurect_p'}]
    const FULL_CUSTOMERS_LINES = [...CUSTOMERS_LINES]

    useEffect(()=>{
        getChurnAndRecoverGraphQL().then((response) => {
            let {data} = response
            if (!data || !data['churnAndRecovered'] || !data['churnAndRecovered']['current']) {
                NotificationManager.warning('Não fora possível carregar os dados no momento', 'Tente novamente mais tarde')
                return
            }
            let {dropout, recovered} = data.churnAndRecovered.current
            setDataChart([{id: 0, churn: dropout, ressurect: recovered, churn_p: 4000, ressurect_p: 340}])
            setChurnAmount(dropout)
            setRessurectAmount(recovered)
        })
    },[data])

    const getDataTip = (increase, past) => {
        if(increase === 0) return `Igual à ${past}`
        return formatIncrease(increase) + " a " + (increase > 0 ? 'mais' : 'menos') + ' que ' + past
    }

    const formatIncrease = increase => ((increase && increase !== Infinity) ?  Math.abs(increase.toFixed(1)) : 0) + '%'

    const onShowStoresClickHandle = () => {
        setExpand(!expand)
        if(storesChurn && storesChurn.length > 0) return

        getStoresChurn()
    }

    const getStoresChurn = () => {
        getChurnByStoreGraphQL(storesChurnPage).then((response)=>{
            try{
                let {data} = response.data.churnByStore.current
                console.log(data);
                if(data.length <= 0) {
                    NotificationManager.info('Você chegou ao fim da lista!')
                    return setEndStoresChurnPage(true)
                }

                let mappedResponse = data.map(store => {
                    if(store.store < 0) {
                        return {title: 'Loja não definida', subtitle: store.churnquantity + ' abandonos', ...store}
                    }else{
                        return {title: 'Loja ' + store.store, subtitle: store.churnquantity + ' abandonos', ...store}
                    }
                })
                setStoresChurn([...storesChurn, ...mappedResponse])
            }catch(e){
                catchErrors(e)
                // NotificationManager.warning('Não conseguimos atualizar o relatório de evasão', 'Tente novamente mais tarde!')
                console.warn(e)
            }
            
        })
    }

    const onStoreClickHandle = item => {
        // console.log('lojaaaa: ', item);
        setChurnedCustomers()
        setCustomersPage(0)
        setSelectedStore(item)
    }

    const getCustomersChurn = (store, append = false) => {
        getChurnGraphQL(store, customersPage).then(response => {
            console.log(response);
            try{
                let {customers} = response.data.churn.current
                // customers = [{customerId:18}, ...customers]
                if(customers.length <= 0) {
                    NotificationManager.info('Você chegou ao fim da lista!')
                    return setEndCustomersPage(true)
                }
                getCustomersByIdListAPI(customers.map(customer=> customer.customerId)).then(customerData => {
                    let churnCustomers = customers.map(churn => (
                        {...customerData.find(cd => cd._source.ID === churn.customerId), ...churn}
                    ))

                    let mappedCustomers = churnCustomers.map(customer => ({id: customer.customerId, title: customer._source.fullName, subtitle: 'Última compra em ' + (customer.lastSale || '...'), data: customer}))
                    if (append)
                        setChurnedCustomers([...churnedCustomers,...mappedCustomers])
                    else
                        setChurnedCustomers([...mappedCustomers])
                })
            }catch(e){
                catchErrors(e)
            }
        })
    }

    const onCustomerClickHandle = item => {
        console.log(item);
        props.store.dispatch({type:'CUSTOMER_DETAILS', customerDetails:item.data._source})
        console.log(props);
        history.push('/main/customer/data')
    }

    const getCardHeader = () => ({
        title:'Evasão',
        actions:[
            expand ? <Minimize size={16} style={{paddingRight: 8}} onClick={()=>onShowStoresClickHandle()}/> : <Maximize size={16} style={{paddingRight: 8}} onClick={()=>onShowStoresClickHandle()}/>,
            <ExportKPIData request={getChurnReportAPI} fileName={'crm_churn_report'} name={'Evasão de clientes'}/>
        ]
    })

    const onSeeMoreClickHandle = (type) => {
        switch(type){
            case 'stores':
                return setStoresChurnPage(storesChurnPage+1)
            case 'customers':
                return setCustomersPage(customersPage+1)
        }
        
    }

    useEffect(()=>{
        if(storesChurnPage > 0){
            getStoresChurn()
        }
    }, [storesChurnPage])

    useEffect(() => {
        if (!selectedStore)
            return
        if(customersPage > 0)
            return getCustomersChurn(selectedStore.store, true)
        getCustomersChurn(selectedStore.store)
    }, [customersPage, selectedStore])

    useEffect(()=>{
        if(!expand) setSelectedStore(false)
    },[expand])

    return (
        <Card style={{padding: 0, overflow: 'hidden', height: 353}} className='customerKPI' header={getCardHeader()}>
            <div style={{display: 'flex'}}>
                <div style={{width:compare ? 350 : 350}}>
                    <div style={{display:'flex', justifyContent: 'center', flex: 1, paddingBottom: 32, paddingTop: 32}}>
                        <KPIInfo title='Abandonos' value={churnAmount} increase={compare && getIncrease(ressurectAmount, churnAmount)} color="#ff68a1" increaseTip={getDataTip(getIncrease(ressurectAmount, churnAmount), '1 ' + filter.text + ' atrás')}/>
                        <KPIInfo title='Retornos' value={ressurectAmount} increase={compare && getIncrease(ressurectAmount, churnAmount)} color="#2196f3" increaseTip={getDataTip(getIncrease(ressurectAmount, churnAmount), '1 ' + filter.text + ' atrás')}/>
                        {/* {compare && <>
                            <KPIInfo title='Abandonos' value={churnAmount} increase={compare && getIncrease(ressurectAmount, churnAmount)} color="#ff68a1" increaseTip={getDataTip(getIncrease(ressurectAmount, churnAmount), '1 ' + filter.text + ' atrás')}/>
                            <KPIInfo title='Retornos' value={ressurectAmount} increase={compare && getIncrease(ressurectAmount, churnAmount)} color="#2196f3" increaseTip={getDataTip(getIncrease(ressurectAmount, churnAmount), '1 ' + filter.text + ' atrás')}/>
                        </>} */}
                    </div>

                    <BaseBarChart colors={["#ff68a1","#2196f3","#ffa600","#97e000"]} hideX={!detailed} hideY={!detailed} hideGrid ={!detailed} x={'dateReference'} bars={compare ? FULL_CUSTOMERS_LINES : CUSTOMERS_LINES} 
                    data={dataChart} customTooltip={<Tooltip title="Evasão de Clientes" subtitle={tooltipSubtitle} labels={{churn: 'Abandonos', ressurect: 'Retornos'}}/>}/>

                </div>
                {expand && <div style={{width: 350,position: 'relative'}}>
                    <h1 className='title' style={{padding: 16, position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 9}}>Lojas com maior evasão</h1>
                    <Ranking onClick={onStoreClickHandle} data={storesChurn} style={{height: 260, overflow: 'scroll'}} onSeeMoreClick={!endStoresChurnPage ? () => onSeeMoreClickHandle('stores') : false}/>
                </div>}
                {selectedStore && <div style={{width: 400,position: 'relative'}}>
                    <h1 className='title' style={{padding: 16, position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 9}}>Evasão {selectedStore.title}</h1>
                    <Ranking onClick={onCustomerClickHandle} data={churnedCustomers} style={{height: 260, overflow: 'scroll'}} onSeeMoreClick={!endCustomersPage ? () => onSeeMoreClickHandle('customers') : false}/>
                </div>}
            </div>
        </Card>
    )
}