import React, {
    Component
} from 'react'
import SmallCard from '../../../component/smallCard/smallCard'
import DropDownFilter from '../../../component/DropDownFilter/DropDownFilter'
import Button from '../../../component/Button/button'
import EmptyMessage from '../../../component/emptyMessage/emptyMessage'
import './searchPromotion.css'
import deepProps, {getFormattedDate, getValueLabel, downloadResponse} from '../../../Utils'
import PromotionApi from '../../../api/PromotionApi'
import {
    Redirect
} from "react-router-dom"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import LoadingMessage,{Loading} from '../../../component/LoadingMessage/loadingMessage';
import { Observable } from 'rxjs/Rx'
import PopupContainer from '../../../component/Popup'
import InputText from '../../../component/inputText/inputText'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { getSaleItemsReportAPI } from '../../../api/ReportApi'
import { Plus } from 'react-feather'

class SearchPromotion extends Component {

    constructor(props) {
        super(props)
        this.state = {
            promotions: [],
            page: 0,
            quantity: 0,
            token: null,
            permission: false,
            logout: false,
            promotionDetail: false,
            count:0,
            maxPerPage:1,
            filter:{},
            startDate: moment().format(),
            endDate: moment().format(),
        }
        this.MAX_DAY_RANGE = 40
    }
    
    componentWillUnmount() {
        try {
            this.unsubscribe()
        }catch(e) {}
    }


    alertDialog = (title,message) => {
		confirmAlert({
		  title: title,
		  message: message,
		  buttons: [
			{
			  label: 'Entendi!',
			  onClick: () => {}
			}
		  ]
		})
	}

    loadPromotion = () => {
        let  promotions = deepProps('promotions')(this.props.store.getState()).getOrElse([])
        let paging = deepProps('pagePromotion')(this.props.store.getState()).getOrElse(null)
        if (paging !== null) {
            let quantity = deepProps('count')(paging).getOrElse(0)
            let page = deepProps('page')(paging).getOrElse(0)
            // page+=1
            this.setState({quantity, page})
        }
       
        this.setState({promotions},()=>{
			// if(promotions.length !== 0)
			this.setState({searching: false})
        })
    }

    init () {
        let permissions = deepProps('permissions')(this.props.store.getState()).getOrElse([])
        let permission = undefined
        for (let p of permissions) {
            if (deepProps('name')(p).get() === 'PROMOTION') {
                permission = p
                break
            }
        }
        let write = false
        let read = false
        if (permission !== undefined) {
            let array = deepProps('allow')(permission).getOrElse([])
            for (let allow of array) {
                if (allow === 'R') {
                    read = true
                    continue
                }
                if (allow === 'W') {
                    write = true
                }
            }
        }
        this.setState({write, read})
    }

    initSearch () {
		let input = document.querySelector('#inputSearchPromotion')
		let eventStream = Observable.fromEvent(input, 'input').map((e) => {
            this.setState({text:e.target.value})
            return e.target.value
        }).debounceTime(650).switchMap(() => {
            this.setState({filtering:true, from: 0, page: 0})
            return PromotionApi.getPromotionWithFilter(this.search.value, this.state.filter, this.state.page, this.state.token, () => {this.setState({searching: false})}, (title,message)=>this.alertDialog(title,message))
        })
		this.initPromotionReactEvent(eventStream, (promotions, nextPage, total) => {
			this.setState({promotions, page:nextPage, filtering:false, quantity : total})
		},() => {
			setTimeout(() => this.initSearch(), 1)
		})
    }
    
    initPromotionReactEvent(eventStream, successFunc = () => {}, errorFunc = () => {}) {
		if (eventStream === undefined)
			return
		let event = eventStream
		.mergeMap(resp => {
			if (resp === null || !(resp instanceof Object))
				throw new Error('No result')
			return resp.json()
		}).map(response => {
            //console.log(response)
            this.setState({filtering:false})
			return {total : deepProps('paging', 'count')(response).getOrElse(0) , promotions : deepProps('data')(response).getOrElse([])}
		}).catch(error => {
			this.setState({filtering:false})
			console.error(error)
			Observable.empty()
		})
		this.subscribe = event.subscribe(result => {
			let promotions = deepProps('promotions')(result).getOrElse([])
			let page = this.state.page
            let total = deepProps('total')(result).getOrElse(0)
		    if (promotions.length > 0)
				page++
			successFunc(promotions, page, total)
		},(error) => {
			console.error(error)
			errorFunc()
		})
	}


   async componentDidMount () {
        this.unsubscribe = this.props.store.subscribe(() => {
            if (this.props.store.getState().type === 'PROMOTION_SEARCH') {
                this.init()
                this.loadPromotion()
            }
        })
        let token = deepProps('token')(this.props.store.getState()).getOrElse(localStorage.getItem('token'))
        let logout = token === null || token === undefined
        await this.setState({logout, token, page:0, searching: true})
        this.init()
        this.props.store.dispatch(PromotionApi.findPromotions(this.state.token, this.state.page, (title,message)=>this.alertDialog(title,message)), ()=>this.setState({searching: false}), ()=>this.setState({searching: false}))
        this.initSearch()
        this.seeMoreEvent()
    }

    seeMoreEvent = () => {
		let seeMoreRef = document.querySelector("#seeMorePromotions")
		let eventStream = Observable.fromEvent(seeMoreRef, 'click').map(e => {
			return this.state.page * this.maxPerPage
		}).switchMap((from) => {
            this.setState({from, filtering:true})
            return PromotionApi.getPromotionWithFilter(this.search.value, this.state.filter, this.state.page, this.state.token, () => {this.setState({searching: false})}, (title,message)=>this.alertDialog(title,message))
        })
		this.initPromotionReactEvent(eventStream, (result, nextPage, total) => {
            let promotions = this.state.promotions
            if (nextPage > 1)
                promotions = promotions.concat(result)
            else
                promotions = result
			this.setState({promotions, page:nextPage, filtering:false, quantity : total})
		},() => {
			setTimeout(() => this.seeMoreEvent(), 1)
		})
    }
    
    showPromotion(identifier) {
        this.props.store.dispatch(PromotionApi.findPromotion(identifier, this.state.token, () => {
            this.setState({
                 searching: false,
                promotionDetail: true
            })
        }, (erroMessage) => {
            this.alertDialog('Ops, algo deu errado', erroMessage)
        }))
    }

    paginator() {
        if (this.state.count % this.state.maxPerPage === 0)
            return this.state.count / this.state.maxPerPage
        return ((this.state.count / this.state.maxPerPage) >> 0) + 1
    }

    newPromotion () {
        this.props.store.dispatch({type:'NEW_PROMOTION'})
        this.setState({searching: false, promotionDetail:true})
    }

    onSelectFilter(filter){
        // console.log(filter)
		this.setState({filter:filter},()=>{
			document.querySelector('#inputSearchPromotion').dispatchEvent(new Event('input'))
		})
    }
    
    seeMore(){
        let result = this.state.page * this.state.maxPerPage + this.state.promotions.length
        if (this.state.count > result) {
			this.setState({searching: true})
            this.props.store.dispatch(PromotionApi.findPromotions(this.state.token, this.state.page + 1,(title,message)=>this.alertDialog(title,message), this.setState({searching: false}), this.setState({searching: false})))
        } else {
			this.setState({searching: false})
		}
    }
    
    getFormattedPromotionValue = (promotion) => {
        let {
            promotionPattern, maxDiscountByCustomer, value, typeValue
        } = promotion
        switch(promotionPattern) {
            case 'FAST':
            default:
                return getValueLabel(value || 0, typeValue || '', ' de desconto')
            case 'VOUCHER':
                return `Voucher de R$ ${this.getFormattedValue(maxDiscountByCustomer)} de desconto.`
        }
    }

    getFormattedValue = (value) => {
        try {
            return parseFloat(value).toFixed(2)
        }catch(e) {
            return value
        }
    }

    downloadReport(){
        try{
            if(this.state.loadingReport) return
    
            let start = this.state.startDate
            let end = this.state.endDate
    
            if(moment(start).isAfter(moment(end))){
                return NotificationManager.warning('A data fim não pode ser depois da data início', 'Verifique as informações')
            }

            if(moment(end).diff(moment(start), 'days') >= this.MAX_DAY_RANGE){
                return NotificationManager.warning(`O período máximo da pesquisa é de ${this.MAX_DAY_RANGE} dias`, 'Verifique as informações')
            }

            this.setState({loadingReport: true})
    
            start = start.substring(0,10)
            end = end.substring(0,10)
    
            let filename = 'promotion-transactions-' + start + '_' + end
    
            start = start + 'T00:00:00'
            end = end + 'T23:59:59'
    
            downloadResponse(filename, () => getSaleItemsReportAPI(start, end), 'csv').then(()=>{
                this.setState({loadingReport: false})
                NotificationManager.success('Relatório de transações baixado com sucesso', 'Download completo!')
            }).catch(()=>{
                NotificationManager.success('Tente novamente mais tarde', 'Ops, algo deu errado!')
            })
        }catch{
            NotificationManager.success('Tente novamente mais tarde', 'Ops, algo deu errado!')
        }
    }
    
    render() {
        if (this.state.logout) {
            return <Redirect to = '/' />
        }
        if (this.state.promotionDetail) {
            return <Redirect to = '/main/promotion/promotion' />
        }
        return (
            <section className = "store" >
                <div className = "header-promotion" >
                <div className = "box-title" >
                <h1 className = "title-page" > Promoções </h1> <span className = "subtitle-page" > {
                    this.state.promotions.length
                }
                /{this.state.quantity} Total</span>
                </div> 
                    <form className="search" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {this.state.filtering && <Loading style={{width:21, height: 21, marginRight: 16}}/>}
                            <input type="text"  className='input-shadow' ref={input => this.search = input} id='inputSearchPromotion'
                                style={deepProps('value')(this.state.filter).getOrElse('all') !== 'cb' ? {display:'none'} : {display:'block'}} 
                                placeholder="Pesquisar..."/> 
                            
                            <span style={{width: 8}}/>
                            <DropDownFilter filters={[{text:'Todas', value:'td'},{text:'Publicadas', value:'pu'},{text:'Pendentes', value:'pe'},{text:'Descontinuadas', value:'ds'},{text:'Código de barras', value:'cb'},]}  onSelect={(filter)=>this.onSelectFilter(filter)}/>
                        </form>
                </div>
                <div className = "container-search">
                {this.state.searching && <LoadingMessage title='Pesquisando promoções...' description='Aguarde, Estamos buscando promoções!' style={{left: 55}}/>}
                {(this.state.promotions.length === 0 && !this.state.searching) && <EmptyMessage title='Nenhum resultado encontrado' description='Nenhum resultado para promoções foi encontrado!'  style={{left: 55}}/>}
                {!this.state.searching &&
                    this.state.promotions.map((promotion) => {
                        let items = promotion.items.filter((item) => item.resourceImage)
                        return ( <
                            SmallCard key = {
                                promotion.id
                            }
                            title={deepProps('description')(promotion).getOrElse('Sem Descrição')}
                            cpf = {
                                /*deepProps('promotionPattern')(promotion).get() === 'VOUCHER' ? 
                                getValueLabel(deepProps('maxDiscountByCustomer')(promotion).getOrElse(0), 'BY_VALUE', ' de desconto') :
                                getValueLabel(deepProps('value')(promotion).getOrElse(0),deepProps('typeValue')(promotion).getOrElse(''), ' de desconto')*/
                                this.getFormattedPromotionValue(promotion)
                            }
                            email = {
                               getFormattedDate(deepProps('dateStart')(promotion).getOrElse('--/--/----'), false) + ' - ' + getFormattedDate(deepProps('dateEnd')(promotion).getOrElse('--/--/----'), false)
                            }
                            photo = {
                                items.length > 0 ? items[0].resourceImage : ''
                            }
                            status = {
                                deepProps('status')(promotion).getOrElse('UNKNOW')
                            }
                            name = {
                                deepProps('description')(promotion).getOrElse('Sem Descrição')
                            }
                            address = {
                               'Válida em ' + deepProps('stores')(promotion).getOrElse([]).length + (deepProps('stores')(promotion).getOrElse([]).length === 1 ? ' loja' : ' lojas')
                            }
                            callback = {
                                () => this.showPromotion(promotion.id)
                            }
                            />
                        )
                    })
                } </div>
                <div className = "footer-promotion">
                    <PopupContainer trigger={<Button text='Baixar transações' textOnly style={{paddingLeft: 16}}/>} style={{maxWidth: 250, padding: 16}}>
                        <div style={{flexDirection: 'column', display: 'flex',flex: '1'}}>
                            <h1 style={{marginBottom: 16}}>Selecione o período da pesquisa</h1>
                            <div>
                                <InputText label='Data início' value={this.state.startDate} date onChange={(e, value) => this.setState({startDate:value})}/>
                                <InputText label='Data fim' value={this.state.endDate} date onChange={(e, value) => this.setState({endDate:value})} style={{marginTop: 8}}/>
                            </div>
                            <Button text='Baixar' loading={this.state.loadingReport} loadingText={'Baixando...'} style={{marginTop: 16}} onClick={() => this.downloadReport()}/>
                        </div>
                    </PopupContainer>
                    <div className = "pagination-footer" >
                        <div className={"paginator" + (this.state.promotions.length === this.state.quantity && 'none')}>
                            <h3 className = "number-pagination" id='seeMorePromotions'> Ver mais</h3>
                            {/*(this.state.page > 0) && <span className = "arrow-left" onClick={() => this.previousPage()}> < img src = {require('../../../img/arrow2.svg')}
                            alt = "" /> </span>} <span className = "number-pagination" > {(this.state.page + 1)}
                            /{this.paginator()}</span >
                            {((this.state.page+1) !== this.paginator()  && this.state.promotions.length >= 16) && <span className = "arrow-right" onClick={() => this.nextPage()}> < img src = {require('../../../img/arrow2.svg')} alt = "" /></span>*/}
                        </div>
                    </div>
                    <div className = "option-footer" >
                        {this.state.write && <Button text='Nova Promoção' icon={<Plus/>} onClick={()=> this.newPromotion()} style={{position: 'absolute', right: 16, minWidth: 137}}/>}
                    </div>
                </div>
            </section>
        );
    }
}

export default SearchPromotion;
