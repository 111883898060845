import React, { Component } from 'react'
import SelectableItem from '../../../component/selectableItem/selectableItem'
import Button from '../../Button/button'
import EmptyMessage from '../../emptyMessage/emptyMessage'
import Toggle from '../../toggle/toggle'
import './store.css'
import deepProps from '../../../Utils'
import StoreAPI from '../../../api/StoreApi'
import { Redirect } from "react-router-dom"
import { AlertDialog } from '../../alertDialog/alertDialog';
import LoadingMessage from '../../LoadingMessage/loadingMessage';
import { NotificationManager } from 'react-notifications'
import { Save } from 'react-feather'

class loja extends Component {
	constructor(props) {
		super(props);
		this.state = {
            stores: [],
            selected: [],
            select_all:false,
            lastSelected:[],
            removed:[],
            promotionId: 0,
            selecteds:[],
            promotionStatus:'',
            token:'',
            redirect : false
		};
        this.unsubscribe = this.props.store.subscribe(()=> {
            let lastState = this.props.store.getState()
            let stores = []
            switch(lastState.type) {
                case 'STORES_SEARCH':
                    stores = deepProps('stores')(lastState).getOrElse([])
                    stores = stores.map((store) => {
                        let obj = {name:store.description, number:store.number, id:store.id, selected : false}
                        return obj
                    })
                    this.setState({stores})
                    this.findPromotionsStore()
                break
                case 'PROMOTION_STORE_SEARCH':
                    let promotionsStores = deepProps('promotionsStores')(lastState).getOrElse([])
                    let lastSelected = []
                    stores = this.state.stores.map((store) => {
                        let selected = promotionsStores.filter((s) => store.number === s.storeNumber).length > 0
                        if (selected) {
                            lastSelected.push(store.number)
                        }
                        let obj = {name:store.name, number:store.number, id:store.id, selected : selected, saved: selected}
                        return obj
                    })
                    this.setState({lastSelected, selecteds:lastSelected, stores})
                break
                case 'PROMOTION_ADD_STORE':
                    let storesAdded = deepProps('storesAdded')(lastState).getOrElse([])
                    stores = this.state.stores.map((store)=> {
                        storesAdded.map((s) => {
                            if (s.storeNumber === store.number) {
                                // alert(23)
                                store.selected = true
                                store['saved'] = true
                            }
                            return s
                        })
                        return store
                    })
                break
                case 'PROMOTION_REMOVED_STORE':
                    let storesRemoved = deepProps('storesRemoved')(lastState).getOrElse([])
                    stores = this.state.stores.map((store)=> {
                        storesRemoved.map((s) => {
                            if (s.storeNumber === store.number) {
                                store.selected = false
                            }
                            return s
                        })
                        return store
                    })
                break
                default:break
            }
        })
        this.updateSelected = this.updateSelected.bind(this)
	}
    
    

	componentDidMount() {
		this.setState({searching: true})
        let lastState = this.props.store.getState()
        let token = deepProps('token')(lastState).getOrElse(null)
        let promotion = deepProps('promotionDetails')(lastState).getOrElse(null)
        if (promotion === null) {
            this.setState({redirect:true})
            return
        }
		this.setState({promotion})
        let promotionStatus = deepProps('status')(promotion).getOrElse('UNKONW')
        let stores = deepProps('stores')(promotion).getOrElse([])
        let lastSelected = stores.map((store) => store.storeNumber)
		this.setState({token, promotionId:promotion.id, promotionStatus, stores:lastSelected})
        this.props.store.dispatch(StoreAPI.findStores(this.props.store.getState().token))
    }
    
    componentWillUnmount() {
        this.unsubscribe()
    }

	/*selectAll(active) {
        this.setState({select_all:active})
        let selecteds = this.state.stores.map((item) => {
            item['selected'] = active
            if (active)
                return item.id
            return null
        }).filter((i) => i !== null)
        this.setState({selecteds})
    }*/

	toggleItemSelecteds(item){
        let selecteds = this.state.selecteds
        let result  = selecteds.filter((s) => s !== item.id)
        if (result.length < selecteds.length) {
            selecteds = result
            item['selected'] = false
        }else {
            selecteds.push(item.id)
            item['selected'] = true
        }
        this.setState({selecteds, select_all: (this.state.stores.length === selecteds.length)})
    }

    updateSelected(store, componente){
        let selected = this.state.selecteds.filter((s) => s !== store)
        let removed = this.state.removed
        let isSelected = false
        if (selected.length === this.state.selecteds.length) {
            isSelected = true
            selected.push(store)
            removed = removed.filter((r) => r !== store)
        }else {
            if (!componente.canUnSelected()) {
                AlertDialog('Ação negada!', 'Não é possível remover lojas devido ao atual status da promoção')
                componente.selected()
                return
            }
            let result = this.state.lastSelected.filter((s) => s === store && removed.filter((r) => r === store).length === 0)
            if (result.length > 0)
                removed.push(store)
            else
                removed = removed.filter((r) => r !== store)
        }
        let result = this.state.stores.filter(s => deepProps('number')(s).getOrElse(-1) === store)
        if (result.length > 0)
            result[0]['selected'] = isSelected
        this.setState({selecteds:selected, removed})
		//this.toggleItemSelecteds(store)
    }

	selectAll(active){
        //this.setState({selectAll:active})
        let removed = []
        let selecteds = this.state.stores.map((item) => {
            if (this.state.promotionStatus !== 'PENDING') {
                let saved = deepProps('saved')(item).getOrElse(false)
                if (saved && !active) {
                    AlertDialog('Ação negada!', 'Não é possível remover lojas devido ao atual status da promoção')
                    return item.number
                }
            }
            item['selected'] = active
            if (active)
                return item.number
            removed = removed.concat(this.state.lastSelected.filter(s => s === item.number))
            return null
        }).filter((i) => i !== null)
        this.setState({selecteds, removed})
    }
    
    findPromotionsStore() {
        this.props.store.dispatch(StoreAPI.findPromotionStore(deepProps('token')(this.props.store.getState()).getOrElse(''), this.state.promotionId, () => {this.setState({searching: false})},
        (errorMessage) => {
            AlertDialog('Ops, algo deu errado', errorMessage)
        }))
    }
    
    saveStores () {
        let storesToSave = this.state.selecteds.filter(store => this.state.lastSelected.indexOf(store) < 0)
        
        if (this.state.removed.length > 0) {
            this.props.store.dispatch(StoreAPI.updateStores(this.state.token, this.state.promotionId, this.state.removed, false, () => {
                if (storesToSave.length > 0) {
                    this.props.store.dispatch(StoreAPI.updateStores(this.state.token, this.state.promotionId, storesToSave, true, () => {
                        this.findPromotionsStore()
                    }, (errorMessage) => {
                        AlertDialog('Ops, algo deu errado', errorMessage)
                    }))
                }else {
                    this.findPromotionsStore()
                }
                NotificationManager.success('As lojas referentes à promoção foram alteradas.', 'Lojas alteradas com sucesso!')
                this.updatePromotionDetails()

            }, (errorMessage) => {
                NotificationManager.warning(errorMessage, 'Ops, algo deu errado!')
            }))
        }else {
            if (storesToSave.length > 0) {
                this.props.store.dispatch(StoreAPI.updateStores(this.state.token, this.state.promotionId, storesToSave, true, () => {
                    this.findPromotionsStore()
                    NotificationManager.success('As lojas referentes à promoção foram alteradas.', 'Lojas alteradas com sucesso!')

                    this.updatePromotionDetails()

                    console.log('dispatch');
                }, (errorMessage) => {
                    NotificationManager.warning(errorMessage, 'Ops, algo deu errado!')
                }))
            }else {
                NotificationManager.info('Nenhuma alteração necessária')
            }
        }
    }

    updatePromotionDetails(){
        let promotion = this.props.store.getState()
        promotion = promotion.promotionDetails
        promotion['stores'] = this.state.selecteds.map(store => ({'storeNumber': store}))
        this.props.store.dispatch({type:'PROMOTION_UPDATED', promotionDetails:promotion})
    }

  render() {
      let {write} = this.props
        if (this.state.redirect) {
            return <Redirect to = '/main/promotions' />
        }
    return (
        <div className="itens-content">
			{(this.state.stores.length > 0 && deepProps('status')(this.state.promotion).getOrElse('UNKNOW') === 'PENDING') && <div className="options-bar">
                {(this.state.stores.length > 0 && deepProps('status')(this.state.promotion).getOrElse('UNKNOW') === 'PENDING') && 
                    write &&
                    <Toggle small switch text='Selecionar todas' active={(this.state.stores.length === this.state.selecteds.length)} onClick={(state)=>this.selectAll(state)}/>}
            </div>}
			<div className="list-itensProduct">
			{this.state.searching && <LoadingMessage title='Pesquisando...' description='Aguarde, Estamos buscando informações!' />}
            {(this.state.stores.length === 0 && !this.state.searching) && <EmptyMessage title='Nenhum resultado encontrado' description='Nenhum resultado para loja foi encontrado!'/>}
                {!this.state.searching && this.state.stores.map( (item, id) =>{
                    return(
                        <SelectableItem key={id} selected={item.selected} title={item.name} 
                            img={"https://cdn.dribbble.com/users/4877/screenshots/284840/store-icon.png"} alt={'imagem da ' + item.name} 
                            callback={(item, component) =>this.updateSelected(item, component)} id={item.number} 
                            canUnSelected={this.state.promotionStatus === 'PENDING' || !item.saved || !item.selected}/>
                )})}
                </div>
                <div className="option-itens">
                {(['PENDING', 'PUBLISHED'].includes(this.state.promotionStatus) && this.state.stores.length > 0 && this.props.write) && <Button text="Salvar" small icon={<Save/>} onClick={()=>{this.saveStores()}} style={{position:'absolute', right:32, bottom: 16}}/>}
			</div>
    	</div>
    );
  }
}

export default loja;