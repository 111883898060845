import React, { Component } from 'react';
import './register.css';
import Button from './../../component/Button/button';
import RegisterAPI from '../../api/RegisterApi';
import TokenApi from '../../api/TokenApi';
import AddressesApi from '../../api/AddressesApi';
import {AlertDialog} from './../../component/alertDialog/alertDialog';
import {getFormattedDate, isCPFValid, isEmpty, isEqual} from '../../Utils';
import InputText from '../../component/inputText/inputText';
import CountryBox from './../../component/countryComboBox/countrycombobox';
import { Redirect } from "react-router-dom"
import { ArrowLeft, ArrowRight } from 'react-feather';

class register extends Component {
	constructor(props) {
    super(props);
		this.state = {
            redirect:false,
			index: 0,
			name: '',
			surname: '',
			gender: '',
			birthDate:'',
			identifier: '',
			type: '',
			telephone: '',

			userName:'',
			password: '',
			confirmpassword: '',
			app: '',
			userType: '',

			zipCode:'',
			streetType:'',
			street:'',
			complement:'',
			district:'',
			city:'',
			state:'',
			kindOfAddress:'',
			acronymState: '',
			number:'',

			address: ''
		}
	}

	componentDidMount(){
		TokenApi.getToken((response)=>{this.setState({token: response.data})
		})
	}

	nextPage(){
		if(this.state.index < 3)
			this.setState({index: this.state.index + 1}) 
	}

	getCustomerJson(){
		let objectToSend  = {
			customer:{
				name: this.state.name,
				surname: this.state.surname,
				gender: this.state.gender,
				birthDate: this.state.birthDate,
				identifierDefault:{
					identifier: this.state.identifier,
					type: 'CPF'
				},
				contact : {
					telephones : [
						{
							ddd : 11,
							ddi : 55,
							number : this.state.telephone,
							type : "PHONE"
						}],
						eletronicAddresses:[{
							address: this.state.address,
							midia: 'E_MAIL'
						}]
				},
				confirmpassword: this.state.confirmpassword,
				address:{
					zipCode: this.state.zipCode,
					streetType: this.state.streetType,
					street: this.state.street,
					complement: this.state.complement,
					district: this.state.district,
					city: this.state.city,
					state: this.state.state,
					number: this.state.number
				},
				usersPerfil: [{
					userName: this.state.identifier,
					password: this.state.password,
					app : "PROMOSHARE",
					userType : "CUSTOMER"
				}]
			}
        }
        return objectToSend 
    }

	registerCustomer = () => {
		let customer = this.getCustomerJson()
        if (this.validateFields(customer) && this.state.index === 2 ){
            RegisterAPI.addCustomer(this.state.token, customer, ()=>{AlertDialog('Cadastrado com sucesso!', 'O usuário foi cadastrado com sucesso! Favor logar com seu usuário e senha'); this.setState({redirect:true})}, ()=>AlertDialog('Cadastro indisponível no momento', 'Não foi possível realizar a ação.'))
		}
	}

	getAddress(zipCode){
		AddressesApi.find((zipCode),(endereco)=>{
			this.setState({
				street: endereco.logradouro,
				complement: endereco.complemento,
				district: endereco.bairro,
				city: endereco.localidade,
				state: endereco.uf,
				kindOfAddress: '',
				acronymState: endereco.uf
			})
		});
	}

	onChange(field,value){
      this.setState({[field]: value});
    }

	onChangeSelect(field, select){
	}

	validateFields(fields){
	    switch(this.state.index){
			case 0:
				if(isEmpty(fields.customer.name.trim())){
					AlertDialog('Informação incompleta!','Favor informar o nome.')
					return false
				}

				if(isEmpty(fields.customer.surname.trim())){
					AlertDialog('Informação incompleta!','Favor informar o sobrenome.')
					return false
				}

				if(isEmpty(fields.customer.birthDate.trim())){
					AlertDialog('Informação incompleta!','Favor informar a data de nascimento.')
					return false
				}

				if(!getFormattedDate(fields.customer.birthDate.trim())){
					AlertDialog('Erro ao informar data de nascimento','Os dados devem ser dd/mm/aa.')
					return false
				}

				if(!isCPFValid(fields.customer.identifierDefault.identifier.trim())){
					AlertDialog('Informação incompleta!','Favor informar o CPF.')
					return false
				}

				if(isEmpty(fields.customer.usersPerfil[0].password.trim()) || isEmpty(fields.customer.confirmpassword.trim())){
					AlertDialog('Informação incompleta!','Favor escolher uma senha.')
					return false
				}

				if (!isEqual(fields.customer.usersPerfil[0].password)(fields.customer.confirmpassword)){
					AlertDialog('Senhas não correspondem!','A senha e a confirmação de senha devem ser iguais')
					return false
				}

				this.nextPage()
			break

			case 1:
				if(isEmpty(fields.customer.address.zipCode.trim())){
					AlertDialog('Informação incompleta!','Favor informar o CEP.')
					return false
				}

				if(isEmpty(fields.customer.address.street.trim())){
					AlertDialog('Informação incompleta!','Favor informar a rua.')
					return false
				}

				if(isEmpty(fields.customer.address.district.trim())){
					AlertDialog('Informação incompleta!','Favor informar o bairro.')
					return false
				}

				if(isEmpty(fields.customer.address.city.trim())){
					AlertDialog('Informação incompleta!','Favor informar a cidade.')
					return false
				}

				if(isEmpty(fields.customer.address.state.trim())){
					AlertDialog('Informação incompleta!','Favor informar a cidade.')
					return false
				}	
				
				this.nextPage()
			break

			case 2:
				if(isEmpty(fields.customer.contact.eletronicAddresses[0].address.trim())){
					AlertDialog('Informação incompleta!','Favor informar o endereço eletrônico.')
					return false
				}
			break

			default:
			break
		}

		return true
    }

	backPage(){
		if (this.state.index > 0) {
			this.setState({index: this.state.index - 1}) 
		} else {
			this.setState({redirect:true})
		}
	}

	getStepClass(index){
		// let classes = "step"
		if (index === this.state.index)
			return "activeStep"

		if (index < this.state.index)
			return "passedStep"

		if (index > this.state.index)
			return "notPassedStep"
	}

  render() {
	  if (this.state.redirect) {
          return <Redirect to = '/' />
      }
    return (
    	<div className="register">
			<form className="form-register">
				<div className="steps">
					<div className="content-circles">
						<ul>
							<li className= {this.getStepClass(0)}>1</li>
							<li className= {this.getStepClass(1)}>2</li>
							<li className= {this.getStepClass(2)}>3</li>
						</ul>
					</div>
				</div>
				<div className="content-register">
					<div className="info-register">
						{(this.state.index === 0 ) && <div className="customer-form">
							<InputText placeholder="Digite seu nome aqui..." text state="name" value={this.state.name} onChange={this.onChange.bind(this)} label="Nome"/>
							<InputText placeholder="Digite seu sobrenome aqui..." text state="surname" value={this.state.surname} onChange={this.onChange.bind(this)} label="Sobrenome"/>
							<InputText date placeholder="xx/xx/xxxx" state="birthDate" value={this.state.birthDate} onChange={this.onChange.bind(this)} label="Data de Nascimento" />
							<InputText text placeholder="xxx.xxx.xxx-xx" state="identifier" value={this.state.identifier} onChange={this.onChange.bind(this)} label="CPF"/>
							<label> 
								Gênero 
								<select className="select" state="gender" onChange={this.onChangeSelect.bind(this)}>
									<option defaultChecked value ="M">Masculino</option>
									<option value="F">Feminino</option>
									<option value="O">Outro</option>
									<option value="N">Não quero identificar</option>
								</select>
							</label>
							<InputText text placeholder="xxxxx-xxxx" state="telephone" value={this.state.telephone} onChange={this.onChange.bind(this)} label="Telefone"/>
							<InputText password placeholder="******" state="password" value={this.state.password} onChange={this.onChange.bind(this)} label="Senha"/>
							<InputText password placeholder="******" state="confirmpassword" value={this.state.confirmpassword} onChange={this.onChange.bind(this)} label="Confirmar senha"/>
						</div>}

						{(this.state.index === 1 ) && <div className="customer-form">
							<InputText text placeholder="xxxxx-xxx" state="zipCode" value={this.state.zipCode} onChange={this.onChange.bind(this)} label="CEP" onBlur={()=>this.getAddress(this.state.zipCode)}/>
							<InputText text placeholder="Digite sua rua aqui..." state="street" value={this.state.street} onChange={this.onChange.bind(this)} label="Rua"/>
							<InputText text placeholder="Digite o complemento aqui..." state="complement" value={this.state.complement} onChange={this.onChange.bind(this)} label="Complemento"/>
							<InputText text placeholder="Digite seu bairro aqui..." state="district" value={this.state.district} onChange={this.onChange.bind(this)} label="Bairro"/>
							<InputText text placeholder="Digite o número da sua residência aqui..." state="number" value={this.state.number} onChange={this.onChange.bind(this)} label="Número"/>
							<InputText text placeholder="Digite sua cidade aqui..." state="city" value={this.state.city} onChange={this.onChange.bind(this)} label="Cidade" />
							<InputText text placeholder="Digite seu estado aqui..." state="state" value={this.state.state} onChange={this.onChange.bind(this)} label="Estado" />
							<label>
								País
								<CountryBox/>
							</label>
						</div>}

						{(this.state.index === 2 ) && <div className="customer-form">
							<InputText text placeholder="exemplo@exemplo.com.br" state="address" value={this.state.address} onChange={this.onChange.bind(this)} label="Contato" />
							<label>
								Tipo
								<select className="select" state="type">
										<option defaultChecked value ="E_MAIL">E-mail</option>
										<option value="FACEBOOK">Facebook</option>
										<option value="INSTAGRAN">Instagram</option>
										<option value="TWITTER">Twitter</option>
										<option value="GOOGLE_PLUS">Google Plus</option>
								</select>
							</label>
							<InputText text placeholder="exemplo@exemplo.com.br" state="address2" value={this.state.address2} onChange={this.onChange.bind(this)} label="Contato" />
							<label>
								Tipo
								<select className="select">
										<option defaultChecked value ="E_MAIL">E-mail</option>
										<option value="FACEBOOK">Facebook</option>
										<option value="INSTAGRAN">Instagram</option>
										<option value="TWITTER">Twitter</option>
										<option value="GOOGLE_PLUS">Google Plus</option>
								</select>
							</label>
							<InputText text placeholder="exemplo@exemplo.com.br" state="address3" value={this.state.address3} onChange={this.onChange.bind(this)} label="Contato" />
							<label>
								Tipo
								<select className="select">
										<option defaultChecked value ="E_MAIL">E-mail</option>
										<option value="FACEBOOK">Facebook</option>
										<option value="INSTAGRAN">Instagram</option>
										<option value="TWITTER">Twitter</option>
										<option value="GOOGLE_PLUS">Google Plus</option>
								</select>
							</label>
						</div>}

						<div className="buttons">
							<Button invert smallButton onClick={()=>this.backPage()} text="Voltar" icon={<ArrowLeft/>}/>
							{(this.state.index !== 2) && <Button smallButton onClick={()=>this.registerCustomer()} text="Próximo" icon={<ArrowRight/>}/>}
							{(this.state.index === 2) && <Button smallButton onClick={()=>this.registerCustomer()} text="Finalizar" icon={<ArrowRight/>}/>}
						</div> 
					</div>
				</div>
			</form>
			<div className="background-register"></div>
    	</div>
    );
  }
}

export default register;