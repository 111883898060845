import React from 'react';
import './styles.scss'
import EmptyMessage from '../emptyMessage/emptyMessage';
import LoadingMessage from '../LoadingMessage/loadingMessage';
import Button from '../Button/button';

const Ranking = (props) => {

    const {children, data, style, onClick, searchingMore, onSeeMoreClick} = props

    const onSeeMoreHandle = () => {
        onSeeMoreClick()
    }

    return (
        <ul className='ranking-list' style={style}>
            {data && Array.isArray(data) && data.map((field, index) =>
                <li key={index} className="ranking-things" onClick={()=>{onClick(field)}}><p>{index + 1}</p>
                    <div className="rankingCard-item">
                        <h1>{props.loading ? '...' : field.title}</h1>
                        <h2>{field.subtitle}</h2>
                    </div>
                   {children}
                </li>
            )}
            {(onSeeMoreClick && data && data.length > 0)&& <Button textOnly text={searchingMore ? 'carregando...': 'ver mais'} onClick={onSeeMoreHandle}/>}
            {data && data.length == 0 && <EmptyMessage title='Nenhum dado encontrado' description='Não há dados suficientes para elaborar o ranking' icon='empty-box'/>}
            {!Array.isArray(data) && <LoadingMessage title='Buscando dados...' description='Aguarde um instante!'/>}
        </ul>
    );
}
 
export default Ranking;