import React, { useState } from 'react'
import "./SwitchList.css"

const SwitchList = (props) => {

    const [active, setActive] = useState(props.active || 0)
    const [width, setWidth] = useState(props.width || 500)

    const onSwitch = (item, idx) =>{
        setActive(idx)
        if(props.onChange) props.onChange(item, idx)
    }

    return (
        <div style={{width, ...props.style}} className={"SwitchList " + (props.flat && ' flat')}>
            <div style={{width: width / props.data.length, left: ((width / props.data.length) * active) + 4, backgroundColor: props.color || "#339AFF" }} className="SwitchBackground" />
            {
               props.data && props.data.map((item, idx) => <div onClick={()=>onSwitch(item, idx)} key={idx}><p style={{color: active == idx ? '#fff' : '#333'}} >{item.text}</p></div>)
            }
        </div>
    )
}

export default SwitchList