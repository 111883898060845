import React, { Component, useState, useEffect } from 'react';
import './inputText.scss';

const InputText = props => {
  const [charLimit, setCharLimit] = useState(0)

  useEffect(()=>{
    if(props.maxLength) setCharLimit(props.maxLength)
  },[])

  useEffect(()=>{
    if(props.maxLength)
      setCharLimit(props.maxLength-props.value.length)
  },[props.value])
  
  const onFieldChange = (event) => {
    const fieldName = props.state;
    const fieldValue = event.target.value;
    props.onChange(fieldName, fieldValue);
  }


  return (
    <label className="title" style={props.style}>
      {props.label && <p className='label'>{props.label}
        {props.maxLength && <h3>{charLimit}</h3>}
      </p>}
      {(props.multiline) && (<textarea id={props.id} disabled={props.disabled} rows={(props.rows) ? props.rows : '5'} className='textInput' maxLength={props.maxLength} placeholder={props.placeholder} value={props.value} onChange={onFieldChange.bind(this)} style={props.styleInput}></textarea>)}
      {(props.text) && <input disabled={props.disabled} type="text" placeholder={props.placeholder} className='textInput' value={props.value} onChange={onFieldChange.bind(this)} onBlur={props.onBlur} style={props.style} maxLength={props.maxLength}></input>}
      {(props.textEmoji) && <>
        <input disabled={props.disabled} type="text" placeholder={props.placeholder} className='textInput' value={props.value} onChange={onFieldChange} onBlur={props.onBlur} style={props.style} maxLength={props.maxLength} />
        //TODO
      </>}
      {(props.number) && 
        <input disabled={props.disabled} type="number" placeholder={props.placeholder} 
          className='textInput' value={props.value} 
          onChange={onFieldChange.bind(this)} onBlur={props.onBlur} 
          min={props.min} max={props.max}></input>}
    {(props.password) && <input disabled={props.disabled} type="password" placeholder={props.placeholder} className='textInput' value={props.value} onChange={onFieldChange.bind(this)}></input>}
        {(props.date) &&  <input type="date" disabled={props.disabled} className='textInput' placeholder={props.placeholder} value={props.value} onChange={onFieldChange.bind(this)}></input>}
        {(props.time) && <input disabled={props.disabled} type="time" id={props.id} placeholder={props.placeholder} className='textInput' value={props.value} onChange={onFieldChange.bind(this)}></input>}
      </label>
    )
}

export default InputText