import { confirmAlert } from 'react-confirm-alert';

export const AlertDialog = (title,message) => {
	confirmAlert({
	    title: title,
		message: message,
		buttons: [{
		    label: 'Entendi!',
		    onClick: () => {}
	    }]
	})
};

export const AskDialog = (title,message,confirmed = ()=>{}, denied=()=>{}) => {
	confirmAlert({
	    title: title,
		message: message,
		buttons: [{
			label: 'Cancelar',
			colo: '#ff000f',
		    onClick: denied
	    },{
		    label: 'Continuar',
		    onClick: confirmed
		}],
		willUnmount: () => {
			denied()
		}
	})
};

export const AlertDialogWithConfirm = (title, message, confirmed = () => {}) => {
	confirmAlert({
	    title: title,
		message: message,
		buttons: [{
		    label: 'Entendi!',
		    onClick: confirmed
	    }],
		willUnmount: () => {
			confirmed()
		}
	})
};

export const ContinueAction = (title, message) =>{
	return new Promise((resolve) =>{
		AskDialog(title, message, ()=>resolve(true), ()=>resolve(false)) 
	})
}