import React, { Component } from 'react'
import SingleDatePickerWrapper from './SingleDatePickerWrapper'

import './CalendarDayPicker.scss'

const moment = require('moment')

class CalendarDayPicker extends Component{
    constructor(props){
        super(props)
        this.state = {
            dateStart :props.startDate,
            dateEnd : props.endDate,
            momentStart: props.startDate,
            momentEnd: props.endDate,
            focusedStartField: false,
            focusedStart : false,
            focusedEnd : false,
          }
    }

    fill = (str, c, length) => {
		while (str.length < length)
			str = `${c}${str}`
		return str
    }

    getDateString = momentDate => {
        if(!momentDate) return ''
        let day = this.fill(momentDate.toDate().getDate().toString(), '0', 2)
        let month = this.fill((momentDate.toDate().getMonth() + 1).toString(), '0', 2) 
        let year = this.fill(momentDate.toDate().getFullYear().toString(), '0', 2)

        return `${day}/${month}/${year}`;
    }
    
    OnDateSet = (date, state) => {
        const dateState = `date${state}`

        let dateStrValue = this.getDateString(date)
        
        this.props.stateDateWrapper(date, dateState)        
        this.setState( {[`moment${state}`]: date, [dateState]: dateStrValue} )
        
        return date
    }

    onChangeDateField = (event, dateState, momentState) => {
        let dateValue = event.target.value
        let valueLength = dateValue.length
        let parsedDate = this.state[dateState]
        let momentDate = this.state[momentState]

        const filterDigits = val => {
            const isDigit = char => {
                return /\d/.test(char)
            }
            
            if(val.length <= 0) return ''
            val = val.split('')
                .filter( char => {
                    return isDigit(char)
                }).reduce((acc, cur) => {
                    return acc + cur
                }, '')
            return val
        }

        const parseDate = val => {
            let _parsedDate = filterDigits(val)
            let finalParsedDate = _parsedDate
            
            if(_parsedDate.length >= 7){
                finalParsedDate = `${_parsedDate.substring(0, 2)}/${_parsedDate.substring(2, 4)}/${_parsedDate.substring(4, 8)}`
                if(_parsedDate.length >= 8){
                    let _momentDate = moment(finalParsedDate, 'DD/MM/YYYY')
                    if(_momentDate.isValid()){
                        momentDate = _momentDate
            
                        this.props.stateDateWrapper(momentDate, dateState)
                    }
                }
            }
            return finalParsedDate
        }

        if(valueLength <= 8 || valueLength == 10){
            parsedDate = parseDate(dateValue)
        } else if(parsedDate.length > valueLength){
            parsedDate = dateValue                        
        }

        this.setState({[dateState]: parsedDate, [momentState]: momentDate})
    }

    OnFocusChange = (focused, state) => {
        const inputField = `${state}Field`

        if(this.state[inputField]){
            this.setState({[state]: true, [inputField]:false})
        } else {
            this.setState({[state]: focused})
        }
    }

    ShouldDayHighlights = day => {
        if (day >= this.state.momentStart && day <= this.state.momentEnd){
            return true
        }
        return false
    }

    render(){
        return(
            <div className='container-date'>
                <div className='column'>
                    <p className='dates-label'>Data de início</p>
                    <div className='row'>
                        <SingleDatePickerWrapper 
                            date={this.state.momentStart ? this.state.momentStart : moment() }
                            placeholder={'Início'} 
                            type={'Start'} 
                            dayHighlightWrapper={this.ShouldDayHighlights}
                            stateDateWrapper={this.OnDateSet}  
                            focusDateWrapper={focused => { this.OnFocusChange(focused, 'focusedStart') }} 
                            focused={this.state.focusedStart} 
                        />
                    </div>
{/* 
                    <input type='text' 
                        onChange={e => this.onChangeDateField(e, 'dateStart', 'momentStart')} 
                        value={this.state.dateStart} 
                        onFocus={() => {
                            this.setState({focusedStartField: true, focusedStart: true})}}
                        onKeyDown={event => {
                            if(event.keyCode == 9)
                                this.setState({focusedStartField: false, focusedStart: false})
                        }}
                        >
                    </input> */}
                </div>
                <div className='column'>
                    <p className='dates-label'>Data de término</p>
                    <div className='row'>
                        <SingleDatePickerWrapper 
                            date={this.state.momentEnd ? this.state.momentEnd : moment()}
                            placeholder={'Término'} 
                            type={'End'} 
                            stateDateWrapper={this.OnDateSet} 
                            focusDateWrapper={focused => { this.OnFocusChange(focused, 'focusedEnd') }} 
                            focused={this.state.focusedEnd}
                            dayHighlightWrapper={this.ShouldDayHighlights}
                        />
                    </div>
                    {/* <input type='text' 
                        onChange={e => this.onChangeDateField(e, 'dateEnd', 'momentEnd')} 
                        value={this.state.dateEnd}
                        onFocus={() => {
                            this.setState({focusedEndField: true, focusedEnd: true})}}
                        onKeyDown={event => {
                            if(event.keyCode == 9)
                                this.setState({focusedEndField: false, focusedEnd: false})
                        }}
                        >
                    </input> */}
                </div>
            </div>
        )
    }
}

export default CalendarDayPicker