import React, { useState, useEffect } from 'react'
import { PlusCircle, MinusCircle, Trash, Edit } from 'react-feather'
import './style.scss'
const HighlightedCoupon = (props) => {

    const [loading, setLoading] = useState(false)
    const {image, title, subtitle, img, edit, id, status, onClick, onPublish, onRemove, onDelete, onEdit, style} = props

    useEffect(()=>{

    },[])

    const onPublishHandle = () => {
        if(onPublish) return onPublish({image, title, id, status})
    }

    const onRemoveHandle = () => {
        if(onRemove) return onRemove({image, title, id, status})
    }

    const onDeleteHandle = () => {
        if(onDelete) return onDelete({image, title, id, status})
    }

    const onEditHandle = () => {
        if(onEdit) return onEdit({image, title, id, status})
    }

    return(
        <div id={`highlighted-coupon-container`} style={props.style} className={false ? 'loading-section' : ''}>
            <img src={img} alt={`imagem representando ${title}`}/>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
        </div>
    )
}

export default HighlightedCoupon