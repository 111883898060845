import {registerEP} from '../server/endpoints_js'
import {treatMessage} from '../Utils'
export default class RegisterApi {
	static addCustomer = (token, customer, successFunc = () => {}, errorFunc = () => {}) => {
		let json = JSON.stringify(customer)
		let url = registerEP.addCustomer()
			fetch(url, {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': token
				},
				body: json
			}).then((response) => {
				if (response.status === 200)
					return response.json()
				throw response
			}).then(response => {
				successFunc(response)
			}).catch(error => {
                treatMessage(error, () => {}, (message) => {
					console.error(message)
                    errorFunc(message)
                })
			}) 
	}
}