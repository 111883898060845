import React, {useState} from 'react'
import { Smile } from 'react-feather'
import Picker from 'emoji-picker-react';
import './style.scss'
import { NotificationManager } from 'react-notifications';

const EmojiPicker = (props) => {
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const [open, setOpen] = useState(false)

    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(emojiObject);
        navigator.clipboard.writeText(emojiObject.emoji)
        NotificationManager.success('agora é só colar. (ctrl+v)', 'Emoji copiado!')
        setOpen(false)
        // props.onChange(props.state, props.value + emojiObject.emoji);
    }

    const onClickHandle = () => {
        setOpen(!open)
    }

    return(
        <div id='emoji-picker' className='actions' style={props.style}>
            <Smile color={'#8a8a8a'} size={21} onClick={onClickHandle}/>
            <div className={`emoji-container ${open ? 'open' : ''}`}>
                <Picker onEmojiClick={onEmojiClick}/>
            </div>
        </div>
    )
}

export default EmojiPicker