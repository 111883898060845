import React, { Component } from 'react'
import AsideProfileCard from '../../component/AsideProfileCard/AsideProfileCard'
import AsideListCard from '../../component/AsideListCard/AsideListCard'
import AsideButtonCard from '../../component/AsideButtonCard/AsideButtonCard'
import './asideStore.css';
import deepProps from '../../Utils'
import storeImg from '../../img/store-icon.png'
import {AlertDialog, AskDialog} from '../../component/alertDialog/alertDialog'
import StoreApi from '../../api/StoreApi';

class AsideStore extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status:'UNKNOW'
		}
	}

	loadData (props) {
		// console.log(props)
		let data = deepProps('data')(props).getOrElse({})
		let description = deepProps('description')(data).getOrElse('')
		let number = deepProps('number')(data).getOrElse(0)
		let id = deepProps('id')(data).getOrElse(0)
		let status = deepProps('status')(data).get()
		this.setState({store:data, description, number, id, status})
	}

	componentDidUpdate(prevProps) {
		if (this.props === prevProps)
			return
		this.loadData(this.props)
	}

	enable = (enable = true) => {
		this.updateStatusStore(enable)
	}

	remove = () => {
		let store = deepProps('store')(this.props).get()
		if (store === undefined) {
			AlertDialog('Ops, algo deu errado', 'Ação indevida')
			return
		}
		let token = deepProps('token')(store.getState()).getOrElse('')
		let storeId = deepProps('data', 'id')(this.props).getOrElse(0)
        AskDialog('Deseja realmente remover os dados da loja?', 'Por favor confirme a solicitação', () => {
            StoreApi.deleteStore(token, storeId, () => {
				AlertDialog('Os dados da loja foram removidos com sucesso.', '')
				this.props.store.dispatch({type:'STORE_DETAILS', storeDetail:{}})
            }, (errorMessage) => {
                AlertDialog('Ops, algo deu errado', 'Não foi possível alterar o status da loja.' + errorMessage)
            })
        })
	}

	updateStatusStore = (enable) => {
		let store = deepProps('store')(this.props).get()
		if (store === undefined) {
			AlertDialog('Ops, algo deu errado', 'Ação indevida')
			return
		}
		let token = deepProps('token')(store.getState()).getOrElse('')
		let storeId = deepProps('data', 'id')(this.props).getOrElse(0)
		let message = 'Deseja realmente ' + (enable ? 'ativar' : 'desativar') + ' a loja'
        AskDialog(message, 'Por favor confirme a solicitação', () => {
            StoreApi.updateStatusStore(token, storeId, enable, () => {
				AlertDialog('O status da loja foi alterado para ' + (enable ? 'ativa' : 'desabilitada'), '')
				this.setState({status: (enable ? 'ENABLE' : 'DISABLE')})
            }, (errorMessage) => {
                AlertDialog('Ops, algo deu errado', 'Não foi possível alterar o status da loja.' + errorMessage)
            })
        })
	}

  	render() {
		return (
			<aside className="asideUser">
				<AsideProfileCard img={storeImg} title={deepProps('description')(deepProps('data')(this.props).getOrElse({})).getOrElse('')}/>
				{(this.props.card2 && this.props.card2.length) > 0 && <AsideListCard data={this.props.card2}/>}
				{(this.props.card3 && this.props.card3.length > 0) && <AsideListCard title={"Endereço"} data={this.props.card3}/>}
				{((this.state.status === 'ENABLE') && (this.props.write)) && <AsideButtonCard title='Desativar Loja' text='Ao desativar a loja, os dados não aparecerão em diversas telas do sistema'
                	button='Desativar' icon='check' onClick={()=>this.enable(false)}/>}
				{(this.state.status === 'DISABLE') && (this.props.write) && <AsideButtonCard title='Ativar Loja' 
					button='Ativar' icon='check' onClick={()=>this.enable()}/>}
				{(this.state.status === 'DISABLE') && (this.props.write) && <AsideButtonCard title='Deletar Loja' text='Ao deletar a loja, os dados serão deletados' 
					button='Deletar' icon='check' onClick={()=>this.remove()}/>}
			</aside>
   		);
  	}
}

export default AsideStore;