import React, { Component } from 'react';
import Grid from '../../Grid/grid';
import InputText from '../../inputText/inputText';
import { getPromotionItensHistoric, getPromotionReportByPromotion } from '../../../api/ReportApi';
import Button from '../../Button/button';
import './reports.scss'
import DropDownFilter from '../../DropDownFilter/DropDownFilter';
import deepProps, {fill, getFormattedDate} from '../../../Utils';
import { Loading } from '../../LoadingMessage/loadingMessage';
import CalendarDayPicker from '../../CalendarDayPicker/CalendarDayPicker'

const moment = require('moment')

export default class Reports extends Component {
    constructor(props){
        super(props)
        this.state = {
            data:[],
            headerGrid: [],
            report: 'r1',
            maxPerPage : 8, 
            page : 0, 
            reportFetch:[], 
            startDate : props.initialStartDate,
            endDate : props.initialEndDate
        }
        
        this.REPORTS = [{text:'Sintético', value:'r1'},{text:'Analítico', value:'r2'}]
    }

    async componentWillMount(){
        let token = deepProps('token')(this.props.store.getState()).getOrElse([])
        let promotion = deepProps('promotionDetails')(this.props.store.getState()).getOrElse([])
        let dateStart = deepProps('dateStart')(promotion).getOrElse('1990-01-01T02:59:00.000+0000')
        let date = new Date(dateStart)
        let momentStart = moment(date)
        dateStart = date.getFullYear() + '-' + fill((date.getMonth() + 1) + '', '0', 2) + '-' + fill(date.getDate() + '', '0', 2)
        let dateEnd = deepProps('dateEnd')(promotion).getOrElse('1990-01-01T02:59:00.000+0000')
        date = new Date(dateEnd)
        let momentEnd = moment(date)
        dateEnd = date.getFullYear() + '-' + fill((date.getMonth() + 1) + '', '0', 2) + '-' + fill(date.getDate() + '', '0', 2)
        // console.log(dateStart + ':' + dateEnd)
        await this.setState({promotion, token, dateStart, dateEnd, startDate: momentStart, endDate: momentEnd})
        // console.log(promotion)
        this.onSearchClick()
    }

    async getPromotionItensHistoric(token, promotionID, startDate, endData, store, format) {
        return await getPromotionItensHistoric(token, promotionID, startDate, endData, store, format)
    }   
    
    async getPromotionReportByPromotion(token, promotionID, startDate, endData, size=10, page=0, store=-1, format='json') {
        endData = this.formatDate(endData)
        startDate = this.formatDate(startDate)
        return await getPromotionReportByPromotion(token, promotionID, startDate, endData, store, page, size, format)
    }

    async downloadFile (format = 'csv') {
        let {token, dateStart, dateEnd, report} = this.state
        let promotionId = deepProps('promotion', 'id')(this.state).get()
        if (promotionId === undefined)
            return
        let date = new Date()
        let fileName = report === 'r1' ? `sintetico-${promotionId}-${date.getFullYear() + '' + fill((date.getMonth() + 1) + '', '0', 2) + '' + fill(date.getDate(), '0', 2)}${fill(date.getHours(), '0', 2)}${fill(date.getMinutes(), '0', 2)}`:
        `analitico-${promotionId}-${date.getFullYear() + '' + fill((date.getMonth() + 1) + '', '0', 2) + '' + fill(date.getDate(), '0', 2)}${fill(date.getHours(), '0', 2)}${fill(date.getMinutes(), '0', 2)}`
        let result = undefined
        if (report === 'r1')
            result = await this.getPromotionItensHistoric(token, promotionId, dateStart, dateEnd, 0, format)
        else
            result = await this.getPromotionReportByPromotion(token, promotionId, dateStart, dateEnd, 0, 0, -1, format)
        let extension = '.csv'
        switch(format) {
            case 'xls':
                extension = '.xls'
            break
            default:break
        }
        this.saveFileAs(fileName + extension, result)
    }

    saveFileAs = (fileName, blob) => {
        let a = document.createElement("a")
        let url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
    }

    formatDate = (dataString) => {
        if (dataString === undefined)
            return '1980-01-01'
        let regEx = /^\d{4}-\d{2}-\d{2}$/
        if (dataString.match(regEx) !== null && dataString.match(regEx) !== undefined)
            dataString += 'T00:00:00'
        return dataString
    }

    onReportSelect(filter){
        this.setState({gridTitle:filter.text, report:filter.value})
        switch(filter.value){
            case 'r1':
                // this.setState({headerGrid:[], data:[]})
                break
            case 'r2':
                // this.setState({headerGrid:headerGrid, data:data})
                break
                default : break
        }
    }

    async search () {

        let {token, dateStart, dateEnd, report, maxPerPage, page} = this.state
        console.log(dateEnd)
        dateEnd += 'T23:59:59'
        await this.setState({searching:true})
        let promotionId = undefined
        let paginator = false
        let total_page = 0
        let current_page = 0
        let data = []
        try{
            switch(report){
                case 'r1':
                    promotionId = deepProps('promotion', 'id')(this.state).get()
                    if (promotionId === undefined)
                        return
                    let response = await this.getPromotionItensHistoric(token, promotionId, dateStart, dateEnd)
                    // console.log(response)
                    data = deepProps('data')(response).get()
                    if (data === undefined)
                        data = []
                    data = data.map(d => {
                        d['timesUsed'] = d.uses.map(e => e.useCount).reduce((sum, a) => sum + a, 0)
                        let measurement_unit = deepProps('measurement_unit')(d).get()
                        if (measurement_unit === 'WEIGHTED') {
                            let q = deepProps('timesUsed')(d).getOrElse(0.00)
                            d['timesUsed'] = q.toFixed(3) + " KG";
                        }
                        console.log(d)
                        return d
                    })
                    // console.log(data)
                    let reportFetch = data
                    data = reportFetch.slice(0, maxPerPage)
                    paginator = reportFetch.length > maxPerPage
                    total_page = reportFetch.length / maxPerPage

                    // console.log(data)
                    this.setState({headerGrid:headerGridSintetica, data, searching:false, paginator, page:0, total_page, reportFetch})
                    break
                case 'r2':
                    promotionId = deepProps('promotion', 'id')(this.state).get()
                    if (promotionId === undefined)
                        return
                    let result = await this.getPromotionReportByPromotion(token, promotionId, dateStart, dateEnd, maxPerPage, page)
                    // console.log(result)
                    let dataSet = deepProps('data')(result).getOrElse([])
                    dataSet = dataSet.map(item => {
                        let fullPrice = deepProps('full_promotion_value')(item).get()
                        let quantity = deepProps('quantity')(item).get()
                        if (fullPrice === undefined)
                            fullPrice = 0.00
                        if (quantity === undefined)
                            quantity = 0.00
                        let item_discount = 0.00
                        if (fullPrice > 0 && quantity > 0)
                            item_discount = fullPrice / quantity
                        item['item_discount'] = item_discount.toFixed(2)
                        item['full_promotion_value'] = fullPrice.toFixed(2)
                        item['commited_at'] = getFormattedDate(item['commited_at'], false)
                        let subTotal = deepProps('subtotal_sale_value')(item).getOrElse(0.00)
                        item['subtotal_sale_value'] = subTotal.toFixed(2)
                        let total = deepProps('total_sale_value')(item).getOrElse(0.00)
                        let itemPrice = deepProps('unitary_value')(item).getOrElse(0.00)
                        item['unitary_value'] = itemPrice.toFixed(2)
                        item['total_sale_value'] = total.toFixed(2)
                        let measurement_unit = deepProps('measurement_unit')(item).get()
                        if (measurement_unit === 'WEIGHTED') {
                            let q = deepProps('quantity')(item).getOrElse(0.00)
                            item['quantity'] = q.toFixed(3) + " KG";
                        }
                        return item
                    })
                    // console.log(dataSet)
                    let paging = deepProps('paging')(result).get()
                    total_page = 0
                    current_page = 0
                    paginator = paging !== undefined
                    if (paginator) {
                        let count = deepProps('count')(paging).getOrElse(0)
                        current_page = deepProps('page')(paging).getOrElse(0)
                        let size = deepProps('size')(paging).getOrElse(-1)
                        if (size > 0) {
                            total_page = ~~(count / size)
                            if (count % size !== 0)
                                total_page++
                        }
                    }
                    this.setState({headerGrid:headerGridAnalystic, data:dataSet, searching:false, total_page, page : current_page, paginator})
                    // this.setState({searching:false})
                    break
                default:break
            }
        }catch (e) {
            console.error(e)
            this.setState({searching:false})
        }
    }

    async onSearchClick(){
        await this.setState({page:0})
        this.search()
    }

    async nextPage () {
        let {page, total_page, searching, report, maxPerPage} = this.state
        if (searching)
            return
        if (page < total_page)
            page++
        await this.setState({page})
        switch(report) {
            case 'r1':
                let {reportFetch} = this.state
                let idx = page * maxPerPage
                let data = reportFetch.slice(idx, (page + 1) * maxPerPage)
                this.setState({data})
            break;
            case 'r2':
                this.search()
            break;
            default:
            break;
        }
    }

    async previousPage () {
        let {page, searching, report, maxPerPage} = this.state
        let current_page = page
        if (searching)
            return
        if (page > 0)
            page--
        await this.setState({page})
        switch(report) {
            case 'r1':
                let {reportFetch} = this.state
                let idx = page * maxPerPage
                let data = reportFetch.slice(idx, current_page * maxPerPage)
                this.setState({data})
            break;
            case 'r2':
                this.search()
            break;
            default:
            break;
        }
    }

    onDatesChange = (date, state) => {
        let dateStrValue = date.toDate().toISOString().split('T')[0];
        this.setState( {[state] : dateStrValue} )
        console.log(state)
        return date
    }

	render() {
        let {gridTitle, searching, data, headerGrid, total_page, page, paginator, maxPerPage, report} = this.state
		return (
			<div className='cardTab-container reports'>
                <DropDownFilter filters={this.REPORTS} selected={this.REPORTS[0]} border onSelect={(filter)=>this.onReportSelect(filter)}/>
                <div className='search-report'>
                    <div style={{display:'flex'}}>
                        <CalendarDayPicker 
                            stateDateWrapper={this.onDatesChange} 
                            startDate={this.state.startDate} 
                            endDate={this.state.endDate} 
                        />
                        {/* <InputText label='Data de Início' date state='dateStart' placeholder="dd/MM/yyyy" value={this.state.dateStart} onChange={(event, value) => this.setState({dateStart: value})} style={{maxWidth: 190, marginRight: 8}}/>
                        <InputText label='Data Fim' date state='dateStart' placeholder="dd/MM/yyyy" value={this.state.dateEnd} onChange={(event, value) => this.setState({dateEnd: value})} style={{maxWidth: 190}}/> */}

                        <div style={{display:'flex',alignItems: 'flex-end', marginLeft:'4px'}}>
                            <Button text="Pesquisar" onClick={()=>{this.onSearchClick()}}/>
                            {searching && <Loading style={{height:32,margin: 4}}/>}
                        </div>
                    </div>
                </div>
                {report === 'r1' &&
                    <Grid header={headerGrid} data={data} loading={searching} title={gridTitle}
                        paginator={paginator} total_page={total_page} page={page + 1} 
                    />
                }
                {report === 'r2' &&
                    <Grid header={headerGrid} data={data} loading={searching} maxPerPage={maxPerPage} title={gridTitle}
                        paginator={paginator} total_page={total_page} page={page + 1} 
                    />
                }
                {paginator &&
                    <div className='options paginator'>
                        {page > 0 &&
                            <Button text="Anterior" className="download-button" style={{marginRight: 8}} onClick={() => this.previousPage()} />
                        }
                        <span className="page" style={{marginRight: 8}}>Pagina atual {page + 1}</span>
                        {(page + 1) < total_page &&
                            <Button text="Proximo" className="download-button" onClick={() => this.nextPage()} />
                        }
                    </div>
                }
                <div className='options'>
                    <Button text="CSV" className="download-button" style={{marginRight: 8}} onClick={()=>{this.downloadFile()}}/>
                    <Button text="XLS" className="download-button" onClick={()=>{this.downloadFile('xls')}}/>
                </div>
            </div>
		);
	}
}
const headerGridSintetica = [
    {label : 'Item ID', key:'item_id', type: 'number'},
    {label : 'GTIN', key:'gtin', type: 'text'},
    {label : 'Descrição', key:'description', type: 'text'},
    {label : 'Quantidade promovida', key:'timesUsed', type: 'float'},
]
const headerGridAnalystic = [
    {label: 'Transação', key: 'transaction_id', type : 'number'},
    {label : 'Finalizado', key:'commited_at', type: 'text'},
    {label : 'Item id', key:'promotion_item_id', type: 'number'},
    {label : 'Loja', key:'store_cnpj', type: 'text'},
    {label : 'Desc Loja', key:'store_description', type: 'text'},
    {label : 'Identificação Cliente', key:'customer_identifier', type: 'text'},
    {label : 'GTIN', key:'gtin', type: 'text'},
    {label : 'Quantidade', key:'quantity', type: 'float'},
    {label : 'Valor Unitario', key:'unitary_value', type: 'float'},
    {label : 'Subtotal', key:'subtotal_sale_value', type: 'float'},
    {label : 'Outros descontos', key:'item_discount', type: 'float'},
    {label : 'Total Desconto', key:'full_promotion_value', type: 'float'},
    {label : 'Valor Venda', key:'total_sale_value', type: 'float'},
]
	