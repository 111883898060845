import { removeUndefinedParams } from "../../Utils"
import { ERRORS } from "../../errors"

export const getPromotionNewItemBody = (data) => {
    let {description, displayName, measurementUnit, products, providers, sectors, quantity, imageURL, type} = data
    
    //TODO Remover após criar o campo displayName no endpoint
    if (displayName) 
        description = displayName
    if (!imageURL)
        imageURL = {}
    let obj = {
        description: description,
        displayName: displayName,
        measurementUnit: (!measurementUnit) ? 'UNITARY' : measurementUnit,
        products,
        providers,
        sectors,
        quantity: quantity,
        resourceImage: imageURL.url,
        type: type
    }
    return removeUndefinedParams(obj) 
}

export const getPromotionItemDTO = (item) => {
    let {description, resourceImage, quantity, measurementUnit, products, displayName} = item
    return {
        description: displayName || description,
        resourceImage,
        quantity,
        products,
        type:'PRODUCT',
        displayName,
        measurementUnit
    }
}

export const getItemAssociatedDTO = (list, type) => {  
    if(type !== 'sectors' && list.filter(item => !isNaN(item)).length < list.length) throw ERRORS.INVALID_ITEM
    return list
}

export const getPromotionSetupDTO = (body) => {  
    switch (String(body.promotionPattern).toLocaleLowerCase()) {
        case 'fast':{
            let {value, typeValue} = body
            if(!value) throw 'To setup a fast promotion you need to inform the VALUE param'
            if(!typeValue) throw 'To setup a fast promotion you need to inform the TYPEVALUE param'

            return{value,typeValue}
        }
        case 'voucher':{
            let {value} = body
            if(!value) throw 'To setup a fast promotion you need to inform the value|maxDiscountByCustomer param'
            // if(!maxDiscountBySale) throw 'To setup a fast promotion you need to inform the maxDiscountBySale param'

            return {maxDiscountByCustomer:value,maxDiscountBySale:0,precondition: {restriction: "TRUE"}}
        }
        case "progressive":{
            let {value, stages, typeValue} = body
            if(!value) throw 'To setup a progressive promotion you need to inform the VALUE param'
            if(!typeValue) throw 'To setup a progessive promotion you need to inform the TYPEVALUE param'
            if(!stages) stages = []

            return{value,typeValue,stages}
        }
        default:
            break;
    }
}