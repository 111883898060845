export const getNavigatorLanguage = () => {
    return navigator.language || navigator.userLanguage
}


export const getToken = () => {
    return localStorage.getItem('token')
}

export const makeRequest = async (endpoint, header = getFetchHeader(), expectedStatus, emptyRequest, format = 'json') => {
    let request = await fetch(endpoint, header)
    validateRequest(request, expectedStatus)
    if(request.status === 204)
        return {}
    switch(format) {
        case 'json':
            return await request.json()
        case 'blob':
            return await request.blob()
        default:
        return request
    }
}

export const makeTextRequest = async (endpoint, header = getFetchHeader(), expectedStatus) => {
    let request = await fetch(endpoint, header)
    validateRequest(request, expectedStatus)
    if(request.status === 204)
        return {}
    return await request.text()
}

export const getFetchGETHeader = (content_type = 'application/json', accept = 'application/json') => {
    return {
        method: "GET",
        headers: {
            Accept: accept,
            'Content-Type': content_type,
            'Authorization': getToken(),
            // 'cache-control': 'no-cache', 
            'Accept-Language' : getNavigatorLanguage(),
        }
    }
}

export const getFetchPOSTHeader = (body) => {
    return {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': getToken(),
            'Accept-Language' : getNavigatorLanguage(),
        },
        body:JSON.stringify(body)
    }
}

export const getFetchHeader = (method = 'GET', body, content_type = 'application/json', accept = 'application/json') => {
    if(body)body = JSON.stringify(body)
    return {
        method,
        headers: {
            Accept: accept,
            'Content-Type': content_type,
            'Authorization': getToken(),
            // 'cache-control': 'no-cache', 
            'Accept-Language' : getNavigatorLanguage(),
        },
        body
    }
}

export const getFetchFormDataHeader = (method = 'POST', formData) => {
    return {
        method,
        headers: {
            'Authorization': getToken(),
            // 'cache-control': 'no-cache', 
            'Accept-Language' : getNavigatorLanguage(),
        },
        body: formData
    }
}

export const getFetchPUTHeader = (body) => {
    return {
        method: "PUT",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': getToken(),
            'Accept-Language' : getNavigatorLanguage(),
        },
        body:JSON.stringify(body)
    }
}

export const getFetchImagePOSTHeader = (img) => {
    return {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Authorization': getToken(),
        },
        body:img
    }
}

export const getFetchDELETEHeader = (body) => {
    if (body !== undefined)
        body = JSON.stringify(body)
    return {
        method: "DELETE",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': getToken(),
            'Accept-Language' : getNavigatorLanguage(),
        },
        body: body
    }
}

export const getPUTRequestSettings = (token, body) => {
    return {
        method: "PUT",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token,
            'Accept-Language' : getNavigatorLanguage(),
        },
        body:JSON.stringify(body)
    }
}

export const getRequestSettings = (type = 'GET', token, body = undefined, content_type = 'application/json', accept = 'application/json') => {
    let headers = {}
    if (content_type !== undefined)
        headers['Content-Type'] = content_type
    if (accept !== undefined)
        headers['Accept'] = accept
    headers['Authorization'] = token
    headers['Accept-Language'] = getNavigatorLanguage()
    let response = {
        method: type,
        headers: headers
    }
    
    if(body !== undefined)
        response["body"] = JSON.stringify(body)
    
    return response
}

export const requestResponseValidate = async (request, format = 'json') => {
    //TODO: Implementar o redirecionamento para o login
    if(request.status !== 200)
        throw request
    let response = undefined
    // console.log(request)
    switch(format) {
        case 'json':
            response = await request.json()
        break
        case 'xls':      
        case 'csv':
            response = await request.blob()  
        break
        default:break
    }
    return response
}

export const validateRequest = async (request, expectedStatus = [200, 201, 204]) => {
    let {status} = request
    if(!expectedStatus.includes(status)) 
        throw request
}