import React, { useEffect } from 'react';
import './rankingCard.scss';
import EmptyMessage from '../emptyMessage/emptyMessage'
import { RankingInfo, RankingState } from '../KPI/info';
import ExportKPIData from '../KPI/exportKPIData';
import Card from '../card';

export default function RankingCard(props) {
	const {exportColumns, exportFileName, exportName} = props

	useEffect(()=>{
	},[])

	const getPromotionLabel = (status, position) => {
		switch (status) {
			case 'NEW':
				return 'Nova'
			default:
				return position > 0 ? '+' + position : position
		}
	}

	return (
		<Card
			style={{padding: 0, flex:1, minHeight: 200}}
			header={{
				title:props.title,
				actions:[
					<ExportKPIData data={props.data} columns={exportColumns} filter={props.filter} fileName={exportFileName} name={exportName}/>
				]
			}}>
			<div>
				<div className="rankingCard-list">
					{props.data.length === 0 && <EmptyMessage icon='empty-box' title="Sem dados suficientes" description="Não há dados suficientes para elaborar o ranking" />}
					<ul>
						{props.data && props.data.map((field, index) =>
							<li key={index} className="ranking-things"><p>{index + 1}</p>
								<div className="rankingCard-item">
									<h1>{props.loading ? '...' : field.description}</h1>
									<h2>{field.subtitle}</h2>
								</div>
								<RankingInfo loading={props.loading} value={field.quantity} description={field.measurement_unit === 'WEIGHTED' ? 'Kilos' : 'Unidades'} toFixed={field.measurement_unit === 'WEIGHTED'}/>
								{/* <RankingInfo value={field.quantity} description='Itens promovidos'/> */}
								<RankingState position={String(field.status).toLowerCase()} title={getPromotionLabel(field.status, field.position_change)}/>
							</li>
						)}
					</ul>
				</div>
			</div>
		</Card>
	)
}