import React, { Component } from 'react';
import './profile.css';
import CardTabProfile from '../cardTabProfile/cardTabProfile';
import AsideProfile from '../asideProfile/asideProfile';
import deepProps from '../../Utils';

export default class Profiles extends Component {  
    constructor(props) {
        super(props)
        this.state = {
            token: '',
            read: true,
            write: false
        }
	}

	componentDidMount(){
        this.setState({profile: this.props.store.getState().profileDetail})
        this.subscribeStore()
        let permissions = deepProps('permissions')(this.props.store.getState()).getOrElse([])
		let permission = undefined
		for (let p of permissions) {
			if (deepProps('name')(p).get() === 'OPERATOR') {
				permission = p
				break
			}
		}
		let write = false
		let read = false
		if (permission !== undefined) {
			let array = deepProps('allow')(permission).getOrElse([])
			for (let allow of array) {
				if (allow === 'R') {
					read = true
					continue
				}
				if (allow === 'W') {
					write = true
				}
			}
        }
        this.setState({write, read})
    }
    
    subscribeStore(){
		this.unsubscribe = this.props.store.subscribe(() => {
			let lastState = this.props.store.getState()
			switch(lastState.type) {
                case 'PROFILE_DETAIL':
                    this.setState({profile: lastState.profileDetail})
				break
				default:break
			}
		})
	}

    render() {
        return (
            <section className="cardProfile">
                {this.state.profile && <AsideProfile data={this.state.profile} store={this.props.store} read={this.state.read} write={this.state.write}/>}
                <CardTabProfile store={this.props.store} read = {this.state.read} write={this.state.write}/>
            </section>
        );
    }
}