import React, { useState, useEffect } from 'react';
import './styles.scss'
import { NotificationManager } from 'react-notifications';
import LoadingMessage, { Loading } from '../LoadingMessage/loadingMessage';
import PopupContainer from '../Popup';
import Button from '../Button/button';
import EmptyMessage from '../emptyMessage/emptyMessage';
import InputText from '../inputText/inputText';
import ImagePicker from '../ImagePicker';
import SearchBar from './SearchBar';
import SelectableItem from '../selectableItem/selectableItem';
import Toggle from '../toggle/toggle';
import deepProps, { isObjEmpty } from '../../Utils';
import SeeMoreIndicator from './SeeMoreIndicator';

const SearchModal = (props) => { 
    const [description, setDescription] = useState('')
    const [identifier, setIdentifier] = useState('')
    const [selected, setSelected] = useState('')
    const [resourceImage, setResourceImage] = useState('')
    const [imageURL, setImageURL] = useState(false)
    const [imageUploading, setImageUploading] = useState(false)
    const [searchData, setSearchData] = useState([])
    const [newItem, setNewItem] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false)
    const [loading, setLoading] = useState(false)
    const [measurementUnit, setMeasurementUnit] = useState('UNITARY')
    const [fields, setFields] = useState({})
    const [quantity, setQuantity] = useState('0')
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [filter, setFilter] = useState('products')
    const [searchInput, setSearchInput] = useState('')
    const [loadingSecondary, setLoadingSecondary] = useState(false)
    const [seeMore, setSeeMore] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [seeMoreQuantity, setSeeMoreQuantity] = useState(0)
   
    const onSubmitSearchHandle= async (filters) => {
        try{
            if(onFilterChange) onFilterChange(filters)
            if(props.search){
                if(currentPage === 0){
                    setLoading(true)
                }else{
                    setLoadingMore(true)
                }
                let response = await props.search(filters, currentPage)
                response = response.map(el=>props.mapResponse(el))
                setSearchData([...searchData, ...response])
            
                if(response.length >= 30){
                    setSeeMore(true)
                    setCurrentPage(currentPage+1)
                }else{
                    setSeeMore(false)
                }

                if(currentPage > 0){
                    setSeeMoreQuantity(response.length)
                }
            }
        }finally{
            setLoading(false)
            setLoadingMore(false)
        }
    }

    const onItemSelect = (item, preSelected = false) => {
        if (!item)
            return
        setImageURL('')
        setDescription(preSelected ? item.description : item.title)
        setResourceImage(item.resourceImage)
        setMeasurementUnit(item['measurementUnit'])
        setIdentifier(getIdentifierByType(item))
        setSelected(item)
        setQuantity(item['quantity'] || 1)
        setFields({...fields, displayName: item.description})
    }

    const getIdentifierByType = (item) => {
        console.log(item)
        switch (item.type) {
            case 'PRODUCT':
                return item.GTIN
            case 'SECTOR':
                return item.sectorId
            case 'PROVIDER':
                return item.providers[0].providerId
            default:
                return item.id
        }
    }

    const onSubmitHandle = async () => {
        if(loadingSubmit) return

        if(onSubmit) {
            if (selected !== undefined) {
                if (imageURL) {
                    selected['newResourceImage'] = imageURL
                }
                try {
                    await props.updateObject(selected)
                }catch(e) {
                    console.error(e)
                }
            }
            try{
                setLoadingSubmit(true)
                validateFields()
                if (selected && Object.keys(selected).length > 0)
                    selected['measurementUnit'] = measurementUnit
                let loading = await onSubmit({...selected, ...fields, quantity, imageURL})
                resetSearch()
            }catch(e){
                if(!(typeof(e) == 'object'))
                    NotificationManager.warning(e)
                else
                    NotificationManager.error('Não foi possível completar a ação', 'Ops, algo deu errado!')
            }finally{
                setLoadingSubmit(false)
                setNewItem(false)
            }
        }
    }

    const onSecondaryActionHandle = async () => {
        if(!onSecondaryClick || loadingSecondary) return

        try{
            setLoadingSecondary(true)
            validateFields()
            let response = await onSecondaryClick({description, identifier, quantity, measurementUnit, imageURL, ...fields})
            resetSearch()
        }catch(e){
            if(!(typeof(e) == 'object'))
                NotificationManager.warning(e)
            else
                NotificationManager.error('Não foi possível completar a ação', 'Ops, algo deu errado!')
        }finally{
            setLoadingSecondary(false)
        }
    }

    const onSeeMoreHandle = async () => {
        if(loadingMore) return
        onSubmitSearchHandle({input: searchInput, filter: filter})
    }

    const validateFields = () => {
        if(description === '') throw 'Favor informar a descrição do item' 
        if(identifier === '') throw 'Favor informar o código identificador do item'
        if(measurementUnit == 'WEIGHTED' && (quantity < 0.001)) throw 'A quantidade do item não pode ser menor a 0.001'
        let unitaryUnit = ['UNITARY', undefined, false]
        if(unitaryUnit.includes(measurementUnit) && (quantity <= 0)) throw 'A quantidade do item não pode ser menor ou igual a 0 (zero)'
        if(unitaryUnit.includes(measurementUnit) && (quantity % 1 > 0)) throw 'A quantidade não pode ser decimal (não pode conter vírgula ou ponto)'
        // if(measurementUnit == 'UNITARY' && (quantity <= 0)) throw 'A quantidade do item não pode ser menor ou igual a 0 (zero)'
        // if(measurementUnit == 'UNITARY' && (quantity % 1 > 0)) throw 'A quantidade não pode ser decimal (não pode conter vírgula ou ponto)'
        if(imageUploading) throw 'Aguarde o carregamento da imagem'
    }

    const resetSearch = () => {
        if(props.selectedItem) return
        setDescription('')
        setIdentifier('')
        setQuantity('')
        setResourceImage('')
        setSelected({})
        setCurrentPage(0)
        setSeeMoreQuantity(0)
        setSearchData([])
        setMeasurementUnit(false)
        Object.keys(fields).map(field => setFields({[field]:''}))
    }

    const getButtonLabel = (loading) => {
        if(loading) return 'Carregando...'
        return submitText ? submitText : 'Salvar'
    }

    const onCleanHandle = () => {
        if(onCleanClick)
            onCleanClick()
        resetSearch()
    }   
    
    const enableToggle = (obj) => {
        let result = isObjEmpty(obj)
        if (result)
            return result
        let type = deepProps('type')(obj).getOrElse('UN_KNOW')
        return ['sectors', 'supliers'].includes(type)
    }

    const onToggleHandle = (on) => setMeasurementUnit(on ? 'WEIGHTED' : 'UNITARY')

    const onOnlyFilterChangeHandle = filter => {
        resetSearch()
        setFilter(filter.value)
    }

    const onOnlyInputChangeHandle = value =>{
        resetSearch()
        setSearchInput(value)
    }

    const onCloseHandle = () => {
        resetSearch()
        setFilter('products')
        onClose && onClose()
    }

    const onImageSelectHandle = img => {
        if(img == '') return
        setResourceImage(img)
        setImageUploading(true)
    }

    const onImageUploadHandle = (url) => {
        setImageURL(url)
        setImageUploading(false)
    }

    let {children, onClose, onCleanClick, onSecondaryClick, onSubmit, filters, onFilterChange, selectedFilter, timeout, submitText, secondaryText, editMode} = props
    return (
        <PopupContainer {...props} style={{display: 'flex', flex:1, margin:100, flexDirection: 'row', minWidth: 760, maxWidth: editMode ? 900 : 'auto'}} onClose={onCloseHandle} onOpen={()=> {onItemSelect(props.selectedItem, true)}}>
            <div id='search-search-container'>
                {!editMode && <>
                    <SearchBar filters={filters} selected={selectedFilter} onSubmit={onSubmitSearchHandle} timeout={timeout} onCleanSearch={resetSearch} onFilterChange={filter => onOnlyFilterChangeHandle(filter)} onInputChange={value => onOnlyInputChangeHandle(value)}/>
                    <div id='search-list-container'>
                        {loading && <LoadingMessage title='Buscando resultados' description='Por favor aguarde...'/>}
                        {searchData.length > 0 && !loading && <CircularItemList data={searchData} onSelect={onItemSelect} onEndReached={() => setSeeMoreQuantity(0)}/>}
                        {searchData.length <= 0 && !loading && <EmptyMessage title='Nenhum resultado encontrado' description='Informe uma descrição ou identificador e tentaremos novamente'/>}
                    </div>
                    {seeMore && <footer id='search-footer'>
                        <Button text={loadingMore ? 'carregando...' : 'Ver mais'} shine style={{width: 150, alignSelf: 'center'}} textOnly onClick={onSeeMoreHandle}/>
                        {(!loadingMore && seeMoreQuantity > 0) &&  <SeeMoreIndicator style={{position:'absolute', top:-20, right: 16}} quantity={seeMoreQuantity}/>}
                    </footer>}
                </>}
                {children}
            </div>
            <div id='search-info-container'>
                <h1 className='title' style={{alignSelf: 'baseline'}}>Selecionado</h1>
                <ImagePicker style={{margin: '16px 0px'}} resourceImage={resourceImage} onSelect={img => onImageSelectHandle(img)} onImageUpload={url=>{onImageUploadHandle(url)}} loading={imageUploading}/>
                <div style={{flex: 1, width: '100%', overflow: 'scroll'}}>
                    <InputText text disabled={!isObjEmpty(selected)} label='Descrição' placeholder="Digite a descrição aqui..." value={description} onChange={(field, value) => setDescription(value)}/>
                    <InputText text disabled={!isObjEmpty(selected)} label='Identificador' placeholder="Digite a identificador aqui..." value={identifier} onChange={(field, value) => setIdentifier(value)}/>
                    <InputText text label='Nome de exibição' placeholder="Digite o nome que os clientes verão aqui..." value={fields.displayName} onChange={(field, value) => setFields({...fields,displayName:value})}/>
                    {true && (
                        <div>
                            <InputText number  label={`Quantidade limite - ${(measurementUnit !== 'WEIGHTED' ? 'em unidades' : 'em medida do item')}`} placeholder="Digite a quantidade limite aqui..." value={quantity} onChange={(field, value) => setQuantity(value)}/>
                            <div style={{paddingLeft: 4, paddingTop: 8}}>
                                <p className="title" style={{paddingBottom: 8}}>Peso variável</p>
                                <div className='row'>
                                    <Toggle disabled={!enableToggle(selected)}  switch small active={measurementUnit === 'WEIGHTED'} value={measurementUnit} onClick={(on)=>onToggleHandle(on)}/>
                                    {!props.selectedItem && <img className='icon-bounce' src={require('../../img/trash-2.svg')} onClick={onCleanHandle}/>}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {(secondaryText && filter == "products") && <Button text={secondaryText} shine style={{width: 150, alignSelf: 'center'}} textOnly onClick={onSecondaryActionHandle}/>}
                <Button disabled={isObjEmpty(selected)} text={getButtonLabel(loadingSubmit)} shine style={{width: 150, alignSelf: 'center'}} onClick={onSubmitHandle}/>
            </div>
        </PopupContainer>
    )
}

const CircularItemList = ({data, onSelect, onEndReached}) => {

    useEffect(()=>{
        let element = document.getElementById('search-list-container')
        element.onscroll = () => (element.scrollHeight - element.scrollTop === element.clientHeight) && onEndReached && onEndReached() 
    },[])

    return ( 
        <>
            {data && data.map((item, idx)=>(
                <SelectableItem key={idx} id={item.id} img={item.resourceImage} 
                title={item.title} subtitle={item.subtitle} tip={item.quantity} 
                callback={()=>onSelect(item)} selected={false}/>
            ))}
        </>
     );
}

export default SearchModal