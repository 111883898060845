import deepProps from "../Utils";

export function reducer(state = {}, action) {

	state["type"] = action.type
	// alert(action.type)
	switch(action.type) {
		case 'REDIRECT':
			state['redirectTo'] = action.to
			break;
		case 'RETRIEVE_SESSION':
			state = deepProps('lastState')(action).getOrElse({})
		break
		case 'OPERATOR_DETAIL':
			state['operatorDetail'] = action.operator
		break
		case 'PROFILE_DETAIL':
			state['profileDetail'] = action.profile
		break
		case 'PERMISSION':
			state["permissions"] = action.response.data
			// return {type : action.type, permissions : action.response}
			break;
		case 'READ_USER':
			state['userAccess'] = action.userAccess
			console.log(action.userAccess)
		break
		case 'LOGIN':
			state["login"] = action.response
			state["token"] = action.response.data
			break;
        case 'PROMOTION':
			state["promotion"] = action.promotion
			break;
        case 'PROMOTION_SEARCH':
			state["promotions"] = action.promotions
			state['pagePromotion'] = action.pagePromotion
			break;
		case 'CHANGE_STATUS_PROMOTION':
			state['promotioThatStatusChanged'] = action.promotionId
			state['promotionStatus'] = action.promotionStatus
		break
		case 'NEW_PROMOTION':
			state["promotionDetails"] = {
				id:0,
				desciption: 'Nova promoção',
				resourceImage: '',
				status: 'CREATING',
				dateStart: new Date(),
				dateEnd: new Date(),
				rules:'A promoção ainda não possui uma regra cadastrada',
				value: '0.0',
				typeValue: 'BY_VALUE',
				stores: []
			}
			break
        case 'PROMOTION_DETAILS':
			state["promotionDetails"] = action.promotionDetails
			break;
		case 'PROMOTION_UPDATE_RESTRICTDAYS':
				state["promotionDetails"] = action.promotionDetails
			break
        case 'PROMOTION_ADDED':
			state["promotionDetails"] = action.promotionDetails
			break;
		case 'PROMOTION_UPDATED':
			state["promotionDetails"] = action.promotionDetails
			break;
		case 'CUSTOMER_SEARCH':
			state['customers'] = action.customers
			break
		case 'CUSTOMER_SEARCH_CLEAN':
			state['customers'] = []
			break
		case 'CUSTOMER_SHOW_MORE':
			state['customers'] = state['customers'].concat(action.customers)
			break
		case 'COUNT':
			state['count'] = action.count
			break
		case  'CUSTOMER_DETAILS':
			state['customerDetails'] = action.customerDetails
		      break
		case 'ADDRESSES_DETAILS':
			state['addressesDetail'] = action.addressesDetail
		break
		case 'CONTACTS_DETAILS':
			state['contactsDetail'] = action.contactsDetail
		break
        case 'STORES_SEARCH':
			state["stores"] = action.stores
			state['pageStore'] = action.pageStore
		  break
		case 'STORE_DETAILS':
			state['storeDetail'] = action.storeDetail
		break
		case 'LOGIN_REQUERIED':
			state = {type:action.type}
		  break
        case 'PROMOTION_ADD_STORE':
            state['storesAdded'] = action.storesAdded
        break
        case 'PROMOTION_ADD_ITEM':
			console.log(action.itemAdded)
            state['itemAdded'] = action.itemAdded
        break
        case 'PROMOTION_REMOVED_STORE':
            state['storesRemoved'] = action.storesRemoved
        break
        case 'PROMOTION_STORE_SEARCH':
            state['promotionsStores'] = action.promotionsStores
        break
        case 'PROMOTION_ITEMS_SEARCH':
            state['promotionsItems'] = action.promotionsItems
        break
        case 'ELASTIC_ITEMS_SEARCH':
            state['elasticItems'] = action.elasticItems
        break
        case 'PRODUCT_IMAGE_UPLOAD':
            state['productImageURL'] = action.productImageURL
		break
		case 'SEARCH_STORE_ADDRESS':
			state['storeAddress'] = action.storeAddress
		break
		case 'CUSTOMER_REGISTERED':
			state['customerRegistered'] = action.customerRegistered
		break
		case 'HOME_REPORT':
			state['homeReport'] = action.homeReport
		break
		case 'PROMOTION_REPORT':
			state['promotionReport'] = action.promotionReport
		break
		case 'ITENS_TIME_REPORT':
			state['itensTimeReport'] = action.itensTimeReport
		break
		case 'USER_TYPE':
			state['userType'] = action.userType
		break
		case 'REDEEM_TYPE_CHANGE':
			state['redeemType'] = !state['redeemType']
			break
		case 'ADD_SEGMENT_PRODUCT_FILTER':
			state['segmentProductFilter'] = action.data
		break
		default:
        
		break;
	}
	return state
}