import { Component } from 'react';
import WebSocketInterface from './WebSocketInterface';
import deepProps from '../../Utils';
// const TIME_TO_TRY = 5
export default class WSInterface extends Component {
    constructor(props) {
        super(props)
        this.websocket = new WebSocketInterface(this.props.endpoint, this.onMessage.bind(this), 1)
    }

    componentDidMount () {
        // console.log(this.props)
        if (!this.props.endpoint) {
            console.error('Endpoint is null')
            return
        }
        let token = deepProps('token')(this.props.store.getState()).get()
        let promotionId = deepProps('promotionId')(this.props).get()
        console.log('Trying to connect to ' + this.props.endpoint)
        this.websocket.conectar(token, promotionId)
        if (this.props.keepAlive) {
            setInterval(() => {
               if (this.websocket)
                this.websocket.send(JSON.stringify({ping:true})) 
            }, 30000);
        }
    }

    componentWillUnmount () {
        console.log('Close connection')
        try {
            this.websocket.closeWS()
        }catch(e){}
    }

    onMessage = (message) => {
        if (this.props.onMessage)
            this.props.onMessage(message)
    }

    render () {
        return ""
    }

}