import React, { useState } from 'react';
import './styles.scss'
import PopupContainer from '../Popup';
import SearchTagSelect from '../SearchTagSelect';
import { getSegmentationProductsReachAPI } from '../../server/segmentation/segmentationAPI';
import { NotificationManager } from 'react-notifications';
import ProductItemRange from './ProductItemRange';
import { ColorEmptyMessage } from '../emptyMessage/emptyMessage';

const ProductReachModal = (props) => { 
    const [selectedItems, setSelectedItems] = useState([])

    const onCloseModal = () =>{
        onAddClickHandle()
        props.onClose && props.onClose(selectedItems)
    }

    const onAddItemHandle = item => {
        return new Promise((accept, reject)=>{
            if(selectedItems.length >= 3) {
                NotificationManager.warning('Escolha no máximo 3 produtos')
                return reject()
            }

            if(selectedItems.find(insertedItem => insertedItem.GTIN == item.GTIN)) {
                NotificationManager.warning('Produto já selecionado')
                return reject()
            }

            getSegmentationProductsReachAPI([item.GTIN]).then(response =>{
                let ranges = response.data.recommenderRange 
                if(!ranges || ranges.length <= 0){
                    NotificationManager.warning('Selecionar esse produto não muda seu público alvo', 'Produto sem compradores calculados')
                    reject()
                    return
                }
 
                item['ranges'] = ranges[0]
                setSelectedItems([...selectedItems, item])
                accept()
                
            }).catch(()=>{
                reject()
            })
        })
    }

    const onSelectRangeHandle = (item) => {
        let {id, selectedChance} = item
        let newSelectedItems = selectedItems.map(selected => {
            if(selected.GTIN === id)
                selected['range'] = selectedChance
            return selected
        })
        setSelectedItems(newSelectedItems)
    }

    const onRemoveItemHandle = async (newList, removeditem) => setSelectedItems(newList, removeditem)

    const onTagsChangeHandle = (selectedItems) => setSelectedItems(selectedItems)

    const onAddClickHandle = () => props.store.dispatch({type: 'ADD_SEGMENT_PRODUCT_FILTER', data: selectedItems})

    const {title} = props

    return (
        <PopupContainer trigger={<></>} style={{backgroundColor:'#fbfcfd', padding: 16}} onClose={() => onCloseModal()} open={props.isVisible}>
            <SearchTagSelect  
                title={title||'Selecione um produto'}
                statusPromotion={'PENDING'}
                searchFor='PRODUCT'
                onAddItem={onAddItemHandle}
                onRemoveItem={onRemoveItemHandle}
                suggestions={props.suggestions}
                // showFeedback
                onChange={(tags)=>onTagsChangeHandle(tags)}
                selectedItem={{products: selectedItems, sectors: [], providers:[]}}
                setSelectedItem={(selected)=>{}}
                />
                <div style={{width: 300, marginLeft: 16, marginTop: -16, marginRight: -16, padding: 16, backgroundColor: '#fff'}}>
                    <h1 className='title' style={{marginBottom: 8}}>Probabilidade de compra</h1>
                    {selectedItems && selectedItems.length == 0 && <ColorEmptyMessage noIcon title='Selecione um produto ao lado' subtitle='E descubra quais clientes gostam dele!'/>}
                    {selectedItems && selectedItems.map((item, index)=>{
                        return <ProductItemRange key={index} id={item.GTIN} style={{marginBottom: 4}} title={item.name} subtitle={item.GTIN} ranges={item.ranges} value={item.range} onSelect={onSelectRangeHandle}/>
                    })}
                </div>
        </PopupContainer>
    )
}

export default ProductReachModal