let pushBehavior = 0;

const registerSW = () => {
    if('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
          navigator.serviceWorker
              .register(`${process.env.PUBLIC_URL}/sw.js`)
              .then(async reg => {
                console.log('Service Worker registered')
              })
              .catch( err => console.log(`Service Worker Error: ${err}`))
      })
    }
}

export default registerSW