import React from 'react';
import './style.scss';
import {User, Home, Percent, Maximize, ShoppingBag} from 'react-feather'
import SmartphoneFrame from '../../component/smartphoneFrame';
import HomeSection from './components/homeSection';
import HighlightedCoupon from '../highlightedCoupon';

const PromoappClone = props => {

    const {sections, banners, highlights} = props

	return (
        <SmartphoneFrame {...props} >
            <div className='promoapp-home'>
                <header>
                    <h1>Meu app</h1>
                    <User/>
                </header>
                <main>
                    <div className='banner'>
                        {banners && banners.length !== 0 && <img src={banners[0] && banners[0].image}/>}
                    </div>
                    <h2>Sempre com você!</h2>
                    <section>
                        {sections && sections.map(section=>
                            (section['status'] === 'PUBLISHED') && <HomeSection style={{width: 100}} {...section} imgStyle={{maxHeight: 100}} key={section.id}/>
                        )}
                    </section>
                    {(!sections || sections.filter(section=>section.status === 'PUBLISHED') == 0) && 
                        <section>
                            <div className='square'></div>
                            <div className='square'></div>
                            <div className='square'></div>
                            <div className='square'></div>
                            <div className='square'></div>
                        </section>
                    }
                    <h2>Promoções em destaque</h2>
                    <section style={{marginTop: -8}}>
                        {highlights && highlights.map(highlight=> <HighlightedCoupon {...highlight} style={{marginRight: 8, minWidth: 260, minHeight: 160}} key={highlight.id}/>)}
                    </section>
                    {(!highlights || highlights.length == 0) && 
                        <section>
                            <div className='rect'></div>
                            <div className='rect'></div>
                            <div className='rect'></div>
                        </section>
                    }
                    
                    <h2>Especiais para você</h2>
                    <section>
                        <div className='rect'></div>
                        <div className='rect'></div>
                        <div className='rect'></div>
                    </section>
                </main>
                <footer>
                    <div className='active'>
                        <Home/>
                        <h1>Home</h1>
                    </div>
                    <div>
                        <Percent/>
                        <h1>Explorar</h1>
                    </div>
                    <div>
                        <Maximize/>
                        <h1>Escanear</h1>
                    </div>
                    <div>
                        <ShoppingBag/>
                        <h1>Meus Cupons</h1>
                    </div>
                </footer>       
            </div>
        </SmartphoneFrame>
	);
}

export default PromoappClone;