import React, { Component } from 'react';
import './profileData.css';
import Button from '../../../component/Button/button';
import OperatorApi from '../../../api/OperatorApi';
import { AlertDialog } from '../../alertDialog/alertDialog';
import deepProps from '../../../Utils';
import SelectableList from '../../SelectableList/SelectableList';
import LoadingMessage from '../../LoadingMessage/loadingMessage';
import { Save } from 'react-feather';

export default class ProfileData extends Component {
	constructor(props){
		super(props)
		this.state = {
			selectedPermissions: {},
			loading : false,
			profile:{
				id:0
			}
		}
	}

	subscribeList(){
		this.loadProfile(this.props.store.getState())
		this.unsubscribe = this.props.store.subscribe(() => {
			let lastState = this.props.store.getState()
			switch(lastState.type) {
				case 'PROFILE_DETAIL':
					this.loadProfile(lastState)
				break
				default:break
			}
		})
	}

	loadProfile = (lastState) => {
		let profile = deepProps('profileDetail')(lastState).getOrElse({})
		this.setState({profile})
	}

	async componentDidMount () {	
		await this.setState({token:localStorage.getItem('token'), searching: true})
		this.loadPermissionsList(localStorage.getItem('token'))
		this.subscribeList()
		this.loadProfileIfExists()
	}

	async loadPermissionsList(token){
		let fullList = await this.getPermissions(token)
		this.setState({list: fullList}, ()=>{
			this.filterList(token)
		})
	}

	getPermissions(token){
		return new Promise((resolve, reject)=>{
			OperatorApi.getPermissions(token,  (response)=>{
				let list = response.data.map(permission=>{return {text: this.translateType(permission.type), value:permission.id, desc: this.translateDescription(permission.description)}})
				resolve(list)
			})
		})
	}

	getPermissionsFromProfile(token, profileId){
		if(!profileId){return []}

		return new Promise((resolve)=>{
			OperatorApi.getProfilesLiberations(token, profileId, (response)=>{
				let profileList = response.data.map(permission=>{ return {text: this.translateType(permission.type), value:permission.id, desc: this.translateDescription(permission.description)}})
				resolve(profileList)
			})
		})
	}

	async filterList(token){
		let profilePermissionsList = await this.getPermissionsFromProfile(token, this.state.profile.id)
		let fullPermissionsList = this.state.list
		let sugestionsPermissionList = await fullPermissionsList.filter(permission => {
			let i = 0
			for (; i < profilePermissionsList.length && profilePermissionsList[i].value !== permission.value; i++);
			return i + 1 > profilePermissionsList.length
		})
		this.setState({profileList: profilePermissionsList, sugestions: sugestionsPermissionList, searching: false})
	}

	loadProfileIfExists(){
		if (this.state.profile === undefined || !this.state.profile.id)
			return
		this.setState({name: deepProps('name')(this.state.profile).getOrElse('')})
	}

	onButtonClick(){
		let {name} = this.state

		if (name === undefined || !name.length || name.length < 1) {
			AlertDialog("Ops! Algo deu errado", 'O campo Nome do perfil deve estar preenchido')
			return
		}

		try{
			let profileId = deepProps('id')(this.state.profile).getOrElse(-1)
			let objectToSend = this.getProfileObject()
			if (this.isNewProfile(profileId)){
				this.createProfile(objectToSend)
			} else {
				this.updateProfile(profileId, objectToSend)
			}
		}catch(erro){
			AlertDialog("Ops! Algo deu errado", erro)
		}
	}

	getProfileObject(){
		return {"name": this.state.name}
	}

	isNewProfile(profileId){return profileId === -1}

	async createProfile(objectToSend){
		try{
			let newProfile = await OperatorApi.createProfile(this.state.token, objectToSend)
			newProfile['permissions'] = await this.getPermissionsArray()
			await OperatorApi.insertPermissionToProfile(this.state.token, newProfile.permissions, newProfile.data.id)
			this.props.store.dispatch({type:'PROFILE_DETAIL', profile:newProfile.data})
			AlertDialog("Sucesso!", "Perfil criado com sucesso")
		}catch(error){
			AlertDialog("Ops, Algo deu errado!", error)
		}
	}

	getPermissionsArray(){
		return this.state.profileList.map(profile=>profile.value)
	}

	async updateProfile(profileId, objectToSend){
		this.setState({loading: true})
		await OperatorApi.updateProfile(this.state.token, profileId, objectToSend)
		let profile = await this.getStateProfile()
		this.props.store.dispatch({type:'PROFILE_DETAIL', profile})
		AlertDialog("Sucesso!", " Perfil alterado com sucesso")
		this.setState({loading:false})
	}

	async getStateProfile(){
		return {name: this.state.name, id: this.state.profile.id, permissions: await this.getPermissionsArray()}
	}

	async addNewPermission(item, removedList, insertedList){
		await OperatorApi.insertPermissionToProfile(this.state.token, [item.value], this.state.profile.id)
		this.setState({profileList: insertedList,sugestions:removedList})
	}

	async removePermission(item, removedList, insertedList){
		await OperatorApi.deletePermissionFromProfile(this.state.token, this.state.profile.id, item.value)
		this.setState({profileList: removedList,sugestions:insertedList})
	}

	async onSelectFilter(profile){
		await this.setState({selectedPermissions: [profile, ...this.state.selectedPermissions]})
	}

	async onDeleteClick(){
		try{
			this.setState({loading:true})
			let profileId = this.state.profile.id
			await OperatorApi.deleteProfile(this.state.token, profileId)
			AlertDialog("Sucesso!", "Perfil removido com sucesso")
			this.props.store.dispatch({type:'PROFILE_DETAIL', profile:{name:"Novo perfil", permissions: []}})
			//Go to search
		}catch{
			AlertDialog("Ops, algo deu errado!", "Não foi possível excluir o perfil, tente novamente mais tarde.")
			this.loadProfileIfExists()
		}finally{
			this.setState({loading:false})
		}		
	}

	render() {
		let {profile} = this.state
		return (
			<form className="profile-data">
				{this.state.loading && 
					<LoadingMessage title='Salvando...' description='Por favor, aguarde...' />
				}
				<div className="profileData-content">
					<>
						<label className="profileData-name" style={{fontWeight: '700'}}>Nome do perfil
							<input value={this.state.name} maxLength={255} placeholder="Digite o nome do perfil" onChange={(field)=>this.setState({name:field.target.value})} style={{marginBottom: 8}}></input>
						</label>
						{(this.state.searching && this.state.profileList) && <LoadingMessage title='Buscando perfis' description='Aguarde um instante...' />}
						{profile.id > 0 && <SelectableList 
							leftTitle="Permissões do perfil" leftItens={this.state.profileList} rightTitle="Sugestões de permissões" 
							rightItens={this.state.sugestions} 
							moveItemToRight={(item, removedList, insertedList)=>this.removePermission(item, removedList, insertedList)} 
							moveItemToLeft={(item, removedList, insertedList)=>this.addNewPermission(item, removedList, insertedList)} 
							leftEmptyTitle={"Nenhuma permissão"} leftEmptySubtitle={"Não há permissões"} 
							rightEmptyTitle={"Nenhuma sugestão"} rightEmptySubtitle={"Não há sugestões disponíveis"}
							edit={this.props.write}/>
						}
					</>
					</div>
					{this.props.write && <Button text='Salvar' icon={<Save/>} style={{maxWidth: 75, bottom: 16, position: 'absolute', right: 16}} onClick={()=>this.onButtonClick()}/>}
			</form>
		);
	  }
	  
	  translateDescription(desc){
		if(desc === 'PERMISSION TO READ DATE OF CUSTOMER') {
			return 'Permissão de ler dados de cliente'
		}

		if(desc === 'PERMISSION INSERT OR UPDATE A DATE OF PROMOTION'){
			return 'Permissão de inserir ou alterar dados de promoção'
		}

		if(desc === 'PERMISSION TO READ DATE OF PERMISSION'){
			return 'Permissão de ler dados de permissão'
		}

		if(desc === 'PERMISSION TO READ DATE OF PROMOTION'){
			return 'Permissão de ler dados de promoção'
		}

		if(desc === 'PERMISSION INSERT OR UPDATE A DATE OF STORES'){
			return 'Permissão de inserir ou alterar dados de lojas'
		}

		if(desc === 'PERMISSION TO READ DATE OF STORES'){
			return 'Permissão de ler dados de lojas'
		}

		if(desc === 'PERMISSION INSERT OR UPDATE A DATE OF PROFILE'){
			return 'Permissão de inserir ou alterar dados de perfis'
		}

		if(desc === 'PERMISSION TO READ DATE OF PROFILE'){
			return 'Permissão de ler dados de perfis'
		}

		if(desc === 'PERMISSION INSERT OR UPDATE A DATE OF OPERATOR'){
			return 'Permissão de inserir ou alterar dados de operador'
		}

		if(desc === 'PERMISSION TO READ DATE OF OPERATOR'){
			return 'Permissão de ler dados de operador'
		}

		if (desc === 'PERMISSION TO READ DATE OF APP CONFIG SCREEN')
			return 'Permissão de ler dados de configuração do aplicativo'
		
		return desc
	}

	translateType(type){
		if(type === 'R'){
			return 'Ler'
		}

		if(type === 'W'){
			return 'Inserir/Alterar'
		}
	}
}