import React, { useState, useEffect } from 'react';
import Card from '../../card';
import './style.scss'
import BaseLineChart from '../../charts/lineChart';
import KPIInfo from '../info';
import deepProps, { getIncrease } from '../../../Utils';
import Tooltip from '../tooltip';
import ExportKPIData from '../exportKPIData'

export default function SalesKPI (props){

    const [mappedData, setMappedData] = useState([])
    const [identifiedAmount, setIdentifiedAmount] = useState(0)
    const [unidentifiedAmount, setUnidentifiedAmount] = useState(0)
    const [pastIdentifiedAmount, setPastIdentifiedAmount] = useState(0)
    const [pastUnidentifiedAmount, setPastUnidentifiedAmount] = useState(0)
    const {data, detailed, compare, filter, tooltipSubtitle, loading} = props

    const ONLY_CURRENT_LINE = [{label: 'Vendas Identificadas', value:'identified_c'}, {label:'Vendas Não Identificadas',value:'unidentified_c'}]
    const COMPARE_LINES = [...ONLY_CURRENT_LINE, {label: 'Vendas Identificadas (Período Passado)',value:'identified_p'}, {label: 'Vendas Não Identificadas (Período Passado)',value:'unidentified_p'}]

    useEffect(()=>{
        if(data.length === 0) return
        loadChartData(data)
    },[data])

    const loadChartData = ({identifiedSales, unidentifiedSales}) => {
      let mappedIdentified = joinSalesPeriod(identifiedSales, 'dateReference', 'identified')
      let mappedUnidentified = joinSalesPeriod(unidentifiedSales, 'dateReference', 'unidentified')

      setIdentifiedAmount(identifiedSales.current.general.quantity)
      setUnidentifiedAmount(unidentifiedSales.current.general.quantity)
      setPastIdentifiedAmount(deepProps("past")("general")("quantity")(identifiedSales).getOrElse(0))
      setPastUnidentifiedAmount(deepProps("past")("general")("quantity")(unidentifiedSales).getOrElse(0))
      setMappedData(joinSales(mappedIdentified, mappedUnidentified, 'dateReference'))

      return mappedIdentified
    }

    const getDataSortByDateReference = (data) => data.sort((a,b)=>a.dateReference - b.dateReference)

    const joinSalesPeriod = (data, unionKey, label) => {
      let {current, past} = data
      past = past ? past.dates : []
      current = current ? current.dates : [] 

      let chartData = current.map((currentData)=>{
        let foundIndex = past.findIndex(pastData=> pastData[unionKey]===currentData[unionKey])
        return {[unionKey]: currentData[unionKey], [`${label}_c`]: currentData.quantity, [`${label}_p`]: (foundIndex != -1) ? past.splice(foundIndex, 1)[0].quantity : 0}
      })

      past = past.map(item => ({[unionKey]: item[unionKey], [`${label}_p`]: item.quantity, [`${label}_c`]: 0}))
      chartData = [...chartData, ...past]
      return getDataSortByDateReference(chartData)
    }

    const joinSales = (identifiedSales, unidentifiedSales, unionKey) => {
      let mappedData = identifiedSales.map(identifiedItem => {
        let ref = identifiedItem[unionKey]
          let foundIndex = unidentifiedSales.findIndex(unidentifiedItem=> unidentifiedItem[unionKey] === identifiedItem[unionKey])
          let unidentifiedObj = (foundIndex === -1) ? {unidentified_c: 0, unidentified_p: 0} :  unidentifiedSales[foundIndex]
          return {[unionKey]: ref, ...identifiedItem, ...unidentifiedObj}
      })
      return mappedData
    }

    const getDataTip = (increase, past) => {
      if(increase === 0) return `Igual à ${past}`
      return formatIncrease(increase) + " a " + (increase > 0 ? 'mais' : 'menos') + ' que ' + past
    }

    const formatIncrease = increase => ((increase && increase !== Infinity) ?  Math.abs(increase.toFixed(1)) : 0) + '%'

    return (
        <Card style={{padding: 0, overflow: 'hidden', minWidth: 500, flex: 2}} loading={true}
          header={{
            title:'Minhas Vendas',
            actions:[
              <ExportKPIData data={mappedData} columns={compare ? COMPARE_LINES : ONLY_CURRENT_LINE} fileName={'crm_sales_report'} name={'Vendas Identificadas e Não identificadas'} filter={filter}/>
            ]
          }}>
            <div style={{display:'flex', flex: 1, paddingBottom: 32, paddingTop: 32}}>
                 <KPIInfo title='Vendas Identificadas' value={identifiedAmount} loading={loading} increase={compare && getIncrease(pastIdentifiedAmount, identifiedAmount)} increaseTip={getDataTip(getIncrease(pastIdentifiedAmount, identifiedAmount),'1 ' + filter.text + ' atrás')} color="#ff68a1"/>
                 <KPIInfo title='Vendas Não Identificadas' value={unidentifiedAmount} loading={loading} increase={compare && getIncrease(pastUnidentifiedAmount, unidentifiedAmount)}  increaseTip={getDataTip(getIncrease(pastUnidentifiedAmount, unidentifiedAmount),'1 ' + filter.text + ' atrás')} style={{marginLeft: 16}} color="#2196f3"/>
                 {compare && <KPIInfo title="Vendas Identificadas" value={pastIdentifiedAmount} loading={loading} increase={false} past={'1 ' + filter.text + ' atrás'} color="#ffa600"/>}
                 {compare && <KPIInfo title="Vendas Não Identificadas" value={pastUnidentifiedAmount} loading={loading} increase={false} past={'1 ' + filter.text + ' atrás'}  style={{marginLeft: 16}} color="#97e000"/>}
             </div>
             <BaseLineChart hideGrid={!detailed} hideX={!detailed} hideY={!detailed} x={"dateReference"} data={mappedData}
              lines={compare ? COMPARE_LINES : ONLY_CURRENT_LINE} 
              customTooltip={
                <Tooltip 
                  title={"Quantidade de vendas"}
                  subtitle={tooltipSubtitle}
                  labels={{identified_c: 'Identificadas', identified_p: 'Identificadas 1 ' + filter.text + ' atrás', unidentified_c: 'Não identificadas', unidentified_p: 'Não identificadas 1 ' + filter.text + ' atrás' }}
                />
              }
            />
        </Card>
    )
}