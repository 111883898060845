import React from 'react';
import './style.scss'

export default function Tooltip ({active, payload, label, title, subtitle, labels}){
    return (
        <div className='tooltip-container'>
            <h1>{title}</h1>
            {subtitle && <h2>{subtitle(payload)}</h2>}
            <ul>
                {payload && payload.map((item, idx)=>{
                    return (
                        <li key={idx}>
                            <div className='subtitle' style={{backgroundColor: item.color}}></div>
                            <h1>{labels[item.name]}: <span>{item.value}</span></h1>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}