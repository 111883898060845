import React, { Component } from 'react';
import SelectableItem from '../../selectableItem/selectableItem'
import Button from '../../Button/button';
import EmptyMessage from '../../emptyMessage/emptyMessage'
import Toggle from '../../toggle/toggle';
import ItemApi from '../../../api/ItemApi'
import './itens.css';
import deepProps, { getMeasurementLabel } from '../../../Utils'
import { Redirect } from "react-router-dom"
import { AskDialog, AlertDialogWithConfirm } from '../../alertDialog/alertDialog';
import LoadingMessage from '../../LoadingMessage/loadingMessage';
import WSInterface from '../../websocket/WSInterface';
import SearchModal from '../../SearchModal';
import { searchAPI, getItemByIdAPI } from '../../../server/search/searchAPI';
import { getPromotionNewItemBody } from '../../../server/Promotion/promotionBody';
import { addPromotionItemAPI, addNewItemAPI, updateElasticItem } from '../../../server/Promotion/promotionAPI';
import { NotificationManager } from 'react-notifications';
import {INITIAL_WS} from '../../../server/endpoints_js'
import { highlightPromotionItemAPI, unhighlightPromotionItemAPI } from '../../../server/highlight/highlightedAPI';
import EditModal from '../../EditModal';
const hostWS = INITIAL_WS + 'ws/link-user-promotion'

class Itens extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
            redirect:false, selectAll:false,
            selecteds:[],selected:null,promotion:null,searching:true,
		};
        this.loadItens = this.loadItens.bind(this)
        this.SEARCH_FILTERS = [{text:'Produtos', value:'products'},{text:'Setores', value:'sectors'},{text:'Fornecedores', value:'supliers'}]
    }

	componentDidMount() {
        this.loadItens()
        this.unsubscribe = this.props.store.subscribe(() => {
            let lastState = this.props.store.getState()
            let promotion = undefined
            switch(lastState.type) {
                // case 'CHANGE_SEGMENTATION':
                //     this.loadItens()
                //     break
                case 'PROMOTION_ADD_ITEM':
                    lastState['promotionDetails']['items'].push(lastState['itemAdded'])
                    this.props.store.dispatch({type:'PROMOTION_UPDATED', promotionDetails:lastState['promotionDetails']})
                    break
                case 'PROMOTION_ITEMS_SEARCH':
                    let promotionItems = deepProps('promotionsItems')(lastState).getOrElse([])
                    promotionItems.map(item=>{
                        switch (item.type){
                            case 'PRODUCT':
                                item["subtitle"] = (item.products[0]) ? item.products[0].eanCode : 'Sem GTIN definido'
                                item["GTIN"] = (item.products[0]) ? item.products[0].eanCode : 'Sem GTIN definido'
                                break;
                            case 'SECTOR':
                                item["subtitle"] = 'Setor'
                                break;
                            case 'PROVIDER':
                                item["subtitle"] = 'Fornecedor'
                                break;
                            default:
                                item["subtitle"] = 'Indefinido'
                        }
                        return item
                    })
                    let promotion = deepProps('promotionDetails')(lastState).getOrElse([])
                    promotion['items'] = promotionItems
                    this.props.store.dispatch({type:'PROMOTION_DETAILS', promotionDetails:promotion})
                    this.setState({items:promotionItems})
                break
                case 'PROMOTION_UPDATED':
                case 'CHANGE_STATUS_PROMOTION':   
                    promotion = deepProps('promotionDetails')(lastState).get()
                    if (promotion !== undefined) {
                        let promotionId = deepProps('id')(promotion).getOrElse(-1)
                        let promotioThatStatusChanged = deepProps('promotioThatStatusChanged')(lastState).getOrElse(0)
                        if (promotionId === promotioThatStatusChanged)
                            promotion['status'] = deepProps('promotionStatus')(lastState).getOrElse('UN_KNOW')
                        this.setState({promotion})
                    }
                break;
                case 'PROMOTION_DETAILS':
                    promotion = deepProps('promotionDetails')(lastState).get()
                    this.setState({promotion})
                    break;
                default:break
            }
        })
    }

    componentWillUnmount () {
        if(this.unsubscribe) this.unsubscribe()
    }
    
    loadItens(){
        let lastState = this.props.store.getState()
        let token = deepProps('token')(lastState).getOrElse(null)
        let promotion = deepProps('promotionDetails')(lastState).getOrElse(null)
        if (promotion == null) {
            this.setState({redirect:true})
            return
        }
        this.setState({promotion})
		this.props.store.dispatch(ItemApi.findItemsPromotion(token, promotion.id, (response) => {
            this.setState({searching: false})
        }, (errorMessage) => {
            NotificationManager.error(errorMessage,'Ops, algo deu errado')
        }))
    }
    
    selectAll(active){
        this.setState({selectAll:active})
        let selecteds = this.state.items.map((item) => {
            item['selected'] = active
            if (active)
                return item.id
            return null
        }).filter((i) => i !== null)
        this.setState({selecteds})
    }
    
    toggleItemSelecteds(item){
        let selecteds = this.state.selecteds
        let result  = selecteds.filter((s) => s !== item.id)
        if (result.length < selecteds.length) {
            selecteds = result
            item['selected'] = false
        }else {
            selecteds.push(item.id)
            item['selected'] = true
        }
        this.setState({selecteds, selectAll: (this.state.items.length === selecteds.length)})
    }

    dispatchSelected() {
        let selectedItems = this.state.items.map((item) => {
            let GTIN = deepProps('GTIN')(item).getOrElse(null)
            if (GTIN === null)
                return null
            return GTIN
        }).filter((item) => item !== null)
        this.props.store.dispatch({type:'SELECTED_ITEMS_PROMOTION_MODAL', selectedItems})
    }

    confirmDelete(item){
        AskDialog('Esse item será deletado!', 'deseja realmente deletar o item ' + item.description + '?', ()=>this.deleteItem(item))
    }
    
    editItem(item){
        if(item.type === 'SECTOR'){
            item['sectorId'] = Object.values(item.sectors[0]).join('_')
        }
        this.setState({selected:item})
    }

    deleteItem(item){
        let lastState = this.props.store.getState()
        let token = deepProps('token')(lastState).getOrElse(null)
        this.props.store.dispatch(ItemApi.removeItem(token, deepProps('id')(item).getOrElse(0), (response) => {
            let itemId = deepProps('data')(response).getOrElse(0)
            let items = this.state.items.filter((item) => item.id !== itemId)
            lastState['promotionDetails']['items'] = items
            this.props.store.dispatch({type:'PROMOTION_UPDATED', promotionDetails:lastState['promotionDetails']})
            this.setState({items})
        }, (error) => {
            NotificationManager.error(JSON.stringify(error), 'Erro inesperado!')
        }))
    }
    
    deleteSelecteds(){
        AskDialog('Deseja realmente remover todos?', 'Ao confirmar, todos os itens selecionados serão removidos da promoção.', ()=>{
            let lastState = this.props.store.getState()
            let token = deepProps('token')(lastState).getOrElse(null)
            let promotionId = deepProps('id')(this.state.promotion).getOrElse(0)
            if (promotionId === null) {
                this.AlertDialog('Promoção não encontrada!','Não foi possível concluir a operação!')
                return
            }
    
            this.props.store.dispatch(ItemApi.removeItems(token, promotionId, this.state.selecteds, () => {
                let result = this.state.selecteds
                let items = this.state.items.filter((item) => result.filter(r => r !== item.id).length === result.length)
                lastState['promotionDetails']['items'] = items
                this.props.store.dispatch({type:'PROMOTION_UPDATED', promotionDetails:lastState['promotionDetails']})
                this.setState({items, selecteds:[], selectAll:false})
            }, (errorMessage) => {
                NotificationManager.error(errorMessage,'Ops, algo deu errado')
            }))
        })
    }
    
    newItem () {
        this.dispatchSelected()
    }
    
    preLoadItens () {
        this.setState({selected:null, selecteds:[], selectAll:null})
        this.loadItens()
    }

    onHighlightItemClick(item){
        if(!item.resourceImage && !item.highlighted) return NotificationManager.info('Adicione uma imagem ao item', 'Quase lá!')
            
        if(!item.highlighted && this.state.promotion.segmentationType !== 'PUBLIC') return NotificationManager.warning('Apenas itens de promoções públicas podem estar em destaque', 'Promoção Segmentada!')

        if(this.state.highlighting) return
        this.setState({highlighting: true})

        let title = (item.description.length > 16) ? `${item.description.substring(0,16)}...` : item.description

        if(!item.highlighted){
            highlightPromotionItemAPI(item.id).then(response=>{
                item.highlighted = true
                NotificationManager.success(`${title} agora aparece na aba de destaques!`, 'Sucesso!')
            }).catch(e=>{
                NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
                console.error(e)
            }).finally(()=>this.setState({highlighting: false}))
        }else{
            unhighlightPromotionItemAPI(item.id).then(response=>{
                item.highlighted = false
                NotificationManager.success(`${title} agora aparece no Explorar!`, 'Sucesso!')
            }).catch(e=>{
                NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
                console.error(e)
            }).finally(()=>this.setState({highlighting: false}))
        }
    }

    makeItems = (status, write = false, item, segmentationType, canSegmentation = false) => {
        if  (status === undefined || item === undefined)
            return ""

        switch(status) {
            case 'PENDING':
                if (write) 
                    return <SelectableItem selected={deepProps('selected')(item).getOrElse(false)} canUnSelected 
                        key={item.id} title={item.description} id={item.id}  
                        subtitle={item.subtitle} {...item} tip={item.quantity + ' ' + getMeasurementLabel(item.measurementUnit)} img={item.resourceImage} 
                        alt={'imagem da ' + item.description} 
                        callback={()=>this.toggleItemSelecteds(item)} actions delete={()=>this.confirmDelete(item)} edit={()=>this.editItem(item)} />
                
                return <SelectableItem selected={deepProps('selected')(item).getOrElse(false)} canUnSelected 
                    key={item.id} title={item.description} id={item.id}
                    subtitle={item.subtitle} {...item} tip={item.quantity + ' ' + getMeasurementLabel(item.measurementUnit)} img={item.resourceImage} 
                    alt={'imagem da ' + item.description} 
                    callback={()=>this.toggleItemSelecteds(item)}  actions />
            case 'PUBLISHED':
                return <SelectableItem selected={deepProps('selected')(item).getOrElse(false)} canUnSelected key={item.id} title={item.description} id={item.id} 
                        subtitle={item.subtitle} {...item} tip={item.quantity + ' ' + getMeasurementLabel(item.measurementUnit)} img={item.resourceImage} alt={'imagem de ' + item.description} 
                        callback={()=>this.toggleItemSelecteds(item)} actions 
                        edit={write ? ()=>this.editItem(item) : false} data={item}
                        highlighted={item.highlighted}
                        onHighlightClick={(id)=>{this.onHighlightItemClick(id)}} 
                        editable={write}/>
            case 'EXPIRED':
            case 'DEPRECATED':
                return <SelectableItem selected={deepProps('selected')(item).getOrElse(false)} canUnSelected key={item.id} title={item.description} id={item.id} 
                        subtitle={item.subtitle} {...item} tip={item.quantity + ' ' + getMeasurementLabel(item.measurementUnit)} img={item.resourceImage} alt={'imagem de ' + item.description} 
                        callback={()=>this.toggleItemSelecteds(item)} actions/>
            default:break
        }
    }

    getMeasurementLabel(measurementUnit){return (measurementUnit == 'WEIGHTED') ? 'Kg' : 'Un'}

    onSegmentationStart = (item) => {

        let {items} = this.state
        if (item) {
            for (let i of items) {
                if (i.id === item.id) {
                    i.segmentation = {
                        done : false,
                        itemPromotionId : item.id
                    }
                }
            }
        }
        this.setState({items})
    }
    segmentationResult = (item, result) => {
        let {items} = this.state
        if (result.status !== 0 && items) {
            for (let i of items) {
                if (i.id === item.id)
                    i.segmentation = undefined
            }
        }
        this.setState({items})

    }
    onMessage = (message) => {
        let {store} = this.props
        let promotionDetails = deepProps('promotionDetails')(store.getState()).get()
        if (store === undefined)
            return
        let result = JSON.parse(message)
        let status = result['status']
        switch(status) {
            case 0:
                    let response = deepProps('data')(result).getOrElse({})
                    if (response.source === 'RECOMMENDATION') {
                        let items = deepProps('fastPromotion', 'items')(response).getOrElse([])
                        if (promotionDetails !== undefined) {
                            promotionDetails['segmentationType'] = deepProps('fastPromotion', 'segmentationType')(response).getOrElse('PUBLIC')
                            store.dispatch({type:'PROMOTION_DETAILS', promotionDetails})
                        }
                        store.dispatch({type:'PROMOTION_ITEMS_SEARCH', promotionsItems:items})
                    }
                break;
            case 13:
                AlertDialogWithConfirm('Atenção', 'Outra sessão foi iniciada com o seu usuário em outro dispositivo', () => {
                    store.dispatch({type:'REDIRECT', to:'search-promotion'})
                })
                break;
            default:
                    NotificationManager.error('Tente novamente mais tarde!','Ops, algo deu errado')
                break
        }
    }

    mapResponse(response) {
        switch (response._index) {
            case 'products':{
                let {GTIN, measurementUnit, productId, name, resourceImage, description} = response._source
                if (!name)
                    name = description
                return {id:GTIN, eanCode:GTIN, measurementUnit, productId, resourceImage, title: name, subtitle: GTIN, type: response._index, object:response._source}
            }
            case 'supliers':{
                let {id, name, tradingName, resourceImage} = response._source
                return {id, name, tradingName, title: tradingName, subtitle: name, resourceImage, type: response._index, object:response._source}
            }
            case 'sectors':{
                let {description} = response._source
                return {...response._source, id: response._id, title: description, subtitle: response._id.replace(/\_/g, ' => '), type: response._index, object:response._source}
            }
            default:
                return response
        }
    }

    async onItemSubmit(item, alreadyExist = false) {
        if(!alreadyExist){
            item['description'] = item.title
            let {imageURL, resourceImage} = item
            if (!imageURL && resourceImage) {
                imageURL = {url:resourceImage}
                item['imageURL'] = imageURL
            }
            switch (item.type){
                case 'products':
                    item['products'] = [{eanCode:item.eanCode}]
                    item['type'] = "PRODUCT"
                    break;
                case 'supliers':
                    item['providers'] = [{providerId:item.id}]
                    item['type'] = "PROVIDER"
                    break;
                case 'sectors':
                    let {sector1, sector2, sector3, sector4, sector5} = item
                    item['sectors'] = [{sector1, sector2, sector3, sector4, sector5}]
                    item['type'] = "SECTOR"
                    break;
            }
        }
        try{
            if(!item.measurementUnit) item.measurementUnit = 'UNITARY' 
            
            if (!item || (!item.description && !item.displayName)) {
                return NotificationManager.warning('Atenção', 'Descrinção ou o nome de exibição precisa estar preenchido.')
            }

            let response = await addPromotionItemAPI(this.state.promotion.id,getPromotionNewItemBody(item))
            this.props.store.dispatch({type:'PROMOTION_ADD_ITEM', itemAdded:response.data})
            NotificationManager.success('Novo promovido incluído', 'Sucesso!')
        }catch(e){
            NotificationManager.error('Tente novamente mais tarde', 'Inclusão')
        }
    }

    
    updateObject (selected) {
        if (selected === undefined)
            return
        let {newResourceImage, id} = selected
        let object = selected['object'] ? selected['object'] : selected
   
        if (object && id && newResourceImage) {
            let {url} = newResourceImage
            let {resourceImage} = object
            if (!url || resourceImage === url)
                return
            object['resourceImage'] = url
            if (false)
                updateElasticItem(id, object, selected['type'])
        }
    }


    async onSecondaryHandle(item){
        try{
            let response = await getItemByIdAPI(item.identifier)
            if(response.found) {
                let {_source} = response
                if (_source === undefined || item['identifier'] === undefined)
                    throw new Error('source not found in database')
                let {measurementUnit, name} =_source
                if (measurementUnit)
                    item['measurementUnit'] = measurementUnit
                if (name)
                    item['description'] = name
                item['products'] = [{eanCode:item.identifier}]
                item['type'] = 'PRODUCT'
                if (!item['imageURL'] && _source['resourceImage'])
                    item['imageURL'] = {url:_source['resourceImage']}
                await this.onItemSubmit(item, true)
                return
            }
            item['products'] = [{eanCode:item.identifier}]
            const body = getPromotionNewItemBody(item)
            const request = await addNewItemAPI(this.state.promotion.id, body)
            //Adicionar a promoção
            NotificationManager.success('Novo promovido incluído', 'Sucesso!')
        }catch(e){
            console.error(e)
            NotificationManager.error('Tente novamente mais tarde', 'Ops, algo deu errado!')
        }
    }

    render() {
        let {write, read, store} = this.props
        let promotionId = 0
        let {
            redirect, promotion,
            items, selecteds, selectAll, searching
        } = this.state
        if (promotion !== null && promotion !== undefined && promotion.id !== undefined && !isNaN(promotion.id))
            promotionId = promotion.id

        if (redirect) {
            return <Redirect to = '/main/home/general' />
        }

    return (
        <div className="itens-content">
            {
                ['PENDING', 'PUBLISHED'].includes(deepProps('status')(promotion).getOrElse('UNKNOW')) && 
                <div className="options-bar">
                    <Toggle  disable={!(items.length > 0 && deepProps('status')(promotion).getOrElse('UNKNOW') === 'PENDING' && write)} switch small active={(items.length > 0 && items.length === selecteds.length)} 
                        text='Selecionar todos' 
                        onClick={(state)=>this.selectAll(state)}/>
                    <div className='itens-row'>
                        {((selectAll || (selecteds.length > 0)) && 
                            deepProps('status')(promotion).getOrElse('UNKNOW') === 'PENDING') && 
                                write &&
                            <Button onClick={()=>this.deleteSelecteds()} 
                                invert text={(selecteds.length > 1) ? 'Remover selecionados' : 'Remover selecionado'} style={{marginRight: 8}}/>}
                        {(items.length === 0 || this.state.promotion.promotionPattern !== 'VOUCHER' && write) && <SearchModal 
                            trigger={<Button text='Novo Promovido'/>} 
                            submitText='Promover'
                            secondaryText='Novo item'
                            filters={this.SEARCH_FILTERS} selectedFilter={this.SEARCH_FILTERS[0]}
                            onFilterChange={filters => {}}
                            search={(filters, page) => searchAPI(filters.input, filters.filter, page)}
                            mapResponse={response=>this.mapResponse(response)}
                            updateObject={selected=>this.updateObject(selected)}
                            onSubmit={item=> this.onItemSubmit(item)}
                            onSecondaryClick={item => {this.onSecondaryHandle(item)}}
                            onClose={() => this.preLoadItens()}>
                        </SearchModal>}
                        <EditModal  
                            trigger={<></>} 
                            statusPromotion={deepProps('status')(promotion).getOrElse('UNKNOW')}
                            selectedItem={this.state.selected}
                            updateObject={selected=>this.updateObject(selected)}
                            setSelectedItem={(selected)=>{this.setState({selected})}}
                            filters={this.SEARCH_FILTERS} selectedFilter={this.SEARCH_FILTERS[0]}
                            onClose={() => this.preLoadItens()}/>
                </div>
            </div>}
            <div className="list-itensProduct">
				{
                    searching && 
                    <LoadingMessage title='Pesquisando itens...' 
                        description='Aguarde, estamos buscando itens!' />
                }
                {
                    (items.length === 0 && !searching) && 
                        <EmptyMessage title='Nenhum item selecionado' 
                            description='Esta promoção ainda não possui nenhum item selecionado!'
                            icon='empty-box'/>
                }
                {
                    items.map((item, index) =>{
                        return(
                            <div key={index}>
                                {
                                    this.makeItems(promotion.status, write, item, promotion.segmentationType)
                                }
                            </div>
                        )
                    })
                }
            </div>
            {promotionId > 0 && write && (promotion.status === 'PENDING' || 
                (promotion.status === 'PUBLISHED' && promotion.segmentationType === 'BY_IDENTIFIER')) &&
                <WSInterface 
                    endpoint={hostWS} 
                    onMessage= {this.onMessage.bind(this)} 
                    write={write} read = {read} 
                    store={store} promotionId={promotionId} keepAlive={true} />}
    	</div>
    );
  }
}
export default Itens;