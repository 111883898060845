import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import OperatorData from '../../component/cardTabOperator/operatorData/operatorData';
import './cardTabOperator.css';

class cardTabOperator extends Component {

	render() {
		return (
			<Router>
				<div className="cardTabOperator">
					<ul className="header-cardTabOperator">					
						<li className="option-cardTabOperator" >
							<NavLink to="/main/operator/data" activeClassName="activeTab"> Operador </NavLink>
						</li>
					</ul>

					<div className="route-operator">
						<Route path="/main/operator/data" component={() => <OperatorData store={this.props.store} write={this.props.write} read={this.props.read}/>} />
					</div>
				</div>
			</Router>
		);
	}
}

export default cardTabOperator;